import {
  Card,
  Typography,
  Toolbar,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  MenuItem,
} from "@mui/material";
import { ProjectPropertiesState } from "..";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import FormValidators from "src/helpers/form-validators";
import { useState } from "react";
import { axiosInstance } from "src/services/AxiosService";
import ApiPath from "src/constants/ApiPath";
import LabelCell from "src/components/LabelCell";

export const CloudProviders = {
  aws: "aws",
  // gcp: "gcp",
};

const DataStreams = ({ classes, project }: ProjectPropertiesViewProps & ProjectPropertiesState) => {
  const [testLoading, setTestLoading] = useState(false);

  const runTestFunc = async (id: string) => {
    try {
      setTestLoading(true);
      const command = `test --bucket --projectId=${id}`;
      const res = await axiosInstance.post(`${ApiPath.api.adminUtils.adminCmd}/${encodeURIComponent(command)}`);
      alert(`Storage test: ${res.data.message}`);
      setTestLoading(false);
    } catch (error) {
      setTestLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Card className={classes.card} style={{ padding: 0 }}>
        <Toolbar>
          <Typography variant="subtitle1">Cloud Storage</Typography>
        </Toolbar>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: 220 }}>
                <LabelCell label="Cloud Platform" />
              </TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  select={true}
                  SelectProps={{ autoWidth: true }}
                  name="clientStorage.cloudPlatform"
                  variant="standard"
                >
                  {Object.keys(CloudProviders).map((key: any, i: number) => (
                    <MenuItem key={i} value={CloudProviders[key]}>
                      {CloudProviders[key]}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.labelCell}>
                <LabelCell
                  label="Bucket Region"
                  tooltip="The AWS Region Code where an S3 bucket is created and stored, e.g., us-east-2"
                />
              </TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" name="clientStorage.bucketRegion" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Bucket Name</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" name="clientStorage.bucketName" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Credentials (JSON)</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="clientStorage.credentials"
                  multiline={true}
                  rows="4"
                  resizeY={true}
                  validate={FormValidators.composeValidators(FormValidators.isValidJson)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button disabled={testLoading} color="primary" onClick={() => runTestFunc(project._id)}>
                  Test
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

export default DataStreams;
