import * as React from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Error from "@mui/icons-material/Error";
import { NavbarHeight } from "../../../styles/Constants";

class AppError extends React.Component<WithStyles<"errorContainer" | "errorIcon">> {
  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <div className={classes.errorContainer}>
        <Error className={classes.errorIcon} />
      </div>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    errorContainer: {
      width: "100%",
      height: `calc(100% - ${NavbarHeight}px)`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    errorIcon: {
      color: "#a22a21",
      fontSize: 70,
    },
  });

const decorate = withStyles(styles);

export default decorate(AppError);
