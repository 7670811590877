import { Grid, Button, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router";
import { ReactSVG } from "react-svg";

import TextFieldControl from "../../../components/form-elements/TextFieldControl";
import FormValidators from "../../../helpers/form-validators";
import { axiosInstance } from "../../../services/AxiosService";
import ApiPath from "../../../constants/ApiPath";
import CheckboxControl from "../../../components/form-elements/CheckboxControl";
import { SetNotification } from "../../../actions/notificationAction";
import store from "../../../store";
import LoaderIcon from "../../../assets/images/loading-progress.svg";
import { AuthRoutes } from "../../../constants/routes/auth-routes";
import { reportError } from "src/services/ErrorService";

interface ISignupForm {
  email: string;
  password: string;
  company: string;
  acceptTerms: boolean;
  termsDate?: Date;
}

const useStyles = makeStyles(
  createStyles({
    root: {
      "& .MuiInputBase-input": {
        paddingTop: "24px",
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    forgotPasswordContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    signInButton: {
      width: "auto",
      textTransform: undefined,
    },
    formContainer: {
      paddingTop: 0,
    },
    link: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      "&:active": {
        textDecoration: "none",
      },
    },
    linkText: {
      color: "#559542",
    },
    progress: {
      display: "flex",
      justifyContent: "center",
      "& svg": {
        height: 20,
        width: 20,
      },
    },
  })
);

const SignupForm = (props: RouteComponentProps<{}>) => {
  const classes = useStyles();

  const onSubmit = (values: ISignupForm): Promise<void | { [x: string]: string }> => {
    const signUpValues = values;
    signUpValues.termsDate = new Date();

    return axiosInstance
      .post(ApiPath.api.signup, signUpValues)
      .then(() => {
        const startEvent = new CustomEvent("startone", {
          detail: { signUpcompany: values.company, Email: values.email },
        });
        window.dispatchEvent(startEvent);
        props.history.push(`${AuthRoutes.SignUpConfirm}/${signUpValues.email}`);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const message = err.response.data.message || err.response.data[0].message;
          if (message) {
            store.dispatch(SetNotification(message));
          }
        } else {
          reportError("Couldn't sign up", err);
        }
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form className={classes.formContainer} onSubmit={handleSubmit}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} className={classes.root}>
              <Field
                component={TextFieldControl}
                type="email"
                name="email"
                label="Email Address"
                validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.isEmail)}
              />
            </Grid>
            <Grid item={true} xs={12} className={classes.root}>
              <Field
                component={TextFieldControl}
                type="password"
                name="password"
                label="Password"
                validate={FormValidators.composeValidators(
                  FormValidators.required(),
                  FormValidators.passwordValidator(),
                  FormValidators.minValue(16, "Password must be at least 16 characters.")
                )}
              />
            </Grid>
            <Grid item={true} xs={12} className={classes.root}>
              <Field
                component={TextFieldControl}
                type="text"
                name="company"
                label="Company"
                validate={FormValidators.required()}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Field
                name="acceptTerms"
                component={CheckboxControl}
                type="checkbox"
                label={
                  <span>
                    I agree to the{" "}
                    <a target="_blank" href="https://www.spearline.com/terms/" rel="noopener noreferrer">
                      Terms of Service
                    </a>
                  </span>
                }
                validate={FormValidators.required(`To open an account on testRTC you
                                                                       must agree to our terms of service.`)}
              />
            </Grid>
            <Grid className={classes.buttonContainer} item={true} xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.signInButton}
                disabled={submitting}
              >
                {!submitting ? <>Sign Up</> : <ReactSVG src={LoaderIcon} className={classes.progress} />}
              </Button>
            </Grid>
            <Grid item={true} xs={6}>
              <Link className={classes.link} to={AuthRoutes.SignIn}>
                <Typography variant="body2" color="textSecondary" className={classes.linkText}>
                  Already have an account?
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default withRouter(SignupForm);
