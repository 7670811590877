import { defaultChartsThresholds, defaultRibbonsThresholds } from "src/constants/thresholds";
import { VM_DEDICATED_MEMORY, VM_PROBES_AMOUNT } from "../constants/vm-probes";

export interface IChartThreshold {
  jitter_audio: number; // 30
  jitter_video: number; // 30

  rtt_audio: number; // 250
  rtt_video: number; // 250

  packetLoss_audio: number; // 4 Percentage
  packetLoss_video: number; // 4 Percentage

  packetLossNumber_audio: number; // 4 Number
  packetLossNumber_video: number; // 4 Number

  videoFrameRate: number; // video only

  browserMemory: number;
  browserCpu: number;
}

export interface IRibbonThreshold {
  score: number[]; // [3,4]
  mos: number[]; // [2,3]
  user: number[]; // [2,4]

  callSetupTime: number; // 500
  minutes: number; // 20

  highlights_bitrate_incoming: number[]; // Only for WatchRTC Highlights
  highlights_bitrate_outgoing: number[]; // Only for WatchRTC Highlights
  bitrate_audio_incoming: number[];
  bitrate_audio_outgoing: number[];
  bitrate_video_incoming: number[];
  bitrate_video_outgoing: number[];

  highlights_packetLoss_incoming: number[]; // [1,4] Only for WatchRTC Highlights
  highlights_packetLoss_outgoing: number[]; // [1,4] Only for WatchRTC Highlights

  packetLoss_audio_incoming: number[]; // [1,4]
  packetLoss_audio_outgoing: number[]; // [1,4]
  packetLoss_video_incoming: number[]; // [1,4]
  packetLoss_video_outgoing: number[]; // [1,4]

  jitter_audio_incoming: number[]; // [30,50]
  jitter_audio_outgoing: number[]; // [30,50]
  jitter_video_incoming: number[]; // [30,50]
  jitter_video_outgoing: number[]; // [30,50]

  highlights_rtt_incoming: number[]; // [200,250] Only for WatchRTC Highlights
  highlights_rtt_outgoing: number[]; // [200,250] Only for WatchRTC Highlights

  rtt_audio_incoming: number[]; // [200,250]
  rtt_audio_outgoing: number[]; // [200,250]
  rtt_video_incoming: number[]; // [200,250]
  rtt_video_outgoing: number[]; // [200,250]

  browserMemory: number; // non-watchrtc (testingRTC/upRTC/analyzeRTC)
  browserCpu: number; // non-watchrtc (testingRTC/upRTC/analyzeRTC)
}

export const chartsThresholdHelper = (charts_thresholds: IChartThreshold, loadFactor?: number): IChartThreshold => {
  const cores = (loadFactor || 1) * 2;

  const machineMemory = VM_DEDICATED_MEMORY * 1024; // convert 7.5GB to MBs;
  const maxMemory = machineMemory / VM_PROBES_AMOUNT;

  const browserMemory = charts_thresholds?.browserMemory || defaultChartsThresholds.browserMemory;
  const browserCpu = charts_thresholds?.browserCpu || defaultChartsThresholds.browserCpu;

  return {
    jitter_audio: charts_thresholds?.jitter_audio || defaultChartsThresholds.jitter_audio,
    jitter_video: charts_thresholds?.jitter_video || defaultChartsThresholds.jitter_video,
    rtt_audio: charts_thresholds?.rtt_audio || defaultChartsThresholds.rtt_audio,
    rtt_video: charts_thresholds?.rtt_video || defaultChartsThresholds.rtt_video,
    packetLoss_audio: charts_thresholds?.packetLoss_audio || defaultChartsThresholds.packetLoss_audio,
    packetLoss_video: charts_thresholds?.packetLoss_video || defaultChartsThresholds.packetLoss_video,
    packetLossNumber_audio: charts_thresholds?.packetLossNumber_audio || defaultChartsThresholds.packetLossNumber_audio,
    packetLossNumber_video: charts_thresholds?.packetLossNumber_video || defaultChartsThresholds.packetLossNumber_video,
    videoFrameRate: charts_thresholds?.videoFrameRate || defaultChartsThresholds.videoFrameRate,
    browserMemory: maxMemory * (browserMemory / 100),
    browserCpu: browserCpu * cores,
  };
};

export const ribbonsThresholdHelper = (ribbon_thresholds: IRibbonThreshold, loadFactor?: number): IRibbonThreshold => {
  const cores = (loadFactor || 1) * 2;

  const machineMemory = VM_DEDICATED_MEMORY * 1024; // convert 7.5GB to MBs;
  const maxMemory = machineMemory / VM_PROBES_AMOUNT;

  const browserMemory = ribbon_thresholds?.browserMemory || defaultRibbonsThresholds.browserMemory;
  const browserCpu = ribbon_thresholds?.browserCpu || defaultRibbonsThresholds.browserCpu;

  return {
    score: ribbon_thresholds?.score || defaultRibbonsThresholds.score,
    mos: ribbon_thresholds?.mos || defaultRibbonsThresholds.mos,
    user: ribbon_thresholds?.user || defaultRibbonsThresholds.user,

    callSetupTime: ribbon_thresholds?.callSetupTime || defaultRibbonsThresholds.callSetupTime,
    minutes: ribbon_thresholds?.minutes || defaultRibbonsThresholds.minutes,

    highlights_rtt_incoming:
      ribbon_thresholds?.highlights_rtt_incoming || defaultRibbonsThresholds.highlights_rtt_incoming,
    highlights_rtt_outgoing:
      ribbon_thresholds?.highlights_rtt_outgoing || defaultRibbonsThresholds.highlights_rtt_outgoing,
    highlights_packetLoss_incoming:
      ribbon_thresholds?.highlights_packetLoss_incoming || defaultRibbonsThresholds.highlights_packetLoss_incoming,
    highlights_packetLoss_outgoing:
      ribbon_thresholds?.highlights_packetLoss_outgoing || defaultRibbonsThresholds.highlights_packetLoss_outgoing,
    highlights_bitrate_incoming:
      ribbon_thresholds?.highlights_bitrate_incoming || defaultRibbonsThresholds.highlights_bitrate_incoming,
    highlights_bitrate_outgoing:
      ribbon_thresholds?.highlights_bitrate_outgoing || defaultRibbonsThresholds.highlights_bitrate_outgoing,
    bitrate_audio_incoming:
      ribbon_thresholds?.bitrate_audio_incoming || defaultRibbonsThresholds.bitrate_audio_incoming,
    bitrate_audio_outgoing:
      ribbon_thresholds?.bitrate_audio_outgoing || defaultRibbonsThresholds.bitrate_audio_outgoing,
    bitrate_video_incoming:
      ribbon_thresholds?.bitrate_video_incoming || defaultRibbonsThresholds.bitrate_video_incoming,
    bitrate_video_outgoing:
      ribbon_thresholds?.bitrate_video_outgoing || defaultRibbonsThresholds.bitrate_video_outgoing,
    packetLoss_audio_incoming:
      ribbon_thresholds?.packetLoss_audio_incoming || defaultRibbonsThresholds.packetLoss_audio_incoming,
    packetLoss_audio_outgoing:
      ribbon_thresholds?.packetLoss_audio_outgoing || defaultRibbonsThresholds.packetLoss_audio_outgoing,
    packetLoss_video_incoming:
      ribbon_thresholds?.packetLoss_video_incoming || defaultRibbonsThresholds.packetLoss_video_incoming,
    packetLoss_video_outgoing:
      ribbon_thresholds?.packetLoss_video_outgoing || defaultRibbonsThresholds.packetLoss_video_outgoing,
    jitter_audio_incoming: ribbon_thresholds?.jitter_audio_incoming || defaultRibbonsThresholds.jitter_audio_incoming,
    jitter_audio_outgoing: ribbon_thresholds?.jitter_audio_outgoing || defaultRibbonsThresholds.jitter_audio_outgoing,
    jitter_video_incoming: ribbon_thresholds?.jitter_video_incoming || defaultRibbonsThresholds.jitter_video_incoming,
    jitter_video_outgoing: ribbon_thresholds?.jitter_video_outgoing || defaultRibbonsThresholds.jitter_video_outgoing,
    rtt_audio_incoming: ribbon_thresholds?.rtt_audio_incoming || defaultRibbonsThresholds.rtt_audio_incoming,
    rtt_audio_outgoing: ribbon_thresholds?.rtt_audio_outgoing || defaultRibbonsThresholds.rtt_audio_outgoing,
    rtt_video_incoming: ribbon_thresholds?.rtt_video_incoming || defaultRibbonsThresholds.rtt_video_incoming,
    rtt_video_outgoing: ribbon_thresholds?.rtt_video_outgoing || defaultRibbonsThresholds.rtt_video_outgoing,
    browserMemory: maxMemory * (browserMemory / 100),
    browserCpu: browserCpu * cores,
  };
};
