import * as EmailValidator from "email-validator";
import { numberWithCommas } from "./numbers";
import { isDate } from "lodash";

export default class FormValidators {
  static none = () => {
    return undefined;
  };

  static required = (message = "") => (value: string) => {
    let returnMessage = "This field is required";
    if (message !== "") {
      returnMessage = message;
    }
    return value ? undefined : returnMessage;
  };

  static minDate = (minDate: string | Date, message = "") => (value: string | Date) => {
    let _value = value;

    if (typeof value === "string") {
      _value = new Date(value);
    }

    if (isDate(_value)) {
      if (!_value.getDate()) {
        return "Date is invalid";
      }
    } else {
      return "Date is invalid";
    }

    if (_value < minDate) {
      return message || "Validation error";
    }

    return undefined;
  };

  static isEmail(value: string) {
    const containsPlusSign = value.includes("+");
    const isEmail = EmailValidator.validate(value) && !containsPlusSign;
    return isEmail ? undefined : "Please enter a valid email.";
  }

  static isPhone(value: string) {
    if (!value) {
      return undefined;
    }

    const regex = /^\+?(?:[0-9] ?)+$/;
    return regex.test(String(value)) ? undefined : "Phone number may only contain '+', numbers and spaces";
  }

  static isURL(value: string) {
    if (!value) {
      return undefined;
    }

    const regex = /^((http(s)?|ftp)(:\/\/))?([www])?\.?[a-zA-Z0-9-_\.]+(\.[a-zA-Z0-9]{2,})([-a-zA-Z0-9:%_\+.~#?&//=]*)/gi;
    return regex.test(String(value)) ? undefined : "This is not an valid URL address";
  }

  static doesRegexMatch = (regex: RegExp, message: string) => (value: string) => {
    if (!value) {
      return undefined;
    }

    return regex.test(String(value)) ? undefined : message;
  };

  static minValue = (length: number, message = "") => (value: string) => {
    let returnMessage = `Field must be at least ${length} characters`;
    if (message !== "") {
      returnMessage = message;
    }

    if (value === undefined) {
      return undefined;
    } else {
      return value.length < length ? returnMessage : undefined;
    }
  };

  static maxValue = (length: number, message = "") => (value: string) => {
    let returnMessage = `Field must be ${length} characters maximum`;
    if (message !== "") {
      returnMessage = message;
    }

    if (value === undefined) {
      return undefined;
    } else {
      return value.length > length ? returnMessage : undefined;
    }
  };

  static positiveValue = (value: string) => {
    if (parseInt(value) < 0) {
      return "Value should be positive";
    }
    return undefined;
  };

  static inRange = (range: { from: number; to: number }) => (value: string | number) => {
    if (!range) {
      return undefined;
    }
    value = parseFloat(value as any);

    if (value < range.from || value > range.to) {
      return `Value should be between ${range.from} and ${numberWithCommas(range.to)}`;
    } else {
      return undefined;
    }
  };

  static isValidJson(value: string) {
    if (!value) {
      return undefined;
    }
    try {
      JSON.parse(value);
      return undefined;
    } catch {
      return "The object is invalid json";
    }
  }

  static passwordValidator = (_message = "") => (value: string) => {
    const isNonWhiteSpace = /^\S*$/g;
    if (!isNonWhiteSpace.test(value)) {
      return "pasword must not contain white space";
    }

    const isConsecutiveCharacters = /(?=.*(.)\1)/g;
    if (isConsecutiveCharacters.test(value)) {
      return "pasword must not contain 2 or more consecutive characters";
    }

    const isRequiredCharacters = /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!-\/:-@[-`{-~])|(?=.*[a-z])(?=.*[0-9])(?=.*[!-\/:-@[-`{-~])|(?=.*[0-9])(?=.*[A-Z])(?=.*[!-\/:-@[-`{-~]))/g;
    if (!isRequiredCharacters.test(value)) {
      return "Password must contain at least 3 of them (uppercase, lowercase, number and special character)";
    }

    return undefined;
  };

  static comaprePasswords = (password: string) => (value: string) => {
    if (password !== value) {
      return "Password and Repeat password doesn’t match";
    }
    return undefined;
  };

  static composeValidators = (...validators: Array<(value: string) => void>) => (value: string) =>
    validators.reduce((error, validator) => error || validator(value), undefined);
}
