// tslint:disable:no-any
// tslint:disable: no-string-literal
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import * as moment from "moment";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import { Button, Theme } from "@mui/material";

import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

import RTCGrid from "src/components/Grid";
import { toKeyValue } from "src/helpers/objHelper";
import KeyValueTableCell from "./KeyValueTableCell";
import { reportError } from "src/services/ErrorService";

type StyledComponent = WithStyles<
  "linkStyles" | "card" | "divider" | "toolbar" | "tableRow" | "tableContainer" | "showMoreButton"
>;

export interface UserMediaProps {
  media: Array<UserMedia>;
  origin?: string;
}

const UserMedia = (props: StyledComponent & UserMediaProps & RouteComponentProps<{}>) => {
  const { classes, media } = props;

  const [expanded, setExpanded] = React.useState(false);

  const parseStringToObject = (strObj: string) => {
    if (strObj === "") {
      return "true";
    }
    const jsonStr = strObj.replace(/(\w+:)|(\w+ :)/g, (s) => {
      return '"' + s.substring(0, s.length - 1) + '":';
    });

    let data;
    if (isJSON(jsonStr)) {
      data = jsonStr;
    } else {
      data = strObj;
    }

    try {
      const obj = JSON.parse(data);
      const objKeyValues = toKeyValue(obj);
      return <KeyValueTableCell data={objKeyValues} />;
    } catch (err) {
      reportError("Couldn't parse string to object", err);
      return jsonStr;
    }
  };

  const isJSONStructure = (obj: string) => {
    return Object.keys(obj || {}).length > 0;
  };

  const isJSON = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch {
      return false;
    }
  };

  const error = (value: any) => {
    return <span style={{ color: "#FC373A" }}>{value || "unknown"}</span>;
  };

  const columnSchema: Array<ColumnSchema> = [
    {
      id: "time",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "Time",
      render: (dataItem: any) =>
        dataItem.time
          ? moment(dataItem.time).format("HH:mm:ss")
          : dataItem.timestamp
          ? moment(dataItem.timestamp).format("HH:mm:ss")
          : "never",
      style: {
        wordBreak: "normal",
      },
    },
    {
      id: "audio",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "Audio",
      render: (dataItem: any, _index: number) => (
        <React.Fragment>
          {"audio" in dataItem
            ? parseStringToObject(dataItem.audio)
            : dataItem.value?.audio === true || dataItem.value?.audio === false
            ? dataItem.value.audio.toString()
            : isJSONStructure(dataItem.value?.audio)
            ? parseStringToObject(JSON.stringify(dataItem.value.audio))
            : dataItem.value?.tracks && dataItem.value?.tracks?.find((x: any) => x.kind === "audio")
            ? dataItem.value.tracks.find((x: any) => x.kind === "audio").label || "none"
            : dataItem.type?.toLowerCase().includes("failure")
            ? error(dataItem.value)
            : dataItem.error
            ? error(`${dataItem.error}: ${dataItem.error_message || "no details"}`)
            : "none"}
        </React.Fragment>
      ),
    },
    {
      id: "video",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "Video",
      render: (dataItem: any, _index: number) => (
        <React.Fragment>
          {"video" in dataItem
            ? parseStringToObject(dataItem.video)
            : dataItem.value?.video === true || dataItem.value?.video === false
            ? dataItem.value.video.toString()
            : isJSONStructure(dataItem.value?.video)
            ? parseStringToObject(JSON.stringify(dataItem.value.video))
            : dataItem.value?.tracks && dataItem.value?.tracks?.find((x: any) => x.kind === "video")
            ? dataItem.value.tracks.find((x: any) => x.kind === "video").label || "none"
            : dataItem.type?.toLowerCase().includes("failure")
            ? error(dataItem.value)
            : dataItem.error
            ? error(`${dataItem.error}: ${dataItem.error_message || "no details"}`)
            : "none"}
        </React.Fragment>
      ),
    },
  ];

  const locaData = media?.length > 3 && !expanded ? media.slice(0, 3) : media;

  return (
    <Fade in={true}>
      <Grid item={true} xs={12}>
        <Card className={classes.card}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="subtitle1">User Media</Typography>
          </Toolbar>
          <Divider className={classes.divider} />
          <Grid container={true} spacing={0} className={classes.tableContainer} id="userMedia-table">
            <RTCGrid
              localData={locaData}
              columnSchema={columnSchema}
              rowProps={{
                className: classes.tableRow,
              }}
            />
            {media?.length > 3 && (
              <Button
                className={classes.showMoreButton}
                onClick={() => {
                  setExpanded(!expanded);
                }}
                variant="contained"
              >
                {expanded ? `Show less` : `Show ${media.length - 3} more`}
              </Button>
            )}
          </Grid>
        </Card>
      </Grid>
    </Fade>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    linkStyles: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    card: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
      "& td": {
        [theme.breakpoints.up("md")]: {
          minWidth: 200,
        },
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        wordBreak: "break-all",
      },
      "& td:first-child": {
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      },
      "& td:last-child": {
        paddingRight: 8,
      },
    },
    tableContainer: {
      maxWidth: "100%",
      width: "max-content",
      minWidth: 600,
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      display: "flex",
      overflowY: "hidden",
      overflowX: "auto",
      justifyContent: "center",
    },
    showMoreButton: {
      marginTop: 10,
    },
  });

const decorate = withStyles(styles);

export default withRouter(decorate(UserMedia));
