// import { useState } from "react";
import {
  Card,
  Typography,
  Toolbar,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import CheckboxControl from "src/components/form-elements/CheckboxControl";

const TestingRTC = ({ classes }: ProjectPropertiesViewProps & ProjectPropertiesState) => {
  return (
    <Card className={classes.card} style={{ padding: 0 }}>
      <Toolbar>
        <Typography variant="subtitle1">testingRTC</Typography>
      </Toolbar>
      <Divider />
      <Table
        sx={{
          width: "100%",
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell>Block Test Runs On Negative Balance</TableCell>
            <TableCell>
              <Field component={CheckboxControl} type="checkbox" name="blockTestRunsOnNegativeBalance" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: 220 }}>Max Concurrent Probes</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="maxConcurrentProbes" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max Monthly Minutes</TableCell>
            <TableCell>
              <div>
                <Field
                  component={TextFieldControl}
                  type="number"
                  name="testingrtc_monthlyMinuteQuota"
                />
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max Timeout</TableCell>
            <TableCell>
              <div>
                <Field component={TextFieldControl} type="number" name="testMaxTimeout" />
              </div>
              <div>{"Minutes"}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Test History</TableCell>
            <TableCell>
              <div>
                <Field component={TextFieldControl} type="number" name="testHistoryDaysBack" />
              </div>
              <div>{"days back"}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max eval tests</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="max_EvaluationTestRunCount" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Performance Dashboard Results Limit</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="testingrtc_performanceChartMaxTests" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default TestingRTC;
