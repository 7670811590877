import { useState, useEffect, useContext } from "react";
import { LegendContext } from "../index";

export const useLegend = () => {
  const { series, shouldHideInOutToggle, shouldShowToggleAll, seriesTooltips, inOutToggleSeriesTooltips } = useContext(
    LegendContext
  );

  const [columnWidth, setColumnWidth] = useState(10);
  const [showInOut, setShowInOut] = useState(false);

  const getColumnWidth = () => {
    // 7 average char width
    // 25 gap for item symbol and padding
    let columnWidth = 10;
    series.forEach((s) => {
      if (s.name?.length > columnWidth) {
        columnWidth = s.name.length;
      }
    });
    return columnWidth * 7 + 25;
  };

  useEffect(() => {
    const shouldShowInOut =
      series.length > 2 &&
      series.some(
        (s: any) =>
          (/outgoing/gi.test(s?.name) && !/outgoingBitrate/gi.test(s?.name)) ||
          (/incoming/gi.test(s?.name) && !/incomingBitrate/gi.test(s?.name))
      );

    setColumnWidth(getColumnWidth());
    setShowInOut(shouldShowInOut && !shouldHideInOutToggle);
  }, [series]);

  return {
    columnWidth,
    showInOut,
    shouldShowToggleAll,
    seriesTooltips,
    inOutToggleSeriesTooltips,
  };
};
