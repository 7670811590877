import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

import AppLayout from "./containers/Layout";
import FrameLayout from "./containers/FrameLayout";
import SharedLayout from "./containers/SharedLayout";
import LoginLayout from "./containers/Auth/LoginLayout";
import AuthProvider from "./containers/Auth/AuthProvider";
import { AppRoutes } from "./constants/routes/app-routes";
import { FrameRoutes } from "./constants/routes/frame-routes";
import { SharedRoutes } from "./constants/routes/shared-routes";
import { ReactComponent as LoaderIcon } from "src/assets/images/loading-progress.svg";

const RouterContainer = () => {
  return (
    <BrowserRouter>
      <AuthProvider loaderIcon={<LoaderIcon />}>
        <Switch>
          <Route path={AppRoutes.root} component={AppLayout} />
          <Route path={FrameRoutes.root} component={FrameLayout} />
          <Route path={SharedRoutes.root} component={SharedLayout} />
          <Route path="/" component={LoginLayout} />
          <Redirect from="*" to="/" />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default RouterContainer;
