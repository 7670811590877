import { Card, Typography, Toolbar, Divider, Table, TableBody, TableRow, TableCell, MenuItem } from "@mui/material";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import CheckboxControl from "src/components/form-elements/CheckboxControl";
import TextFieldControl from "src/components/form-elements/TextFieldControl";

import { Products } from "./DataStream";
import DataStreamConfigurationCard from "./DataStream";
import LabelCell from "src/components/LabelCell";

type IWatchRTCProps = {
  values: any;
  setActiveJobs: (options: string) => void;
};

const IPAddressObfuscation = {
  None: "none",
  Partial: "partial",
  Full: "full",
};

const WatchRTC = ({
  classes,
  values,
  setActiveJobs,
}: IWatchRTCProps & ProjectPropertiesViewProps & ProjectPropertiesState) => {
  return (
    <div>
      <Card className={classes.card} style={{ padding: 0 }}>
        <Toolbar>
          <Typography variant="subtitle1">watchRTC</Typography>
        </Toolbar>
        <Divider />
        <Table
          sx={{
            width: "100%",
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell style={{ width: 220 }}>watchRTC Enable</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="watchrtc_enable" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>watchRTC Live Enable</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="watchrtc_live_enable" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Console Logs Enable</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="watchrtc_console_logs_enable" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Max minutes (1000's)</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="watchrtc_maxMinutes" min={0} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Max concurrent connections</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="watchrtc_max_concurrent_connections" min={0} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Retention Period</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="watchrtc_retentionPeriod" min={1} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Domains</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" name="watchrtc_domains" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Embedding Domains</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" name="watchrtc_embedded_domains" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Collection interval (ms)</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="watchrtc_interval" min={1000} step={1000} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Send interval</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="watchrtc_sendInterval" min={1} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Conference sizes</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" name="watchrtc_conferenceSizes" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Conference clousure wait time (minutes)</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="watchrtc_conferenceClousureWaitTime" min={0} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Custom Keys (JSON)</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="watchrtc_keys"
                  multiline={true}
                  rows="5"
                  resizeY={true}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>IP address obfuscation</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="watchrtc_ipAddressObfuscation"
                  SelectProps={{ autoWidth: true }}
                  select={true}
                >
                  {Object.keys(IPAddressObfuscation).map((key: any, i: number) => (
                    <MenuItem key={i} value={IPAddressObfuscation[key]}>
                      {key}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>RoomID URL template</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="watchrtc_roomIdURLTemplate"
                  placeholder="https://example.com/room{%roomId%}"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PeerID URL template</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="watchrtc_peerIdURLTemplate"
                  placeholder="https://example.com/room{%peerId%}"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>qualityRTC Config Path</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="watchrtc_qualityrtc_config_path"
                  placeholder="https://voicetest.testrtc.com/.netlify/functions/get-config"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <div>
        <Card className={classes.card} style={{ padding: 0 }}>
          <Toolbar>
            <Typography variant="subtitle1">Retention Period</Typography>
          </Toolbar>
          <Divider />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: 220 }}>InfluxDB</TableCell>
                <TableCell>
                  <Field
                    component={TextFieldControl}
                    select={true}
                    SelectProps={{ autoWidth: true }}
                    name="watchrtc_influxdb_retention"
                    variant="standard"
                  >
                    {[45, 60, 90, 180, 365].map((key: any, i: number) => (
                      <MenuItem key={i} value={key}>
                        {key} days
                      </MenuItem>
                    ))}
                  </Field>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: 220 }}>
                  <LabelCell
                    label="InfluxDB Custom Bucket Name"
                    tooltip="Allows to write customers data in totally separated bucket from all customers with the given retention period from above. Warning: Before setting this field, please contact InfluxDB developers to create a bucket in influxDB system."
                  />
                </TableCell>
                <TableCell>
                  <Field component={TextFieldControl} type="text" name="watchrtc_influxdb_custom_bucket_name" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </div>
      <DataStreamConfigurationCard
        classes={classes}
        values={values}
        product={Products.watch}
        setActiveJobs={setActiveJobs}
      />
    </div>
  );
};

export default WatchRTC;
