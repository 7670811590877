import { SET_DISTINCT_TESTS, SET_DISTINCT_MONITOR, SET_MONITOR_SORT, SET_COMPANIES } from "../constants/ActionTypes";
import { ISetTestsDistinctAction } from "../actions/dictionaryAction";

const defaultState: DictionaryReducerModel = {
  testRuns: [],
  monitorRuns: [],
  monitorSort: [],
  companies: [],
};

export default (state: DictionaryReducerModel = defaultState, action: ISetTestsDistinctAction) => {
  switch (action.type) {
    case SET_DISTINCT_TESTS: {
      return {
        ...state,
        testRuns: action.payload,
      };
    }
    case SET_DISTINCT_MONITOR: {
      return {
        ...state,
        monitorRuns: action.payload,
      };
    }
    case SET_MONITOR_SORT: {
      return {
        ...state,
        monitorSort: action.payload,
      };
    }
    case SET_COMPANIES: {
      return {
        ...state,
        companies: action.payload,
      };
    }
    default:
      return state;
  }
};
