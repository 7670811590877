import { createStyles, makeStyles } from "@mui/styles";

import Widget, { WidgetConfig } from "./components/Widget";
import { Paper } from "@mui/material";

interface RibbonStatsProps {
  data: WidgetConfig[];
}

const useStyles = makeStyles(
  createStyles({
    container: {
      width: "100%",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      overflow: "hidden",

      breakInside: "avoid",
    },
  })
);

const RibbonStats = ({ data }: RibbonStatsProps) => {
  const classes = useStyles();

  return (
    <Paper elevation={1} className={classes.container}>
      {data.map((widgetData) => (
        <Widget {...widgetData} key={widgetData.title} />
      ))}
    </Paper>
  );
};

export default RibbonStats;
