import * as React from "react";

import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import PersonalIcon from "@mui/icons-material/Person";
import GlobalIcon from "@mui/icons-material/Language";
import { Select, SelectChangeEvent } from "@mui/material";

// import { GridPreset } from "..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(0),
      marginRight: theme.spacing(1),
      minWidth: 120,
    },
    menuItem: {
      display: "flex",
      alignItems: "center",
      fontSize: 14,
      "& svg": {
        opacity: 0.7,
        marginRight: 3,
      },
    },
    label: {
      transform: "translate(14px, 7.5px) scale(1)",
      marginLeft: -12,
      marginTop: 12,
      "&.Mui-focused": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
    },
  })
);

interface ISelectControlProps {
  options: any[];
  selected?: string;
  onSelect?: (value: string) => void;
}

export default function SelectControl({ options, selected, onSelect }: ISelectControlProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(selected || "");

  React.useEffect(() => {
    setValue(selected || "");
  }, [selected]);

  const handleChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    const id = event.target.value as string;
    setValue(id);
    if (onSelect) {
      onSelect(id);
    }
  };

  return (
    <div>
      <FormControl className={classes.formControl} variant="standard">
        {value === "" && (
          <InputLabel className={classes.label} id="select-helper-label">
            Preset
          </InputLabel>
        )}
        <Select
          labelId="select-helper-label"
          id="select-helper"
          value={value as any}
          onChange={handleChange}
          variant="standard"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((option: any) => (
            <MenuItem key={option._id} value={option._id}>
              <div className={classes.menuItem}>
                {option.global ? <GlobalIcon fontSize="small" /> : <PersonalIcon fontSize="small" />}
                <span>{option.name}</span>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
