import { Alert } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export interface AlertAreaProps {
  notes?: string;
  error?: string;
  errorLevel?: string;
  additionalInfo?: string;
}

const useStyles = makeStyles(
  createStyles({
    notesContainer: { padding: "16px 0 16px", display: "flex", alignItems: "flex-start" },
    notesLabel: {
      margin: 0,
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 500,
      letterSpacing: "0.1px",
      whiteSpace: "pre-wrap",
    },
    notes: {
      flex: 1,
      margin: 0,

      fontSize: 14,
      lineHeight: "20px",
      whiteSpace: "pre-wrap",
      letterSpacing: "0.17px",
    },
    alert: { margin: "8px 0 0" },
  })
);

const AlertArea = ({ notes, error, errorLevel, additionalInfo }: AlertAreaProps) => {
  const classes = useStyles();

  const stripHtmlTagsFromString = (stringWithHtmlTags: string): string => {
    try {
      const tempDocument = new DOMParser().parseFromString(stringWithHtmlTags, "text/html");
      return tempDocument.body.textContent || "";
    } catch (error) {
      return stringWithHtmlTags;
    }
  };
  const modifiedError =
    error &&
    stripHtmlTagsFromString(
      error?.replace('href="app/support-ticket/"', 'href="/app/support-ticket/" target="_blank"')
    );

  return (
    <>
      {notes ? (
        <div className={classes.notesContainer}>
          <p className={classes.notesLabel}>Notes: </p>
          <p className={classes.notes}>{notes}</p>
        </div>
      ) : null}

      {modifiedError ? (
        <Alert severity="info" color={errorLevel === "warn" ? "warning" : "error"} className={classes.alert}>
          <span id="rtc-error-line" dangerouslySetInnerHTML={{ __html: modifiedError }} />
        </Alert>
      ) : null}

      {additionalInfo ? (
        <Alert severity="info" className={classes.alert}>
          {additionalInfo}
        </Alert>
      ) : null}
    </>
  );
};

export default AlertArea;
