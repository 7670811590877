import { Card, Typography, Toolbar, Divider, Table, TableBody, TableRow, TableCell, Button } from "@mui/material";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";

interface IUpRTC {
  classes: any;
  testUpRtcEmails(alertEmails: string): void;
  values: any;
}

const UpRTC = ({ classes, testUpRtcEmails, values }: IUpRTC) => {
  const onTestClick = () => {
    testUpRtcEmails(values.alertEmails);
  };
  return (
    <Card className={classes.card} style={{ padding: 0 }}>
      <Toolbar>
        <Typography variant="subtitle1">upRTC</Typography>
      </Toolbar>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: 220 }}>Number of Active Monitors</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="enableMonitoring" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max Number of Probes Per Monitor</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="maxAgentsPerMonitor" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max Daily Monitor Runs</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="uprtc_maxDailyMonitorRunsLimit" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Alert Emails</TableCell>
            <TableCell className={classes.tableCellRow}>
              <Field component={TextFieldControl} type="text" name="alertEmails" />
              <Button style={{ marginLeft: 10 }} color="primary" variant="contained" onClick={onTestClick}>
                Test
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Performance Dashboard Results Limit</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="upRTC_performanceMaxTests" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default UpRTC;
