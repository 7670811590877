import * as _ from "lodash";
import { reportError } from "src/services/ErrorService";

const DATA_SERIES_COLORS = [
  "#058DC7",
  "#50B432",
  "#ED561B",
  "#DDDF00",
  "#24CBE5",
  "#64E572",
  "#FF9655",
  "#FFF263",
  "#6AF9C4",
];

const generateGraphTitle = (title: string, connectionId: string) => {
  if (title.toLowerCase().indexOf("bweforvideo") !== -1) {
    return `Bandwidth Estimation (${title} ${connectionId})`;
  }
  if (title.toLowerCase().indexOf("send") !== -1) {
    return `Sender (${title} ${connectionId})`;
  }

  if (title.toLowerCase().indexOf("recv") !== -1) {
    return `Receiver (${title} ${connectionId})`;
  }

  return `${title} (connection ${connectionId})`;
};

const sortGraphs = (graphs: Array<any>, connectionId: string) => {
  const obj = {
    bweforvideo: [] as Array<any>,
    ssrc_send: [] as Array<any>,
    ssrc_recv: [] as Array<any>,
    unknown: [] as Array<any>,
  };
  for (let i = 0; i < graphs.length; i++) {
    const graph = graphs[i];
    const title = graph.title.substring(0, graph.title.indexOf("(")).replace(/\s+/, "");
    // replace common symbol to get specific type of graph
    const key = title
      // eslint-disable-next-line
      .replace(/[0-9]/g, "")
      // eslint-disable-next-line
      .replace(/\-/g, "")
      // eslint-disable-next-line
      .replace(/\_/, "");

    if (obj.hasOwnProperty(key)) {
      graph.title = generateGraphTitle(title, connectionId);
      obj[key].push(graph);
    } else {
      obj.unknown.push(graph);
    }
  }
  const result = obj.bweforvideo.concat(obj.ssrc_send).concat(obj.ssrc_recv).concat(obj.unknown);
  return result;
};

export const prepareGraphs = (graphs: any, dataFrequency: number) => {
  const graphsState = {};
  Object.keys(graphs).forEach((connection) => {
    const preparedGraphs: Array<any> = [];
    Object.keys(graphs[connection]).forEach((report) => {
      let idx = 0;
      let startTime = Number.MAX_SAFE_INTEGER;
      let endTime = 0;

      const dataset = [];

      for (const serie of graphs[connection][report].series) {
        if (serie.name.includes("ssrc")) {
          continue;
        }
        if (serie.startTime < startTime) {
          startTime = serie.startTime;
        }
      }

      for (const serie of graphs[connection][report].series) {
        if (serie.name.includes("ssrc")) {
          continue;
        }

        // check if series starts in the same time
        // if not - find a factor to move serie startTime to be at same time position
        // as all anoter series
        const timeDiff = serie.startTime - startTime;
        if (timeDiff > 0) {
          const factor = Math.floor(timeDiff / (dataFrequency * 1000));
          serie.startTime = factor * dataFrequency;
        } else {
          serie.startTime = 0;
        }

        const data = {
          label: serie.name,
          color: DATA_SERIES_COLORS[idx % DATA_SERIES_COLORS.length],
          visible: serie.visible,
          startTime: serie.startTime,
        } as any;

        let zero = true;
        const pointsData = serie.data.reduce((acc: any, point: any, i: number) => {
          if (point) {
            zero = false;
          }
          const xValue = serie.startTime + i * dataFrequency;
          let yValue = point || 0;

          // original jitter values are seconds
          // we need them in milliseconds
          if (serie.name === "jitter") {
            yValue *= 1000;
          }
          if (xValue > endTime) {
            endTime = xValue;
          }

          if (yValue !== null) {
            acc.push([xValue, yValue]);
          }

          return acc;
        }, []);

        data.data = pointsData;
        if (zero) {
          data.visible = false;
        }

        idx++;
        dataset.push(data);
      }

      // https://redmine.testrtc.com/issues/5610
      // make all series one length
      // fill missing values with previous value
      try {
        dataset
          .filter((x) => x.data?.length > 0)
          .forEach((x: any) => {
            if (x.data?.length) {
              let lastEndTime = x.data[x.data.length - 1][0];
              if (lastEndTime < endTime) {
                let i = x.data.length;
                while (lastEndTime < endTime) {
                  const lastValue = x.data[x.data.length - 1][1] || 0;
                  x.data[i] = [lastEndTime + dataFrequency, lastValue];
                  lastEndTime = x.data[i][0];
                  i++;
                }
              }
            }
          });
      } catch (err) {
        reportError("Couldn't fill missing values with previous", err);
      }

      // Put all zero series to the end
      dataset.sort((a: any, b: any) => {
        if (!a.data && b.data) {
          return 1;
        } else if (a.data && !b.data) {
          return -1;
        } else {
          return 0;
        }
      });

      preparedGraphs.push({
        title: graphs[connection][report].title,
        startTime: graphs[connection][report].startTime,
        type: graphs[connection][report].type,
        ssrc: graphs[connection][report].ssrc,
        kind: graphs[connection][report].kind,
        options: {
          series: {
            lines: { show: true, lineWidth: 2 },
            points: { show: true, radius: 3 },
            shadowSize: 0,
          },
          grid: {
            hoverable: true,
            mouseActiveRadius: 10,
          },
          tooltip: {
            show: true,
            content: (yval: string) => {
              return "<strong>%x</strong><br/>%s — <strong>" + yval + "</strong>";
            },
            formatter: function () {
              const self: any = this;
              return `<strong>${self.point.x}</strong><br/>${self?.series?.name} — <strong>${_.round(
                self.point.y,
                5
              ).toLocaleString()}</strong>`;
            },
          },
          xAxis: {
            type: "datetime",
            labels: {
              formatter: function (context: any) {
                const value = context.value * 1000;
                const range = context.chart?.xAxis[0]?.max || 0 - context.chart?.xAxis[0]?.min;
                const format = range < 3600 ? "%M:%S" : "%H:%M:%S";
                if (value >= 0) {
                  return (window as any).Highcharts.dateFormat(format, value);
                } else {
                  return `-${(window as any).Highcharts.dateFormat(format, Math.abs(value))}`;
                }
              },
            },
          },
          yAxis: {
            formatter: (val: number) => {
              if (Math.abs(val) > 1000000) {
                return _.round(val / 1000000, 0) + "m";
              } else if (Math.abs(val) > 1000) {
                return _.round(val / 1000, 0) + "k";
              } else if (Math.abs(val) > 0 && Math.abs(val) < 1) {
                return _.round(val, 5);
              } else {
                return _.round(val, 0);
              }
            },
          },
        },
        originalDataset: dataset,
        dataset: dataset,
        additionalGraphInfo: graphs[connection][report].additionalGraphInfo,
      });
    });
    graphsState[connection] = sortGraphs(preparedGraphs, connection);
  });

  return graphsState;
};
