import { IS_DATA_LOADING } from "../constants/ActionTypes";

const defaultState: DataLoadingReducerModel = {
  dataLoading: false,
};

// tslint:disable-next-line:no-any
export default (state: DataLoadingReducerModel = defaultState, action: any) => {
  switch (action.type) {
    case IS_DATA_LOADING: {
      return {
        ...state,
        dataLoading: action.payload,
      };
    }
    default:
      return state;
  }
};
