export const AuthRoutes = {
  SignIn: "/signin",
  SignUp: "/signup",
  SignUpConfirm: "/signup-confirm",
  ConfirmAccount: "/confirm-account",
  ConfirmAccountId: "/confirm-account/:objectId",
  ForgotPassword: "/forgot-password",
  ResetPassword: "/reset",
  ResetPasswordId: "/reset/:token",
  Subscribe: "/",
  Analyze: "/analyze-log",
  ReportFree: "/app/report/free",
  ReportFreeId: "/report/free/:objectId",
};
