import * as React from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import { ChartsState, ChartsProps } from "../index";
import ExpandChannelDetails from "./ExpandChannelDetails";

type StyledComponent = WithStyles<"card" | "divider" | "toolbar" | "contentContainer" | "noWebrtcMessage">;

class View extends React.PureComponent<ChartsState & ChartsProps & StyledComponent> {
  render(): JSX.Element {
    const { classes, channels, advancedAnalyticsLink } = this.props;
    const counts = {
      "audio-in": 0,
      "audio-out": 0,
      "video-in": 0,
      "video-out": 0,
    };
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Card className={classes.card}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="subtitle1">Channels</Typography>
            </Toolbar>
            <Divider className={classes.divider} />
            <Grid className={classes.contentContainer} container={true} spacing={5}>
              {channels.map((channel, _i) => {
                let id;
                const channelDirection = channel.isOutgoing ? "out" : "in";
                const key = `${channel.channelType}-${channelDirection}`;
                if (key in counts) {
                  counts[key] += 1;
                  id = counts[key];
                } else {
                  id = _i + 1; // Fallback
                }
                return (
                  <ExpandChannelDetails
                    key={channel.guid}
                    id={_i + 1}
                    channel={channel}
                    channelId={id}
                    advancedAnalyticsLink={advancedAnalyticsLink}
                    testRtc
                  />
                );
              })}

              {!channels.length && (
                <Typography className={classes.noWebrtcMessage} variant="h5" gutterBottom={true} align="center">
                  No WebRTC Data collected for this session
                </Typography>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      marginTop: 16,
      padding: theme.spacing(2),
      overflow: "visible",
    },
    divider: {
      margin: "16px -16px",
    },
    contentContainer: {
      padding: 16,
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
    },
    noWebrtcMessage: {
      textTransform: "capitalize",
      fontSize: 19,
      textAlign: "center",
      width: "100%",
      marginTop: 20,
    },
  });

const decorate = withStyles(styles);

export default decorate(View);
