import * as _ from "lodash";

import ChartHelper from "src/helpers/chartHelper";
import SeriesBuilder, { getMaxY } from "src/components/Chart/seriesBuilder";

import { formatDuration } from "src/helpers/testDetails";

import { dataTypesValues, labels, MediaType, DataType, ByType, dataTypeToTitle, yAxisSoftMinMax } from "./constants";
import { reportError } from "src/services/ErrorService";
import * as moment from "moment";

export const processData = (stat: any, events: any[]) => {
  const newData = {
    watchRTC: stat.watchRTC,
    thresholds: stat.thresholds,
  } as any;

  newData.events = events;
  newData.dataFrequency = stat.dataFrequency || 1;
  newData.performanceStep = stat.performanceStep || stat.dataFrequency * 1000 || 5000;

  newData.rawFilterOptions = stat.filterData;
  newData.rawFilterOptions_BCNL = stat.filterData_BCNL;

  newData.rawMinMaxBand = stat.maxMinBand;
  newData.rawMinMaxBand_BCNL = stat.maxMinBand_BCNL || [];

  newData.rawProbsOptions = stat.probsData;

  newData.rawPerfData = stat.perfChartData || {};
  newData.rawProbesPerfData = stat.probesPerfChartData || {};

  const fltrDataset = newData.rawFilterOptions || [];
  const fltrDataset_BCNL = newData.rawFilterOptions_BCNL || [];

  const probDataset = ChartHelper.prepareFilterOptions(newData.rawProbsOptions, "probeId", newData.dataFrequency);
  const probDatasetSorted = ChartHelper.prepareFilterOptions(newData.rawProbsOptions, "probeId", newData.dataFrequency);

  const bits = dataTypesValues.bits;
  const loss = dataTypesValues.loss;
  const lossPCT = dataTypesValues.lossPCT;
  const videoDelay = dataTypesValues.videoDelay;
  const videoFrameRate = dataTypesValues.videoFrameRate;

  // that is because in database we have different naming
  for (const prop of Object.keys(probDataset)) {
    for (const p of Object.keys(probDataset[prop])) {
      for (const type of Object.keys(probDataset[prop][p])) {
        switch (type) {
          case "bytes":
            probDatasetSorted[prop][p][bits] = probDatasetSorted[prop][p][type];
            probDataset[prop][p][bits] = probDataset[prop][p][type];
            newData.rawProbsOptions[prop][p][bits] = newData.rawProbsOptions[prop][p][type];
            break;

          case "packetLoss":
            probDatasetSorted[prop][p][loss] = probDatasetSorted[prop][p][type];
            probDataset[prop][p][loss] = probDataset[prop][p][type];
            newData.rawProbsOptions[prop][p][loss] = newData.rawProbsOptions[prop][p][type];
            break;
          case "packetLossPCT":
            probDatasetSorted[prop][p][lossPCT] = probDatasetSorted[prop][p][type];
            probDataset[prop][p][lossPCT] = probDataset[prop][p][type];
            newData.rawProbsOptions[prop][p][lossPCT] = newData.rawProbsOptions[prop][p][type];
            break;

          case "rtt":
            probDatasetSorted[prop][p][videoDelay] = probDatasetSorted[prop][p][type];
            probDataset[prop][p][videoDelay] = probDataset[prop][p][type];
            newData.rawProbsOptions[prop][p][videoDelay] = newData.rawProbsOptions[prop][p][type];
            break;
          case "fps":
            probDatasetSorted[prop][p][videoFrameRate] = probDatasetSorted[prop][p][type];
            probDataset[prop][p][videoFrameRate] = probDataset[prop][p][type];
            newData.rawProbsOptions[prop][p][videoFrameRate] = newData.rawProbsOptions[prop][p][type];
            break;
          default:
            break;
        }
      }
    }
  }

  // add all prop to filter options
  newData.fltrDataset = ChartHelper.audioVideoMerge(fltrDataset, true);
  newData.fltrDataset_BCNL = ChartHelper.audioVideoMerge(fltrDataset_BCNL, true);
  newData.probDatasetSorted = probDatasetSorted;
  newData.fltrPerf = ChartHelper.prepareFilterPerformance(newData.rawPerfData);
  newData.fltrProbPerf = ChartHelper.prepareFilterProbePerformance(newData.rawProbesPerfData);

  const chartBuilding = stat.chartBuilding;
  if (chartBuilding) {
    newData.setupStartTime = new Date(chartBuilding.setupStartTime);
    // where markers should start working for audio
    newData._channelReadyTime = new Date(chartBuilding.channelReadyTime);

    // set chart startUp time to minimum timestamp from globel event.
    const globalEvents = ChartHelper.prepareGlobalEvents(events);

    // if no global event found, set current timestamp. which willl always miximum then setupStartTime.
    const initValue = { timestamp: new Date().getTime() };
    const firstEvent = globalEvents
      .filter((evt) => evt && evt.timestamp)
      .reduce(function (prev: { timestamp: number }, curr: { timestamp: number }) {
        return prev.timestamp < curr.timestamp ? prev : curr;
      }, initValue);

    const setupStartTime = new Date(chartBuilding.setupStartTime);

    newData._chartSampleStartTime =
      firstEvent && new Date(firstEvent.timestamp) < setupStartTime ? new Date(firstEvent.timestamp) : setupStartTime;

    newData.delayTime = Math.round(
      (setupStartTime.getTime() - newData._chartSampleStartTime.getTime()) / (newData.dataFrequency * 1000)
    );

    newData.perfDelayTime = Math.round(
      (setupStartTime.getTime() - newData._chartSampleStartTime.getTime()) / (newData.performanceStep * 1000)
    );

    newData.audioOffset = ((newData._channelReadyTime as any) - newData._chartSampleStartTime.getTime()) / 1000;
    newData.firstProbEndsIn = (chartBuilding.firstProbEndsIn + newData.delayTime) / 1000;

    newData.firstProbEndsInSessionIndex = chartBuilding.firstProbEndsInSessionIndex;

    // Video and audio
    newData.rawFilterOptions = ChartHelper.audioVideoMerge(newData.rawFilterOptions, false);
    newData.rawFilterOptions_BCNL = ChartHelper.audioVideoMerge(newData.rawFilterOptions_BCNL, false);
  }

  return newData;
};

export const buildAllCharts = (data: any, mediaType: MediaType, byType: ByType) => {
  data = _.cloneDeep(data);
  const charts: any = {};
  if (mediaType !== "performance") {
    charts.bits = buildChart(data, mediaType, "bits", byType);
    charts.jitter = buildChart(data, mediaType, "jitter", byType);
    charts.packets = buildChart(data, mediaType, "packets", byType);
    charts.videoDelay = buildChart(data, mediaType, "videoDelay", byType);
    charts.videoFrameRate = buildChart(data, mediaType, "videoFrameRate", byType);
    charts.loss = buildChart(data, mediaType, "loss", byType);
    charts.lossPCT = buildChart(data, mediaType, "lossPCT", byType);
  } else {
    charts.browserCpu = buildChart(data, "performance", "browserCpu", byType);
    charts.browserMemory = buildChart(data, "performance", "browserMemory", byType);
  }
  return {
    ...charts,
  };
};

const buildChart = (data: any, mediaType: MediaType, chartType: DataType, byType: ByType) => {
  switch (byType) {
    case "time/probe":
    case "time/channel":
      if (mediaType !== "performance") {
        return buildByTimeChart(data, mediaType, chartType, byType);
      } else {
        return buildByTimePerfChart(data, chartType, mediaType);
      }
    case "probe":
      if (mediaType !== "performance") {
        return buildByProbeChart(data, mediaType, chartType);
      } else {
        return buildByProbePerfChart(data, chartType);
      }
    default:
      throw Error("Unknown chart by-type.");
  }
};

const buildByTimeChart = (data: any, mediaType: MediaType, dataType: DataType, byType: ByType) => {
  let isPCT = false;
  if (dataType === "lossPCT") {
    isPCT = true;
    dataType = "loss";
  }
  const sb = new SeriesBuilder(true);

  // incoming/outgoing
  let sbConfig = {};
  if (byType !== "probe" && dataType === "loss") {
    sbConfig = {
      bars: { show: true, fill: true, horizontal: false },
      lines: { show: false },
    };
  }

  const src = byType === "time/probe" ? data.fltrDataset : data.fltrDataset_BCNL;
  const sendSeries = (src[mediaType] && src[mediaType].send[dataType]) || [[0, 0]];
  const recvSeries = (src[mediaType] && src[mediaType].recv[dataType]) || [[0, 0]];

  sb.addConfigs([sbConfig, sbConfig]).addSeries([recvSeries, sendSeries], labels, data.dataFrequency, data.delayTime);
  // from bits to kbits for first two series
  if (dataType === "bits") {
    for (let sIdx = 0; sIdx < 2; sIdx++) {
      for (const pair of sb.series[sIdx]) {
        pair[1] = pair[1] / 1000;
      }
    }
  }

  // min/max bands
  if (byType !== "probe" && dataType !== "loss") {
    const sbConfigBand = {
      ...sbConfig,
      lines: { lineWidth: 0, fill: true, show: true },
    };
    const srcBand = byType === "time/probe" ? data.rawMinMaxBand : data.rawMinMaxBand_BCNL;
    const sendBandSeries = (srcBand[mediaType] && srcBand[mediaType].send && srcBand[mediaType].send[dataType]) || [
      [0, 0],
    ];
    const recvBandSeries = (srcBand[mediaType] && srcBand[mediaType].recv && srcBand[mediaType].recv[dataType]) || [
      [0, 0],
    ];
    const bandSend = prepareBand(sendBandSeries.min, sendBandSeries.max, data.dataFrequency, data.delayTime);
    const bandRecv = prepareBand(recvBandSeries.min, recvBandSeries.max, data.dataFrequency, data.delayTime);

    sb.addCustomSeries(bandSend)
      .addCustomSeries(bandRecv)
      // .setOffsetToSeries(events.length ? 4 : 3, events.length ? 6 : 5)
      .addConfigs([sbConfigBand, sbConfigBand])
      .addLabel("Outgoing min/max band")
      .addLabel("Incoming min/max band");
    // from bits to kbits for min max bands
    if (dataType === "bits") {
      const sLen = sb.series.length - 1;
      for (let sIdx = sLen; sIdx > sLen - 2; sIdx--) {
        for (const pair of sb.series[sIdx]) {
          pair[1] = pair[1] / 1000;
          if (pair[2]) {
            pair[2] = pair[2] / 1000;
          } else {
            pair[2] = 0;
          }
        }
      }
    }
  }

  if (!data.watchRTC) {
    //call end
    sb.addLabel("Call end")
      .addCustomSeries([[data.firstProbEndsIn, 0]])
      .setOffset(0);
    sb.addConfig({ bars: { show: true, fill: true, horizontal: false } });
  }

  // add events series label and series
  const events = getEvents(data) || [];
  if (events.length) {
    if (data.watchRTC) {
      addWatchRTCEvents(events, sb);
    } else {
      addTestingRTCEvents(events, sb);
    }
  }

  let dataset = sb.build();
  if (_.isArray(dataset)) {
    dataset = getSortedLegend(dataset);
    dataset = setSeriesColors(dataset);
  }

  const options = {
    ...getDefaultOptions(dataType, isPCT, dataset, data.thresholds, mediaType),
    xAxis: {
      type: "datetime",
      axisLabel: "Time",
      labels: {
        formatter: function (context: any) {
          const value = context.value * 1000;
          const range = context.chart?.xAxis[0]?.max || 0 - context.chart?.xAxis[0]?.min;
          const format = range < 3600 ? "%M:%S" : "%H:%M:%S";
          if (value >= 0) {
            return (window as any).Highcharts.dateFormat(format, value);
          } else {
            return `-${(window as any).Highcharts.dateFormat(format, Math.abs(value))}`;
          }
        },
      },
      events: {
        afterSetExtremes: (e: any) => syncZoomEvent(e),
      },
    },
    tooltip: byTimeTooltip(data, events),
  };

  return {
    options,
    dataset,
  };
};

const buildByProbeChart = (data: any, mediaType: MediaType, dataType: DataType) => {
  let isPCT = false;
  if (dataType === "lossPCT") {
    isPCT = true;
  }

  const sb = new SeriesBuilder(true);

  // incoming / outgoing;
  const sbConfig = {
    bars: { show: true, fill: true, horizontal: false },
    lines: { show: false },
  };
  const src = data.probDatasetSorted;

  const sendSeries = (src[mediaType] && src[mediaType].send[dataType]) || [[0, 0]];
  const recvSeries = (src[mediaType] && src[mediaType].recv[dataType]) || [[0, 0]];

  const optRef = data.rawProbsOptions ? data.rawProbsOptions[mediaType] : null;
  const sendProbeInfo = (optRef.send && optRef.send[dataType]) || [[0, 0]];
  const recvProbeInfo = (optRef.recv && optRef.recv[dataType]) || [[0, 0]];

  sb.addConfigs([sbConfig, sbConfig]).addSeries([recvSeries, sendSeries], labels, 1);

  sb.addHelperData({ tooltip: recvProbeInfo }, 0).addHelperData({ tooltip: sendProbeInfo }, 1);

  let dataset = sb.build();

  if (_.isArray(dataset)) {
    dataset = getSortedLegend(dataset);
    dataset = setSeriesColors(dataset);
  }

  const options = {
    ...getDefaultOptions(dataType, isPCT),
    events: {
      afterSetExtremes: (e: any) => syncZoomEvent(e),
    },
    xAxis: {
      type: "number",
      labels: {
        formatter: function (context: any) {
          const value = context.value;
          return value.toString();
        },
      },
    },
    tooltip: data.watchRTC
      ? {
          show: true,
          formatter: function () {
            const _this = this as any;

            // https://redmine.testrtc.com/issues/7439
            // as incoming and outgoing tooltips differ only in values, but the same in ordering
            // we can use either inc or out tooltips data
            const incTooltips = recvProbeInfo;
            const peer = incTooltips.find((x: any) => x.probeId === _this.x);

            return _this.points.reduce((s: any, point: any) => {
              return `<div style="color:#E6E6E7">${s}<br/> ${point.series.name}: ${point.y}</div>`;
            }, `<b>Peer #${_this.x} ${peer ? `-${peer.name}` : ""}</b><br/>`);
          },
        }
      : undefined,
  };

  return {
    options,
    dataset,
  };
};

export const buildByTimePerfChart = (data: any, chartType: DataType, mediaType: MediaType) => {
  const src = data.fltrPerf;
  const sb = new SeriesBuilder(true);

  const avgSeries = (src[chartType] && src[chartType].avg) || [[0, 0]];
  const maxSeries = (src[chartType] && src[chartType].max) || [[0, 0]];
  const minSeries = (src[chartType] && src[chartType].min) || [[0, 0]];

  sb.addConfigs([{}]).addSeries([avgSeries], [`Average Load`], data.performanceStep, data.perfDelayTime);

  // min/max bands
  const sbConfigBand = {
    lines: { lineWidth: 0, fill: true, show: true },
  };

  const bandSeries = prepareBand(maxSeries, minSeries, data.performanceStep, data.perfDelayTime);

  sb.addCustomSeries(bandSeries).addConfigs([sbConfigBand]).addLabel(`Min/max load band`);

  if (!data.watchRTC) {
    //call end
    sb.addLabel("Call end")
      .addCustomSeries([[data.firstProbEndsIn * 1000, 0]])
      .setOffset(0)
      .addConfig({ bars: { show: true, fill: true, horizontal: false } });
  }

  // add events series label and series
  const events = getEvents(data, true) || [];
  if (events.length) {
    if (data.watchRTC) {
      addWatchRTCEvents(events, sb);
    } else {
      addTestingRTCEvents(events, sb);
    }
  }

  let dataset = sb.build();
  if (_.isArray(dataset)) {
    dataset = getSortedLegend(dataset);
    dataset = setSeriesColors(dataset);
  }

  const options = {
    ...getDefaultOptions(chartType, undefined, dataset, data.thresholds, mediaType),
    events: {
      afterSetExtremes: (e: any) => syncZoomEvent(e),
    },
    xAxis: {
      type: "datetime",
      axisLabel: "Time",
      labels: {
        formatter: function (context: any) {
          const value = context.value;
          const range = context.chart?.xAxis[0]?.max || 0 - context.chart?.xAxis[0]?.min;
          const format = range < 3600 * 1000 ? "%M:%S" : "%H:%M:%S"; // https://redmine.testrtc.com/issues/9295 - "range" here is in milliseconds
          if (value >= 0) {
            return (window as any).Highcharts.dateFormat(format, value);
          } else {
            return `-${(window as any).Highcharts.dateFormat(format, Math.abs(value))}`;
          }
        },
      },
    },
    tooltip: {
      show: true,
      formatter: function () {
        const _this = this as any;
        if (_this.series.name.toLowerCase().indexOf("call end") !== -1) {
          const date = new Date(0);
          date.setSeconds(_this.x / 1000);

          const probeNumberSpan = data.firstProbEndsInSessionIndex
            ? `<span>Probe #${data.firstProbEndsInSessionIndex}</span><br />`
            : "";
          return `<strong>${_this.series.name}</strong><br />${probeNumberSpan}<span>${formatDuration(
            date.getTime(),
            "DHMS"
          )}</span>`;
        }

        if (
          _this.series.name.toLowerCase().indexOf("global") !== -1 ||
          _this.series.name.toLowerCase().indexOf("local") !== -1
        ) {
          const date = new Date(0);
          date.setSeconds(_this.x / 1000);
          const event = events.find((x: any) => x[0] === _this.x);
          if (event) {
            const eventName = event[2];
            const probeNumber = event[4];
            return `<strong>Probe #${probeNumber}</strong><br /><span>${eventName}</span><br/ ><span>${formatDuration(
              date.getTime(),
              "DHMS"
            )}</span>`;
          } else {
            return `<strong>${_this.series.name}</strong><br /><span>${formatDuration(date.getTime(), "DHMS")}</span>`;
          }
        }
        return false;
      },
    },
  };
  return {
    options,
    dataset,
  };
};

export const buildByProbePerfChart = (data: any, chartType: DataType) => {
  const src = data.fltrProbPerf;
  const sb = new SeriesBuilder(true);

  const sbConfig = {
    bars: { show: true, fill: false },
    plotOptions: {
      column: {
        opacity: 1,
      },
    },
  };
  const avgSeries = (src[chartType] && src[chartType].avg) || [[0, 0]];
  const maxSeries = (src[chartType] && src[chartType].max) || [[0, 0]];
  const minSeries = (src[chartType] && src[chartType].min) || [[0, 0]];

  const optRef = data.rawProbesPerfData ? data.rawProbesPerfData[chartType] : [[0, 0]];

  sb.addConfigs([sbConfig, sbConfig, sbConfig]);
  sb.addSeries([maxSeries, avgSeries, minSeries], ["Max", "Avg", "Min"], 1);

  sb.addHelperData({ tooltip: optRef }, 0);
  sb.addHelperData({ tooltip: optRef }, 1);
  sb.addHelperData({ tooltip: optRef }, 2);

  let dataset = sb.build();

  if (_.isArray(dataset)) {
    dataset = getSortedLegend(dataset);
    dataset = setSeriesColors(dataset);
  }

  const options = {
    ...getDefaultOptions(chartType),
    events: {
      afterSetExtremes: (e: any) => syncZoomEvent(e),
    },
  };

  return {
    options,
    dataset,
  };
};

const prepareBand = (min: any, max: any, dataFrequency: number, delayTime?: number) => {
  if (!min || !max) {
    return [];
  }

  const staggered = delayTime || 0;
  for (let i = 0; i < staggered; i++) {
    min.unshift(null);
    max.unshift(null);
  }
  // it works only for arrays which is equal
  // in case one larger than another - drop it on that point
  const len = min.length > max.length ? max.length : min.length;

  // prepare arrays to be flot-like series
  for (let i = 0; i < len; i++) {
    const tmpMin = min[i];
    const tmpMax = max[i];

    // set seconds
    min[i] = [i * dataFrequency, tmpMin];
    max[i] = [i * dataFrequency, tmpMax];

    // set bottom border
    max[i][2] = min[i][1];
  }

  return max;
};

const getSortedLegend = (dataset: Array<any>) => {
  const copy = _.cloneDeep(dataset);
  copy.sort((a, b) => {
    const aCopy = _.clone(a);
    const bCopy = _.clone(b);

    if (aCopy.label.indexOf("Global events") !== -1) {
      aCopy.label = "Global events";
    }
    if (bCopy.label.indexOf("Global events") !== -1) {
      bCopy.label = "Global events";
    }
    if (aCopy.label.indexOf("Join") !== -1) {
      aCopy.label = "Join";
    }
    if (bCopy.label.indexOf("Join") !== -1) {
      bCopy.label = "Join";
    }
    if (aCopy.label.indexOf("Leave") !== -1) {
      aCopy.label = "Leave";
    }
    if (bCopy.label.indexOf("Leave") !== -1) {
      bCopy.label = "Leave";
    }
    const labelOrder = [
      "Incoming",
      "Outgoing",
      "Average Load",
      "Min/max load band",
      "Max",
      "Avg",
      "Min",
      "Incoming min/max band",
      "Outgoing min/max band",
      "Global events",
      "Join",
      "Leave",
      "Call end",
    ];
    const aIndex = labelOrder.indexOf(aCopy.label);
    const bIndex = labelOrder.indexOf(bCopy.label);

    return aIndex - bIndex;
  });
  return copy;
};

const setSeriesColors = (dataset: Array<any>) => {
  const copy = _.cloneDeep(dataset);
  const map = {
    Incoming: "rgba(70, 130, 192, 1)",
    Outgoing: "rgba(162, 42, 33, 1)",
    "Incoming min/max band": "rgba(70, 130, 192, 0.7)",
    "Outgoing min/max band": "rgba(162, 42, 33, .7)",
    "Call end": "#f1cd2b",
    Avg: "rgb(192,127,70)",
    Min: "rgba(70, 130, 192, 1)",
    Max: "rgba(162, 42, 33, 1)",
  };

  copy.forEach((d) => {
    if (d.label.indexOf("Global events") !== -1) {
      d.color = "#262d31";
    } else if (d.label.indexOf("Join") !== -1) {
      d.color = "#0EEB1A";
    } else if (d.label.indexOf("Leave") !== -1) {
      d.color = "#f1cd2b";
    } else if (d.label.indexOf("Min/max load band") !== -1) {
      d.color = "rgba(150, 42, 33, .7)";
    } else {
      if (map[d.label]) {
        d.color = map[d.label];
      }
    }
  });
  return copy;
};

const getEvents = (data: any, performance = false) => {
  const events: any[] = data.events || [];
  const isThereActualEvents =
    events?.filter((evt) => {
      return (
        evt.events &&
        evt.events.length > 0 &&
        evt.events.filter((e: any) => {
          return e.type === "global";
        }).length > 0
      );
    }).length > 0;

  const eventsSeries: any = [];
  if (Array.isArray(events) && isThereActualEvents) {
    const globalEvents = ChartHelper.prepareGlobalEvents(events);
    ChartHelper.sortEventsAsc(globalEvents);

    if (data._chartSampleStartTime) {
      const initialPoint = new Date(data._chartSampleStartTime).getTime();

      let offset = 0;
      for (const event of globalEvents) {
        if (!event) {
          continue;
        }
        const divider = performance ? 1 : 1000;
        offset = (event.timestamp - initialPoint) / divider;
        let inSessionIdx = event.inSessionIdx;
        const splitMachineName = event.machine?.split("-");
        if (splitMachineName?.length > 0) {
          const sessionIndex = Number(splitMachineName[splitMachineName.length - 1]);
          if (!isNaN(sessionIndex)) {
            inSessionIdx = sessionIndex;
          }
        }

        eventsSeries[eventsSeries.length] = (data.audioOffset || data.audioOffset === 0) && [
          offset,
          -1,
          event.event,
          event.machine || event.metadata?.peerName || "",
          inSessionIdx,
          event.metadata || {},
          event.timestamp,
        ];
      }
    }
  }

  return eventsSeries;
};

const addTestingRTCEvents = (events: any[], sb: any) => {
  const numberOfEvents = events.filter((evt: any) => evt[1] === -1).length;
  sb.addLabel(`Global events - ${numberOfEvents}`);

  sb.addCustomSeries(events);
  sb.addHelperData({ tooltip: events }, 2);

  sb.addConfig({
    bars: { lineWidth: 0, align: "top", barWidth: 1, fill: true, show: true },
  });
};

const addWatchRTCEvents = (events: any[], sb: any) => {
  const joinEvents = events.filter((evt: any) => evt[2]?.toLowerCase() === "join");
  const leftEvents = events.filter((evt: any) => evt[2]?.toLowerCase() === "leave");

  // join and leave events are global
  // but we want to show them as separate series
  // and to separate all another global events we
  // filter global events without join and leave events
  const globalEvents = events.filter(
    (evt: any) => evt[1] === -1 && evt[2]?.toLowerCase() !== "join" && evt[2]?.toLowerCase() !== "leave"
  );

  sb.addLabel(`Join - ${joinEvents.length}`);
  sb.addCustomSeries(joinEvents);
  sb.addConfig({
    bars: { lineWidth: 0, align: "top", barWidth: 1, fill: true, show: true },
  });
  sb.addHelperData({ tooltip: events }, 2);

  sb.addLabel(`Leave - ${leftEvents.length}`);
  sb.addCustomSeries(leftEvents);
  sb.addConfig({
    bars: { lineWidth: 0, align: "top", barWidth: 1, fill: true, show: true },
  });
  sb.addHelperData({ tooltip: events }, 2);

  sb.addLabel(`Global events - ${globalEvents.length}`);
  sb.addCustomSeries(globalEvents);
  sb.addConfig({
    bars: { lineWidth: 0, align: "top", barWidth: 1, fill: true, show: true },
  });
  sb.addHelperData({ tooltip: events }, 2);
};

const getDefaultOptions = (
  dataType: DataType,
  isPCT = false,
  dataset: any[] = [],
  thresholds?: any,
  mediaType = ""
) => {
  if (isPCT && dataType == "loss") {
    dataType = "lossPCT";
  }
  const thresholdMapper = (dataType: string, mediaType: string) => {
    if (dataType === "videoFrameRate") {
      return thresholds["videoFrameRate"];
    }

    if (mediaType === "performance") {
      return thresholds[dataType];
    }

    if (mediaType) {
      if (dataType === "lossPCT") {
        return thresholds[`packetLoss_${mediaType}`];
      }

      if (dataType === "loss") {
        return thresholds[`packetLossNumber_${mediaType}`];
      }

      if (dataType === "jitter") {
        return thresholds[`jitter_${mediaType}`];
      }

      if (dataType === "videoDelay") {
        return thresholds[`rtt_${mediaType}`];
      }
      return thresholds[dataType];
    }
  };

  const threshold = thresholds && thresholdMapper(dataType, mediaType);
  return {
    chart: {
      zooming: { type: "x" },
    },
    yAxis: [
      {
        axisLabel: dataTypeToTitle[isPCT ? dataTypesValues.lossPCT : dataType].yAxisLabel,
        title: {
          text: dataTypeToTitle[dataType].yAxisLabel,
        },
        softMin: yAxisSoftMinMax.min[dataType],
        softMax: yAxisSoftMinMax.max[dataType],
        plotBands: thresholds ? getThresholdArea(threshold, getMaxY(dataset), mediaType) : undefined,
        plotLines: thresholds ? getThresholdLine(threshold, getMaxY(dataset), mediaType) : undefined,
        minRange: mediaType === "performance" ? threshold : undefined,
      },
      {
        visible: false,
        max: 1,
      },
    ],
    plotOptions: {
      column: {
        borderWidth: 0,
      },
    },
  };
};

export const getThresholdArea = (threshold: number, _maxY: number, mediaType: string) => {
  let result;

  if (threshold && (threshold <= _maxY || mediaType === "performance")) {
    result = [
      {
        from: threshold,
        to: 10000000,
        color: "#FAE6E4",
      },
    ];
  }

  return result;
};

export const getThresholdLine = (threshold: number, _maxY: number, mediaType: string) => {
  let result;

  if (threshold && (threshold <= _maxY || mediaType === "performance")) {
    result = [
      {
        value: threshold,
        color: "#f59e95",
        width: 3,
        dashStyle: "Dash",
      },
    ];
  }

  return result;
};

const byTimeTooltip = (data: any, events: any[]) => {
  return {
    show: true,
    backgroundColor: "#191b24bf",
    borderWidth: 0,
    style: {
      color: "#E6E6E7",
    },
    formatter: function () {
      const _this = this as any;
      const date = new Date(0);
      date.setSeconds(_this.x);
      const absoluteTime = data._chartSampleStartTime;
      const formattedAbsoluteTime = moment(absoluteTime).add("seconds", _this.x).format("HH:mm:ss");

      if (_this.series.name.toLowerCase().indexOf("call end") !== -1) {
        const probeNumberSpan = data.firstProbEndsInSessionIndex
          ? `<span>Probe #${data.firstProbEndsInSessionIndex}</span><br />`
          : "";
        return `<strong>${_this.series.name}</strong><br />${probeNumberSpan}<span>${formatDuration(
          date.getTime(),
          "DHMS"
        )}</span><br/><span>${formattedAbsoluteTime}</span>`;
      }
      if (
        _this.series.name.toLowerCase().indexOf("global") !== -1 ||
        _this.series.name.toLowerCase().indexOf("local") !== -1 ||
        _this.series.name.toLowerCase().indexOf("join") !== -1 ||
        _this.series.name.toLowerCase().indexOf("leave") !== -1
      ) {
        const event = events.find((x: any) => x[0] === _this.x);
        if (event) {
          const eventName = event[2];
          const peerName = event[3];
          const probeNumber = event[4];
          const metadata = event[5];
          const absoluteTime = event && moment(new Date(event[6])).format("HH:mm:ss");
          const probe_peerElement = data.watchRTC
            ? `<strong>${peerName}</strong>`
            : `<strong>Probe #${probeNumber}</strong>`;

          const wrtcParameters =
            data.watchRTC && metadata?.parameters
              ? `<br/><span>${JSON.stringify(metadata?.parameters, null, 2)}<span/><br/>`
              : "";

          return `
          <span>${probe_peerElement}</span>
          <br />
          <br />
          <span>${eventName}</span>
          <span>${wrtcParameters}</span>
          <br/ >
          <span>${formatDuration(date.getTime(), "DHMS")}</span>
          <br/>
          <span>${absoluteTime}</span>`;
        } else {
          return `<br/ ><br/ ><strong>${_this.series.name}</strong><br /><span>${formatDuration(
            date.getTime(),
            "DHMS"
          )}</span><br/><span>${formattedAbsoluteTime}</span>`;
        }
      }

      return false;
    },
  };
};

const syncZoomEvent = (e: any) => {
  const charts = (window as any).Highcharts?.charts;
  const eChart = e.target.chart;
  const zoomTrigger = e.trigger === "zoom";

  // don't execute for nested events
  if (charts.length && zoomTrigger) {
    const eMin = e.min;
    const eMax = e.max;
    const userMax = e.userMax;
    const eventIndex = eChart.index ? eChart.index : 0;

    // only trigger zoom event for others charts.(exclude event chart)
    (charts as Array<any>)
      .filter((chart: any) => chart && chart.index !== eventIndex)
      .forEach((c: any) => {
        // sync show/hide Rest Zoom button
        if (userMax) {
          c.xAxis[0].setExtremes(eMin, eMax, true);
          !c.resetZoomButton && c.showResetZoom();
        } else {
          c.zoomOut();
        }
      });
  }
};

export const checkIfHasData = (data: any, type: "audio" | "video") => {
  try {
    const sourceKeys = ["fltrDataset", "fltrDataset_BCNL", "probDatasetSorted"];
    const directions = ["recv", "send"];

    let hasData = false;

    sourceKeys.forEach((src) => {
      if (!data[src]) {
        return;
      }

      directions.forEach((dir) => {
        if (data[src][type] && data[src][type][dir]) {
          const dataKeys = Object.keys(data[src][type][dir]);
          dataKeys.forEach((dk) => {
            const values = data[src][type][dir][dk];

            if (values?.length) {
              const valuesAreNullOrZeroOrNan = values.every((x: any) => !x || isNaN(x));
              if (!valuesAreNullOrZeroOrNan) {
                hasData = true;
              }
            }
          });
        }
      });
    });

    return hasData;
  } catch (err) {
    reportError("Couldn't check if has data", err);
    return true;
  }
};

export const checkIfHasPerfData = (data: any) => {
  try {
    let hasData = false;
    const sources = ["fltrPerf", "fltrProbPerf"]; // processedData keys
    const usedKeys = ["browserCpu", "browserMemory"];
    const metrics = ["avg", "min", "max"];
    sources.forEach((source) => {
      if (data.hasOwnProperty(source) && data[source]) {
        if (source === "fltrPerf") {
          usedKeys.forEach((key) => {
            if (data[source].hasOwnProperty(key) && data[source][key]) {
              metrics.forEach((metric) => {
                if (data[source][key].hasOwnProperty(metric)) {
                  const values = data[source][key][metric];
                  const isAllNull = values.every((x: any) => x === 0 || x === null || isNaN(x));
                  if (!isAllNull) {
                    hasData = true;
                  }
                }
              });
            }
          });
        }
        if (source === "fltrProbPerf") {
          usedKeys.forEach((key) => {
            if (data[source].hasOwnProperty(key)) {
              const probesPerformance = data[source][key];
              if (probesPerformance?.length) {
                probesPerformance.forEach((probeData: any) => {
                  metrics.forEach((metric) => {
                    const value = probeData.values[metric];
                    if (value === null || isNaN(value)) {
                      hasData = false;
                    }
                  });
                });
              }
            }
          });
        }
      } else {
        return;
      }
    });
    return hasData;
  } catch (err) {
    reportError("Couldn't check if has performance data", err);
    return false;
  }
};
