import { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";

const useIsElementOverflowing = <HTMLElementType extends HTMLElement>() => {
  const elementRef = useRef<HTMLElementType>(null);

  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(function initIsOverflowingCheck() {
    const checkIfOverflowing = () => {
      if (elementRef.current) {
        setIsOverflowing(elementRef.current.scrollWidth > elementRef.current.clientWidth);
      }
    };
    const checkIfOverflowingDebounced = debounce(checkIfOverflowing, 200);

    checkIfOverflowing();
    window.addEventListener("resize", checkIfOverflowingDebounced);

    return () => {
      window.addEventListener("resize", checkIfOverflowingDebounced);
    };
  }, []);

  return { elementRef, isOverflowing };
};

export default useIsElementOverflowing;
