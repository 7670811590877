import * as React from "react";
import { Theme, Tab, Tabs, Box } from "@mui/material";

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

type TabsProps = {
  content: React.ReactNode[];
  labels: string[];
};

interface ITabState {
  value: any;
  visible: boolean;
}

type StyledComponent = WithStyles<typeof styles>;

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

class Tabulation extends React.Component<TabsProps & StyledComponent, ITabState> {
  constructor(props: TabsProps & StyledComponent) {
    super(props);
    this.state = {
      value: 0,
      visible: false,
    };
  }

  handleChange = (_event: any, newValue: any) => {
    this.setState({
      value: newValue,
    });
  };

  render() {
    const { content, labels, classes } = this.props;
    const { value } = this.state;

    const tabs = labels.map((label, index) => {
      return <Tab className={classes.tab} key={index} label={label} />;
    });
    const panels = content.map((node, index) => {
      return (
        <TabPanel className={classes.panel} value={value} index={index} key={index}>
          {node}
        </TabPanel>
      );
    });

    return (
      <div>
        <Tabs textColor="inherit"  indicatorColor="secondary" variant={"scrollable"} value={value} onChange={this.handleChange} className={classes.tabsBar}>
          {tabs}
        </Tabs>
        {panels}
      </div>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    tab: {
      textTransform: "none",
      minWidth: 72,
      [_theme.breakpoints.up('sm')] : {
        minWidth: 160,
      }
    },
    panel: { padding: 0 },
    tabsBar: {
      backgroundColor: "white",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
  });

export default withStyles(styles)(Tabulation);
