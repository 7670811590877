import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";

import { useLegend } from "../hooks/useLegend";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      paddingLeft: 23,
    },
    label: {
      fontSize: 12,
      fontFamily: `"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif`,
      color: "#337ab7",
      "&:hover": {
        color: "#23527c",
      },
    },
    labelUnchecked: {
      color: "#cccccc",
    },
    checkbox: {
      color: "rgba(0, 0, 0, 0.54)",
      padding: 0,
      "& svg": {
        height: 18,
      },
    },
    incomingCheckboxChecked: {
      color: "#4782c0 !important",
    },
    outgoingCheckboxChecked: {
      color: "#a22a21 !important",
    },
  })
);

interface InOutToggleProps {
  isIncomingActive: boolean;
  isOutgoingActive: boolean;
  toggleIsIncomingActive: () => void;
  toggleIsOutgoingActive: () => void;
}

const InOutToggle = ({
  isIncomingActive,
  isOutgoingActive,
  toggleIsIncomingActive,
  toggleIsOutgoingActive,
}: InOutToggleProps) => {
  const classes = useStyles();

  const { showInOut, inOutToggleSeriesTooltips } = useLegend();

  if (!showInOut) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Tooltip title={inOutToggleSeriesTooltips["Incoming"]}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isIncomingActive}
              onChange={toggleIsIncomingActive}
              name="incoming"
              color="primary"
              size={"small"}
              classes={{ root: classes.checkbox, checked: classes.incomingCheckboxChecked }}
            />
          }
          classes={{
            label: `${classes.label} ${!isIncomingActive ? classes.labelUnchecked : ""}`,
          }}
          label="Incoming"
        />
      </Tooltip>
      <Tooltip title={inOutToggleSeriesTooltips["Outgoing"]}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isOutgoingActive}
              onChange={toggleIsOutgoingActive}
              name="outgoing"
              size={"small"}
              classes={{
                root: classes.checkbox,
                checked: classes.outgoingCheckboxChecked,
              }}
            />
          }
          classes={{
            label: `${classes.label} ${!isOutgoingActive ? classes.labelUnchecked : ""}`,
          }}
          label="Outgoing"
        />
      </Tooltip>
    </div>
  );
};

export default InOutToggle;
