import { Theme } from "@mui/material/styles";
import Header from "./Header";
import NavItem from "./NavItem";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    section: {
      position: "relative",
    },
    spacing: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  })
);

const NavList = (props: {
  items: any[];
  name: string;
  admin?: boolean;
  openedItems?: string[];
  setOpenedItems?: (...props: any) => void;
}) => {
  const { items = [], name = "", admin = false, openedItems, setOpenedItems } = props;
  const classes = useStyles();

  const render_items: any = [];

  const onToggleClick = (name: string) => (setOpenedItems ? setOpenedItems([name]) : () => true);

  items.forEach((item: any, index: any) => {
    if (item && !item.hidden) {
      render_items.push(
        <NavItem
          {...item}
          id={!!item?.id}
          key={index}
          onToggleClick={onToggleClick}
          open={openedItems && openedItems.includes(item.name)}
        />
      );
    }
  });

  return (
    <div className={classes.section}>
      {!!name && <Header name={name} admin={admin} />}
      <div className={classes.spacing}>{render_items}</div>
    </div>
  );
};

export default NavList;
