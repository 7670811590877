import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";

interface ICiphersProps {
  data: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "flex-start",
    },
    title: {
      marginRight: theme.spacing(1),
    },
    table: {},
  })
);

const Ciphers = ({ data }: ICiphersProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid item={true} xs={12} md={2}>
        <Typography variant="subtitle1" className={classes.title}>
          Ciphers:
        </Typography>
      </Grid>
      <Grid
        item={true}
        xs={12}
        md={10}
        sx={{
          wordBreak: "break-all",
        }}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Audio</TableCell>
              <TableCell>Video</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>TLS Version</TableCell>
              <TableCell>{data.audio_tlsVersion}</TableCell>
              <TableCell>{data.video_tlsVersion}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DTLS</TableCell>
              <TableCell>{data.audio_dtlsCipher}</TableCell>
              <TableCell>{data.video_dtlsCipher}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SRTP</TableCell>
              <TableCell>{data.audio_srtpCipher}</TableCell>
              <TableCell>{data.video_srtpCipher}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </div>
  );
};

export default Ciphers;
