import { Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: "#8dc3ff",
      textTransform: "uppercase",
      padding: "0 16px",
    },
    spacing: {
      marginTop: theme.spacing(3),
    },
  })
);

type HeaderProps = {
  name: string;
  admin?: boolean;
};

const Header = ({ name, admin }: HeaderProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={`${classes.header} ${admin ? classes.spacing : ""}`} variant={"body2"}>
        {name}
      </Typography>
    </>
  );
};

export default Header;
