import { axiosExternalInstance, axiosInstance } from "./AxiosService";
import { AxiosResponse } from "axios";

export default class DumpFileService {
  static getDumpFile(url: string, config?: any, isFramed?: boolean): Promise<AxiosResponse> {
    return (!isFramed ? axiosInstance : axiosExternalInstance).get(url, config);
  }

  static replayAsWatchRTC(url: string) {
    return axiosInstance.get(url);
  }
}
