import * as _ from "lodash";

import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { toKeyValue } from "src/helpers/objHelper";
import KeyValueTableCell from "./KeyValueTableCell";

interface IRTCConfiguration {
  config: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      overflow: "auto",
    },
    padding: {
      paddingRight: 5,
    },
    focusedText: {
      maxWidth: 900,
      overflow: "auto",
      background: "#f3f3f3",
      boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12)`,
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    configs: {
      "& > div:not(:first-of-type)": {
        marginTop: 20,
      },
    },
  })
);

const RTCConfiguration = ({ config }: IRTCConfiguration) => {
  const classes = useStyles();

  const deleteInternalFields = (config: any) => {
    const internalFields = [
      "rtcApiKey",
      "rtcRoomId",
      "rtcPeerId",
      "projectId",
      "interval",
      "sendInterval",
      "conferenceClousureWaitTime",
      "connectionId",
      "clientId",
      "sdkVersion",
      "endTime",
      "hardware",
    ];
    internalFields.forEach((field: string) => {
      delete config[field];
    });
  };

  const markStandardFields = (_data: any[]) => {
    let data = [];
    _data.forEach((item: any) => {
      let flag = false;
      [
        "iceServers",
        "iceTransportPolicy",
        "bundlePolicy",
        "rtcpMuxPolicy",
        "certificates",
        "iceCandidatePoolSize",
      ].forEach((field) => {
        if (item?.key?.includes(field)) {
          flag = true;
        }
      });
      item.default = flag ? 1 : 0;
    });
    data = _data;
    return data;
  };

  const makeJson = (str: string) => {
    let prepearedStr = str.replace(/ /g, "").replace(/"/g, "'");
    // remove curly braces at start and end
    prepearedStr = prepearedStr.substring(1);
    prepearedStr = prepearedStr.substring(0, prepearedStr.length - 1);

    const iceServers = prepearedStr.substring(prepearedStr.indexOf("[") + 1, prepearedStr.lastIndexOf("]")).split(",");

    const result = {
      iceServers: [] as Array<any>,
    };
    for (let i = 0; i < iceServers.length; i++) {
      const key = iceServers[i].substring(0, iceServers[i].indexOf(":"));
      // replace . with {*} to correct parse object to key value object
      const value = iceServers[i].substring(iceServers[i].indexOf(":") + 1);
      const iceServer = {
        [key]: value.replace(/\./g, "{*}"),
      };
      result.iceServers.push(iceServer);
    }

    const stringKeysWithoutIceServers = prepearedStr.substring(prepearedStr.lastIndexOf("]") + 2).split(",");
    for (let i = 0; i < stringKeysWithoutIceServers.length; i++) {
      const keyValue = stringKeysWithoutIceServers[i].split(":");
      const key = keyValue[0];
      const value = keyValue[1];
      result[key] = value;
    }
    return result;
  };

  const getConfiguration = (config: any) => {
    try {
      if (typeof config === "string") {
        const json = makeJson(config);
        const objKeyValues = toKeyValue(json);
        return (
          <div className={classes.focusedText}>
            <KeyValueTableCell data={objKeyValues} />
          </div>
        );
      } else if (typeof config === "object") {
        if (_.isArray(config.iceServers)) {
          config.iceServers.forEach((is: any) => {
            if (_.isString(is.urls)) {
              is.urls = is.urls.replace(/\./g, "{*}");
            }
            if (_.isString(is.url)) {
              is.url = is.url.replace(/\./g, "{*}");
            }
          });
        }
        if (_.isString(config.wsServer)) {
          config.wsServer = config.wsServer.replace(/\./g, "{*}");
        }
        deleteInternalFields(config);

        const data = markStandardFields(toKeyValue(config));
        const standard = data.filter((item) => item.default);
        const proprietary = data.filter((item) => !item.default);
        return (
          <>
            {!!standard.length && (
              <Grid item>
                <Typography variant="subtitle1">Standard parameters:</Typography>
                <div className={classes.focusedText}>
                  <KeyValueTableCell data={standard} />
                </div>
              </Grid>
            )}
            {!!proprietary.length && (
              <Grid item>
                <Typography variant="subtitle1">Proprietary parameters:</Typography>
                <div className={classes.focusedText}>
                  <KeyValueTableCell data={proprietary} />
                </div>
              </Grid>
            )}
          </>
        );
      } else {
        return (
          <div className={classes.focusedText}>
            <KeyValueTableCell data={[]} />
          </div>
        );
      }
    } catch (err) {
      return (
        <div className={classes.focusedText}>
          <Typography variant="body2">{JSON.stringify(config)}</Typography>
        </div>
      );
    }
  };

  return (
    <Toolbar className={classes.toolbar}>
      <Grid item xs={12} md={2} className={classes.padding}>
        <Typography variant="subtitle1">Configuration:</Typography>
      </Grid>
      <Grid item container direction="column" xs={12} md={10} className={classes.configs}>
        {getConfiguration(config)}
      </Grid>
    </Toolbar>
  );
};

export default RTCConfiguration;
