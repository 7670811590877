import * as React from "react";
import * as _ from "lodash";
// import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Tooltip,
  Tab,
  Tabs,
  AppBar,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { IExpectation } from "./Interfaces";
import { MapMetric, MapType } from "src/constants/watchRTCExpectation";
import TableFilter from "./TableFilter";
import { insightCodeLinkMap } from "../TestIteration/Components/Overview/LogToolbar/View";

interface IExpectationsProps {
  expectations: IExpectation[];
  userMedia: any[];
  link: string;
}

const TableHeadStyles = {
  backgroundColor: "#F5F5F6",
  color: "#121C2D",
  fontWeight: 600,
  fontSize: "0.875rem",
  padding: "16px 16px",
} as React.CSSProperties;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(3),

      breakInside: "avoid",
    },
    divider: {
      margin: "16px -16px",
    },
    limitButton: {
      margin: "auto",
      width: "100%",
      display: "block",
      color: theme.palette.primary.main,
      borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "18px 0px",
      borderRadius: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    column: {},
    customColumn: {
      ...TableHeadStyles,
      width: "15%",
      borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      paddingLeft: "32px",
    },
    typeColumn: {
      ...TableHeadStyles,
      width: "15%",
    },
    metricColumn: {
      ...TableHeadStyles,
      width: "70%",
    },
    peerNameColumn: {
      ...TableHeadStyles,
      width: "15%",
    },
    peerNoColumn: {
      ...TableHeadStyles,
      width: "5%",
    },
    toolbar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      position: "relative",
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    filterContainer: {
      display: "flex",
      flexDirection: "row",
    },
    insightLink: {
      color: theme.palette.primary.main,

      "&:hover": { color: theme.palette.primary.dark },
    },
  })
);

export const MapTypeLabel = ({ type }: { type: string }) => {
  const color = (opacity: number) => {
    const COLORS = {
      error: `rgba(222, 28, 26, ${opacity})`,
      warning: `rgba(255, 157, 0, ${opacity})`,
      info: `rgba(0, 161, 95, ${opacity})`,
    };
    return COLORS[type.toLowerCase()];
  };

  return (
    <Box py={"1px"} px={"8px"} bgcolor={color(0.15)} maxWidth={"max-content"} borderRadius={"4px"}>
      <Typography
        color={color(1)}
        fontSize={"12px"}
        lineHeight={"18px"}
        letterSpacing={"3%"}
        textAlign={"center"}
        fontWeight={500}
      >
        {type}
      </Typography>
    </Box>
  );
};

const Expectations = ({ expectations, userMedia }: IExpectationsProps) => {
  const classes = useStyles();
  const [all, setAll] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [checked, setChecked] = React.useState<boolean[]>([true, true]);
  const [filteredData, setFilteredData] = React.useState<IExpectation[]>(expectations);

  const types: string[] = Array.from(new Set(expectations.map((ex) => ex.type)));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(event);
    setValue(newValue);
  };

  if (!expectations?.length) {
    return null;
  }

  // refactored to show rules engine error above all errors and rules engine warnings above all warnings
  const sort = (a: IExpectation, b: IExpectation) => {
    //return a.type === "error" ? -1 : 0;
    if (a.type === "error" && a.metric === MapMetric.RulesEngine) {
      return -1;
    } else if (a.type === "error" && !(b.type === "error" && b.metric === MapMetric.RulesEngine)) {
      return -1;
    } else if (a.type === "warning" && a.metric === MapMetric.RulesEngine && !(b.type === "error")) {
      return -1;
    } else {
      return 0;
    }
  };

  const formatMetric = (ex: IExpectation) => {
    // metric : ICERestart, ICEFailure, isGetUserMediaError are moved to insights
    // which is metric : RulesEngine
    // so metric === "ICERestart" or "ICEFailure" or "isGetUserMediaError" never hit
    // but can if old records pop up via Select All filter, so we show to maintain counts and messages
    if (ex.metric === MapMetric.RulesEngine) {
      const insightCode = ex.value;
      return (
        <>
          <a href={insightCodeLinkMap[insightCode]} target="_blank" rel="noreferrer" className={classes.insightLink}>
            {insightCode}
          </a>{" "}
          : {ex.message}
        </>
      );
    } else if (ex.metric === "ICERestart" || ex.metric === "ICEFailure") {
      return ex.metric;
    } else if (ex.metric === "isGetUserMediaError") {
      return "GetUserMediaError";
    } else {
      return `${MapMetric[ex.metric] ?? ex.metric} ${ex.value} ${ex.operator} ${ex.threshold}`;
    }
  };

  const formatMessage = (ex: IExpectation) => {
    // metric : isGetUserMediaError are moved to insights
    // which is metric : RulesEngine
    // so metric === "isGetUserMediaError" never hit
    // but can if old records pop up, so we show to maintain counts and messages
    if (ex.metric === MapMetric.RulesEngine) {
      return "";
    } else if (ex.metric === "isGetUserMediaError") {
      const failureMessage = userMedia.find((user) => user.type === "navigator.mediaDevices.getUserMediaOnFailure");

      return `Failed to GetUserMedia: ${failureMessage?.value || ""}`;
    } else {
      return ex.message;
    }
  };

  const dynamicText = (ex: IExpectation): string => {
    return ex.metric === "RulesEngine" ? "Insight" : "Alerts";
  };

  return (
    <>
      <Grid className={classes.container}>
        <AppBar className={classes.toolbar}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label={
                <span>
                  <span>{`Notifications ${expectations.length > 0 ? "(" + expectations.length + ")" : ""}`}</span>
                </span>
              }
            />
          </Tabs>
        </AppBar>
        <Stack className={classes.filterContainer}>
          <Box
            sx={{
              width: expanded ? "150px" : "28px",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "column",
              display: expectations.length > 5 && value === 0 ? "flex" : "none",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F5F5F6",
                width: "100%",
                borderRadius: "0px",
                "&:hover": {
                  backgroundColor: "#F5F5F6",
                },
                position: "relative",
                minHeight: "57px",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <Button
                sx={{
                  padding: "12px 16px",
                  position: "absolute",
                  right: "-32px",
                  rotate: expanded ? "180deg" : "0deg",
                  height: "100%",
                }}
                onClick={() => setExpanded(!expanded)}
              >
                <PlayCircleOutlineIcon
                  sx={{
                    color: "#0000008A",
                  }}
                />
              </Button>
            </Box>
            <TableFilter
              types={types}
              filteredData={expectations}
              setFilteredData={setFilteredData}
              checked={checked}
              setChecked={setChecked}
              expanded={expanded}
            />
          </Box>
          {value === 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.customColumn}>Type</TableCell>
                  <TableCell className={classes.typeColumn}>Severity</TableCell>
                  <TableCell className={classes.metricColumn}>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .sort(sort)
                  .slice(0, all ? expectations.length : 2)
                  .map((ex, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell
                          sx={{
                            borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                            paddingLeft: "32px",
                          }}
                        >
                          {/* Is here a static value? */}
                          {dynamicText(ex)}
                        </TableCell>
                        <TableCell>
                          <Tooltip title={_.capitalize(ex.type)} placement="top">
                            <MapTypeLabel type={MapType[ex.type]} />
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Stack>
                            <Box>{formatMetric(ex)}</Box>
                            <Box color={"#de1c1a"}>{formatMessage(ex)}</Box>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {filteredData.length > 2 && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        padding: 0,
                      }}
                    >
                      <Button onClick={() => setAll(!all)} className={classes.limitButton}>
                        {`Show ${all ? "less" : "all"}`}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default Expectations;
