import * as z from "zod";

type ErrorWithMessage = {
  message: string;
};

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
};

const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (isErrorWithMessage(maybeError)) {
    return maybeError;
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    return new Error(String(maybeError));
  }
};

const validate = <T extends z.ZodType>(schema: T, data: any): any => {
  return schema.parse(data);
};

const getZodErrorMessage = (error: z.ZodError<any>) => {
  return toErrorWithMessage(error.issues[0]).message;
};

export { getZodErrorMessage, validate };
