import { withRouter, RouteComponentProps } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import AppError from "./AppError";
import { NavbarHeight, DrawerWidth } from "../../../styles/Constants";
import { useContext } from "react";
import { AppContext } from "../../../App";

const AppContent = (
  props: WithStyles<"contentProperty" | "contentWrapper"> & RouteComponentProps<{}> & { children: any }
) => {
  const { classes, children } = props;
  const { error } = useContext(AppContext);
  return (
    <main id="content" className={classes.contentProperty}>
      {!error ? <div className={classes.contentWrapper}>{children}</div> : <AppError />}
    </main>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    contentProperty: {
      width: "100%",
      minHeight: `calc(100vh - ${NavbarHeight}px)`,
      flexGrow: 1,
      padding: 0,
      marginTop: NavbarHeight,
      overflowY: "auto",

      [theme.breakpoints.up("md")]: {
        position: "relative",
        width: `calc(100% - ${DrawerWidth}px)`,
      },

      "@media (max-width: 520px)": {
        marginTop: NavbarHeight + 40,
      },

      "@media screen": {
        backgroundColor: "#EDEDED",
      },
      "@media print": {
        overflowY: "visible",
      },
    },
    contentWrapper: {
      padding: 16,
      marginBottom: theme.spacing(7),
    },
  });

const decorate = withStyles(styles);

export default withRouter(decorate(AppContent));
