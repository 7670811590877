import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

import SigninForm from "./SigninForm";
// import AnalyzerBanner from "../../../components/auth/AnalyzerBanner";
import LoginSocialButtons from "../../../components/auth/LoginSocialButtons";
import FooterColors from "../../../components/auth/FooterColors";
import RemindPasswordModal from "./RemindPasswordModal";
import SubscribeModal from "./SubscribeModal";
import { ILoginForm } from "./index";
import SingleSignOnForm from "./SingleSignOnForm";

interface ViewProps {
  remindOpened: boolean;
  subscribeOpened: boolean;
  emailRemind: string;
  isLoading: boolean;
  closeModal: () => void;
  onSubmit: (values: ILoginForm) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5),
      position: "relative",
      paddingTop: 0,
      [theme.breakpoints.down(1680)]: {
        padding: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: 30,
      },
    },
    orText: {
      margin: `0 0 ${theme.spacing(2)} 0`,
      padding: "0 10px",
      textAlign: "center",
      textTransform: "uppercase",
    },
    title: {
      fontSize: "1.9rem",
      color: "white",
      fontWeight: 300,
      margin: 0,
      userSelect: "none",
    },
    titleContainer: {
      backgroundColor: theme.palette.primary.main,
      padding: "40px",
      paddingBottom: "10px",
      paddingTop: "20px",
      margin: "0 -40px",
      justifyContent: "center",
      [theme.breakpoints.down(1680)]: {
        margin: `0 -${theme.spacing(2)}`,
      },
      marginBottom: 10,
    },
    link: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      "&:active": {
        textDecoration: "none",
      },
    },
  })
);

const View = (props: ViewProps) => {
  const classes = useStyles();

  const { isLoading, onSubmit } = props;

  return (
    <Grid container={true} direction="row" justifyContent="center">
      <Grid item={true} xs={12} sm={8} md={6} lg={4}>
        <Slide direction="right" in={true} mountOnEnter={true} unmountOnExit={true}>
          <Paper className={classes.root} elevation={4}>
            <RemindPasswordModal
              emailRemind={props.emailRemind}
              isOpen={props.remindOpened}
              onClose={props.closeModal}
            />
            <SubscribeModal emailRemind={props.emailRemind} isOpen={props.subscribeOpened} onClose={props.closeModal} />
            <div className={classes.titleContainer}>
              <Typography className={classes.title} align="left" variant="h6">
                Login to testRTC
              </Typography>
            </div>
            <Grid container={true}>
              <Grid item={true} xs={12}>
                <SigninForm isLoading={isLoading} onSubmit={onSubmit} />
              </Grid>
              <Grid item={true} xs={12}>
                <div className={classes.orText}>
                  <Typography variant="body2">Or</Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} justifyContent="center" display="flex">
              <LoginSocialButtons />
            </Grid>
            <Grid item xs={12} justifyContent="center" display="flex" paddingTop={2}>
              <SingleSignOnForm />
            </Grid>
            <FooterColors />
          </Paper>
        </Slide>
      </Grid>
      {/* <Grid item={true} xs={12} sm={4} lg={3}>
        <AnalyzerBanner />
      </Grid> */}
    </Grid>
  );
};

export default View;
