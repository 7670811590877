import { useState, useEffect, RefObject } from "react";

import { HighchartsChartRef, LegendSeries } from "src/components/Chart/types";

interface UseUserChoices {
  chartRefOrRefs: RefObject<HighchartsChartRef> | RefObject<HighchartsChartRef>[];
  series: LegendSeries[];
}

export const useUserChoices = ({ chartRefOrRefs, series }: UseUserChoices) => {
  const [userChoices, setUserChoices] = useState<Record<string, boolean>>({});
  const [isIncomingActive, setIsIncomingActive] = useState(true);
  const [isOutgoingActive, setIsOutgoingActive] = useState(true);

  useEffect(
    function includeNewUserChoices() {
      const newUserChoices = { ...userChoices };

      for (const thisSeries of series) {
        if (newUserChoices[thisSeries.name] === undefined) {
          if (thisSeries.visible !== undefined) {
            newUserChoices[thisSeries.name] = thisSeries.visible;
          } else if (thisSeries.name.toLowerCase().includes("incoming")) {
            newUserChoices[thisSeries.name] = isIncomingActive;
          } else if (thisSeries.name.toLowerCase().includes("outgoing")) {
            newUserChoices[thisSeries.name] = isOutgoingActive;
          } else {
            newUserChoices[thisSeries.name] = true;
          }
        }
      }

      for (const seriesName of Object.keys(newUserChoices)) {
        toggleChartSeriesVisibility(seriesName, newUserChoices[seriesName]);
      }

      setUserChoices(newUserChoices);
    },
    [series]
  );

  const toggleUserChoice = (name: string, shouldBeVisible: boolean) => {
    setUserChoices((currentUserChoices) => ({
      ...currentUserChoices,
      [name]: shouldBeVisible,
    }));
  };

  const toggleUserChoicesByNamePart = (namePart: string, shouldBeVisible: boolean) => {
    setUserChoices((currentUserChoices) => {
      const result = { ...currentUserChoices };

      for (const seriesName of Object.keys(result)) {
        if (namePart === "" || seriesName.toLowerCase().includes(namePart)) {
          result[seriesName] = shouldBeVisible;
        }
      }

      return result;
    });
  };

  const toggleChartSeriesVisibility = (name: string, shouldBeVisible: boolean) => {
    if (Array.isArray(chartRefOrRefs)) {
      for (const chartRef of chartRefOrRefs) {
        const foundSeries = chartRef.current?.chart?.series?.find((currentSeries: any) => currentSeries.name === name);

        if (foundSeries) {
          if (shouldBeVisible && !foundSeries.visible) {
            foundSeries.show();
          } else if (!shouldBeVisible && foundSeries.visible) {
            foundSeries.hide();
          }
        }
      }
    } else {
      const foundSeries = chartRefOrRefs.current?.chart?.series?.find(
        (currentSeries: any) => currentSeries.name === name
      );

      if (foundSeries) {
        if (shouldBeVisible && !foundSeries.visible) {
          foundSeries.show();
        } else if (!shouldBeVisible && foundSeries.visible) {
          foundSeries.hide();
        }
      }
    }
  };

  const toggleChartSeriesVisibilityByNamePart = (namePart: string, shouldBeVisible: boolean) => {
    if (Array.isArray(chartRefOrRefs)) {
      for (const chartRef of chartRefOrRefs) {
        if (chartRef.current?.chart?.series) {
          for (const thisSeries of chartRef.current.chart.series) {
            const { name: seriesName } = thisSeries;

            if (namePart === "" || seriesName.toLowerCase().includes(namePart)) {
              if (shouldBeVisible && !thisSeries.visible) {
                thisSeries.show();
              } else if (!shouldBeVisible && thisSeries.visible) {
                thisSeries.hide();
              }
            }
          }
        }
      }
    } else {
      if (chartRefOrRefs.current?.chart?.series) {
        for (const thisSeries of chartRefOrRefs.current.chart.series) {
          const { name: seriesName } = thisSeries;

          if (namePart === "" || seriesName.toLowerCase().includes(namePart)) {
            if (shouldBeVisible && !thisSeries.visible) {
              thisSeries.show();
            } else if (!shouldBeVisible && thisSeries.visible) {
              thisSeries.hide();
            }
          }
        }
      }
    }
  };

  const getIsVisible = (name: string) => userChoices[name] ?? false;

  const toggleIndividualSeriesVisibility = (name: string) => {
    const shouldBeVisible = !getIsVisible(name);

    toggleUserChoice(name, shouldBeVisible);
    toggleChartSeriesVisibility(name, shouldBeVisible);
  };

  const toggleIsIncomingActive = () => {
    setIsIncomingActive(!isIncomingActive);

    toggleUserChoicesByNamePart("incoming", !isIncomingActive);
    toggleChartSeriesVisibilityByNamePart("incoming", !isIncomingActive);
  };

  const toggleIsOutgoingActive = () => {
    setIsOutgoingActive(!isOutgoingActive);

    toggleUserChoicesByNamePart("outgoing", !isOutgoingActive);
    toggleChartSeriesVisibilityByNamePart("outgoing", !isOutgoingActive);
  };

  const toggleAll = () => {
    const isAllVisible = Object.values(userChoices).every((isVisible) => isVisible);

    setIsIncomingActive(!isAllVisible);
    setIsOutgoingActive(!isAllVisible);

    toggleUserChoicesByNamePart("", !isAllVisible);
    toggleChartSeriesVisibilityByNamePart("", !isAllVisible);
  };

  return {
    userChoices,
    isIncomingActive,
    isOutgoingActive,
    toggleIndividualSeriesVisibility,
    toggleIsIncomingActive,
    toggleIsOutgoingActive,
    toggleAll,
  };
};
