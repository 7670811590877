export type MediaType = "audio" | "video" | "performance";
export const mediaType = {
  audio: "audio",
  video: "video",
  performance: "performance",
};
export const medias = [mediaType.audio, mediaType.video, mediaType.performance];
export const mediaIcons = {
  [mediaType.audio]: "headset",
  [mediaType.video]: "videocam",
  [mediaType.performance]: "speed",
};

type ChartData = { [key in MediaType]: string[] };

interface ICharts {
  throughput: ChartData;
  quality: ChartData;
}

const chartTypeValues = {
  flotBits: "flotBits",
  flotPacket: "flotPacket",
  flotPacketLossNumber: "flotPacketLossNumber",
  flotPacketLossPct: "flotPacketLossPct",
  flotJitter: "flotJitter",
  delay: "delay",
  frameRate: "frameRate",
  cpu: "cpu",
  memory: "memory",
};

export const charts: ICharts = {
  throughput: {
    audio: [chartTypeValues.flotBits, chartTypeValues.flotPacket],
    video: [chartTypeValues.flotBits, chartTypeValues.flotPacket],
    performance: [chartTypeValues.cpu],
  },
  quality: {
    audio: [
      chartTypeValues.flotJitter,
      chartTypeValues.delay,
      chartTypeValues.flotPacketLossPct,
      chartTypeValues.flotPacketLossNumber,
    ],
    video: [
      chartTypeValues.flotJitter,
      chartTypeValues.delay,
      chartTypeValues.flotPacketLossPct,
      chartTypeValues.flotPacketLossNumber,
      chartTypeValues.frameRate,
    ],
    performance: [chartTypeValues.memory],
  },
};

export const chartTypeMapper: {
  [key: string]: { label: string; yAxisLabel: string; yAxis2Label?: string };
} = {
  [chartTypeValues.flotBits]: {
    label: "Bitrate (ms)",
    yAxisLabel: "Kbits",
  },
  [chartTypeValues.flotPacket]: {
    label: "Packets",
    yAxisLabel: "Packets",
  },
  [chartTypeValues.flotJitter]: {
    label: "Jitter",
    yAxisLabel: "ms",
  },
  [chartTypeValues.flotPacketLossNumber]: {
    label: "Packets Loss (#)",
    yAxisLabel: "#",
  },
  [chartTypeValues.flotPacketLossPct]: {
    label: "Packet Loss (%)",
    yAxisLabel: "%",
  },
  [chartTypeValues.delay]: {
    label: "Round trip time",
    yAxisLabel: "ms",
  },
  [chartTypeValues.frameRate]: {
    label: "Frame Rate",
    yAxisLabel: "fps",
  },
  [chartTypeValues.cpu]: { label: "CPU Usage", yAxisLabel: "%" },
  [chartTypeValues.memory]: { label: "Memory Usage", yAxisLabel: "MBytes" },
};

export const tooltips = {
  chartType: {
    flotBits: `Media bitrate over time`,
    flotPacket: `Media packets sent and received over time`,
    flotJitter: `Jitter over time, in milliseconds`,
    delay: `Round trip time over time, in milliseconds`,
    flotPacketLossPct: `Percentage of packet loss over time`,
    flotPacketLossNumber: `Number of lost packets over time`,
    frameRate: `Frame rate over time`,
    performanceByProbe: "Utilization of CPU and memory by probe",
    performanceByBrowser: "Utilization of CPU and memory by browser",
  },
  inOutToggleSeries: {
    Incoming: `Click to toggle the view of incoming media streams`,
    Outgoing: `Click to toggle the view of outgoing media streams`,
  },
};

export const yAxisHardMinMax = {
  min: { cpu: 0, memory: 0 },
  max: {},
};

export const yAxisSoftMinMax = {
  min: {
    [chartTypeValues.flotJitter]: 0,
    [chartTypeValues.delay]: 0,
    [chartTypeValues.flotPacketLossNumber]: 0,
    [chartTypeValues.flotPacketLossPct]: 0,
    [chartTypeValues.frameRate]: 0,
  },
  max: {
    [chartTypeValues.flotJitter]: 50,
    [chartTypeValues.delay]: 50,
    [chartTypeValues.flotPacketLossNumber]: 2,
    [chartTypeValues.flotPacketLossPct]: 10,
    [chartTypeValues.frameRate]: 15,
  },
};

export const tresholdsChartTypeMap = {
  flotJitter: "jitter",
  flotPacketLossPct: "lossPCT",
  delay: "videoDelay",
};
