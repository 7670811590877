import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

export type XAxisTimeline = "absolute" | "relative";

export interface IXAxisTimelineSwitchProps {
  xAxisTimeline: XAxisTimeline;
  setXAxisTimeline: (xAxisTimeline: XAxisTimeline) => void;
}

const XAxisTimelineSwitch = ({ xAxisTimeline, setXAxisTimeline }: IXAxisTimelineSwitchProps) => {
  const tooltip = xAxisTimeline === "absolute" ? `Switch to relative time` : `Switch to absolute time`;
  const switchTo = xAxisTimeline === "absolute" ? "relative" : "absolute";
  return (
    <Tooltip title={tooltip} placement="top">
      <Button color="inherit" variant="outlined" onClick={() => setXAxisTimeline(switchTo)}>
        <Icon color={"action"}>access_time</Icon>
      </Button>
    </Tooltip>
  );
};

export default XAxisTimelineSwitch;
