import * as React from "react";

import { formatNumber, formatScore } from "../../../../helpers/testDetails";
import { numberWithCommas } from "../../../../helpers/numbers";
import { ChartsState } from "../index";
import { ProjectsContext } from "src/containers/AppController";
import { ribbonsThresholdHelper } from "src/helpers/thresholdHelper";
import { higherIsBetterColor, lowerIsBetterColor } from "src/helpers/ribbons";
import RibbonStats from "src/components/RibbonStats";
import { WidgetConfig } from "src/components/RibbonStats/components/Widget";

type TestStatsProps = {
  test: any;
  isReport: boolean;
  freeReport: boolean;
  manualUpload: boolean;
};

const TestStats = (props: ChartsState & TestStatsProps) => {
  const { calc, total, performance, manualUpload } = props;
  const { selectedProject } = React.useContext(ProjectsContext);

  if (!total) {
    return null;
  }

  const _e = (key: string, suffix: string): string => {
    if (performance) {
      const val = performance[key];
      return val ? `${numberWithCommas(formatNumber(val, 0))} ${suffix}` : "-";
    } else {
      return "-";
    }
  };

  const _e2 = (key: string): number => {
    if (performance) {
      const val = performance[key];
      return val;
    } else {
      return NaN;
    }
  };

  const thresholds = ribbonsThresholdHelper(selectedProject?.configData?.ribbon_thresholds);

  const mos = !calc.mosCount ? "-" : calc.mos || "-";
  const score = calc.rank || "-";

  const performanceWidget: WidgetConfig[] = !manualUpload
    ? [
        {
          title: "Performance",
          helpLink: "https://support.testrtc.com/hc/en-us/articles/8428330129039-Machine-performance-values/",
          variant: "double",
          data: [
            {
              name: "CPU",
              value: _e("browserCpu", "%"),
              tooltip: {
                description: "CPU used",
                value: performance.browserCpu,
                graph: true,
                type: "browserCpu",
              },
              color: _e2("browserCpu") > thresholds.browserCpu ? "bad" : "default",
              id: "agent_performance_cpu",
            },
            {
              name: "Memory",
              value: _e("browserMemory", "MB"),
              tooltip: {
                description: "Memory used",
                value: performance.browserMemory,
                graph: true,
                type: "browserMemory",
              },
              color: _e2("browserMemory") > thresholds.browserMemory ? "bad" : "default",
              id: "agent_performance_memory",
            },
          ],
        },
      ]
    : [];

  const widgetsConfig: WidgetConfig[] = [
    {
      title: "Quality",
      helpLink: "https://support.testrtc.com/hc/en-us/articles/8428345876623-testRTC-test-score/",
      variant: "double",
      data: [
        {
          name: "Score",
          value: Number(formatScore(calc)) !== 0 ? formatScore(calc) : "-",
          tooltip: { description: "Media quality score", value: formatScore(calc), graph: true, type: "score" },
          color: higherIsBetterColor(score, thresholds.score),
          id: "agent_quality_score",
        },
        {
          name: "MOS",
          value: mos,
          tooltip: {
            description: "Mean Opinion Score",
            value: mos,
            graph: true,
            type: "mos",
          },
          color: higherIsBetterColor(mos, thresholds.mos),
          id: "agent_quality_mos",
        },
      ],
    },
    ...performanceWidget,
    {
      title: "Bitrate",
      sharedUnit: "Kbps",
      helpLink: "https://support.testrtc.com/hc/en-us/articles/8428300556943-Bitrate-values/",
      variant: "table",
      data: {
        audio: {
          recv: {
            value:
              total?.audio?.recv?.totalPackets !== 0
                ? numberWithCommas(formatNumber(total.audio?.recv?.bitrate, 0))
                : "-",
            tooltip: {
              description: "Incoming audio bitrate",
              value:
                total?.audio?.recv?.totalPackets !== 0
                  ? `${numberWithCommas(formatNumber(total.audio?.recv?.bitrate, 0))} Kbps`
                  : "-",
            },
            id: "agent_bitrate_audio_in",
          },
          send: {
            value:
              total?.audio?.send?.totalPackets !== 0
                ? numberWithCommas(formatNumber(total.audio?.send?.bitrate, 0))
                : "-",
            tooltip: {
              description: "Outgoing audio bitrate",
              value:
                total?.audio?.send?.totalPackets !== 0
                  ? `${numberWithCommas(formatNumber(total.audio?.send?.bitrate, 0))} Kbps`
                  : "-",
            },
            id: "agent_bitrate_audio_out",
          },
        },
        video: {
          recv: {
            value:
              total?.video?.recv?.totalPackets !== 0
                ? numberWithCommas(formatNumber(total.video?.recv?.bitrate, 0))
                : "-",
            tooltip: {
              description: "Incoming video bitrate",
              value:
                total?.video?.recv?.totalPackets !== 0
                  ? `${numberWithCommas(formatNumber(total.video?.recv?.bitrate, 0))} Kbps`
                  : "-",
            },
            id: "agent_bitrate_video_in",
          },
          send: {
            value:
              total?.video?.send?.totalPackets !== 0
                ? numberWithCommas(formatNumber(total.video?.send?.bitrate, 0))
                : "-",
            tooltip: {
              description: "Outgoing video bitrate",
              value:
                total?.video?.send?.totalPackets !== 0
                  ? `${numberWithCommas(formatNumber(total.video?.send?.bitrate, 0))} Kbps`
                  : "-",
            },
            id: "agent_bitrate_video_out",
          },
        },
      },
    },
    {
      title: "Packet loss",
      helpLink: "https://support.testrtc.com/hc/en-us/articles/8428324562319-Packet-loss-values/",
      variant: "table",
      data: {
        audio: {
          recv: {
            value:
              total?.audio?.recv?.totalPackets !== 0 && !isNaN(total.audio?.recv?.packetLossPCT)
                ? `${numberWithCommas(formatNumber(total.audio?.recv?.packetLossPCT, 1))}%`
                : "-",
            tooltip: {
              description: "Incoming audio packet loss",
              value:
                total?.audio?.recv?.totalPackets !== 0 && !isNaN(total.audio?.recv?.packetLossPCT)
                  ? formatNumber(total.audio?.recv?.packetLossPCT, 1)
                  : "-",
              graph: true,
              type: "pl_audio_inc",
            },
            color: lowerIsBetterColor(
              total?.audio?.recv?.totalPackets !== 0 && !isNaN(total.audio?.recv?.packetLossPCT)
                ? total.audio?.recv?.packetLossPCT
                : "-",
              thresholds.packetLoss_audio_incoming
            ),
            id: "agent_pl_audio_in",
          },
          send: {
            value:
              total?.audio?.send?.totalPackets !== 0 && !isNaN(total.audio?.send?.packetLossPCT)
                ? `${numberWithCommas(formatNumber(total.audio?.send?.packetLossPCT, 1))}%`
                : "-",
            tooltip: {
              description: "Outgoing audio packet loss",
              value:
                total?.audio?.send?.totalPackets !== 0 && !isNaN(total.audio?.send?.packetLossPCT)
                  ? formatNumber(total.audio?.send?.packetLossPCT, 1)
                  : "-",
              graph: true,
              type: "pl_audio_out",
            },
            color: lowerIsBetterColor(
              total?.audio?.send?.totalPackets !== 0 && !isNaN(total.audio?.send?.packetLossPCT)
                ? total.audio?.send?.packetLossPCT
                : "-",
              thresholds.packetLoss_audio_outgoing
            ),
            id: "agent_pl_audio_out",
          },
        },
        video: {
          recv: {
            value:
              total?.video?.recv?.totalPackets !== 0 && !isNaN(total.video?.recv?.packetLossPCT)
                ? `${numberWithCommas(formatNumber(total.video?.recv?.packetLossPCT, 1))}%`
                : "-",
            tooltip: {
              description: "Incoming video packet loss",
              value:
                total?.video?.recv?.totalPackets !== 0 && !isNaN(total.video?.recv?.packetLossPCT)
                  ? formatNumber(total.video?.recv?.packetLossPCT, 1)
                  : "-",
              graph: true,
              type: "pl_video_inc",
            },
            color: lowerIsBetterColor(
              total?.video?.recv?.totalPackets !== 0 && !isNaN(total.video?.recv?.packetLossPCT)
                ? total.video?.recv?.packetLossPCT
                : "-",
              thresholds.packetLoss_video_incoming
            ),
            id: "agent_pl_video_in",
          },
          send: {
            value:
              total?.video?.send?.totalPackets !== 0 && !isNaN(total.video?.send?.packetLossPCT)
                ? `${numberWithCommas(formatNumber(total.video?.send?.packetLossPCT, 1))}%`
                : "-",
            tooltip: {
              description: "Outgoing video packet loss",
              value:
                total?.video?.send?.totalPackets !== 0 && !isNaN(total.video?.send?.packetLossPCT)
                  ? formatNumber(total.video?.send?.packetLossPCT, 1)
                  : "-",
              graph: true,
              type: "pl_video_out",
            },
            color: lowerIsBetterColor(
              total?.video?.send?.totalPackets !== 0 && !isNaN(total.video?.send?.packetLossPCT)
                ? total.video?.send?.packetLossPCT
                : "-",
              thresholds.packetLoss_video_outgoing
            ),
            id: "agent_pl_video_out",
          },
        },
      },
    },
    {
      title: "Jitter",
      sharedUnit: "ms",
      helpLink: "https://support.testrtc.com/hc/en-us/articles/8428300565263-Jitter-values/",
      variant: "table",
      data: {
        audio: {
          recv: {
            value:
              total?.audio?.recv?.totalPackets !== 0
                ? numberWithCommas(formatNumber(total.audio?.recv?.jitter, 0))
                : "-",
            tooltip: {
              description: "Incoming audio jitter",
              value: total?.audio?.recv?.totalPackets !== 0 ? formatNumber(total.audio?.recv?.jitter, 0) : "-",
              graph: true,
              type: "jitter_audio_inc",
            },
            color: lowerIsBetterColor(
              total?.audio?.recv?.totalPackets !== 0 ? total.audio?.recv?.jitter : "-",
              thresholds.jitter_audio_incoming
            ),
            id: "agent_jitter_audio_in",
          },
          send: {
            value:
              total?.audio?.send?.totalPackets !== 0
                ? numberWithCommas(formatNumber(total.audio?.send?.jitter, 0))
                : "-",
            tooltip: {
              description: "Outgoing audio jitter",
              value: total?.audio?.send?.totalPackets !== 0 ? formatNumber(total.audio?.send?.jitter, 0) : "-",
              graph: true,
              type: "jitter_audio_out",
            },
            color: lowerIsBetterColor(
              total?.audio?.send?.totalPackets !== 0 ? total.audio?.send?.jitter : "-",
              thresholds.jitter_audio_outgoing
            ),
            id: "agent_jitter_audio_out",
          },
        },
        video: {
          recv: {
            value:
              total?.video?.recv?.totalPackets !== 0
                ? numberWithCommas(formatNumber(total.video?.recv?.jitter, 0))
                : "-",
            tooltip: {
              description: "Incoming video jitter",
              value: total?.video?.recv?.totalPackets !== 0 ? formatNumber(total.video?.recv?.jitter, 0) : "-",
              graph: true,
              type: "jitter_video_inc",
            },
            color: lowerIsBetterColor(
              total?.video?.recv?.totalPackets !== 0 ? total.video?.recv?.jitter : "-",
              thresholds.jitter_video_incoming
            ),
            id: "agent_jitter_video_in",
          },
          send: {
            value:
              total?.video?.send?.totalPackets !== 0
                ? numberWithCommas(formatNumber(total.video?.send?.jitter, 0))
                : "-",
            tooltip: {
              description: "Outgoing video jitter",
              value: total?.video?.send?.totalPackets !== 0 ? formatNumber(total.video?.send?.jitter, 0) : "-",
              graph: true,
              type: "jitter_video_out",
            },
            color: lowerIsBetterColor(
              total?.video?.send?.totalPackets !== 0 ? total.video?.send?.jitter : "-",
              thresholds.jitter_video_outgoing
            ),
            id: "agent_jitter_video_out",
          },
        },
      },
    },
    {
      title: "RTT",
      sharedUnit: "ms",
      helpLink: "https://support.testrtc.com/hc/en-us/articles/8428314385551-RTT-values/",
      variant: "table",
      data: {
        audio: {
          recv: {
            value:
              total?.audio?.recv?.totalPackets !== 0 && total.audio?.recv?.rtt !== 0
                ? numberWithCommas(formatNumber(total.audio?.recv?.rtt, 0))
                : "-",
            tooltip: {
              description: "Incoming audio round trip time",
              value:
                total?.audio?.recv?.totalPackets !== 0 && total.audio?.recv?.rtt !== 0
                  ? formatNumber(total.audio?.recv?.rtt, 0)
                  : "-",
              graph: true,
              type: "rtt_audio_inc",
            },
            color: lowerIsBetterColor(
              total?.audio?.recv?.totalPackets !== 0 && total.audio?.recv?.rtt !== 0 ? total.audio?.recv?.rtt : "-",
              thresholds.rtt_audio_incoming
            ),
            id: "agent_rtt_audio_in",
          },
          send: {
            value:
              total?.audio?.send?.totalPackets !== 0 ? numberWithCommas(formatNumber(total.audio?.send?.rtt, 0)) : "-",
            tooltip: {
              description: "Outgoing audio round trip time",
              value: total?.audio?.send?.totalPackets !== 0 ? formatNumber(total.audio?.send?.rtt, 0) : "-",
              graph: true,
              type: "rtt_audio_out",
            },
            color: lowerIsBetterColor(
              total?.audio?.send?.totalPackets !== 0 ? total.audio?.send?.rtt : "-",
              thresholds.rtt_audio_outgoing
            ),
            id: "agent_rtt_audio_out",
          },
        },
        video: {
          recv: {
            value:
              total?.video?.recv?.totalPackets !== 0 && total.video?.recv?.rtt !== 0
                ? numberWithCommas(formatNumber(total.video?.recv?.rtt, 0))
                : "-",
            tooltip: {
              description: "Incoming video round trip time",
              value:
                total?.video?.recv?.totalPackets !== 0 && total.video?.recv?.rtt !== 0
                  ? formatNumber(total.video?.recv?.rtt, 0)
                  : "-",
              graph: true,
              type: "rtt_video_inc",
            },
            color: lowerIsBetterColor(
              total?.video?.recv?.totalPackets !== 0 && total.video?.recv?.rtt !== 0 ? total.video?.recv?.rtt : "-",
              thresholds.rtt_video_incoming
            ),
            id: "agent_rtt_video_in",
          },
          send: {
            value:
              total?.video?.send?.totalPackets !== 0 ? numberWithCommas(formatNumber(total.video?.send?.rtt, 0)) : "-",
            tooltip: {
              description: "Outgoing video round trip time",
              value: total?.video?.send?.totalPackets !== 0 ? formatNumber(total.video?.send?.rtt, 0) : "-",
              graph: true,
              type: "rtt_video_out",
            },
            color: lowerIsBetterColor(
              total?.video?.send?.totalPackets !== 0 ? total.video?.send?.rtt : "-",
              thresholds.rtt_video_outgoing
            ),
            id: "agent_rtt_video_out",
          },
        },
      },
    },
  ];

  return <RibbonStats data={widgetsConfig} />;
};

export default TestStats;
