import * as beautify from "js-beautify";

export const beautifier = (data: string | object): string => {
  if (!data) {
    data = "{}";
  }

  if (typeof data === "string") {
    data = JSON.parse(data);
  }

  return beautify(JSON.stringify(data));
};
