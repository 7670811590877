import { useState } from "react";
import { RouteProps } from "react-router";

import View from "./View";
import { getLocationTitle } from "../../constants/PageStateDictionary";

export type AppLayoutState = {
  mobileOpen: boolean;
};

const AppLayout = (props: RouteProps & AppLayoutState) => {
  const [mobileOpen, handleDrawerToggle] = useState(false);
  const { location } = props;

  document.title = location?.pathname ? "testRTC | " + (getLocationTitle(location.pathname) || "App") : "testRTC";

  return <View handleDrawerToggle={() => handleDrawerToggle(!mobileOpen)} mobileOpen={mobileOpen} />;
};

export default AppLayout;
