import { RouteComponentProps } from "react-router";
import { Grid, Typography, Button } from "@mui/material";

import { AuthRoutes } from "../../../constants/routes/auth-routes";

interface IRouterProps {
  email: string;
}

const SignUpConfirmPage = (props: RouteComponentProps<IRouterProps>) => {
  return (
    <Grid container={true} direction="row" justifyContent="center">
      <Grid item={true} xs={6}>
        <div>
          <Typography variant={"h6"}>Thanks for signing up!</Typography>
          <br />
          <Typography variant="body2">
            We have sent a verification link to your email address <strong>{props.match.params.email}</strong>.
            <br />
            Please follow the instruction in your email.
          </Typography>
          <br />
          <Button variant="contained" onClick={() => props.history.push(AuthRoutes.SignIn)}>
            Back to login
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default SignUpConfirmPage;
