import * as React from "react";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Global from "@mui/icons-material/Language";

import ApiPath from "src/constants/ApiPath";
import { axiosInstance } from "src/services/AxiosService";

import usePresetsContext from "./usePresetsContext";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 0,
    },
  })
);

export default function GlobalControl() {
  const classes = useStyles();
  const { preset, presets, setPreset, setPresets } = usePresetsContext();
  const [saving, setSaving] = React.useState(false);

  const setGlobal = async (flag: boolean) => {
    try {
      if (preset) {
        const gridPreset = {
          global: flag,
          preset: preset.preset,
        };

        const result = await axiosInstance.put(`${ApiPath.api.gridPreset.root}/${preset?._id}`, gridPreset);
        const gridPresetIndex = presets.findIndex((x) => x._id === preset?._id);
        const presetsCopy = [...presets];
        const newPreset = result.data;
        presetsCopy[gridPresetIndex] = newPreset;
        setPresets(presetsCopy);
        setPreset(newPreset);
        return flag;
      }
      return !flag;
    } catch (err) {
      //   setNotification(flag ? "Could not remove global flag." : "Could not make global.");
      return !flag;
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSaving(true);
    await setGlobal(checked);
    setSaving(false);
  };

  return (
    <FormGroup row={true}>
      <Tooltip title={preset?.global ? "Unset Global" : "Set Global"} placement="top">
        <FormControlLabel
          style={{ margin: 0 }}
          control={
            <Checkbox
              size="small"
              disabled={saving}
              checked={preset?.global}
              onChange={handleChange}
              icon={<Global fontSize="small" />}
              checkedIcon={<Global fontSize="small" />}
              value="global"
              classes={{
                root: classes.root,
              }}
            />
          }
          label=""
        />
      </Tooltip>
    </FormGroup>
  );
}
