import * as React from "react";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import RTCGrid from "src/components/Grid";

interface IICECandidatesTablesLegacy {
  data: any;
}

const colProps = {
  numeric: false,
  disablePadding: false,
  sortable: false,
  style: { textAlign: "left", paddingLeft: "10px", paddingRight: "10px" },
};
const numericColProps = {
  numeric: true,
  disablePadding: false,
  sortable: false,
  style: { paddingLeft: "10px", paddingRight: "10px" },
};
const LinkStyles = {
  textDecoration: "none",
  color: "#53b6ef",
  cursor: "pointer",
} as React.CSSProperties;

const renderIpRedirectCell = (address: any) => {
  let url = null;
  if (address) {
    url = `http://ip-score.com/checkip/${address.split(":")[0]}`;
  }
  if (url) {
    return (
      <span>
        <a href={url} target="_blank" style={LinkStyles} rel="noopener noreferrer">
          {address}
        </a>
      </span>
    );
  }
  return <span>none</span>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      marginTop: theme.spacing(2),
    },
    contentContainer: {
      padding: 10,
    },
    expansionPanel: {
      width: "100%",
      display: "block",
    },
    expandHead: {
      "& div": {
        justifyContent: "space-between",
      },
    },
    expandHeadFist: {
      display: "inline-flex",
      alignItems: "center",
    },
    expandHeadSecond: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    expandDetailHead: {
      [theme.breakpoints.down('md')]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "space-between",
      },

      "& p": {
        margin: 0,
      },
    },
    resultTableHead: {
      marginBottom: 5,
    },
    pairId: {
      overflow: "visible",
      textOverflow: "ellipsis",
    },
    center: {
      textAlign: "center",
    },
    xsRight: {
      [theme.breakpoints.down('md')]: {
        textAlign: "right",
      },
    },
    tableContainer: {
      paddingBottom: theme.spacing(3),
      overflowY: "hidden",
      overflowX: "auto",
      "& thead tr th": {
        textAlign: "center",
      },
    },
    tableRow: {
      cursor: "pointer",
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
    },
  })
);

const ICECandidatesTablesLegacy = ({ data }: IICECandidatesTablesLegacy) => {
  const classes = useStyles();

  const columnSchema: Array<ColumnSchema> = [
    {
      id: "googLocalAddress",
      label: "Local address",
      render: (dataItem: any, _index: number) => {
        return renderIpRedirectCell(dataItem.googLocalAddress);
      },
      ...numericColProps,
    },
    {
      id: "googLocalCandidateType",
      label: "Local type",
      render: (dataItem: any, _index: number) => (
        <span>{dataItem.googLocalCandidateType ? dataItem.googLocalCandidateType : "none"}</span>
      ),
      ...colProps,
    },
    {
      id: "localCandidateId",
      label: "Local id",
      render: (dataItem: any, _index: number) => (
        <span>{dataItem.localCandidateId ? dataItem.localCandidateId : "none"}</span>
      ),
      ...colProps,
    },
    {
      id: "googRemoteAddress",
      label: "Remote address",
      render: (dataItem: any, _index: number) => {
        return renderIpRedirectCell(dataItem.googRemoteAddress);
      },
      ...numericColProps,
    },
    {
      id: "googRemoteCandidateType",
      label: "Remote type",
      render: (dataItem: any, _index: number) => (
        <span>{dataItem.googRemoteCandidateType ? dataItem.googRemoteCandidateType : "none"}</span>
      ),
      ...colProps,
    },
    {
      id: "localCandidateId",
      label: "Remote id",
      render: (dataItem: any, _index: number) => (
        <span>{dataItem.localCandidateId ? dataItem.localCandidateId : "none"}</span>
      ),
      ...colProps,
    },
    {
      id: "requestsSent",
      label: "Requests sent",
      render: (dataItem: any, _index: number) => <span>{dataItem.requestsSent ? dataItem.requestsSent : "none"}</span>,
      ...numericColProps,
    },
    {
      id: "responsesReceived",
      label: "Responses received",
      render: (dataItem: any, _index: number) => (
        <span>{dataItem.responsesReceived ? dataItem.responsesReceived : "none"}</span>
      ),
      ...numericColProps,
    },
    {
      id: "requestsReceived",
      label: "Requests received",
      render: (dataItem: any, _index: number) => (
        <span>{dataItem.requestsReceived ? dataItem.requestsReceived : "none"}</span>
      ),
      ...numericColProps,
    },
    {
      id: "responsesSent",
      label: "Responses sent",
      render: (dataItem: any, _index: number) => (
        <span>{dataItem.responsesSent ? dataItem.responsesSent : "none"}</span>
      ),
      ...numericColProps,
    },
    {
      id: "googActiveConnection",
      label: "Active Connection",
      render: (dataItem: any, _index: number) => <span>{dataItem.googActiveConnection ? "Active" : ""}</span>,
      ...colProps,
    },
  ];

  return (
    <>
      <Divider className={classes.divider} />
      <Grid container={true} spacing={5} className={classes.tableContainer}>
        <RTCGrid
          onRowClick={(_e: React.MouseEvent, d: any) => console.warn(d)}
          localData={data}
          columnSchema={columnSchema}
          defaultSort={{
            order: "desc",
            orderBy: "lastRunDate",
          }}
          rowProps={{
            className: classes.tableRow,
          }}
          rowColorFunc={(model: any) => {
            return model.googActiveConnection ? "#bcbcfa" : "#fff";
          }}
        />
      </Grid>
    </>
  );
};

export default ICECandidatesTablesLegacy;
