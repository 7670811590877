import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";

import { useLegend } from "../hooks/useLegend";

interface ToggleAllProps {
  toggleAll: () => void;
}

export const useStyles = makeStyles((_theme: Theme) => createStyles({}));

const ToggleAll = ({ toggleAll }: ToggleAllProps) => {
  const { shouldShowToggleAll } = useLegend();

  if (!shouldShowToggleAll) {
    return null;
  }

  return (
    <Button
      style={{ maxWidth: "fit-content", marginTop: 10, marginLeft: 15 }}
      color={"primary"}
      size={"small"}
      variant={"outlined"}
      onClick={toggleAll}
    >
      Toggle All
    </Button>
  );
};

export default ToggleAll;
