import { HIDE_NOTIFICATION, SET_NOTIFICATION } from "../constants/ActionTypes";

const defaultState: NotificationReducerModel = {
  notification: "",
};

// tslint:disable-next-line:no-any
export default (state: NotificationReducerModel = defaultState, action: any) => {
  switch (action.type) {
    case HIDE_NOTIFICATION: {
      return {
        ...state,
        notification: "",
      };
    }
    case SET_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload,
      };
    }
    default:
      return state;
  }
};
