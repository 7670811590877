import { AUTHORIZE_USER } from "../constants/ActionTypes";
import { axiosInstance } from "../services/AxiosService";
import ApiPath from "../constants/ApiPath";
import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";

export interface ISetUserAction {
  type: string;
  payload: User;
}

export const SetUserAction = (data: User): ISetUserAction => {
  return {
    type: AUTHORIZE_USER,
    payload: data,
  };
};

export const FetchUser = (
  queryParams: Record<string, string | number | boolean> = {}
): ThunkAction<Promise<AxiosResponse>, IStore, null, ISetUserAction> => async (dispatch: Dispatch<ISetUserAction>) => {
  try {
    const result = await axiosInstance.get(ApiPath.api.user.me, { params: queryParams });

    dispatch(SetUserAction(result.data));
    return Promise.resolve(result);
  } catch (error) {
    return Promise.resolve(error);
  }
};

export const UpdateUser = (user: User): ThunkAction<Promise<AxiosResponse>, IStore, null, ISetUserAction> => async (
  dispatch: Dispatch<ISetUserAction>
) => {
  const result = await axiosInstance.put(ApiPath.api.user.me, user);
  dispatch(SetUserAction(result.data));
  return Promise.resolve(result);
};
