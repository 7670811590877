import { TableSchema, TablesCustomisationSchema } from "./tables-customisation.schema";

import { TABLE_ID } from "./constants/table-id.constants";
import {
  WATCH_RTC_ROOMS_HISTORY_TABLE_COLUMNS,
  WATCH_RTC_ROOM_PEERS_TABLE_COLUMNS,
  QUALITY_RTC_TESTS_HISTORY_TABLE_COLUMNS,
  QUALITY_RTC_INVITES_HISTORY_TABLE_COLUMNS,
  PROBE_RTC_PROBE_HISTORY_TABLE_COLUMNS,
  TESTING_RTC_TEST_PROBES_TABLE_COLUMNS,
  TESTING_RTC_TESTS_HISTORY_TABLE_COLUMNS,
  UP_RTC_TESTS_HISTORY_TABLE_COLUMNS,
  PROBE_RTC_PROBES_LIST_TABLE_COLUMNS,
  WATCH_RTC_PEERS_HISTORY_TABLE_COLUMNS,
} from "./constants/column-names.constants";

const {
  PROBE_RTC_PROBES_LIST,
  PROBE_RTC_PROBE_HISTORY,
  QUALITY_RTC_TESTS_HISTORY,
  WATCH_RTC_ROOMS_HISTORY,
  WATCH_RTC_PEERS_HISTORY,
  WATCH_RTC_ROOM_PEERS,
  QUALITY_RTC_INVITES_HISTORY,
  QUALITY_RTC_INVITE_TESTS,
  TESTING_RTC_TEST_PROBES,
  TESTING_RTC_TESTS_HISTORY,
  UP_RTC_TESTS_HISTORY,
} = TABLE_ID;

const getTableSchema = (
  tablesCustomisationSchema: TablesCustomisationSchema | undefined,
  tableID: string | undefined
) => {
  const tableSchema = tablesCustomisationSchema?.tables?.find((tableSchema) => tableSchema.tableID === tableID);
  if (tableSchema) {
    return tableSchema;
  } else if (tableID) {
    return { tableID };
  } else {
    return undefined;
  }
};

const getColumnNamesForExport = (tableSchema: TableSchema | null | undefined) => {
  const tableID = tableSchema?.tableID;

  let columns: string[];
  switch (tableID) {
    case WATCH_RTC_ROOMS_HISTORY:
      columns = WATCH_RTC_ROOMS_HISTORY_TABLE_COLUMNS;
      break;
    case WATCH_RTC_PEERS_HISTORY:
      columns = WATCH_RTC_PEERS_HISTORY_TABLE_COLUMNS
      break;
    case WATCH_RTC_ROOM_PEERS:
      columns = WATCH_RTC_ROOM_PEERS_TABLE_COLUMNS;
      break;
    case QUALITY_RTC_TESTS_HISTORY:
      columns = QUALITY_RTC_TESTS_HISTORY_TABLE_COLUMNS;
      break;
    case QUALITY_RTC_INVITES_HISTORY:
      columns = QUALITY_RTC_INVITES_HISTORY_TABLE_COLUMNS;
      break;
    case QUALITY_RTC_INVITE_TESTS:
      columns = QUALITY_RTC_TESTS_HISTORY_TABLE_COLUMNS;
      break;
    case PROBE_RTC_PROBES_LIST:
      columns = PROBE_RTC_PROBES_LIST_TABLE_COLUMNS;
      break;
    case PROBE_RTC_PROBE_HISTORY:
      columns = PROBE_RTC_PROBE_HISTORY_TABLE_COLUMNS;
      break;
    case TESTING_RTC_TEST_PROBES:
      columns = TESTING_RTC_TEST_PROBES_TABLE_COLUMNS;
      break;
    case TESTING_RTC_TESTS_HISTORY:
      columns = TESTING_RTC_TESTS_HISTORY_TABLE_COLUMNS;
      break;
    case UP_RTC_TESTS_HISTORY:
      columns = UP_RTC_TESTS_HISTORY_TABLE_COLUMNS;
      break;

    default:
      columns = [];
      break;
  }

  tableSchema?.addColumns?.forEach((column) => columns.push(column.label));

  const uniqueColumns = new Set([...columns]);
  tableSchema?.removeColumns?.forEach((column) => uniqueColumns.delete(column));

  return Array.from(uniqueColumns);
};

export { getTableSchema, getColumnNamesForExport };
