import { connect } from "react-redux";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import RTCGrid from "../../../../components/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sessionContainer: {
      overflowX: "auto",
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      width: "100%",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    tableRowItemHover: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f7f7f7",
        "& button": {
          visibility: "visible",
          pointerEvents: "all",
        },
      },
    },
  })
);

const columnSchema = [
  {
    id: "varName",
    label: "Var Name",
    numeric: false,
    disablePadding: false,
    sortable: false,
  },
  {
    id: "readableName",
    label: "Readable Name",
    numeric: false,
    disablePadding: false,
    sortable: false,
  },
  {
    id: "value",
    label: "Value",
    numeric: false,
    disablePadding: false,
    sortable: false,
  },
  {
    id: "description",
    label: "Description",
    numeric: false,
    disablePadding: false,
    sortable: false,
  },
];

export interface Feature {
  _id: number;
  varName: string;
  readableName: string;
  value: any;
  description: string;
  url?: string;
}

type FeaturesProps = {
  data: Array<Feature>;
  user?: User;
};

const Features = (props: FeaturesProps) => {
  const classes = useStyles();

  const isAdmin = props.user?.role === "admin";
  if (!isAdmin) {
    return null;
  }

  return (
    <div className={classes.sessionContainer}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="subtitle1">{"Features (Admin Only)"}</Typography>
      </Toolbar>
      <RTCGrid
        localData={props.data}
        columnSchema={columnSchema}
        rowProps={{
          className: classes.tableRowItemHover,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

export default connect(mapStateToProps, null)(Features);
