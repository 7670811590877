export default {
  // legacy ag-grid
  TestingRTCProbe: "TestingRTCProbe",
  QualityRTC: "QualityRTC",
  ProbeRTC: "ProbeRTC",
  WatchRTC: "WatchRTC",
  WatchRTCLive: "WatchRTCLive",
  WatchRTCExpectation: "WatchRTCExpectation",
  BillingData: "BillingData",
  AnalyticsEvents: "AnalyticsEvents",
  Probes: "Probes",
  QualityRTCInvite: "QualityRTCInvite",
  NetworkTestBenchmark: "NetworkTestBenchmark",

  // redesigned ag-grid
  TestingRTCProbes: "TestingRTCProbes",
  WatchRTCHistory: "WatchRTCHistory",
  WatchRTCPeersHistory: "WatchRTCPeersHistory",
  QualityRTCHistory: "QualityRTCHistory",
  QualityRTCInvites: "QualityRTCInvites",
  QualityRTCInviteResults: "QualityRTCInviteResults",
  ProbeRTCProbes: "ProbeRTCProbes",
  ProbeRTCHistory: "ProbeRTCHistory",
};
