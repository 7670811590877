import * as React from "react";

import { Theme } from "@mui/material/styles";

import withStyles from '@mui/styles/withStyles';

import IconButton from "@mui/material/IconButton";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";

const styles = (theme: Theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
});

interface IGridPaginationActionsProps {
  classes: {
    root: string;
  };
  onPageChange: (event: React.MouseEvent<HTMLElement>, page: number) => void;
  count: number;
  page: number;
  rowsPerPage: number;
  theme: Theme;
}

class GridPaginationActions extends React.PureComponent<IGridPaginationActionsProps & TablePaginationActionsProps> {
  handleFirstPageButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    this.props.onPageChange(event, 0);
  };

  handleBackButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    this.props.onPageChange(event, this.props.page - 1);
  };

  handleNextButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    this.props.onPageChange(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    this.props.onPageChange(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1));
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
          size="large">
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
          size="large">
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
          size="large">
          {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
          size="large">
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(GridPaginationActions);
