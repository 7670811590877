import { Link } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { Grid, Button, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { Email, Lock } from "@mui/icons-material";

import TextFieldControl from "src/components/form-elements/TextFieldControl";
import FormValidators from "src/helpers/form-validators";
import { AuthRoutes } from "src/constants/routes/auth-routes";
import { ILoginForm } from "./";

interface SigninFormProps {
  onSubmit: (values: ILoginForm) => void;
  isLoading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputBase-input": {
        paddingTop: "24px",
      },
    },
    formSection: {
      padding: `${theme.spacing(1)} 0`,
    },
    fieldIconContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: 16,
      opacity: 0.4,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    forgotPasswordContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    signInButton: {
      width: "auto",
      textTransform: "none",
    },
    formContainer: {
      marginTop: 16,
    },
    link: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      "&:active": {
        textDecoration: "none",
      },
    },
    linkText: {
      color: "#559542",
    },

    nthTextFieldSimple: {
      marginTop: 16,
    },
    linkSimple: {
      display: "block",
      marginTop: 16,

      textAlign: "right",
      color: theme.palette.primary.main,
      textDecoration: "none",

      transition: "0.2s color ease",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
    loginButtonSimple: { display: "block", margin: "24px auto 0" },
  })
);

const SigninForm = ({ onSubmit, isLoading }: SigninFormProps) => {
  const classes = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form className={classes.formContainer} onSubmit={handleSubmit}>
          <Grid container={true} spacing={2}>
            <Grid className={classes.fieldIconContainer} item={true} xs={1}>
              <Email />
            </Grid>
            <Grid item={true} xs={11} className={classes.root}>
              <Field
                component={TextFieldControl}
                type="text"
                name="email"
                label="Email Address"
                autoComplete="email"
                validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.isEmail)}
              />
            </Grid>
            <Grid className={classes.fieldIconContainer} item={true} xs={1}>
              <Lock />
            </Grid>
            <Grid item={true} xs={11} className={classes.root}>
              <Field
                component={TextFieldControl}
                type="password"
                name="password"
                label="Password"
                autoComplete="current-password"
                validate={FormValidators.required()}
              />
            </Grid>
            <Grid item={true} xs={12} className={classes.formSection}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={6} className={classes.forgotPasswordContainer}>
                  <Link className={classes.link} to={AuthRoutes.ForgotPassword}>
                    <Typography variant="body2" align="right" className={classes.linkText}>
                      Forgot password?
                    </Typography>
                  </Link>
                </Grid>
                <Grid item={true} xs={6} className={classes.buttonContainer}>
                  <Button
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.signInButton}
                  >
                    {isLoading ? "Loading" : "Sign in"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xs={12} className={classes.formSection}>
              <Grid container={true} spacing={2} className={classes.formSection}>
                <Grid item={true} xs={6}>
                  <Link className={classes.link} to={AuthRoutes.SignUp}>
                    <Typography variant="body2" color="textSecondary" className={classes.linkText}>
                      Create an account
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default SigninForm;
