import * as React from "react";

import Channels from "./Channels";
import Overview from "./Overview";
import TestStats from "./TestStats";
import { ChartsState } from "./index";
import { TestIterationContext } from "../index";
// import SyncCharts from "src/components/Chart/SyncCharts";
// import Audio from "./Audio";
// import Video from "./Video";
import Charts from "./Charts";
import PaginationControls from "src/components/PaginationControls";
import { createStyles, makeStyles } from "@mui/styles";
import { useRouteMatch } from "react-router-dom";

interface ViewProps {
  pushToFileNames: (item: string) => void;
  changePacketLMAudio: (e: React.ChangeEvent<HTMLInputElement>) => void;
  changePacketLMVideo: (e: React.ChangeEvent<HTMLInputElement>) => void;
  advancedAnalyticsLink?: string;
}

const buildQualityLimitation = (chartData: any): any => {
  const _qualityLimitation: any[] = [];
  // eslint-disable-next-line
  for (const [_key, value] of Object.entries<any>(chartData)) {
    if (value.qualityLimitation) {
      _qualityLimitation.push(value.qualityLimitation);
    }
  }
  return _qualityLimitation;
};

const useStyles = makeStyles(
  createStyles({
    actionsContainer: {
      position: "absolute",
      top: 0,
      right: 0,
      display: "flex",
      columnGap: 16,
      alignItems: "center",
      zIndex: 10,
    },
  })
);

export default (props: ChartsState & ViewProps) => {
  const classes = useStyles();

  const match = useRouteMatch<{ objectId: string }>();

  return (
    <React.Fragment>
      <TestIterationContext.Consumer>
        {({
          test,
          testRun,
          testDefinition,
          testIterations,
          navigateToIteration,
          saveTestIteration,
          updateTest,
          isReport,
          freeReport,
        }) => {
          return (
            <>
              <div className={classes.actionsContainer}>
                <PaginationControls
                  currentPage={Number(
                    testIterations?.find((iteration) => iteration?._id === match.params.objectId)?.idx ?? 1
                  )}
                  lastPage={testIterations?.length}
                  handlePageChange={navigateToIteration}
                />
              </div>
              <TestStats
                {...props}
                test={test}
                isReport={isReport}
                freeReport={freeReport}
                manualUpload={test?.runName === "manual-upload"}
              />
              <Overview
                test={test}
                testRun={testRun}
                testDefinition={testDefinition}
                saveTestIteration={saveTestIteration}
                updateTest={updateTest}
                isReport={isReport}
                {...props}
              />
              {test.stat && <Channels test={test} testRun={testRun} isReport={isReport} {...props} />}
              {/* <SyncCharts width={"100%"} zoomOnly={true}> */}
              {(props.audio || props.video) && (
                <Charts
                  audio={props.audio}
                  video={props.video}
                  performance={props.performanceData}
                  showAudio={props.showAudio}
                  showVideo={props.showVideo}
                  showPerformance={props.showPerformance}
                  voiceStartTime={props.voiceStartTime.getTime()}
                  qualityLimitation={buildQualityLimitation(test.chartData)}
                />
              )}
              {/* </SyncCharts> */}

              {/* {(props.showAudio || props.showVideo) && (
                      <>
                          {props.showAudio && <Audio {...props} />}
                          {props.showVideo && <Video {...props} />}
                        </SyncCharts>
                      </>
                    )} */}
            </>
          );
        }}
      </TestIterationContext.Consumer>
    </React.Fragment>
  );
};
