// tslint:disable:no-console
import * as SockJS from "sockjs-client";
import AuthService from "./AuthService";

const logger = {
  log: (message: string, info: any | false = false) => {
    if (process.env.NODE_ENV !== "development") {
      if (info) {
        console.log({ message, info });
      } else {
        console.log({ message });
      }
    }
  },
  error: (message: string, error: any | false = false) => {
    if (error) {
      console.error({ message, error });
    } else {
      console.error({ message });
    }
  },
};

export default class SocketService {
  public static _instance: SocketService;
  // tslint:disable-next-line:no-any
  public socket: any;

  previousSubscriptions = "";
  connected: boolean;
  authenticated: boolean;
  handlers = {};
  subscriptions = {};

  constructor() {
    if (SocketService._instance) {
      throw new Error("SocketService is already created");
    }
    this.socket = new SockJS(process.env.REACT_APP_API_PATH + "/ws");
    SocketService._instance = this;
  }

  connect() {
    // tslint:disable-next-line: no-any
    (window as any).socket = this.socket;

    const self = SocketService._instance;
    self.socket.onopen = () => {
      self.connected = true;
      logger.log("SockJS: connection is opened v2");

      if (AuthService.loggedIn()) {
        // Authenticate only if the current user is logged in
        self.send("auth.login", { token: AuthService.getToken() });
      }

      // this.syncSubscriptions(true); // too early need to wait to auth.login to complete, Force sync subscriptions
    };

    self.socket.onclose = () => {
      self.connected = false;

      logger.log("SockJS: connection was closed unexpectedly... Trying to reconnect in 5 seconds...");

      setTimeout(() => {
        // prevent memory leaks
        // todo: find better solution
        this.socket.onopen = undefined;
        this.socket.onclose = undefined;
        this.socket.onmessage = undefined;
        delete this.socket;
        this.socket = new SockJS(process.env.REACT_APP_API_PATH + "/ws");
        this.connect();
      }, 5000);
    };

    // tslint:disable-next-line:no-any
    self.socket.onmessage = (evt: any) => {
      const msg = JSON.parse(evt.data),
        type = msg.t,
        message = msg.m;

      // tslint:disable-next-line:no-console
      // logger.log("SockJS: incoming message (" + type + "):", message);

      if (type === "auth.login") {
        if (message.error) {
          self.authenticated = false;
          logger.log("SockJS: error authenticating:", message.error);
        } else {
          self.authenticated = true;
          logger.log("SockJS: authenticated successfully");

          self.syncSubscriptions(true); // Force sync subscriptions
        }
      }

      if (self.handlers[type]) {
        // tslint:disable-next-line:no-any
        self.handlers[type].forEach((handler: any) => {
          setTimeout(() => {
            handler(message).catch((err: Error) => logger.error("Error MessageHandler", { err: err.stack }));
          });
        });
      }
    };
  }

  /**
   * Listen to messages of specified type
   * Subscription should be managed separately
   */
  // tslint:disable-next-line:no-any
  on(type: string, cb: any) {
    const self = SocketService._instance;
    if (!(type in self.handlers)) {
      self.handlers[type] = [];
    }

    if (self.handlers[type].indexOf(cb) !== -1) {
      // This handler already exists
      return;
    }

    self.handlers[type].push(cb);
  }

  /**
   * Remove specified listener
   * Omit cb parameter in order to remove all listeners
   */
  // tslint:disable-next-line:no-any
  off(type: string, cb?: any) {
    const self = SocketService._instance;
    if (!(type in self.handlers)) {
      return;
    }

    if (!cb) {
      // Remove all handlers
      delete self.handlers[type];
      return;
    }

    const idx = self.handlers[type].indexOf(cb);
    if (idx !== -1) {
      self.handlers[type].splice(idx, 1);
    }
  }

  // tslint:disable-next-line:no-any
  send(type: string, msg?: any) {
    const self = SocketService._instance;
    if (!self.connected) {
      return;
    }

    const msgToSend = {
      t: type,
      m: msg,
    };

    self.socket.send(JSON.stringify(msgToSend, null, 0));
  }

  refresh() {
    const self = SocketService._instance;
    if (!self.connected) {
      return;
    }

    self.send("auth.refresh");
  }

  /**
   * Subscribe to the specified type of messages
   */
  subscribe(type: string) {
    const self = SocketService._instance;
    self.subscriptions[type] = 1;
    self.syncSubscriptions(true);
  }

  /**
   * Unsubscribe from the specified type of messages
   */
  unsubscribe(type: string) {
    const self = SocketService._instance;
    delete self.subscriptions[type];
    self.syncSubscriptions();
  }

  /**
   * Internal method to sync subscriptions with the server
   */
  syncSubscriptions(force?: boolean) {
    const self = SocketService._instance;
    if (!self.connected) {
      return;
    }

    const currentSubscribtions = Object.keys(self.subscriptions).join("|");
    if (!force && self.previousSubscriptions === currentSubscribtions) {
      // Avoid sending the same data multiple times
      return;
    }

    self.send("subscribe", { subscriptions: self.subscriptions });
    self.previousSubscriptions = currentSubscribtions;
  }
}
