import * as React from "react";
import { connect } from "react-redux";

import { PushNode, ResetBreadcrumb, UpdateNode } from "../../actions/breadcrumbActions";
import { getLocationTitle } from "../../constants/PageStateDictionary";
import { getRouteParents } from "./RoutesMap";
import { AppRoutes } from "src/constants/routes/app-routes";

const mapDispatchToProps = (dispatch: any) => ({
  push: (link: string, text?: string) =>
    dispatch(
      PushNode({
        link,
        text: text || getLocationTitle(link),
      })
    ),
  reset: () => dispatch(ResetBreadcrumb()),
  update: (link: string, text: string) => dispatch(UpdateNode({ link, text })),
});

export type WithBreadcrumb = {
  breadcrumbs: {
    push(link: string, text?: string): void;
    reset(): void;
    update(link: string, text: string): void;
  };
};

function withBreadcrumb(Child: any, isFramed?: boolean) {
  return connect(
    null,
    mapDispatchToProps
  )(
    class extends React.Component<any, WithBreadcrumb, any> {
      UNSAFE_componentWillMount() {
        const { match, reset, push } = this.props;

        reset();

        if (!isFramed) {
          push(AppRoutes.Main, "Home");
        } else {
          push("#", "Loading...");
        }

        const result = getRouteParents(match.path);
        if (result.length) {
          result.forEach((p: BreadcrumbNode) => {
            push(p.link, p.text);
          });
        }
      }

      render() {
        const nextProps = {
          ...this.props,
        };
        delete nextProps.push;
        delete nextProps.update;
        delete nextProps.reset;

        return (
          <Child
            {...nextProps}
            breadcrumbs={{
              push: this.props.push,
              reset: this.props.reset,
              update: this.props.update,
            }}
          />
        );
      }
    }
  );
}

export default withBreadcrumb;
