import * as React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

interface WorkingSectionProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 25,
      [theme.breakpoints.down(1680)]: {
        paddingTop: 15,
        paddingBottom: 15,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: 0,
      },
      minHeight: "calc(100vh - 140px)",
      backgroundColor: "#F3F3F4",
    },
  })
);

const WorkingSection = ({ children }: WorkingSectionProps) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default WorkingSection;
