import * as React from "react";
import { useLayoutEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "src/constants/routes/app-routes";
import { ProjectsContext } from "src/containers/AppController";
import { WatchRTCContext } from "src/containers/WatchRTC/Context";

export interface NavigationItem {
  name: string;
  link: string;
  Icon?: any;
  hidden?: boolean;
  disabled?: boolean;
  child?: string[];
  items?: NavigationItem[];
}

export interface AuthorizationCheckerProps {
  user: User;
}

function isUnauthorized(
  navigationItem: NavigationItem,
  parentNavigationItem: NavigationItem | null,
  navigationBlockName: string,
  isAdmin: boolean,
  pathname: string
) {
  const isLinkMatching =
    pathname.includes(navigationItem.link) ||
    Boolean(navigationItem.child?.find((childLink) => pathname.includes(childLink)));

  const isForbidden =
    navigationItem.disabled ||
    navigationItem.hidden ||
    parentNavigationItem?.disabled ||
    parentNavigationItem?.hidden ||
    (navigationBlockName === "adminItems" && !isAdmin);

  return !isAdmin && isLinkMatching && isForbidden;
}

function withAuthorization(
  getNavigationBlocks: (
    project: Project | null | undefined,
    accountIsSuspended: boolean,
    isMonitoringDisabled: boolean,
    watchrtcNotifications: number | null,
    isProjectAdmin: boolean
  ) => Record<string, NavigationItem[]>
) {
  return function withAuthorizationInner<T extends AuthorizationCheckerProps>(Child: React.ComponentType<T>) {
    return function AuthorizationChecker(props: T) {
      const location = useLocation();
      const history = useHistory();

      const { selectedProject } = useContext(ProjectsContext);
      const { watchrtcNotifications } = useContext(WatchRTCContext);
      useLayoutEffect(
        function redirectTo403IfNotAuthorized() {
          if (selectedProject) {
            const { accountType, enableMonitoring } = (selectedProject as Project) || {};

            const isAdmin = props.user?.role === "admin";
            const isProjectAdmin =
              props.user?.projectRoleName === "Account Admin" || props.user?.projectRoleName === "Super Admin";
            const accountIsSuspended = accountType === "Suspended";
            const isMonitoringDisabled = accountIsSuspended || !enableMonitoring;

            const navigationBlocks = getNavigationBlocks(
              selectedProject,
              accountIsSuspended,
              isMonitoringDisabled,
              watchrtcNotifications,
              isProjectAdmin
            );

            for (const [navigationBlockName, navigationBlock] of Object.entries(navigationBlocks)) {
              for (const parentNavigationItem of navigationBlock) {
                if (isUnauthorized(parentNavigationItem, null, navigationBlockName, isAdmin, location.pathname)) {
                  history.replace(AppRoutes.Forbidden);
                  return;
                } else if (parentNavigationItem.items) {
                  for (const childNavigationItem of parentNavigationItem.items) {
                    if (
                      isUnauthorized(
                        childNavigationItem,
                        parentNavigationItem,
                        navigationBlockName,
                        isAdmin,
                        location.pathname
                      )
                    ) {
                      history.replace(AppRoutes.Forbidden);
                    }
                  }
                }
              }
            }
          }
        },
        [selectedProject]
      );

      return <Child {...props} />;
    };
  };
}

export default withAuthorization;
