import * as queryString from "query-string";
import { useHistory } from "react-router-dom";
import * as moment from "moment";

import { IFiltersData } from "../Interfaces";
import { filterMap, defaultFiltersData, defaultQualityData } from "../constants";
import { reportError } from "src/services/ErrorService";

export const useUrlFilterData = () => {
  const history = useHistory();

  const getUrlFilterData = () => {
    if (!history.location.search) {
      return;
    }
    const search = queryString.parse(history.location.search);

    try {
      const filtersData: any = {};
      const activeFilters: string[] = [];

      Object.keys(search)
        .filter((key) => key !== "customInsightsIssues")
        .forEach((key) => {
          if (!search[key]) {
            return;
          }

          // time from
          if (key === "timeFrom") {
            const from = moment(search[key] as string)
              .startOf("day")
              .valueOf();
            filtersData.time = {
              ...filtersData.time,
              from,
            };
            return;
          }
          // time to
          if (key === "timeTo") {
            const to = moment(search[key] as string)
              .endOf("day")
              .valueOf();
            filtersData.time = {
              ...filtersData.time,
              to,
            };
            return;
          }

          // quality filters
          if (key.includes("_max") || key.includes("_min")) {
            filtersData[key] = Number(search[key]);

            if (!activeFilters.includes(filterMap.quality)) {
              activeFilters.push(filterMap.quality);
            }
            return;
          }

          // keys
          if (key.includes("keys.")) {
            if (!filtersData.keys?.length) {
              filtersData.keys = [];
            }
            const originalKey = key;
            filtersData.keys.push({
              key: originalKey,
              value: (search[key] as string).split(","),
            });

            activeFilters.push(key);
            return;
          }

          // location/os/ipType/...
          filtersData[key] = (search[key] as string).split(",");

          // add active filter
          if (filterMap[key]) {
            activeFilters.push(filterMap[key]);
          }
        });

      return { filtersData, activeFilters };
    } catch (err) {
      reportError(`Couldn't apply URL filters: ${JSON.stringify(search)}`, err);
      return;
    }
  };

  const setUrlFilterData = (appliedData: IFiltersData) => {
    if (!appliedData) {
      return;
    }
    try {
      const timeFrom = moment(appliedData.time.from).format("YYYY-MM-DD");
      const timeTo = moment(appliedData.time.to).format("YYYY-MM-DD");

      const filters: any = {
        ...appliedData,
        time: undefined,
        timeFrom,
        timeTo,
      };

      if (
        appliedData.time.from === defaultFiltersData.time.from &&
        appliedData.time.to === defaultFiltersData.time.to
      ) {
        filters.timeFrom = undefined;
        filters.timeTo = undefined;
      }

      let query = `?`;
      Object.keys(filters).forEach((key) => {
        const filter = filters[key];
        if (!filter || (Array.isArray(filter) && !filter.length)) {
          return;
        }

        // time
        if (key.includes("time")) {
          query += `${key}=${filter}&`;
          return;
        }

        // quality filters (min,max)
        if (typeof filter === "number" && filter !== defaultQualityData[key]) {
          query += `${key}=${filter}&`;
          return;
        }

        if (Array.isArray(filter)) {
          // keys filters
          if (key === "keys") {
            filter.forEach((f: { key: string; value: string[] }) => {
              const innerKey = f.key;
              const values = f.value.join(",");
              query += `${innerKey}=${values}&`;
            });
            return;
          }

          // location/os/ipType/...
          query += `${key}=${filters[key].join(",")}&`;
          return;
        }
      });
      const customInsightsIssues = new URLSearchParams(history.location.search).get("customInsightsIssues");
      if (customInsightsIssues) {
        query += `customInsightsIssues=${encodeURIComponent(customInsightsIssues)}`;
      }
      history.replace(query);
    } catch (err) {
      reportError(`Couldn't set filters to URL: ${JSON.stringify(appliedData)}`, err);
    }
  };

  return { getUrlFilterData, setUrlFilterData };
};
