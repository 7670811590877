import { Fragment } from "react";
import cn from "classnames";
import { createStyles, makeStyles } from "@mui/styles";
import { IconButton, Theme, Tooltip } from "@mui/material";
import { ReactSVG } from "react-svg";
import { getAlpha2ByName } from "country-locale-map";

import useIsElementOverflowing from "src/hooks/useIsElementOverflowing";
import { CopyToClipboard } from "react-copy-to-clipboard";
import windowsIconSrc from "src/assets/images/icons/os/windows.svg";
import linuxIconSrc from "src/assets/images/icons/os/linux.svg";
import macOsIconSrc from "src/assets/images/icons/os/macos.svg";
import androidIconSrc from "src/assets/images/icons/os/android.svg";
import iosIconSrc from "src/assets/images/icons/os/ios.svg";
import chromeIconSrc from "src/assets/images/icons/browsers/chrome.svg";
import edgeIconSrc from "src/assets/images/icons/browsers/edge.svg";
import operaIconSrc from "src/assets/images/icons/browsers/opera.svg";
import firefoxIconSrc from "src/assets/images/icons/browsers/firefox.svg";
import safariIconSrc from "src/assets/images/icons/browsers/safari.svg";
import samsungIconSrc from "src/assets/images/icons/browsers/samsung.svg";
import webviewIconSrc from "src/assets/images/icons/browsers/webview.png";

import "country-flag-icons/3x2/flags.css";
import { useDispatch } from "react-redux";
import { SetNotification } from "src/actions/notificationAction";
import { FileCopyOutlined } from "@mui/icons-material";

export interface SectionDataPointConfig {
  label: string;
  value: string | number | JSX.Element | Array<{ value: string | number | JSX.Element; color?: string }>;
  tooltip?: string;
  link?: string;
  onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => boolean;
  color?: string;
  id?: string;
  isFlagHidden?: boolean;
  copyToClipboard?: boolean;
}

type SectionDataPointProps = SectionDataPointConfig;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      maxWidth: "fit-content",
      margin: "8px 0 4px",
      fontSize: 12,
      lineHeight: "20px",
      fontWeight: 400,
      color: theme.palette.text.secondary,
      letterSpacing: "0.17px",
    },

    link: {
      display: "block",
      minWidth: 0,
      maxWidth: "fit-content",

      color: "#366EB6",
      textDecoration: "none",

      "&:hover": { textDecoration: "underline" },
    },
    hoverLink: {
      display: "flex",
      alignItems: "center",
      minWidth: 0,
      maxWidth: "fit-content",

      "&:hover": {
        textDecoration: "underline",
        "& $copyToClipboardIcon": {
          opacity: 1,
        },
        "& $link": {
          textDecoration: "underline",
        },
      },
    },
    copyToClipboardIcon: {
      opacity: 0,
      marginLeft: theme.spacing(1),
      transition: "opacity 0.3s ease",
      cursor: "pointer",
    },
    valueContainer: { maxWidth: "fit-content", display: "flex", alignItems: "center" },
    valueIcon: { height: 20, marginRight: 8, objectFit: "cover", fontSize: 20, lineHeight: 1 },
    valueIconFixedWidth: { width: 20 },
    value: {
      margin: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",

      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 400,
      letterSpacing: "0.17px",
    },
    tooltip: {
      fontSize: 10,
      lineHeight: "14px",
      background: theme.palette.text.primary,
    },
    tooltipArrow: {
      color: theme.palette.text.primary,
    },
  })
);

const osLogoSrcMap = {
  windows: windowsIconSrc,
  linux: linuxIconSrc,
  "mac os": macOsIconSrc,
  android: androidIconSrc,
  ios: iosIconSrc,
};

const CHROME_WEBVIEW = "chrome webview";

const browserLogoSrcMap = {
  chrome: chromeIconSrc,
  [CHROME_WEBVIEW]: webviewIconSrc,
  edge: edgeIconSrc,
  opera: operaIconSrc,
  firefox: firefoxIconSrc,
  safari: safariIconSrc,
  "mobile safari": safariIconSrc,
  "samsung browser": samsungIconSrc,
};

const SectionDataPoint = ({
  label,
  value,
  tooltip,
  link,
  onLinkClick,
  color,
  id,
  isFlagHidden,
}: SectionDataPointProps) => {
  const classes = useStyles();

  const { elementRef: valueRef, isOverflowing: isValueOverflowing } = useIsElementOverflowing<HTMLParagraphElement>();

  let valueIconJsx = null;

  const getCountryName = (value: string) => {
    if (value.includes(",")) {
      return value.split(", ")[1].trim();
    }
    return value;
  };

  const dispatch = useDispatch();

  const handleCopyClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  if (typeof value === "string") {
    if (label === "OS") {
      const osLogoSrc = osLogoSrcMap[value.toLowerCase()];

      if (osLogoSrc) {
        valueIconJsx = <ReactSVG src={osLogoSrc} className={cn(classes.valueIcon, classes.valueIconFixedWidth)} />;
      }
    } else if (label === "Browser") {
      if (value.toLowerCase() === CHROME_WEBVIEW) {
        valueIconJsx = (
          <img src={browserLogoSrcMap[CHROME_WEBVIEW]} className={cn(classes.valueIcon, classes.valueIconFixedWidth)} />
        );
      } else {
        const browserLogoSrc = browserLogoSrcMap[value.toLowerCase().split(":")[0]];

        if (browserLogoSrc) {
          valueIconJsx = (
            <ReactSVG src={browserLogoSrc} className={cn(classes.valueIcon, classes.valueIconFixedWidth)} />
          );
        }
      }
    } else if ((label === "Location" || label === "Remote location") && typeof value === "string") {
      const locationValue = getCountryName(value);
      const countryCode = getAlpha2ByName(locationValue, true);

      if (countryCode && !isFlagHidden) {
        valueIconJsx = <div className={cn(classes.valueIcon, `flag:${countryCode}`)} />;
      }
    }
  }

  let valueJsx: JSX.Element;

  if (typeof value === "string" || typeof value === "number") {
    valueJsx = (
      <div className={classes.valueContainer}>
        {valueIconJsx}
        <p ref={valueRef} className={classes.value} style={{ color }} id={id}>
          {value}
        </p>
      </div>
    );
  } else if (Array.isArray(value)) {
    const tooltipValue = value.map((item) => item.value).join(", ");

    valueJsx = (
      <div ref={valueRef} className={classes.valueContainer}>
        <Tooltip title={tooltipValue} arrow>
          <p className={classes.value} id={id}>
            <span style={{ color: value[0].color || color }}>{value[0].value}</span>
            {value.length > 1 && <span style={{ color }}>, ....</span>}
          </p>
        </Tooltip>
      </div>
    );
  } else {
    valueJsx = value;
  }

  if (link) {
    if (label === "Room name" || label === "Peer name") {
      valueJsx = (
        <div className={classes.hoverLink}>
          <a href={link} target="_blank" rel="noreferrer" onClick={onLinkClick} className={classes.link}>
            {valueJsx}
          </a>
          <CopyToClipboard
            text={value}
            onCopy={(_text: string | undefined) =>
              dispatch(SetNotification(value ? `${value} copied to clipboard` : "Copy to clipboard failed"))
            }
          >
            <IconButton size="small" className={classes.copyToClipboardIcon} onClick={handleCopyClick}>
              <FileCopyOutlined
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </IconButton>
          </CopyToClipboard>
        </div>
      );
    }
    valueJsx = (
      <a href={link} target="_blank" rel="noreferrer" onClick={onLinkClick} className={classes.link}>
        {valueJsx}
      </a>
    );
  }

  const labelTooltipText = tooltip ?? "";

  let valueTooltipText = tooltip ?? "";

  if ((typeof value === "string" || typeof value === "number") && isValueOverflowing) {
    valueTooltipText = value.toString();
  }

  return (
    <Fragment key={label}>
      <Tooltip title={labelTooltipText} arrow>
        <h4 className={classes.label}>{label}</h4>
      </Tooltip>

      {Array.isArray(value) ? (
        valueJsx
      ) : (
        <Tooltip title={valueTooltipText} arrow>
          {valueJsx}
        </Tooltip>
      )}
    </Fragment>
  );
};

export default SectionDataPoint;
