import * as React from "react";
import { connect } from "react-redux";

import {
  Card,
  Typography,
  Toolbar,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Alert,
  Collapse,
  Link,
} from "@mui/material";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import CheckboxControl from "src/components/form-elements/CheckboxControl";
import { ProjectClientStorageValidate } from "./validator/project.validator";
import { SetNotification } from "src/actions/notificationAction";
import LabelCell from "src/components/LabelCell";

enum Products {
  "watch" = "watch",
  "probe" = "probe",
  "quality" = "quality",
}
type Product = keyof typeof Products;

type IDataStreamProps = {
  classes: any;
  values: any;
  product: Product;
  setActiveJobs: (options: string) => void;
  setNotification(message: string): void;
};

const DataStreamConfigurationCard = ({
  classes,
  values,
  product,
  setActiveJobs,
  setNotification,
}: IDataStreamProps) => {
  const projectId = values._id;
  const lastUploadedDataLocation = values.dataStreamUploadConfigs?.[product]?.lastUploadedDataLocation;

  const enableFieldName = `${product}RTC Data Stream Enable`;

  const [open, setOpen] = React.useState(false);

  const onChangeCollectionInterval = (event: any) => {
    if (!event.target.value) {
      setActiveJobs(product);
    }
  };

  const checkDataStream = (event: any) => {
    if (event) {
      const cloudStorage = ProjectClientStorageValidate(values?.clientStorage);
      const collectionInterval =
        values.dataStreamUploadConfigs && values.dataStreamUploadConfigs[product]?.collectionInterval;
      if (!collectionInterval || !!cloudStorage) {
        let notif = cloudStorage;
        if (!collectionInterval) {
          notif = `Collection Interval in ${product} Data Stream is not defined`;
        }
        setNotification(notif);
        setActiveJobs(product);
      }
    }
  };

  React.useEffect(() => {
    if (values.dataStreamUploadConfigs && values.dataStreamUploadConfigs[product]?.isActiveUploadJob) {
      const cloudStorage = ProjectClientStorageValidate(values?.clientStorage);
      if (!!cloudStorage) {
        setActiveJobs(product);
      }
    }
  }, []);

  return (
    <div>
      <Card className={classes.card} style={{ padding: 0 }}>
        <Toolbar>
          <Typography variant="subtitle1">Data Stream</Typography>
        </Toolbar>
        <Divider />
        <Box sx={{ width: "100%", height: "100" }}>
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 0 }}
              severity="info"
            >
              Data Stream feature not configured. Please check storage and watchRTC configurations.
            </Alert>
          </Collapse>
        </Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: 220 }}>{enableFieldName}</TableCell>
              <TableCell>
                <Field
                  component={CheckboxControl}
                  type="checkbox"
                  name={`dataStreamUploadConfigs.${product}.isActiveUploadJob`}
                  onChange={checkDataStream}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Filename Prefix</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name={`dataStreamUploadConfigs.${product}.filenamePrefix`}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Folder Path</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name={`dataStreamUploadConfigs.${product}.pathToFiles`}
                  placeholder={`${projectId}/${product}-rtc/ by default`}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Collection Interval</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="number"
                  name={`dataStreamUploadConfigs.${product}.collectionInterval`}
                  min={0}
                  max={1440}
                  placeholder="120 minutes by default"
                  onchange={onChangeCollectionInterval}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <LabelCell
                  label="Recent Export Link"
                  tooltip="It's likely you need the appropriate permissions to view the content, if the bucket isn't publicly accessible"
                />
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  {(
                    <Link href={lastUploadedDataLocation} target="_blank">
                      {lastUploadedDataLocation}
                    </Link>
                  ) || "N/A"}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default connect(null, mapDispatchToProps)(DataStreamConfigurationCard);

export { Products };
