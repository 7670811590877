import { checkNan } from "../../../../helpers/testDetails";
import { WithStyles } from "@mui/styles";

type CompWithStyles = WithStyles<"rowItem" | "rowItemLabel">;

type InfoRowProps = {
  items: string[];
  isHeader?: boolean;
  id?: string | string[];
};

export const InfoRow = (props: CompWithStyles & InfoRowProps) => {
  const { items, isHeader, classes, id } = props;

  if (!items.length) {
    return null;
  }
  return (
    <div style={{ display: "flex" }}>
      {items.map((item, index) => {
        const itemId = Array.isArray(id) ? id[index] : id;
        if (item === "" || checkNan(item)) {
          return (
            <div className={`${index ? classes.rowItem : classes.rowItemLabel}`} key={index} id={itemId}>
              {!index || isHeader ? <b>{item}</b> : item}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default InfoRow;
