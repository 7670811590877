import { TextField, InputAdornment } from "@mui/material";
import { FieldRenderProps } from "react-final-form";

interface TextFieldControlSimpleProps {
  type?: string;
  disabled?: boolean;
  multiline?: boolean;
  maxLength?: number;
  label?: string;
  helperText?: string;
  autoComplete?: string;
  endAdornment?: JSX.Element;
  variant?: "filled" | "outlined" | "standard";
  size?: "medium" | "small";
  className?: string;
}

const TextFieldControlSimple = ({
  input: { name, value, onChange, ...inputProps },
  meta,
  type,
  disabled,
  multiline,
  maxLength,
  label,
  helperText,
  autoComplete,
  endAdornment,
  variant = "standard",
  size = "medium",
  className,
}: FieldRenderProps & TextFieldControlSimpleProps) => {
  let finalHelperText: string | undefined = helperText;

  if (meta.touched && meta.error) {
    finalHelperText = meta.error;
  } else if (meta.touched && meta.submitError && !meta.dirtySinceLastSubmit) {
    finalHelperText = meta.submitError;
  }

  return (
    <TextField
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      disabled={disabled}
      multiline={multiline}
      label={label}
      variant={variant}
      size={size}
      helperText={finalHelperText}
      error={Boolean(meta.touched && (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)))}
      InputProps={{
        autoComplete: autoComplete,
        endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : null,
      }}
      inputProps={{
        ...inputProps,
        "data-cy": name,
        maxLength,
      }}
      className={className}
      fullWidth
    />
  );
};

export default TextFieldControlSimple;
