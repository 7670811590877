import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import * as moment from "moment";

interface ITestDuration {
  updateLog: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    padding: {
      paddingRight: 5,
    },
    focusedText: {
      width: 900,
      overflow: "auto",
      background: "#f3f3f3",
      boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12)`,
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    configs: {
      "& > div:not(:first-of-type)": {
        marginTop: 20,
      },
    },
  })
);

const TestDuration = ({ updateLog }: ITestDuration) => {
  const classes = useStyles();

  const getDuration = (startTime: any, endTime: any) => {
    if (startTime === endTime) {
      return "0s";
    } else if (!startTime || !endTime) {
      return "0s";
    } else if (startTime && endTime) {
      const diff = moment.duration(moment(endTime).diff(moment(startTime))).as("seconds");
      const duration = moment(diff * 1000)
        .utc()
        .format("m:s");
      const formattedDuration = duration.split(":");
      return `${formattedDuration[0]}m ${formattedDuration[1]}s`;
    } else {
      return "0s";
    }
  };
  return (
    <Toolbar className={classes.toolbar}>
      <Grid item xs={12} md={4} className={classes.padding}>
        <Typography variant="subtitle1">
          <strong>Start Time:</strong>
        </Typography>
      </Grid>
      <Grid item container direction="column" xs={12} md={8} className={classes.configs}>
        <Typography variant="subtitle1">
          {updateLog[0]?.time ? moment(updateLog[0].time).format("HH:mm:ss") : "0"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.padding}>
        <Typography variant="subtitle1">
          <strong>Duration:</strong>
        </Typography>
      </Grid>
      <Grid item container direction="column" xs={12} md={8} className={classes.configs}>
        <Typography variant="subtitle1">
          {getDuration(updateLog[0]?.time, updateLog[updateLog.length - 1]?.time)}
        </Typography>
      </Grid>
    </Toolbar>
  );
};

export default TestDuration;
