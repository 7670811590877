export enum AssureTestStatus {
  FAIR = "Fair",
  GOOD = "Good",
  CRITICAL = "Critical",
}

// Record<KebabToCamelCase<TestStatus>, TestStatus>
export const Statuses = {
  warnings: "warnings",
  error: "error",
  failure: "failure",
  timeout: "timeout",
  finished: "finished",
  completed: "completed",
  serviceFailure: "service-failure",
  terminated: "terminated",
  started: "started",
  retry: "retry",
  dismissed: "dismissed",
} as const;
