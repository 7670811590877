import { createRef, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ProjectsContext } from "../../../AppController";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import UserProjectsList from "./UserProjectsList";

type ProjectSelectorProps = {};

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
    },
    current: {
      fontWeight: 500,
      borderWidth: "2px !important",
      textTransform: "none",
      "&.active": {
        borderColor: "#53b6ef",
      },
    },
  })
);

const ProjectSelector = (_props: ProjectSelectorProps): JSX.Element | null => {
  const ref = createRef<HTMLDivElement>();
  const classes = useStyles();

  const { user } = useSelector((store: IStore) => store.userAuth);
  const { projects, selectedProject, setSelectedProject } = useContext(ProjectsContext);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  const isAdmin = user?.role === "admin";

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (isDialogOpen && ref.current && !ref.current.contains(e.target)) {
        setDialogOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDialogOpen]);

  if (!projects?.length || !selectedProject || !setSelectedProject) {
    return null;
  }

  return (
    <div className={classes.container} ref={ref}>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        className={classes.current + (isDialogOpen ? " active" : "")}
        onClick={() => setDialogOpen(!isDialogOpen)}
      >
        <span>{selectedProject.name}</span>
        {isDialogOpen ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <UserProjectsList
        isAdmin={isAdmin}
        open={isDialogOpen}
        close={() => setDialogOpen(false)}
        projects={projects}
        currentId={selectedProject._id}
      />
    </div>
  );
};

export default ProjectSelector;
