import { Card, Typography, Toolbar, Divider, Table, TableBody, TableRow, TableCell, MenuItem } from "@mui/material";
import { ProjectPropertiesState } from "..";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import LabelCell from "src/components/LabelCell";

export const CloudProviders = {
  gcp: "gcp",
  aws: "aws",
};

const DataStreams = ({ classes }: ProjectPropertiesViewProps & ProjectPropertiesState) => {
  return (
    <div>
      <Card className={classes.card} style={{ padding: 0 }}>
        <Toolbar>
          <Typography variant="subtitle1">Cloud Provider</Typography>
        </Toolbar>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: 220 }}>
                <LabelCell label="Cloud Platform" />
              </TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  select={true}
                  SelectProps={{ autoWidth: true }}
                  name="clientProvider.cloudPlatform"
                  variant="standard"
                >
                  {Object.keys(CloudProviders).map((key: any, i: number) => (
                    <MenuItem key={i} value={CloudProviders[key]}>
                      {CloudProviders[key]}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

export default DataStreams;
