import Button from "@mui/material/Button";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as React from "react";

export type ConfirmDialogProps = {
  handleConfirm: () => void;
  handleClose?: () => void;
  open: boolean;
  title?: string;
  content?: string;
  isDeletingProject?: boolean;
  isCheckbox?: boolean;
  checked?: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    isDeletingProject,
    handleClose,
    handleConfirm,
    open,
    title,
    content,
    isCheckbox,
    checked,
    setChecked,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setChecked === undefined) {
      return;
    }
    setChecked(event.target.checked);
  };

  const isDisabled = () => {
    if (checked === undefined) {
      return isDeletingProject;
    }
    if (isDeletingProject === undefined) {
      return !checked;
    } else {
      return undefined;
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        {content && !isCheckbox && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
          </DialogContent>
        )}
        {isCheckbox && (
          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              paddingRight: "17px",
              paddingLeft: "12px",
              paddingBottom: "5px",
            }}
          >
            <Checkbox checked={checked} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} />
            <DialogContentText id="alert-dialog-description" fontSize={"14px"} color="#212121" ml={"2px"}>
              {content}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="textSecondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={isDisabled()}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
