import { axiosInstance } from "src/services/AxiosService";
import ApiPath from "src/constants/ApiPath";
import { WatchRTCFilterView, WatchRTCFilterViewCore } from "../types";
import { reportError } from "src/services/ErrorService";

export const getFilterViews = async () => {
  let filterViews: WatchRTCFilterView[] = [];
  let error: Error | undefined;

  try {
    const result = await axiosInstance.get<WatchRTCFilterView[]>(ApiPath.api.watchRTCFilterView);

    if (Array.isArray(result.data)) {
      filterViews = result.data;
    }
  } catch (err) {
    reportError("Couldn't get filter views", err);
    error = err;
  }

  return { filterViews, error };
};

export const postFilterView = async (newFilterView: WatchRTCFilterViewCore) => {
  let filterView: WatchRTCFilterView | undefined;
  let error: Error | undefined;

  try {
    const result = await axiosInstance.post<WatchRTCFilterView>(ApiPath.api.watchRTCFilterView, newFilterView);

    if (result.data) {
      filterView = result.data;
    }
  } catch (err) {
    reportError("Couldn't post filter view", err);
    error = err;
  }

  return { filterView, error };
};

export const putFilterView = async (changedFilterView: WatchRTCFilterView) => {
  let filterView: WatchRTCFilterView | undefined;
  let error: Error | undefined;

  try {
    const result = await axiosInstance.put<WatchRTCFilterView>(
      `${ApiPath.api.watchRTCFilterView}/${changedFilterView._id}`,
      changedFilterView
    );

    if (result.data) {
      filterView = result.data;
    }
  } catch (err) {
    reportError("Couldn't put filter view", err);
    error = err;
  }

  return { filterView, error };
};

export const deleteFilterView = async (id: string) => {
  let error: Error | undefined;

  try {
    await axiosInstance.delete<unknown>(`${ApiPath.api.watchRTCFilterView}/${id}`);
  } catch (err) {
    reportError("Couldn't delete filter view", err);
    error = err;
  }

  return { error };
};
