// tslint:disable:no-any
import * as React from "react";
import { useState, useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";

import ChartWithLegend from "src/components/Chart/components/ChartWithLegend";
import XAxisTimelineSwitch, { XAxisTimeline } from "src/components/XAxisTimelineSwitch";
import { useLocalStorage } from "usehooks-ts";
import * as moment from "moment";
type StyledComponent = WithStyles<"panelRoot" | "timeLine">;

export interface GraphPanelProps {
  id: string;
  graph: any;
}

const chartCollapsedViewStyle: React.CSSProperties = {
  flexDirection: "column",
  alignItems: "flex-start",
};

const panelTitleStyle: React.CSSProperties = {
  fontSize: 15,
};

const expandedPanelViewStyle: React.CSSProperties = {
  padding: "8px 0 16px",
  flexDirection: "column",
};

const tableViewStyle: React.CSSProperties = {
  marginTop: 20,
};

const getAdditionalDataTitle = (key: string) => {
  switch (key) {
    case "mediaType":
      return "Media";
    case "googCodecName":
      return "Codec";
    case "codecImplementationName":
      return "Implementation";
    case "googContentType":
      return "Content";
    default:
      return "Unknown";
  }
};

const getAdditionalDataValue = (data: any) => {
  if (!data) {
    return "";
  }
  try {
    const values = JSON.parse(data.values);
    const filteredValues = values.filter((val: any) => val !== "");

    return filteredValues[0];
  } catch {
    return "";
  }
};

const getOptions = (options: any, startTime: any, xAxisTimeline: XAxisTimeline) => {
  const relativeXAxisLabelFormatter = options.xAxis?.labels?.formatter;

  const absoluteXAxisLabelFormatter = (context: any) => {
    const { value } = context;
    const momentFormat = "HH:mm:ss";
    const timeLabel = moment(new Date(startTime).getTime() + value * 1000).format(momentFormat);

    if (value >= 0) {
      return timeLabel;
    } else {
      return `-${timeLabel}`;
    }
  };

  return {
    ...options,
    xAxis: {
      ...options.xAxis,
      labels: {
        formatter: xAxisTimeline === "relative" ? relativeXAxisLabelFormatter : absoluteXAxisLabelFormatter,
      },
    },
  };
};

const AccordionAny = Accordion as any;

const GraphPanel = (props: StyledComponent & GraphPanelProps) => {
  const { classes, id, graph } = props;
  const isData = graph.dataset && graph.dataset.length > 0 && !!graph.dataset[0].data;
  const [wasOpened, setWasOpened] = useState(false);
  const key = graph.ssrc !== null ? graph.ssrc : graph.title;

  const urlHash = window.location.hash;
  const url = urlHash?.split("#").pop() || "";

  React.useEffect(() => {
    if (url === key) {
      const element = document.getElementById(url);

      setTimeout(() => {
        setWasOpened(true);
        element?.scrollIntoView();
      }, 0);
    }
  }, []);

  if (!isData) {
    graph.dataset[0] = {};
    graph.dataset[0].data = [];
  }

  // adding time as h:m:s notation to graph title
  const title = useMemo(() => {
    const filterData = graph.dataset.find((item: any) => item?.data && item);
    if (!filterData) {
      return `${graph.type} (id=${graph.title}${graph.ssrc ? `, ssrc=${graph.ssrc}` : ""}) (empty)`;
    }

    const dataLength = filterData?.data?.length;

    const milliseconds = dataLength ? filterData?.data[dataLength - 1][0] : 0;

    const seconds = Math.floor(milliseconds % 60);
    const minutes = Math.floor((milliseconds / 60) % 60);
    const hours = Math.floor((milliseconds / (60 * 60)) % 24);

    const formattedTime = `${hours ? `${hours}h ` : ""}${minutes ? `${minutes}m ` : ""}${seconds}s`;
    return `${graph.type} (id=${graph.title}${graph.ssrc ? `, ssrc=${graph.ssrc}` : ""}) - ${
      graph.kind ? `${graph.kind}: ` : ""
    }${formattedTime}`;
  }, [graph.dataset]);

  const [xAxisTimeline, setXAxisTimeline] = useLocalStorage<XAxisTimeline>("TimeLine", "absolute");

  return (
    <AccordionAny
      id={key}
      defaultExpanded={key === url}
      className={classes.panelRoot}
      onChange={(_e: any, expanded: boolean) => {
        if (expanded && !wasOpened) {
          setWasOpened(true);
        }
      }}
    >
      <AccordionSummary style={chartCollapsedViewStyle}>
        <Typography variant="h5" gutterBottom={true} align="center" style={panelTitleStyle}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={expandedPanelViewStyle}>
        {wasOpened && (
          <>
            <ChartWithLegend
              id={`logs-graph${id}`}
              dataset={graph.dataset}
              options={getOptions(graph.options, graph.startTime, xAxisTimeline)}
              width="100%"
              height="300px"
              hoverable
              showNoDataMessage
              shouldShowToggleAll
              customLegendButton={
                <div className={classes.timeLine}>
                  <XAxisTimelineSwitch xAxisTimeline={xAxisTimeline} setXAxisTimeline={setXAxisTimeline} />
                </div>
              }
              key={id}
            />
          </>
        )}
        {graph.additionalGraphInfo && (
          <Table style={tableViewStyle}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "20%" }} align="left">
                  {"Type"}
                </TableCell>
                <TableCell style={{ width: "80%" }} align="left">
                  {"Value"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(graph.additionalGraphInfo).map((key: string) => {
                const data = graph.additionalGraphInfo[key];
                if (!data) {
                  return null;
                }
                return (
                  <TableRow key={key}>
                    <TableCell style={{ width: "20%" }}>{getAdditionalDataTitle(key)}</TableCell>
                    <TableCell style={{ width: "80%" }}>{getAdditionalDataValue(data)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </AccordionDetails>
    </AccordionAny>
  );
};

const styles = () =>
  createStyles({
    panelRoot: {
      boxShadow: "none",
      "&::before": {
        height: 0,
      },

      breakInside: "avoid",
    },
    timeLine: {
      float: "right",
      marginTop: 5,
    },
  });

const decorate = withStyles(styles);

export default decorate(GraphPanel);
