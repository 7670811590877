import { Button, Tooltip } from "@mui/material";

import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";

interface NavigationButtonProps {
  text: string;
  link?: string;
  iconJsx?: JSX.Element;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    button: {
      height: 32,
      display: "flex",
      alignItems: "center",

      "& svg": {
        marginRight: 10,
      },
      "& span": {
        lineHeight: 1,
        textTransform: "none",
      },
    },
  })
);

const NavigationButton = ({ text, link, iconJsx }: NavigationButtonProps): JSX.Element | null => {
  const classes = useStyles();

  let resultJsx: JSX.Element | null = null;

  if (link !== undefined) {
    resultJsx = (
      <Tooltip title={link === "" ? "Unavailable" : ""}>
        <Button
          disabled={link === ""}
          component={Link}
          to={encodeURI(link)}
          variant="contained"
          color="primary"
          size="small"
          disableElevation
          className={classes.button}
        >
          {iconJsx ? iconJsx : null}
          <span>{text}</span>
        </Button>
      </Tooltip>
    );
  }

  return resultJsx;
};

export default NavigationButton;
