import { Card, Typography, Toolbar, Divider, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import DatePickerControl from "src/components/DatePicker";

type StatisticsProps = {
  lastRunDate: any;
  onLastRunChange: (e: any) => void;
};

const Statistics = ({
  classes,
  lastRunDate,
  onLastRunChange,
}: StatisticsProps & ProjectPropertiesViewProps & ProjectPropertiesState) => {
  return (
    <Card className={classes.card} style={{ padding: 0 }}>
      <Toolbar>
        <Typography variant="subtitle1">Statistics</Typography>
      </Toolbar>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: 220 }}>Total Test Run Count</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="stat_testRunCount" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total Monitor Count</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="stat_monitorCount" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last Test Run</TableCell>
            <TableCell>
              <DatePickerControl value={lastRunDate} onChange={onLastRunChange} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max Test Run during evaluation</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="max_EvaluationTestRunCount" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default Statistics;
