// tslint:disable:no-any
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import AuthService from "../../../services/AuthService";
import { FetchUser } from "../../../actions/authAction";
import { SetNotification } from "src/actions/notificationAction";
import View from "./View";
import { AuthRoutes } from "../../../constants/routes/auth-routes";
import { AppRoutes } from "../../../constants/routes/app-routes";

export interface ILoginForm {
  email: string;
  password: string;
}

type SignInPageDispatch = {
  // tslint:disable-next-line: no-any
  fetchUser: () => any;
  setNotification(message: string): void;
};

export const SignInPage = (props: SignInPageDispatch & RouteComponentProps<{}>) => {
  const [state, setState] = React.useState({
    remindOpened: false,
    subscribeOpened: false,
    emailRemind: "",
    isLoading: false,
  });

  React.useEffect(() => {
    if (props.location?.state) {
      const state: any = props.location.state;
      if (state.isPasswordRemind) {
        setState(() => ({
          ...state,
          remindOpened: true,
          emailRemind: state.emailRemind,
        }));
      }
      if (state.isSubscribeSent) {
        setState(() => ({
          ...state,
          subscribeOpened: true,
          emailRemind: state.emailRemind,
        }));
      }
      props.history.replace({ pathname: AuthRoutes.SignIn }, {});
    }
  }, []);

  const openSnackbar = (message: string) => {
    props.setNotification(message);
  };

  const closeModal = () => {
    setState(() => ({
      ...state,
      remindOpened: false,
      subscribeOpened: false,
      isLoading: false,
    }));
  };

  const onSubmit = async (values: ILoginForm): Promise<void | { [x: string]: string }> => {
    try {
      setState(() => ({
        ...state,
        isLoading: true,
      }));
      await AuthService.login(values.email, values.password);
      const user = await props.fetchUser();
      setState(() => ({
        ...state,
        isLoading: false,
      }));
      localStorage.setItem("Email", values.email);
      if (user.data && user.data.role === "pending") {
        openSnackbar("The user is not confirmed. Please confirm the user.");
        await AuthService.logout("testrtc");
        return;
      }
      ///////////////////// Gainsight ////////////////////////////
      // https://redmine.testrtc.com/issues/8908#note-5 - Gainsight Just commented will be used in future -->
      //passing user and account objects:
      // const currentTime = new Date().getTime();
      // window["aptrinsic"](
      //   "identify",
      //   {
      //     //User Fields
      //     id: user?.data?._id, // Required for logged in app users
      //     email: values?.email,
      //     signUpDate: currentTime, //unix time in ms
      //   },
      //   {
      //     //Account Fields
      //     id: user?.data?.project._id, //Required
      //     name: user?.data?.project?.name,
      //     Program: user?.data?.project?.accountType,
      //   }
      // );
      ///////////////////////Gainsight/////////////////////////////
      const redirectUrl = AuthService.getRedirectUrl();
      AuthService.setRedirectUrl("");
      if (redirectUrl) {
        window.location.href = redirectUrl;
        return;
      } else {
        props.history.push(AppRoutes.Main);
        return;
      }
    } catch (err) {
      setState(() => ({
        ...state,
        isLoading: false,
      }));

      return { password: err.message };
    }
  };

  return <View closeModal={closeModal} onSubmit={onSubmit} {...state} />;
};

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): SignInPageDispatch => ({
  fetchUser: () => dispatch(FetchUser()),
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

// tslint:disable-next-line:no-any
export default connect<any, SignInPageDispatch, RouteComponentProps<{}>>(null, mapDispatchToProps)(SignInPage);
