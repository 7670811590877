import { createContext, RefObject } from "react";

import LegendList from "./components/LegendList";
import InOutToggle from "./components/InOutToggle";
import ToggleAll from "./components/ToggleAll";
import { useUserChoices } from "./hooks/useUserChoices";
import { HighchartsChartRef, LegendSeries } from "../../types";

export interface LegendOptionsProps {
  seriesTooltips?: Record<string, string | undefined>;
  inOutToggleSeriesTooltips?: {
    Incoming: string;
    Outgoing: string;
  };
  shouldHideInOutToggle?: boolean;
  shouldShowToggleAll?: boolean;
  customLegendButton?: React.ReactNode;
  hasNewDesign?: boolean;
  links?: {
    code: string;
    link: string;
  }[];
}

export interface LegendProps extends LegendOptionsProps {
  chartRefOrRefs: RefObject<HighchartsChartRef> | RefObject<HighchartsChartRef>[];
  series: LegendSeries[];
}

interface LegendContext {
  series: LegendSeries[];
  seriesTooltips: Record<string, string | undefined>;
  inOutToggleSeriesTooltips: {
    Incoming: string;
    Outgoing: string;
  };
  shouldHideInOutToggle: boolean;
  shouldShowToggleAll: boolean;
}

export const LegendContext = createContext<LegendContext>({} as any);

const Legend = ({
  chartRefOrRefs,
  series = [],
  seriesTooltips = {},
  inOutToggleSeriesTooltips = {
    Incoming: `Click to toggle the view of incoming media streams`,
    Outgoing: `Click to toggle the view of outgoing media streams`,
  },
  shouldHideInOutToggle = false,
  shouldShowToggleAll = false,
  customLegendButton,
  hasNewDesign = false,
}: LegendProps) => {
  const {
    userChoices,
    isIncomingActive,
    isOutgoingActive,
    toggleIndividualSeriesVisibility,
    toggleIsIncomingActive,
    toggleIsOutgoingActive,
    toggleAll,
  } = useUserChoices({
    chartRefOrRefs,
    series,
  });

  return series.length ? (
    <LegendContext.Provider
      value={{
        series,
        shouldHideInOutToggle,
        shouldShowToggleAll,
        seriesTooltips,
        inOutToggleSeriesTooltips,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: hasNewDesign ? 0 : 16,
        }}
      >
        <div className="custom-legend">
          <LegendList
            series={series}
            userChoices={userChoices}
            toggleIndividualSeriesVisibility={toggleIndividualSeriesVisibility}
            hasNewDesign={hasNewDesign}
          />
          <InOutToggle
            isIncomingActive={isIncomingActive}
            isOutgoingActive={isOutgoingActive}
            toggleIsIncomingActive={toggleIsIncomingActive}
            toggleIsOutgoingActive={toggleIsOutgoingActive}
          />
          <ToggleAll toggleAll={toggleAll} />
          {customLegendButton}
        </div>
      </div>
    </LegendContext.Provider>
  ) : null;
};

export default Legend;
