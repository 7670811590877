import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import NavBar from "../Auth/LoginLayout/components/NavBar";
import WorkingSection from "../Auth/LoginLayout/components/WorkingSection";
import { Route, Switch } from "react-router";

import AsyncComponent from "../../components/asyncComponent";
import { SharedRoutes } from "../../constants/routes/shared-routes";
const AsyncOnPremiseProbeCharts = AsyncComponent(() => import("../OnPremiseProbeCharts"));
const AsyncOnPremiseProbeResults = AsyncComponent(() => import("../OnPremiseProbeResults"));
const AsyncEmbedQualityRtcInviteTests = AsyncComponent(() => import("../EmbedQualityRTCInviteTests"));

class SharedLayout extends React.Component<RouteComponentProps<{}>> {
  render() {
    return (
      <>
        <NavBar />
        <WorkingSection>
          <Switch>
            <Route exact={true} path={SharedRoutes.ProbeRtcViewId} component={AsyncOnPremiseProbeCharts} />
            <Route exact={true} path={SharedRoutes.ProbeRtcResultsId} component={AsyncOnPremiseProbeResults} />
            <Route exact={true} path={SharedRoutes.QualityRTCInviteTests} component={AsyncEmbedQualityRtcInviteTests} />
          </Switch>
        </WorkingSection>
      </>
    );
  }
}

export default withRouter(SharedLayout);
