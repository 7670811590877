import { AxiosError } from "axios";

export interface ErrorStack {
  err: string;
}

export const reportError = (
  message: string,
  error: AxiosError | Error | ErrorEvent | PromiseRejectionEvent | ErrorStack,
  isForced?: boolean
) => {
  if (((error as AxiosError).isAxiosError || (error as AxiosError).response?.status === 401) && !isForced) {
    console.log(message, error);
  } else {
    console.error(message, error);
  }
};
