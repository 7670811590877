import { Card, Typography, Toolbar, Divider, Table, TableBody, TableRow, TableCell, MenuItem } from "@mui/material";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import DatePickerControl from "src/components/DatePicker";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import { AccountingTypes } from "../";
import * as moment from "moment";

type AccountingProps = {
  values: any;
  setServiceStartDate: (e: any) => void;
  setPaymentExpiry: (e: any) => void;
};
const Accounting = ({
  classes,
  values,
  setServiceStartDate,
  setPaymentExpiry,
}: AccountingProps & ProjectPropertiesViewProps & ProjectPropertiesState) => {
  return (
    <Card className={classes.card} style={{ padding: 0 }}>
      <Toolbar>
        <Typography variant="subtitle1">Accounting</Typography>
      </Toolbar>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: 220 }}>Project Start Date</TableCell>
            <TableCell>
              <DatePickerControl
                maxDate={moment().toDate()}
                clearable={true}
                value={values.serviceStartDate > moment().toDate().toISOString() ? "" : values.serviceStartDate}
                onChange={(e: any) => setServiceStartDate(e)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Project End Date</TableCell>
            <TableCell>
              <DatePickerControl
                clearable={true}
                value={values.paymentExpiry}
                onChange={(e: any) => setPaymentExpiry(e)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>
              <Field
                component={TextFieldControl}
                select={true}
                SelectProps={{ autoWidth: true }}
                name="accountType"
                variant="standard"
              >
                {Object.keys(AccountingTypes).map((key: any, i: number) => (
                  <MenuItem key={i} value={AccountingTypes[key]}>
                    {AccountingTypes[key]}
                  </MenuItem>
                ))}
              </Field>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default Accounting;
