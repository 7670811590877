import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Tooltip,
  Tab,
  Tabs,
  AppBar,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { MapTypeLabel, useStyles } from "src/containers/WatchRTCPeer/Expectations";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import TableFilter from "./TableFilter";
import { MapType } from "src/constants/watchRTCExpectation";
import MediaPreview from "./MediaPreview";
import LogList from "./LogList";

export interface Alert {
  time: number;
  alertType: "err" | "warn" | "info";
  channel: string;
  message: string;
  topic?: string;
  isAdminOnly?: boolean;
}
export const alertLabels = {
  err: "error",
  warn: "warning",
  info: "info",
};

export const insightCodeLinkMap = {
  2030: "https://support.testrtc.com/hc/en-us/articles/10866314731535-Status-2030-Microphone-camera-not-supported",
  2050: "https://support.testrtc.com/hc/en-us/articles/9358496223887-Status-2050-Generic-device-failure",
  2051: "https://support.testrtc.com/hc/en-us/articles/9358496224527-Status-2051-Device-is-already-in-use",
  2052: "https://support.testrtc.com/hc/en-us/articles/9358511476111-Status-2052-Device-has-issues",
  2053: "https://support.testrtc.com/hc/en-us/articles/9358543002383-Status-2053-Potential-software-bug",
  2054: "https://support.testrtc.com/hc/en-us/articles/9358511484559-Status-2054-User-denied-access-to-device",
  2100: "https://support.testrtc.com/hc/en-us/articles/9889957051663-Status-2100-2101-VPN-is-likely-in-use",
  2101: "https://support.testrtc.com/hc/en-us/articles/9889957051663-Status-2100-2101-VPN-is-likely-in-use",
  2110: "https://support.testrtc.com/hc/en-us/articles/9889972213519-Status-2110-Cellular-network-used",
  2120: "https://support.testrtc.com/hc/en-us/articles/9889972221711-Status-2120-Wireless-headset-used",
  2130: "https://support.testrtc.com/hc/en-us/articles/9889957069839-Status-2130-Potential-use-of-a-VM-VDI-machine",
  3001: "https://support.testrtc.com/hc/en-us/articles/9141729273615-Status-3001-Peer-connection-closed-a-lot-later-than-needed",
  3002: "https://support.testrtc.com/hc/en-us/articles/9141700312591-Status-3002-User-s-machine-time-got-modified-mid-session",
  3050: "https://support.testrtc.com/hc/en-us/articles/9358511508879-Status-3050-ICE-connection-failure",
  3051: "https://support.testrtc.com/hc/en-us/articles/9358527251215-Status-3051-ICE-restart",
  3060: "https://support.testrtc.com/hc/en-us/articles/9358527259663-Status-3060-setRemoteDescription-failure",
  3061: "https://support.testrtc.com/hc/en-us/articles/9358517901967-Status-3061-setLocalDescription-failure",
  3070: "https://support.testrtc.com/hc/en-us/articles/9889977877647-Status-3070-Too-many-network-changes-during-the-session",
  3500: "https://support.testrtc.com/hc/en-us/articles/10825631828495-Status-3500-Call-failed-to-connect",
  3600: "https://support.testrtc.com/hc/en-us/articles/10825645113999-Status-3600-Call-closed-abnormally",
  4001: "https://support.testrtc.com/hc/en-us/articles/9141729282191-Status-4001-Media-device-changed-multiple-times",
  4002: "https://support.testrtc.com/hc/en-us/articles/9141700323343-Status-4002-No-incoming-packets-mid-session",
  4003: "https://support.testrtc.com/hc/en-us/articles/9890020957839-Status-4003-No-media-for-an-hour-or-more",
  4200: "https://support.testrtc.com/hc/en-us/articles/9889977899663-Status-4200-4201-4210-4211-Network-fluctuations-might-be-causing-incoming-audio-quality-issues",
  4201: "https://support.testrtc.com/hc/en-us/articles/9889977899663-Status-4200-4201-4210-4211-Network-fluctuations-might-be-causing-incoming-audio-quality-issues",
  4210: "https://support.testrtc.com/hc/en-us/articles/9889977899663-Status-4200-4201-4210-4211-Network-fluctuations-might-be-causing-incoming-audio-quality-issues",
  4211: "https://support.testrtc.com/hc/en-us/articles/9889977899663-Status-4200-4201-4210-4211-Network-fluctuations-might-be-causing-incoming-audio-quality-issues",
  5001: "https://support.testrtc.com/hc/en-us/articles/9141722554255-Status-5001-No-audio-on-incoming-channels",
  5002: "https://support.testrtc.com/hc/en-us/articles/9358527274127-Status-5002-Too-many-audio-packets-are-getting-discarded",
  6001: "https://support.testrtc.com/hc/en-us/articles/9141715969423-Status-6001-Video-encoder-limited-by-CPU",
  6002: "https://support.testrtc.com/hc/en-us/articles/9358496280719-Status-6002-Encoded-more-frames-than-sent",
  6003: "https://support.testrtc.com/hc/en-us/articles/9358543052687-Status-6003-Decoded-less-frames-than-received",
  6110: "https://support.testrtc.com/hc/en-us/articles/9978610690831-Status-6110-Video-encode-time-too-high",
  6111: "https://support.testrtc.com/hc/en-us/articles/9978580790159-Status-6111-Video-decode-time-too-high",
  10001: "https://support.testrtc.com/hc/en-us/articles/9141715973775-Status-10001-Old-browser-version",
  10002: "https://support.testrtc.com/hc/en-us/articles/9141705858063-Status-10002-Data-center-selected-might-not-be-optimal",
  20001: "https://support.testrtc.com/hc/en-us/articles/9141700333327-Status-20001-High-outgoing-packet-loss",
  20002: "https://support.testrtc.com/hc/en-us/articles/9141745750799-Status-20002-High-incoming-packet-loss",
  20003: "https://support.testrtc.com/hc/en-us/articles/9141705868559-Status-20003-High-RTT",
  20004: "https://support.testrtc.com/hc/en-us/articles/9141722577295-Status-20004-Connected-but-no-media",
  20005: "https://support.testrtc.com/hc/en-us/articles/9141705872655-Status-20005-Bandwidth-estimation",
  30001: "https://support.testrtc.com/hc/en-us/articles/9141729331087-Status-30001-WiFi-access-point-is-far-away",
  30002: "https://support.testrtc.com/hc/en-us/articles/9141745766799-Status-30002-You-are-connected-via-WiFi",
  30003: "https://support.testrtc.com/hc/en-us/articles/9141700350991-Status-30003-30004-30006-You-are-connected-via-VPN",
  30004: "https://support.testrtc.com/hc/en-us/articles/9141700350991-Status-30003-30004-30006-You-are-connected-via-VPN",
  30005: "https://support.testrtc.com/hc/en-us/articles/9141745776015-Status-30005-30007-You-are-connected-via-cellular",
  30006: "https://support.testrtc.com/hc/en-us/articles/9141700350991-Status-30003-30004-30006-You-are-connected-via-VPN",
  30007: "https://support.testrtc.com/hc/en-us/articles/9141745776015-Status-30005-30007-You-are-connected-via-cellular",
  40001: "https://support.testrtc.com/hc/en-us/articles/9141745777807-Status-40001-UDP-connectivity",
  50001: "https://support.testrtc.com/hc/en-us/articles/9141729343887-Status-50001-Low-throughput",
  60001: "https://support.testrtc.com/hc/en-us/articles/9141722605071-Status-60001-Wireless-headset",
  60002: "https://support.testrtc.com/hc/en-us/articles/9141705891727-Status-60002-60003-Virtual-Desktop-VDI",
  60003: "https://support.testrtc.com/hc/en-us/articles/9141705891727-Status-60002-60003-Virtual-Desktop-VDI",
};

const View = (props: any) => {
  const {
    warnings,
    warningsCount,
    mediaList,
    hasLogs,
    resourceList,
    stdout,
    standOutUrl,
    assetsFileNames,
    testIteration,
    testRun,
  } = props;
  const classes = useStyles();
  const [all, setAll] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [checked, setChecked] = React.useState<boolean[]>([true, true]);
  const [filteredData, setFilteredData] = React.useState<Alert[]>([]);
  const [data, setData] = React.useState<Alert[]>([]);
  const [types, setTypes] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (warningsCount > 0) {
      const allAlerts = Object.values(warnings[0]).flat() as Alert[];
      const alertTypes = Array.from(new Set(allAlerts.map((alert) => alert.alertType)));
      setTypes(alertTypes);
      setData(allAlerts);
    }
  }, [warnings[0]]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // refactored to show rules engine error above all errors and rules engine warnings above all warnings
  const sort = (a: any, b: any) => {
    //return (a.type === "error" || a.alertType === "err") ? -1 : 0;
    if ((a.type === "error" || a.alertType === "err") && a.channel === "Insight") {
      return -1;
    } else if (
      (a.type === "error" || a.alertType === "err") &&
      !((b.type === "error" || b.alertType === "err") && b.channel === "Insight")
    ) {
      return -1;
    } else if (
      (a.type === "warning" || a.alertType === "warn") &&
      a.channel === "Insight" &&
      !(b.type === "error" || b.alertType === "err")
    ) {
      return -1;
    } else {
      return 0;
    }
  };

  return (
    <>
      <Grid className={classes.container}>
        <AppBar className={classes.toolbar}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label={
                <span>
                  <span>{`Notifications ${warningsCount > 0 ? "(" + warningsCount + ")" : ""}`}</span>
                </span>
              }
            />
            <Tab label="Media" />
            <Tab label="Logs" />
          </Tabs>
        </AppBar>
        <Stack className={classes.filterContainer}>
          <Box
            sx={{
              width: expanded ? "150px" : "28px",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "column",
              display: warningsCount > 5 && value === 0 ? "flex" : "none",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F5F5F6",
                width: "100%",
                borderRadius: "0px",
                "&:hover": {
                  backgroundColor: "#F5F5F6",
                },
                position: "relative",
                minHeight: "57px",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <Button
                sx={{
                  padding: "12px 16px",
                  position: "absolute",
                  right: "-32px",
                  rotate: expanded ? "180deg" : "0deg",
                }}
                onClick={() => setExpanded(!expanded)}
              >
                <PlayCircleOutlineIcon
                  sx={{
                    color: "#0000008A",
                  }}
                />
              </Button>
            </Box>
            <TableFilter
              types={types}
              filteredData={data}
              setFilteredData={setFilteredData}
              checked={checked}
              setChecked={setChecked}
              expanded={expanded}
            />
          </Box>
          {value === 0 &&
            (filteredData.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.customColumn}>Channel</TableCell>
                    <TableCell className={classes.typeColumn}>Type</TableCell>
                    <TableCell className={classes.metricColumn}>Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .sort(sort)
                    .slice(0, all ? filteredData.length : 2)
                    .map((ex, i) => {
                      const insightCode = ex.message.match(/^(\d+)/)?.[1];
                      return (
                        <TableRow key={i}>
                          <TableCell
                            sx={{
                              borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                              paddingLeft: "32px",
                            }}
                          >
                            {ex.channel}
                          </TableCell>
                          <TableCell>
                            <Tooltip title={"ex type"} placement="top">
                              <MapTypeLabel type={MapType[alertLabels[ex.alertType]]} />
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            {ex.channel !== "Insight" || !insightCode ? (
                              ex.message
                            ) : (
                              <>
                                <a
                                  href={insightCodeLinkMap[insightCode]}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={classes.insightLink}
                                >
                                  {insightCode}
                                </a>
                                {ex.message.slice(insightCode.length)}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {filteredData.length > 2 && (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        sx={{
                          padding: 0,
                        }}
                      >
                        <Button onClick={() => setAll(!all)} className={classes.limitButton}>
                          {`Show ${all ? "less" : "all"}`}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            ) : (
              <Typography variant="subtitle1">No warnings or errors reported</Typography>
            ))}
          {value === 1 && <MediaPreview mediaList={mediaList} />}
          {value === 2 && (
            <LogList
              assetsFileNames={assetsFileNames}
              stdout={stdout}
              standOutUrl={standOutUrl}
              hasLogs={hasLogs}
              resourceList={resourceList}
              test={testIteration}
              testRun={testRun}
            />
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default View;
