// tslint:disable:no-any
import { axiosInstance } from "./AxiosService";
import { AxiosResponse } from "axios";

export default class DbUtilsService {
  static rpc(serviceName: string, actionName: string, id?: string, params?: any, config?: any): Promise<AxiosResponse> {
    let url = "/api/" + serviceName + "/" + actionName;
    if (id) {
      url += "/" + id;
    }
    if (params) {
      if (Array.isArray(params)) {
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            url += "/" + params[key];
          }
        }
      } else {
        url += "/" + params.toString();
      }
    }

    if (typeof config !== "object" || config === null) {
      config = {};
    }

    return axiosInstance
      .get(url, config)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  }
}
