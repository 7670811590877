import { useContext, useMemo } from "react";
import { TABLE_ID } from "src/components/AgGrid/export/constants/table-id.constants";

import { getColumnNamesForExport, getTableSchema } from "src/components/AgGrid/export/table.utils";
import { AppControllerState, ProjectsContext } from "src/containers/AppController";

export const useTableSchema = (tableId: typeof TABLE_ID[keyof typeof TABLE_ID] | undefined) => {
  const projectContext = useContext<AppControllerState>(ProjectsContext);

  const tableSchema = useMemo(
    () => getTableSchema(projectContext.selectedProject?.tablesCustomisationSchema, tableId),
    [projectContext.selectedProject?.tablesCustomisationSchema, tableId]
  );
  const columnNamesForExport = getColumnNamesForExport(tableSchema);

  return { tableSchema, columnNamesForExport };
};
