import * as React from "react";

interface IChartSizeContext {
  size: number;
  height: number;
  setSize: (size: number) => void;
  setHeight: (height: number) => void;
}
export const ChartSizeContext = React.createContext<IChartSizeContext>({} as any);

export function ChartSizeProvider(props: any) {
  const [size, setSize] = React.useState<number>(1);
  const [height, setHeight] = React.useState<number>();

  return (
    <ChartSizeContext.Provider
      value={{
        size,
        height: height as number,
        setSize,
        setHeight,
      }}
    >
      {props.children}
    </ChartSizeContext.Provider>
  );
}
