import { useContext, useEffect, useState } from "react";
import { RouteProps, Route, Switch, useHistory } from "react-router-dom";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import AsyncComponent from "../../components/asyncComponent";
import { getLocationTitle } from "../../constants/PageStateDictionary";
import { WatchRTCProvider } from "../WatchRTC/Context";
import AppRoot from "src/containers/Layout/components/AppRoot";
import AppContentSpinner from "src/containers/Layout/components/AppContentSpinner";
import Loader from "../../components/Loader/Loader";
import Breadcrumb from "src/containers/Layout/components/Breadcrumb";
import { FrameRoutes } from "../../constants/routes/frame-routes";
import { PRODUCT } from "../Trace/enums";
import AppError from "../Layout/components/AppError";
import { AppContext } from "src/App";
import { axiosExternalInstance, isAxiosError } from "src/services/AxiosService";
import ApiPath from "src/constants/ApiPath";
import { AppRoutes } from "src/constants/routes/app-routes";

const WatchRTCRoom = AsyncComponent(() => import("../WatchRTCRoom"));
const WatchRTCPeer = AsyncComponent(() => import("../WatchRTCPeer"));
const Trace = AsyncComponent(() => import("../Trace"));
const AdvancedAnalytics = AsyncComponent(() => import("src/containers/LogsContainer/WebRTCInternals/WatchRTC"));

const ForbiddenPage = AsyncComponent(() => import("../ForbiddenPage"));
const ContentMissingPage = AsyncComponent(() => import("../ContentMissingPage"));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      width: "100%",
      minHeight: "100vh",
      flexGrow: 1,
      padding: 0,
      overflowY: "auto",

      [theme.breakpoints.up("md")]: {
        position: "relative",
      },

      "@media screen": {
        backgroundColor: "#EDEDED",
      },
      "@media print": {
        overflowY: "visible",
      },
    },
    content: {
      padding: 16,
      marginBottom: theme.spacing(7),
    },
  })
);

const invalidOriginErrorMessage = `Parent window origin does not match allowed "Embedding domains" from watchRTC project settings`;

const isEmbeddingAllowed = (embeddingHostname: string, projectConfig: FrameProjectConfig) => {
  const allowedDomains = projectConfig?.watchrtc_embedded_domains.split(",");

  return allowedDomains.some((allowedHostname) => {
    let result: boolean;

    if (!allowedHostname.includes("*")) {
      result = embeddingHostname === allowedHostname;
    } else {
      const regexStr = `^${allowedHostname}$`.replace(/\./g, "\\.").replace(/\-/g, "\\-").replace(/\*/g, ".*");
      const regex = new RegExp(regexStr);
      result = regex.test(embeddingHostname);
    }

    return result;
  });
};

const FrameLayout = ({ location }: RouteProps) => {
  const classes = useStyles();

  const history = useHistory();

  const { error } = useContext(AppContext);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [projectConfig, setProjectConfig] = useState<FrameProjectConfig | null>(null);

  const initProjectConfig = async (apiKey: string, eventOrigin: string) => {
    let isSuccess = false;

    const originHostname = new URL(eventOrigin).hostname;

    try {
      const projectConfigResponse = await axiosExternalInstance.get<FrameProjectConfig>(
        ApiPath.frame.showProjectConfig,
        {
          params: { origin: originHostname },
          headers: { apikey: apiKey },
        }
      );

      if (projectConfigResponse.data && isEmbeddingAllowed(originHostname, projectConfigResponse.data)) {
        axiosExternalInstance.interceptors.request.use((config) => {
          config.headers = {
            apikey: apiKey,
            ...config.headers,
          };

          config.params = {
            origin: originHostname,
            ...config.params,
          };

          return config;
        });

        setIsLoaded(true);
        setProjectConfig(projectConfigResponse.data);

        isSuccess = true;
      } else {
        history.push(FrameRoutes.Forbidden);
        setIsLoaded(true);
        console.error(invalidOriginErrorMessage);
      }
    } catch (error) {
      let errorMessage = "Failed to fetch project config, reason:";
      let errorObject: unknown = error;

      if (isAxiosError(error)) {
        const message: string | undefined = error.response?.data?.message;
        errorObject = message;

        if (error.response?.status === 400) {
          history.push(FrameRoutes.WrongLink);
        } else if (error.response?.status === 401) {
          history.push(FrameRoutes.Forbidden);
        } else if (error.response?.status === 403) {
          history.push(FrameRoutes.Forbidden);

          if (message?.includes("invalid-origin")) {
            errorMessage = invalidOriginErrorMessage;
          }
        } else if (error.response?.status === 500) {
          history.push(FrameRoutes.ServerError);
        }
      }

      setIsLoaded(true);
      console.error(errorMessage, errorObject);
    }

    return isSuccess;
  };

  useEffect(function listenForApiKeyMessage() {
    const isIframe = window.self !== window.top;

    if (isIframe) {
      let isHandlingMessage = false;
      const apiKeysBlacklist = new Set();

      const messageHandler = async (event: MessageEvent<{ apiKey: string }>) => {
        if (!isHandlingMessage && !apiKeysBlacklist.has(event.data.apiKey) && typeof event.data?.apiKey === "string") {
          isHandlingMessage = true;

          const isSuccess = await initProjectConfig(event.data.apiKey, event.origin);

          if (!isSuccess) {
            apiKeysBlacklist.add(event.data.apiKey);
          }

          event.source?.postMessage({ isSuccess }, { targetOrigin: event.origin });

          setTimeout(() => {
            isHandlingMessage = false;
          }, 500);
        }
      };

      window.addEventListener("message", messageHandler);
      () => {
        window.removeEventListener("message", messageHandler);
      };
    } else {
      window.location.href = AppRoutes.WatchRTCHistory;
    }
  }, []);

  useEffect(function updateTitle() {
    document.title = location?.pathname ? "testRTC | " + (getLocationTitle(location.pathname) || "App") : "testRTC";
  });

  return (
    <WatchRTCProvider isFramed>
      <AppRoot>
        <main className={classes.contentContainer}>
          {!error ? (
            <div className={classes.content}>
              <AppContentSpinner Loader={Loader} isForced={!isLoaded} />
              <div style={{ position: "relative" }}>
                {!projectConfig ? (
                  <Switch>
                    <Route
                      exact
                      path={FrameRoutes.Forbidden}
                      render={(props) => (
                        <>
                          <Breadcrumb isFramed />
                          <ForbiddenPage {...props} />
                        </>
                      )}
                    />
                    <Route
                      exact
                      path={FrameRoutes.NotFound}
                      render={(props) => (
                        <>
                          <Breadcrumb isFramed />
                          <ContentMissingPage {...props} />
                        </>
                      )}
                    />
                    <Route
                      exact
                      path={FrameRoutes.WrongLink}
                      render={(props) => (
                        <>
                          <Breadcrumb isFramed />
                          <ContentMissingPage {...props} />
                        </>
                      )}
                    />
                    <Route
                      exact
                      path={FrameRoutes.ServerError}
                      render={(props) => (
                        <>
                          <Breadcrumb isFramed />
                          <ContentMissingPage {...props} />
                        </>
                      )}
                    />
                  </Switch>
                ) : (
                  <Switch>
                    <Route
                      exact
                      path={FrameRoutes.WatchRTCRoomId}
                      render={(props) => <WatchRTCRoom {...props} isFramed frameProjectConfig={projectConfig} />}
                      key="WatchRTCRoom"
                    />
                    <Route
                      exact
                      path={FrameRoutes.WatchRTCPeerTrace}
                      render={() => <Trace product={PRODUCT.watchRTC} isFramed />}
                      key="Trace"
                    />
                    <Route
                      exact
                      path={FrameRoutes.WatchRTCPeerId}
                      render={(props) => <WatchRTCPeer {...props} isFramed frameProjectConfig={projectConfig} />}
                      key="WatchRTCPeer"
                    />
                    <Route
                      exact
                      path={FrameRoutes.WebrtcInternalsWatchRtcId}
                      render={(props) => <AdvancedAnalytics {...props} isFramed />}
                      key="AdvancedAnalytics"
                    />

                    <Route
                      exact
                      path={FrameRoutes.Forbidden}
                      render={(props) => (
                        <>
                          <Breadcrumb isFramed />
                          <ForbiddenPage {...props} />
                        </>
                      )}
                    />
                    <Route
                      exact
                      path={FrameRoutes.NotFound}
                      render={(props) => (
                        <>
                          <Breadcrumb isFramed />
                          <ContentMissingPage {...props} />
                        </>
                      )}
                    />
                    <Route
                      exact
                      path={FrameRoutes.WrongLink}
                      render={(props) => (
                        <>
                          <Breadcrumb isFramed />
                          <ContentMissingPage {...props} />
                        </>
                      )}
                    />
                    <Route
                      exact
                      path={FrameRoutes.ServerError}
                      render={(props) => (
                        <>
                          <Breadcrumb isFramed />
                          <ContentMissingPage {...props} />
                        </>
                      )}
                    />
                  </Switch>
                )}
              </div>
            </div>
          ) : (
            <AppError />
          )}
        </main>
      </AppRoot>
    </WatchRTCProvider>
  );
};

export default FrameLayout;
