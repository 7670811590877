import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cn from "classnames";

interface BreadcrumbProps {
  isFramed?: boolean;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    wrapper: {
      maxWidth: "calc(100% - 300px)",
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      alignItems: "center",
      marginBottom: 16,
    },
    framedWrapper: { margin: 0 },
    node0: {
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
      color: _theme.palette.primary.dark,
      fontSize: 12,
      marginRight: 4,
      textDecoration: "underline",
      position: "relative",
      "&::after": {
        content: '":"',
        display: "block",
        position: "absolute",
        right: "-5px",
        color: "#4f4f4f",
        pointerEvents: "none",
      },
      "&:hover": {
        color: _theme.palette.primary.main,
      },
      "& > *": {
        padding: 0,
        margin: 0,
      },
      "&:last-child": {
        pointerEvents: "none",
        color: "#4f4f4f",
        textDecoration: "none",
        "&::after": {
          display: "none",
        },
      },
    },
    node: {
      display: "flex",
      alignItems: "center",

      padding: "0 10px",

      color: _theme.palette.primary.dark,
      fontSize: 12,

      marginRight: 4,
      textDecoration: "underline",

      position: "relative",

      "&::after": {
        content: '"/"',
        display: "block",
        position: "absolute",
        right: "-5px",
        color: "#4f4f4f",
        pointerEvents: "none",
      },

      "&:hover": {
        color: _theme.palette.primary.main,
      },
      "& > *": {
        padding: 0,
        margin: 0,
      },
      "&:last-child": {
        pointerEvents: "none",
        color: "#4f4f4f",
        textDecoration: "none",
        "&::after": {
          display: "none",
        },
      },
    },
    node2: {
      display: "flex",
      alignItems: "center",

      padding: "5px 10px",

      background: "#c1c1c1",
      transition: "0.1s ease-in-out",

      color: "white",
      fontSize: 10,
      fontWeight: "bold",
      textShadow: "2px 2px 4px #0000006b",
      textDecoration: "none",

      marginRight: 4,

      border: "1px solid #9d9d9d",
      borderRadius: 5,
      "&:hover": {
        transform: "translate(-2px,-2px)",
        boxShadow: "2px 2px 0px 0px #9d9d9d",
      },
      "& > *": {
        padding: 0,
        margin: 0,
        transition: "0.1s ease-in-out",
      },
      "&:last-child": {
        pointerEvents: "none",
        background: "#9d9d9d",
      },
    },
  })
);

const Breadcrumb = ({ isFramed }: BreadcrumbProps) => {
  const classes = useStyles();

  const { nodes } = useSelector((store: IStore) => store.breadcrumb);

  if (!nodes.length || (nodes.length < 2 && !isFramed) || window.location.href.includes("/app/main")) {
    return null;
  }

  const backBtnLink = nodes[nodes.length - 2]?.link;
  return (
    <nav className={cn(classes.wrapper, { [classes.framedWrapper]: isFramed })}>
      {nodes.length > 2 && (
        <Link className={classes.node0} to={backBtnLink}>
          <p>{"< Back"}</p>
        </Link>
      )}
      {nodes.map((b: BreadcrumbNode, i: number) => (
        <Link key={i} to={b.link} style={{ zIndex: nodes.length - i }} className={classes.node}>
          <p>{b.text}</p>
        </Link>
      ))}
    </nav>
  );
};

export default Breadcrumb;
