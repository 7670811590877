import { useState } from "react";
import { RouteComponentProps } from "react-router";
import { AppRoutes } from "src/constants/routes/app-routes";
import DbUtilsService from "src/services/DbUtilsService";
import DumpFileService from "src/services/DumpFileService";
import { RouteParams } from "../interfaces";
import { saveAs } from "file-saver";
import ApiPath from "src/constants/ApiPath";

export const useDumpUrl = (props: RouteComponentProps<RouteParams>, isFramed?: boolean) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { testIterationId, iterationMachine, fileName = "dump", dumpUrl } = props.match.params;

  const generateDumpUrl = async (convertRTCStats = true) => {
    const isLive = props.location.pathname.includes(AppRoutes.WebrtcInternalsWatchRtcLive);
    if (isLive) {
      return `${process.env.REACT_APP_API_PATH}/api/watchRTCLive/advance-webrtc/${testIterationId}`;
    }

    if (isFramed) {
      return `${ApiPath.frame.getAdvanceWebRtc
        .replace(":fileUrl", dumpUrl ?? "")
        .replace(":machineName", iterationMachine)
        .replace(":action", "download")}?preprocess=${convertRTCStats}`;
    }

    let apiControllerPath: string;

    if (props.match.path === AppRoutes.WebrtcInternalsWatchRtcId) {
      apiControllerPath = "watchRTCPeer";
    } else {
      apiControllerPath = "test_iterations";
    }

    let url = "";
    if (dumpUrl) {
      url = `${process.env.REACT_APP_API_PATH}/api/${apiControllerPath}/get-advance-webrtc/${dumpUrl}/${iterationMachine}/download?preprocess=${convertRTCStats}`;
    } else {
      // most likely it's not watchRTC so no need to set apiControllerPath
      const resp = await DbUtilsService.rpc("test_iterations", "get-screen-shots", testIterationId);
      const result = resp.data;
      for (const key in result.screens) {
        if (result.screens.hasOwnProperty(key)) {
          const screen = result.screens[key];
          if (screen.name.indexOf(fileName) >= 0) {
            url =
              process.env.REACT_APP_API_PATH +
              "/api/test_iterations/get-advance-webrtc/" +
              encodeURIComponent(screen.url) +
              "/" +
              iterationMachine +
              `/download?preprocess=${convertRTCStats}&iterationId=${testIterationId}`;
          }
        }
      }
    }
    return url;
  };

  const getClearDumpFile = async () => {
    setLoading(true);
    const url = await generateDumpUrl(false);
    const response = await DumpFileService.getDumpFile(url, undefined, isFramed);
    const machine = response?.data?.sessionData?.hardware?.machine;
    const file = machine ? machine + "_" + fileName : fileName + "_getstat_logs";

    const blob = new Blob([JSON.stringify(response.data, null, 2)]);
    const fileExt = ["txt", "json"];
    const parts = file.split(".");
    if (fileExt.includes(parts[parts.length - 1])) {
      saveAs(blob, file);
    } else {
      saveAs(blob, `${file}.txt`);
    }
    setLoading(false);
  };

  return { isLoading, generateDumpUrl, getClearDumpFile };
};
