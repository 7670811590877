export interface IProbeRTCTest {
  name: string;
  title: string;
  tooltip: string;
  link: string;
}

export type TestName =
  | "CallQuality"
  | "TurnConnectivity"
  | "Location"
  | "Ping"
  | "Throughput"
  | "BandwidthSpeed"
  | "DNSLookup"
  | "RCVTURNServers"
  | "VideoBandwidth"
  | "NetworkInformation"
  | "VideoQuality"
  | "Traceroute";

export const ProbeRTCTests: { [name in TestName]: IProbeRTCTest } = {
  CallQuality: {
    name: "CallQuality",
    title: "Call Quality",
    tooltip: "Call Quality",
    link: "https://testrtc.com/docs/call-quality-what-we-measure/",
  },
  TurnConnectivity: {
    name: "TurnConnectivity",
    title: "Turn Connectivity",
    tooltip: "Turn Connectivity",
    link: "https://testrtc.com/docs/turn-connectivity-what-we-measure/",
  },
  Location: {
    name: "Location",
    title: "Location",
    tooltip: "Location",
    link: "https://testrtc.com/docs/location-what-we-measure/",
  },
  Ping: {
    name: "Ping",
    title: "Ping",
    tooltip: "Ping",
    link: "https://testrtc.com/docs/ping-what-we-measure/",
  },
  Throughput: {
    name: "Throughput",
    title: "Throughput",
    tooltip: "Throughput",
    link: "https://testrtc.com/docs/throughput-what-we-measure/",
  },
  BandwidthSpeed: {
    name: "BandwidthSpeed",
    title: "Bandwidth Speed",
    tooltip: "Bandwidth Speed",
    link: "https://testrtc.com/docs/bandwidth-speed-what-we-measure/",
  },
  DNSLookup: {
    name: "DNSLookup",
    title: "DNS Lookup",
    tooltip: "DNS Lookup",
    link: "https://testrtc.com/docs/dns-lookup-what-we-measure/",
  },
  RCVTURNServers: {
    name: "RCVTURNServers",
    title: "RCV Turn Servers",
    tooltip: "RCV Turn Servers",
    link: "https://testrtc.com/docs/turn-connectivity-what-we-measure/",
  },
  VideoBandwidth: {
    name: "VideoBandwidth",
    title: "Video Bandwidth",
    tooltip: "Video Bandwidth",
    link: "https://testrtc.com/docs/video-bandwidth-what-we-measure/",
  },
  NetworkInformation: {
    name: "NetworkInformation",
    title: "Network Information",
    tooltip: "NetworkInformation",
    link: "",
  },
  VideoQuality: {
    name: "VideoQuality",
    title: "Video Quality",
    tooltip: "Video Quality",
    link: "https://testrtc.com/docs/video-quality-what-we-measure/",
  },

  Traceroute: {
    name: "Traceroute",
    title: "Traceroute",
    tooltip: "Traceroute",
    link: "",
  },
};

export const DefaultNormalTestList = [
  ProbeRTCTests.CallQuality.name,
  ProbeRTCTests.TurnConnectivity.name,
  ProbeRTCTests.Location.name,
  ProbeRTCTests.Ping.name,
];
export const DefaultThoroughTestList = [
  ProbeRTCTests.CallQuality.name,
  ProbeRTCTests.TurnConnectivity.name,
  ProbeRTCTests.Location.name,
  ProbeRTCTests.Ping.name,
  ProbeRTCTests.Throughput.name,
];
