import { Field } from "react-final-form";
import DatePickerControl from "src/components/DatePicker";
// import SelectFieldControl from "../form-elements/SelectFieldControl";
import * as moment from "moment";

type TripleDateFilter = {
  classes: any;
  index: number;
  name: string;
  fieldName: string;
  label: string;
  options: any;
};

const FromDate = "fromDate";
const ToDate = "toDate";

const DatePickerField = (props: any) => {
  const {
    input: { name, onChange, value },
    placeholder,
  } = props;
  return (
    <DatePickerControl
      name={name}
      placeholder={placeholder}
      onChange={(date) => {
        if (date) {
          if (name.indexOf(FromDate) !== -1) {
            onChange(moment(date).startOf("day").toLocaleString());
            return;
          }
          if (name.indexOf(ToDate) !== -1) {
            onChange(moment(date).endOf("day").toLocaleString());
            return;
          }
          onChange(moment(date).toLocaleString());
          return;
        }
        onChange(date);
      }}
      value={value}
      clearable={true}
      variant="outlined"
    />
  );
};

const TripleDateFilter = ({ classes, name, fieldName }: TripleDateFilter) => {
  return (
    <>
      <div className={classes.datePickerFilterField}>
        <Field component={DatePickerField} name={name.replace(fieldName, FromDate)} placeholder={"From"} />
      </div>
      <div className={classes.datePickerFilterField}>
        <Field component={DatePickerField} name={name.replace(fieldName, ToDate)} placeholder={"To"} />
      </div>
      {/* <Field component={SelectFieldControl} name={name} label={label} options={options} /> */}
    </>
  );
};

export default TripleDateFilter;
