export type DataType =
  | "bits"
  | "jitter"
  | "packets"
  | "loss"
  | "lossPCT"
  | "videoDelay"
  | "videoFrameRate"
  | "browserCpu"
  | "browserMemory";
export type MediaType = "audio" | "video" | "performance";
export type ByType = "time/probe" | "time/channel" | "probe";

export const byTypeArrayValues = {
  timeProbe: "time/probe",
  timeChannel: "time/channel",
  probe: "probe",
};
export const byTypeArray = [byTypeArrayValues.timeProbe, byTypeArrayValues.timeChannel, byTypeArrayValues.probe];

export const dataTypesValues = {
  bits: "bits",
  jitter: "jitter",
  packets: "packets",
  loss: "loss",
  lossPCT: "lossPCT",
  videoDelay: "videoDelay",
  videoFrameRate: "videoFrameRate",
  browserCpu: "browserCpu",
  browserMemory: "browserMemory",
};

export const mediaType = {
  audio: "audio",
  video: "video",
  performance: "performance",
};
export const medias = [mediaType.audio, mediaType.video, mediaType.performance];
export const mediaIcons = {
  [mediaType.audio]: "headset",
  [mediaType.video]: "videocam",
  [mediaType.performance]: "speed",
};

export const MediaTypeToName = {
  [mediaType.video]: "Video",
  [mediaType.audio]: "Audio",
  [mediaType.performance]: "Performance",
};

export const dataTypeToTitle = {
  [dataTypesValues.bits]: {
    title: "Outgoing and incoming {0} bitrate",
    yAxisLabel: "Kbits",
    label: "Bitrate",
  },
  [dataTypesValues.loss]: {
    title: "# of packet loss",
    yAxisLabel: "# of packets lost",
    label: "Packets loss (#)",
  },
  [dataTypesValues.lossPCT]: {
    title: "% of packet loss",
    yAxisLabel: "% of packets lost",
    label: "Packets loss (%)",
  },
  [dataTypesValues.jitter]: {
    title: "{0} jitter",
    yAxisLabel: "ms",
    label: "Jitter",
  },
  [dataTypesValues.packets]: {
    title: "Outgoing and incoming {0} packets",
    yAxisLabel: "Packets",
    label: "Packets",
  },
  [dataTypesValues.videoDelay]: {
    title: "Outgoing and incoming {0} RTT",
    yAxisLabel: "ms",
    label: "Round Trip Time",
  },
  [dataTypesValues.videoFrameRate]: {
    title: "Outgoing and incoming {0} Frame Rate",
    yAxisLabel: "fps",
    label: "Frame rate",
  },
  [dataTypesValues.browserCpu]: {
    title: "CPU Usage by browser",
    yAxisLabel: "%",
    label: "By Browser CPU",
  },
  [dataTypesValues.browserMemory]: {
    title: "Memory Usage by browser",
    yAxisLabel: "MBytes",
    label: "By Browser Memory",
  },
};

export const labels = ["Incoming", "Outgoing"];

export const chartGroup = {
  [byTypeArrayValues.timeChannel]: {
    bitrate: {
      audio: [dataTypesValues.bits],
      video: [dataTypesValues.bits],
    },
    quality: {
      audio: [dataTypesValues.jitter, dataTypesValues.videoDelay, dataTypesValues.lossPCT, dataTypesValues.loss],
      video: [
        dataTypesValues.jitter,
        dataTypesValues.videoDelay,
        dataTypesValues.lossPCT,
        dataTypesValues.loss,
        dataTypesValues.videoFrameRate,
      ],
    },
  },
  [byTypeArrayValues.timeProbe]: {
    bitrate: {
      audio: [dataTypesValues.bits],
      video: [dataTypesValues.bits],
      performance: [dataTypesValues.browserCpu],
    },
    quality: {
      audio: [dataTypesValues.jitter, dataTypesValues.videoDelay, dataTypesValues.lossPCT, dataTypesValues.loss],
      video: [
        dataTypesValues.jitter,
        dataTypesValues.videoDelay,
        dataTypesValues.lossPCT,
        dataTypesValues.loss,
        dataTypesValues.videoFrameRate,
      ],
      performance: [dataTypesValues.browserMemory],
    },
  },
  [byTypeArrayValues.probe]: {
    bitrate: {
      audio: [dataTypesValues.bits],
      video: [dataTypesValues.bits],
      performance: [dataTypesValues.browserCpu],
    },
    quality: {
      audio: [dataTypesValues.jitter, dataTypesValues.videoDelay, dataTypesValues.lossPCT, dataTypesValues.loss],
      video: [
        dataTypesValues.jitter,
        dataTypesValues.videoDelay,
        dataTypesValues.lossPCT,
        dataTypesValues.loss,
        dataTypesValues.videoFrameRate,
      ],
      performance: [dataTypesValues.browserMemory],
    },
  },
};

export const chartHeights = {
  Compact: 0.6,
  Normal: 0.8,
  Large: 1,
};

export const chartHeightsArray = [chartHeights.Compact, chartHeights.Normal, chartHeights.Large];
export const chartHeightsLabels = {
  [chartHeights.Compact]: "Compact View",
  [chartHeights.Normal]: "Normal View",
  [chartHeights.Large]: "Large View",
};
export const nextChartHeight = {
  [chartHeights.Compact]: chartHeights.Normal,
  [chartHeights.Normal]: chartHeights.Large,
  [chartHeights.Large]: chartHeights.Compact,
};

export const tooltips = {
  mediaTypes: {
    [mediaType.audio]: `Show voice channels information`,
    [mediaType.video]: `Show video channels information`,
    [mediaType.performance]: `Show probe machine performance information`,
  },
  tabs: {
    [byTypeArrayValues.timeProbe]: `Show aggregate results over time across the probes.
    Data from multiple incoming and outgoing peer connections and channels will be summed up`,
    [byTypeArrayValues.timeChannel]: `Show aggregate results over time across the probes.
    Data from multiple incoming and outgoing peer connections and channels will be averaged across the channels`,
    [byTypeArrayValues.probe]: `Show average values per probe participating in the sessions`,
  },
  dataTypesByType: {
    [byTypeArrayValues.timeProbe]: {
      [dataTypesValues.jitter]: `Jitter over time, in milliseconds.
      Values are the sum of jitter data across all channels`,
      [dataTypesValues.videoDelay]: `Round trip time over time, in milliseconds.
      Values are the sum of round trip time data across all channels`,
      [dataTypesValues.lossPCT]: `Percentage of packet loss over time.
      Values are the sum of packet loss percentage across all channels`,
      [dataTypesValues.loss]: `Number of lost packets over time.
      Values are the sum of lost packets across all channels`,
      [dataTypesValues.videoFrameRate]: `Frame rate over time.
      Values are the sum of frames per second across all channels`,
    },
    [byTypeArrayValues.timeChannel]: {
      [dataTypesValues.jitter]: `Jitter over time, in milliseconds.
      Values are the average jitter across all channels`,
      [dataTypesValues.videoDelay]: `Round trip time over time, in milliseconds.
      Values are the average round trip time across all channels`,
      [dataTypesValues.lossPCT]: `Percentage of packet loss over time.
      Values are the average packet loss percentage across all channels`,
      [dataTypesValues.loss]: `Number of lost packets over time.
      Values are the average lost packets across all channels`,
      [dataTypesValues.videoFrameRate]: `Frame rate over time.
      Values are the average frames per second across all channels`,
    },
    [byTypeArrayValues.probe]: {
      [dataTypesValues.jitter]: `Average jitter per probe`,
      [dataTypesValues.videoDelay]: `Average round trip time per probe`,
      [dataTypesValues.lossPCT]: `Percentage of packet loss per probe`,
      [dataTypesValues.loss]: `Number of lost packets per probe`,
      [dataTypesValues.videoFrameRate]: `Average frames per second per probe`,
    },
  },
  seriesByTypeAndMediaType: {
    [byTypeArrayValues.timeProbe]: {
      [mediaType.audio]: {
        Incoming: `Average across all probes related to incoming channels`,
        Outgoing: `Average across all probes related to outgoing channels`,
        "Incoming min/max band": `Minimum and maximum values across all probes related to incoming channels`,
        "Outgoing min/max band": `Minimum and maximum values across all probes related to outgoing channels`,
        "Global events": `Events defined as global using rtcEvent()`,
        "Call end": `Marker for first probe leaving the scenario`,
      },
      [mediaType.video]: {
        Incoming: `Average across all probes related to incoming channels`,
        Outgoing: `Average across all probes related to outgoing channels`,
        "Incoming min/max band": `Minimum and maximum values across all probes related to incoming channels`,
        "Outgoing min/max band": `Minimum and maximum values across all probes related to outgoing channels`,
        "Global events": `Events defined as global using rtcEvent()`,
        "Call end": `Marker for first probe leaving the scenario`,
      },
      [mediaType.performance]: {
        "Global events": `Events defined as global using rtcEvent()`,
        "Call end": `Marker for first probe leaving the scenario`,
      },
    },
    [byTypeArrayValues.timeChannel]: {
      [mediaType.audio]: {
        Incoming: `Average across all probes related to incoming channels`,
        Outgoing: `Average across all probes related to outgoing channels`,
        "Incoming min/max band": `Minimum and maximum values across all probes related to incoming channels`,
        "Outgoing min/max band": `Minimum and maximum values across all probes related to outgoing channels`,
        "Global events": `Events defined as global using rtcEvent()`,
        "Call end": `Marker for first probe leaving the scenario`,
      },
      [mediaType.video]: {
        Incoming: `Average across all probes related to incoming channels`,
        Outgoing: `Average across all probes related to outgoing channels`,
        "Incoming min/max band": `Minimum and maximum values across all probes related to incoming channels`,
        "Outgoing min/max band": `Minimum and maximum values across all probes related to outgoing channels`,
        "Global events": `Events defined as global using rtcEvent()`,
        "Call end": `Marker for first probe leaving the scenario`,
      },
      [mediaType.performance]: {},
    },
    [byTypeArrayValues.probe]: {
      [mediaType.audio]: {
        Incoming: `Incoming data, averaged per probe`,
        Outgoing: `Outgoing data, averaged per probe`,
      },
      [mediaType.video]: {
        Incoming: `Incoming data, averaged per probe`,
        Outgoing: `Outgoing data, averaged per probe`,
      },
      [mediaType.performance]: {
        Max: `Maximum CPU and memory values measured on the probe`,
        Avg: `Average CPU and memory values measured on the probe during the session`,
        Min: `Minimum CPU and memory values measured on the probe`,
      },
    },
  },
};

export const yAxisSoftMinMax = {
  min: {
    jitter: 0,
    videoDelay: 0,
    loss: 0,
    lossPCT: 0,
    videoFrameRate: 0,
    browserCpu: 0,
    browserMemory: 0,
  },
  max: {
    jitter: 50,
    videoDelay: 50,
    loss: 2,
    lossPCT: 10,
    videoFrameRate: 15,
    browserCpu: 100,
    browserMemory: 1500,
  },
};
