import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { FieldRenderProps } from "react-final-form";

interface SelectFieldControlSimpleProps {
  id: string;
  options: Record<string | number, string>;
  label?: string;
  helperText?: string;
  variant?: "filled" | "outlined" | "standard";
  size?: "medium" | "small";
  className?: string;
}

const SelectFieldControlSimple = ({
  input: { name, value, onChange },
  meta,
  id,
  options,
  label,
  helperText,
  variant = "standard",
  size = "medium",
  className,
}: FieldRenderProps & SelectFieldControlSimpleProps) => {
  const isError = meta.touched && meta.error;

  const optionsJsx = Object.entries(options).map(([value, text]) => (
    <MenuItem value={value} key={value}>
      {text}
    </MenuItem>
  ));

  return (
    <FormControl variant={variant} size={size} className={className} fullWidth>
      {label ? (
        <InputLabel id={`${id}-label`} error={isError}>
          {label}
        </InputLabel>
      ) : null}
      <Select
        id={id}
        labelId={label ? `${id}-label` : undefined}
        name={name}
        value={value}
        onChange={onChange}
        label={label}
        error={isError}
      >
        {optionsJsx}
      </Select>
      {isError || helperText ? (
        <FormHelperText error={isError}>{isError ? meta.error : helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default SelectFieldControlSimple;
