import * as React from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { CommentOutlined as CommentOutlinedIcon } from "@mui/icons-material";
import { useBoolean } from "usehooks-ts";

import { SetNotification } from "src/actions/notificationAction";

export interface NotesProps {
  notes?: string;
  saveNotes?: (newComment: string) => Promise<boolean>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentButton: { margin: "-4px -8px" },
    commentIcon: { width: 24, height: "auto" },
    commentIconCommented: { color: theme.palette.primary.main },

    dialog: {
      maxWidth: 444,
      width: "100%",
    },
  })
);

const Notes = ({ notes = "", saveNotes }: NotesProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { value: isOpen, toggle: toggleIsOpen } = useBoolean(false);

  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    setValue(notes);
  }, [notes]);

  let resultJsx: JSX.Element | null = null;

  if (saveNotes) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };

    const handleSave = async () => {
      toggleIsOpen();

      const result = await saveNotes(value.trim());

      if (result) {
        dispatch(SetNotification("Note saved successfully"));
      } else {
        dispatch(SetNotification("Error while saving note"));
      }
    };

    const title = notes ? "Edit note" : "Add note";

    resultJsx = (
      <>
        <Tooltip title={title} placement="top">
          <IconButton onClick={toggleIsOpen} className={classes.commentButton}>
            <CommentOutlinedIcon
              className={cn(classes.commentIcon, { [classes.commentIconCommented]: Boolean(notes) })}
            />
          </IconButton>
        </Tooltip>

        <Dialog
          open={isOpen}
          onClose={toggleIsOpen}
          classes={{
            paper: classes.dialog,
          }}
          aria-labelledby="notes-dialog-title"
        >
          <DialogTitle id="notes-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <TextField label="Notes" value={value} onChange={handleChange} multiline fullWidth variant="standard" />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleIsOpen} color="textSecondary">
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return resultJsx;
};

export default Notes;
