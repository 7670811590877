import * as React from "react";
import CalendarIcon from "@mui/icons-material/CalendarTodayOutlined";
import TextField from "@mui/material/TextField";
import { DatePickerModalProps } from "material-ui-pickers/DatePicker/DatePickerModal";
import InputAdornment from "@mui/material/InputAdornment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import DatePicker from "@mui/lab/DatePicker";

type DatePickerControlProps = {
  fullWidth?: boolean;
  clearable?: boolean;
  variant?: string;
  name?: string;
  dateTime?: boolean;
  minDate?: Date;
  maxDate?: Date;
};

const DatePickerControl = ({
  value,
  onChange,
  fullWidth,
  clearable,
  name,
  InputProps,
  label,
  placeholder,
  dateTime,
  minDate,
  maxDate,
}: DatePickerControlProps & DatePickerModalProps) => {
  const PickerComponent = (dateTime ? DateTimePicker : DatePicker) as React.ElementType;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <PickerComponent
        renderInput={(props: any) => (
          <TextField {...props} name={name} fullWidth={fullWidth} placeholder={placeholder} />
        )}
        label={label}
        inputFormat={dateTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
        value={value ? value : null}
        onChange={(date: any) => {
          if (!date) {
            onChange(clearable ? null : minDate);
          } else {
            onChange(dateTime ? date : new Date(date.setHours(0, 0, 0, 0)));
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        clearable={clearable}
        showTodayButton
        // variant="dialog"
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon style={{ opacity: 0.7 }} />
            </InputAdornment>
          ),
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerControl;
