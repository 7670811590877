export const numberWithCommas = (x: string | number) => {
  if (x.toString().indexOf(",") !== -1 || x.toString().indexOf(".") !== -1) {
    return x;
  }
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

// sometimes we get values like 2.746665199475256e+131
export const parsePotentiallyHugeValue = (val: any) => {
  if (val > Number.MAX_SAFE_INTEGER) {
    const indexOfDot = val.toString().indexOf(".");
    if (indexOfDot >= 0) {
      const newVal = val.toString().substring(0, indexOfDot + 2);
      if (parseFloat(newVal) !== NaN) {
        return parseFloat(newVal);
      }
      return 0;
    }
    return 0;
  }
  return val;
};
