import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import { Card, styled } from "@mui/material";
import { ProjectPropertiesState } from "./index";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import General from "./Components/General";
import Accounting from "./Components/Accounting";
import CloudPlatform from "./Components/CloudPlatform";
import CloudProvider from "./Components/CloudProvider";
import TestingRTC from "./Components/TestingRTC";

import UpRTC from "./Components/UpRTC";
import Scripting from "./Components/Scripting";
import QualityRTC from "./Components/QualityRTC";
import ProbeRTC from "./Components/ProbeRTC";
import WatchRTC from "./Components/WatchRTC";
import AnalyzeRTC from "./Components/AnalyzeRTC";

import Actions from "./Components/Actions";
import Statistics from "./Components/Statictics";

import ConfirmDialog, { ConfirmDialogProps } from "src/components/Dialog/Confirm";
import Buttons from "./Components/Buttons";
import scrollIntoView from "scroll-into-view";
import GridPresets from "./Components/GridPresets";
import Tabulation from "../../../components/Tabs";
import MetricsCustomizations from "./Components/MetricsCustomizations";
import InsightsCustomisations from "./Components/InsightsCustomisations";
import AgentAssure from "./Components/AgentAssure";
import { InsightsProducts } from "./constants/insightsConfig";

interface RouterProps {
  objectId?: string;
}

type StyledComponent = WithStyles<
  | "root"
  | "card"
  | "toolbar"
  | "divider"
  | "formRoot"
  | "saveBtn"
  | "formButtonsContainer"
  | "deleteBtn"
  | "projectLink"
>;

export type ProjectPropertiesViewProps = {
  classes: any;
  isProjectDataValid: boolean;

  confirmDeleteHistory: () => void;
  toggleDeleteHistoryDialog: () => void;
  confirmDeleteProject: () => void;
  toggleDeleteProjectDialog: () => void;
  exportCsv: (from: any, to: any, omitMonitor: boolean) => void;
  onSubmit(values: any): void;
  addUser(id: any): void;
  searchUsers(search: string): void;
  removeUser(id: string): void;
  getConfig(): void;
  testUpRtcEmails(alertEmails: string): void;
  setProjectDataValid(value: boolean): void;
  changeWatchRtcDataStreamsStatus: () => void;
};

const FORM_LABEL_WIDTH = 200;

class View extends React.Component<
  ProjectPropertiesViewProps &
    RouteComponentProps<RouterProps> &
    StyledComponent &
    ProjectPropertiesState &
    ConfirmDialogProps
> {
  render() {
    const {
      classes,
      project,
      initialValues,
      deleteProjectDialogOpened,
      config,
      default_insights_config,
      confirmDeleteProject,
      toggleDeleteProjectDialog,
      onSubmit,
      getConfig,
      isDeletingProject,
      changeWatchRtcDataStreamsStatus,
    } = this.props;
    if (!project) {
      return null;
    }
    return (
      <Grid container={true} spacing={2} className={classes.root}>
        <Grid item={true} xs={12}>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            mutators={{
              ...arrayMutators,
              setServiceStartDate: (values, state, { changeValue }) => {
                changeValue(state, "serviceStartDate", () => values[0]);
              },
              setPaymentExpiry: (values, state, { changeValue }) => {
                changeValue(state, "paymentExpiry", () => values[0]);
              },
              setLastTestRun: (values, state, { changeValue }) => {
                changeValue(state, "stat_lastTestRun", () => values[0]);
              },
              setProbeRtcOptions: (values, state, { changeValue }) => {
                changeValue(state, "opp_options", () => values[0]);
              },

              setQualityRTCOptions: (values, state, { changeValue }) => {
                changeValue(state, "qualityrtc_options", () => values[0]);
              },
              setProductActiveUploadJob: (values, state, { changeValue }) => {
                changeValue(state, `dataStreamUploadConfigs.${values[0]}.isActiveUploadJob`, () => false);
              },
            }}
            render={({ handleSubmit, form, values, submitFailed, initialValues }) => {
              // looks ugly but it is what it is
              if (form.getState().errors && form.getState().errors.userConfig && submitFailed) {
                const el = document.querySelector("textarea[name='userConfig']");
                if (el) {
                  scrollIntoView(el, {
                    time: 500,
                    align: {
                      top: 0,
                    },
                  });
                }
              }
              return (
                <form className={classes.formRoot} onSubmit={handleSubmit}>
                  <Buttons {...this.props} sticky={true} />
                  <Tabulation
                    labels={[
                      "General",
                      "testingRTC",
                      "upRTC",
                      "qualityRTC",
                      "probeRTC",
                      "watchRTC",
                      "analyzeRTC",
                      "AgentAssure",
                      "Admin",
                    ]}
                    content={[
                      <>
                        <General {...this.props} />
                        <Accounting
                          values={values}
                          setServiceStartDate={form.mutators.setServiceStartDate}
                          setPaymentExpiry={form.mutators.setPaymentExpiry}
                          {...this.props}
                        />
                        <CloudProvider {...this.props} />
                        <CloudPlatform {...this.props} />
                      </>,
                      <>
                        {" "}
                        <TestingRTC {...this.props} />
                        <Scripting values={values} {...this.props} />
                      </>,
                      <>
                        <UpRTC values={values} {...this.props} />
                      </>,
                      <>
                        <QualityRTC
                          {...this.props}
                          values={values}
                          setOptions={form.mutators.setQualityRTCOptions}
                          setActiveJobs={form.mutators.setProductActiveUploadJob}
                        />
                        <InsightsCustomisations
                          {...this.props}
                          config={default_insights_config}
                          form={form}
                          initial={initialValues.insights_customisations}
                          product={InsightsProducts.qualityRTC}
                        />
                      </>,
                      <>
                        <ProbeRTC
                          {...this.props}
                          values={values}
                          setOptions={form.mutators.setProbeRtcOptions}
                          setActiveJobs={form.mutators.setProductActiveUploadJob}
                        />
                      </>,
                      <>
                        <WatchRTC
                          {...this.props}
                          values={values}
                          changeWatchRtcDataStreamsStatus={changeWatchRtcDataStreamsStatus}
                          setActiveJobs={form.mutators.setProductActiveUploadJob}
                        />
                      </>,
                      <>
                        <AnalyzeRTC {...this.props} />
                        <InsightsCustomisations
                          {...this.props}
                          config={default_insights_config}
                          form={form}
                          initial={initialValues.insights_customisations}
                          product={InsightsProducts.analyzeRTC}
                        />
                      </>,
                      <>
                        <AgentAssure {...this.props} values={values} setOptions={form.mutators.setQualityRTCOptions} />
                      </>,
                      <>
                        <Actions {...this.props} />
                        <Statistics
                          {...this.props}
                          lastRunDate={values.stat_lastTestRun}
                          onLastRunChange={form.mutators.setLastTestRun}
                        />
                        <GridPresets {...this.props} />
                        <MetricsCustomizations {...this.props} />
                      </>,
                    ]}
                  />

                  <Buttons {...this.props} showGeneratedConfig={getConfig} />
                  {config && (
                    <Card className={classes.card} style={{ marginTop: 20 }}>
                      <ScrollCodeBlock>
                        <pre className={classes.preview}>{config}</pre>
                      </ScrollCodeBlock>
                    </Card>
                  )}
                </form>
              );
            }}
          />

          <ConfirmDialog
            isDeletingProject={isDeletingProject}
            open={deleteProjectDialogOpened}
            title="Are you sure?"
            content="You will not be able to recover the project!"
            handleConfirm={confirmDeleteProject}
            handleClose={toggleDeleteProjectDialog}
          />
        </Grid>
      </Grid>
    );
  }
}

const ScrollCodeBlock = styled("div")`
  height: 800px;
  overflow: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f5f5f5;
`;

const decorate = withStyles((theme) =>
  createStyles({
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "felx",
      justifyContent: "space-between",
      alignItems: "center",
    },
    root: {
      marginTop: -theme.spacing(3),
    },
    card: {
      width: "100%",
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
      paddingBottom: 20,
    },
    fieldLabel: {
      display: "inline-block",
      fontSize: 13,
      padding: "10px 12px 0 0",
      textAlign: "right",
      width: FORM_LABEL_WIDTH,
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
      },
    },
    privilegesFieldLabel: {
      display: "inline-block",
      fontSize: 13,
      padding: "10px 12px 0 0",
      textAlign: "right",
    },
    formFieldGridItem: {
      flexGrow: 1,
    },
    userMenuItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
      fontSize: 13,
    },
    userLink: {
      textDecoration: "none",
      color: "#337ab7",
      "&:hover": {
        color: "#23527c",
      },
    },
    formButtonsContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginTop: theme.spacing(3),
    },
    saveBtn: {
      marginLeft: 10,
    },
    preview: {
      whiteSpace: "pre-wrap",
      fontSize: 12,
      wordBreak: "break-word",
      backgroundColor: "#f5f5f5",
      border: "none",
      borderRadius: 4,
      minHeight: 10,
      margin: 0,
      padding: 8
    },
    tableCellRow: {
      display: "flex",
      flexDirection: "row",
    },
    userRole: {
      position: "relative",
    },
    userRoles: {
      zIndex: 1,
      width: 200,
      position: "absolute",
      top: 30,
      left: 0,
      background: "#fff",
      padding: 10,
      borderRadius: 5,
      border: "1px solid #ccc",
      "&>span": {
        marginBottom: 5,
      },
    },
    roleItem: {
      padding: 5,
      borderRadius: 5,
      border: "1px solid white",
      borderBottomColor: "#ccc",
      cursor: "pointer",
      "&:hover": {
        background: "#ccc",
      },
    },
    assignedRole: {
      pointerEvents: "none",
      background: "#2e7d32",
      color: "white",
    },
  })
);

export default withRouter(decorate(View));
