import { useRef } from "react";
import ChartHelper from "src/helpers/chartHelper";
import Chart, { ChartProps } from "../..";
import { HighchartsChartRef, LegendSeries } from "../../types";
import Legend, { LegendOptionsProps } from "../Legend";

const ChartWithLegend = (props: ChartProps & LegendOptionsProps) => {
  const { dataset, allowDatasetToHaveNoData, shouldHideSeriesIfHasNoData, links } = props;

  const chartRef = useRef<HighchartsChartRef>(null);

  const legendSeries: LegendSeries[] = ChartHelper.getLegendSeries(
    dataset,
    allowDatasetToHaveNoData,
    shouldHideSeriesIfHasNoData,
    links
  );
  return (
    <>
      <Chart {...props} ref={chartRef} />
      <Legend {...props} chartRefOrRefs={chartRef} series={legendSeries} />
    </>
  );
};

export default ChartWithLegend;
