import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import LegendItem from "./LegendItem";
import { LegendSeries } from "src/components/Chart/types";

interface LegendListProps {
  series: LegendSeries[];
  userChoices: Record<string, boolean>;
  toggleIndividualSeriesVisibility: (name: string) => void;
  hasNewDesign: boolean;
}

const LegendList = ({ series, userChoices, toggleIndividualSeriesVisibility, hasNewDesign }: LegendListProps) => {
  const legendItemsJsx = series.map((item: any, index: number) => {
    return (
      <LegendItem
        key={`${item.name + index}`}
        item={{ key: `${item.name + index}`, ...item }}
        index={index}
        isVisible={userChoices[item.name] ?? false}
        toggleIndividualSeriesVisibility={toggleIndividualSeriesVisibility}
        hasNewDesign={hasNewDesign}
      />
    );
  });

  let resultJsx;

  if (hasNewDesign) {
    resultJsx = (
      <Grid container columnSpacing={3} rowSpacing={1}>
        {legendItemsJsx}
      </Grid>
    );
  } else {
    resultJsx = (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          pl: "18px",
        }}
      >
        {legendItemsJsx}
      </Box>
    );
  }

  return resultJsx;
};

export default LegendList;
