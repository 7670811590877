import { VisibilityRules } from "../VisibilityRules";

const additionalGraphInfoKeys = {
  mediaType: "mediaType",
  googCodecName: "googCodecName",
  codecImplementationName: "codecImplementationName",
  googContentType: "googContentType",
};

export const preparedDataForGraphs = (webRtcData: any) => {
  const graphs = {};
  const grapsReportObj = {};
  for (const connid in webRtcData.PeerConnections) {
    if (webRtcData.PeerConnections.hasOwnProperty(connid)) {
      const connection = webRtcData.PeerConnections[connid];
      grapsReportObj[connid] = {};
      graphs[connid] = {};
      const reportobj = {};
      let values;
      for (const _reportName in connection.stats) {
        let comp, stat;

        if (connection.stats.hasOwnProperty(_reportName)) {
          const reportName = _reportName;

          // special casing of computed stats, in particular [a-b]
          if (reportName.indexOf("[") !== -1) {
            const t = reportName.split("[");
            comp = "[" + t.pop();
            stat = t.join("");
            stat = stat.substr(0, stat.length - 1);
          } else {
            const t = reportName.split("-");
            comp = t.pop();
            stat = t.join("-");
          }

          if (!reportobj.hasOwnProperty(stat)) {
            reportobj[stat] = [];
            if ("statsType" in connection.stats[_reportName]) {
              reportobj[stat].push([
                "type",
                [connection.stats[_reportName].statsType],
                connection.stats[_reportName].startTime,
              ]);
            }
          }
          values = JSON.parse(connection.stats[_reportName].values);
          values = values.map((currentValue: string) => {
            return currentValue;
          });
          reportobj[stat].push([comp, values, connection.stats[_reportName].startTime]);
        }
      }
      // sort so we get a more useful order of graphs:
      // * ssrcs
      // * bwe
      // * everything else alphabetically
      let names = Object.keys(reportobj);
      const ssrcs = names
        .filter((name) => {
          return name.indexOf("ssrc_") === 0;
        })
        .sort((a, b) => {
          // sort by send/recv and ssrc
          const aParts = a.split("_");
          const bParts = b.split("_");
          if (aParts[2] === bParts[2]) {
            return parseInt(aParts[1], 10) - parseInt(bParts[1], 10);
          } else if (aParts[2] === "send") {
            return -1;
          }
          return 1;
        });

      const bwe = names.filter((name) => {
        return name === "bweforvideo";
      });
      names = names.filter((name) => {
        return name.indexOf("ssrc_") === -1 && name !== "bweforvideo";
      });
      names = ssrcs.concat(bwe, names);

      const IceCandidatesToSkip: any[] = [];

      for (const reportName of names) {
        const series: Array<any> = [];
        const reports = reportobj[reportName];

        const typeProperties = reports.find((properties: any) => properties[0] === "type");
        const kindProperties = reports.find((properties: any) => properties[0] === "kind");
        const ssrcProperties = reports.find((properties: any) => properties[0] === "ssrc");
        const isRemoteProperties = reports.find((properties: any) => properties[0] === "isRemote");
        let reportType = typeProperties ? typeProperties[1][0] : null;
        const reportKind = kindProperties ? kindProperties[1][0] : null;
        const ssrc = ssrcProperties ? ssrcProperties[1][0] : null;

        // Get type when in Chrome-internals (Chrome < M105)
        if (!reportType) {
          if (reportName.match(/RTCPeerConnection/)) {
            reportType = "peer-connection";
          } else if (reportName.match(/RTCDataChannel/)) {
            reportType = "data-channel";
          } else if (reportName.match(/RTCAudioSource/) || reportName.match(/RTCVideoSource/)) {
            reportType = "media-source";
          } else if (reportName.match(/RTCTransport/)) {
            reportType = "transport";
          } else if (reportName.match(/RTCCertificate/)) {
            reportType = "certificate";
          } else if (reportName.match(/RTCIceCandidatePair/)) {
            reportType = "candidate-pair";
          } else if (reportName.match(/RTCIceCandidate/)) {
            const isRemote = isRemoteProperties ? isRemoteProperties[1][0] : null;
            if (isRemote) {
              reportType = "remote-candidate";
            } else {
              reportType = "local-candidate";
            }
          } else if (reportName.match(/MediaStreamTrack/)) {
            reportType = "track";
          } else if (reportName.match(/RTCCodec/)) {
            reportType = "codec";
          } else if (reportName.match(/RTCOutboundRTPAudioStream/) || reportName.match(/RTCOutboundRTPVideoStream/)) {
            reportType = "outbound-rtp";
          } else if (reportName.match(/RTCInboundRTPAudioStream/) || reportName.match(/RTCInboundRTPVideoStream/)) {
            reportType = "inbound-rtp";
          } else if (
            reportName.match(/RTCRemoteInboundRtpAudioStream/) ||
            reportName.match(/RTCRemoteInboundRtpVideoStream/)
          ) {
            reportType = "remote-inbound-rtp";
          } else if (
            reportName.match(/RTCRemoteOutboundRtpAudioStream/) ||
            reportName.match(/RTCRemoteOutboundRtpVideoStream/)
          ) {
            reportType = "remote-outbound-rtp";
          }
        }

        const direction = reportType === "inbound-rtp" ? "recv" : reportType === "outbound-rtp" ? "send" : null;

        if (reportType === "candidate-pair") {
          reports.forEach((item: Array<any>) => {
            if (item[0] === "nominated") {
              if (!item[1].includes(true)) {
                IceCandidatesToSkip.push(reportName);
              }
            }
          });
        }

        // ignore useless graphs
        if (
          reportType === "codec" ||
          reportType === "certificate" ||
          reportType === "local-candidate" ||
          reportType === "remote-candidate" ||
          reportType === "peer-connection" ||
          IceCandidatesToSkip.includes(reportName)
        ) {
          continue;
        }

        const toRemove = VisibilityRules.remove;
        const toHide = VisibilityRules.hide;
        const mixedRules = VisibilityRules.mixed;
        const reportNames = Object.keys(toHide);

        const mappedStreamNameReport = (reports || []).find((x: any) => x[0] === "mappedStreamName");
        let title = `${reportName}`;
        if (mappedStreamNameReport) {
          title = `${title} [${mappedStreamNameReport[1][0]}]`;
        }

        reports.sort().forEach(function (report: any) {
          if (toRemove.includes(report[0])) {
            return;
          }
          if (
            toRemove.includes(report[0]) &&
            !["remote-inbound-rtp", "remote-outbound-rtp"].includes(reportType) &&
            report[0] === "roundTripTime"
          ) {
            return;
          }
          if (reportType === "candidate-pair") {
            if (report[0] === "currentRoundTripTime") {
              report[1] = report[1].map((item: number) => item * 1000);
            }
            if (mixedRules["candidate-pair"].visible.includes(report[0])) {
              series.push({
                name: report[0],
                visible: mixedRules["candidate-pair"].disabled.indexOf(report[0]) === -1,
                data: report[1],
                startTime: report[2],
              });
            }
          } else {
            if (typeof report[1][0] !== "number") {
              return;
            }
            if (
              report[0] === "bytesReceived" ||
              report[0] === "bytesSent" ||
              report[0] === "packetsReceived" ||
              report[0] === "packetsSent" ||
              report[0] === "googCaptureStartNtpTimeMs"
            ) {
              return;
            }
            let done = false;
            if (reportNames.includes(`${reportType}${reportKind ? `-${reportKind}` : ""}`)) {
              series.push({
                name: report[0],
                visible: toHide[`${reportType}${reportKind ? `-${reportKind}` : ""}`].indexOf(report[0]) === -1,
                data: report[1],
                startTime: report[2],
              });
              done = true;
            }
            if (!done) {
              const hiddenSeries = [
                "qpSum",
                "framesEncoded",
                "framesDecoded",
                "framesReceived",
                "totalInterFrameDelay",
                "audioInputLevel",
                "audioOutputLevel",
                "googEchoCancellationEchoDelayStdDev",
                "totalSamplesDuration",
                "totalSamplesReceived",
                "totalAudioEnergy",
                "totalDecodeTime",
                "totalFramesDuration",
                "framesSent",
                "jitterBufferDelay",
                "googDecodingCTN",
                "totalPacketSendDelay",
                "totalEncodeTime",
                "headerBytesReceived",
                "jitterBufferEmittedCount",
                "jitterBufferTargetDelay",
                "relativePacketArrivalDelay",
                "roundTripTime",
                "googDecodingCNG",
                "googDecodingNormal",
                "googDecodingPLCCNG",
                "googDecodingCTSG",
                "googDecodingMuted",
              ];

              if (reportType === "remote-inbound-rtp" && reportKind === "video") {
                // created a separate Hidden Series Array so it will not effect the rest.
                const RTCRemoteInboundRtpVideoStreamHiddenSeries = [
                  "packetsLost",
                  "roundTripTimeMeasurements",
                  "totalRoundTripTime",
                  "ssrc",
                  "fractionLost",
                ];

                hiddenSeries.push(...RTCRemoteInboundRtpVideoStreamHiddenSeries);
              }

              series.push({
                name: report[0],
                visible: hiddenSeries.indexOf(report[0]) === -1,
                data: report[1],
                startTime: report[2],
              });
            }
          }
          if (series.length > 0) {
            grapsReportObj[connid][reportName] = {
              title,
              series,
              additionalGraphInfo:
                reportName.indexOf("ssrc_") !== -1
                  ? {
                      [additionalGraphInfoKeys.mediaType]:
                        connection.stats[`${reportName}-${additionalGraphInfoKeys.mediaType}`],
                      [additionalGraphInfoKeys.googCodecName]:
                        connection.stats[`${reportName}-${additionalGraphInfoKeys.googCodecName}`],
                      [additionalGraphInfoKeys.codecImplementationName]:
                        connection.stats[`${reportName}-${additionalGraphInfoKeys.codecImplementationName}`],
                      [additionalGraphInfoKeys.googContentType]:
                        connection.stats[`${reportName}-${additionalGraphInfoKeys.googContentType}`],
                    }
                  : undefined,
              startTime: report[2],
              ssrc: ssrc && direction ? `${connection.features?.connectionId}-${ssrc}-${direction}` : null,
              type: reportType,
              kind: reportKind,
            };
          }
        });
      }
    }
  }
  return grapsReportObj;
};
