import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import TestIteration from "src/containers/TestIteration";
import { Grid, Card, Toolbar, Typography, Divider, Theme } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import SocialButtons from "./SocialButtons";

type FreeReportParams = {
  objectId: string;
};
type Props = {
  // tslint:disable-next-line: no-any
  classes: any;
};

type State = {
  expired: boolean;
};

class FreeReport extends React.Component<Props & RouteComponentProps<FreeReportParams>, State> {
  constructor(props: Props & RouteComponentProps<FreeReportParams>) {
    super(props);

    this.state = {
      expired: false,
    };
  }
  setExpired = (flag: boolean) => {
    this.setState({
      expired: flag,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container={true} direction="column" alignItems="center">
        <Grid item={true} xs={11} sm={10} md={8} style={{ width: "100%", marginBottom: 10 }}>
          <Card className={classes.card}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="subtitle1">testRTC Background</Typography>
            </Toolbar>
            <Divider className={classes.divider} />
            <Grid container={true}>
              <Grid item={true} xs={12}>
                <Typography style={{ marginBottom: 10 }}>
                  {`
                    testRTC is a testing platform designed 
                    and built for the new generation of WebRTC-based 
                    communications. It employs Internet web-scale 
                    thinking and architecture to solve traditional 
                    VoIP problems, providing a unique and powerful 
                    set of capabilities to testing teams.
                  `}
                </Typography>
                <Typography style={{ marginBottom: 10 }}>
                  {`
                    testRTC can be used to test WebRTC based 
                    services during development, before a service 
                    launch and in an ongoing manner to make 
                    sure the best serviceability possible, 
                    taking into consideration all the challenges 
                    that WebRTC poses.
                  `}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {this.state.expired && <Typography variant="body1">{"Report is expired"}</Typography>}
        {!this.state.expired && (
          <Grid item={true} xs={11} sm={10} md={8} style={{ width: "100%", marginBottom: 30 }}>
            <Card className={classes.card}>
              <Toolbar className={classes.toolbar}>
                <Typography variant="subtitle1">{"Feedback"}</Typography>
              </Toolbar>
              <Divider className={classes.divider} />
              <SocialButtons {...this.props} reportId={this.props.match.params.objectId} />
            </Card>
          </Grid>
        )}
        <Grid item={true} xs={11} sm={10} md={8}>
          <TestIteration {...this.props} freeReport={true} setExpired={this.setExpired} />
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
      overflow: "visible",
    },
    divider: {
      margin: "16px -16px",
    },
    contentContainer: {
      padding: 16,
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
    },
  });

const decorate = withStyles(styles);

export default withRouter(decorate(FreeReport));
