import { AppRoutes } from "./app-routes";

export const RedirectRules = {
  Root: {
    from: "/",
    to: AppRoutes.Main,
  },
  UpRTCRoot: {
    from: AppRoutes.Monitoring,
    to: AppRoutes.MonitorList,
  },
  TestRTCRoot: {
    from: AppRoutes.Testing,
    to: AppRoutes.TestingScripts,
  },

  // old routes
  TestRunId: {
    from: "/app/testRunDetails/:objectId",
    to: AppRoutes.TestRunDetailsId,
  },
  TestAgentId: {
    from: "/app/testIteration/:objectId",
    to: AppRoutes.TestIterationId,
  },
  WatchRTCRoot: {
    from: AppRoutes.WatchRTC,
    to: AppRoutes.WatchRTCHistory,
  },
  WatchRTCRoomId: {
    from: "/app/watchrtc-room/:objectId",
    to: AppRoutes.WatchRTCRoomId,
  },
  WatchRTCPeerId: {
    from: "/app/watchrtc-peer/:objectId",
    to: AppRoutes.WatchRTCPeerId,
  },
  TestPropertyId: {
    from: "/app/testProperties/:objectId",
    to: AppRoutes.TestPropertyId,
  },
  MonitorPropertyId: {
    from: "/app/monitorProperties/:objectId",
    to: AppRoutes.MonitorPropertyId,
  },
  QualityRTCInviteId: {
    from: "/app/qualityrtc-invite/:objectId",
    to: AppRoutes.QualityRTCInviteId,
  },
  OnPremiseProbeHistoryId: {
    from: "/app/probertc-history/:probeId",
    to: AppRoutes.OnPremiseProbeHistoryId,
  },
  OnPremiseProbePropertyId: {
    from: "/app/probertc-edit/:objectId",
    to: AppRoutes.OnPremiseProbePropertyId,
  },
  OnPremiseProbeChartsId: {
    from: "/app/probertc-view/:objectId",
    to: AppRoutes.OnPremiseProbeChartsId,
  },
  OnPremiseProbeResultsId: {
    from: "/app/probertc-view/results/:probeId/:objectId",
    to: AppRoutes.OnPremiseProbeResultsId,
  },
  AssetPropertyId: {
    from: "/app/assets/:objectId",
    to: AppRoutes.AssetPropertyId,
  },
  UserDetailsId: {
    from: "/app/user-details/:objectId",
    to: AppRoutes.UserDetailsId,
  },
  ProjectPropertiesId: {
    from: "/app/projectProperties/:objectId",
    to: AppRoutes.ProjectPropertiesId,
  },
  AnalyzeDump: {
    from: "/app/analyzeDump",
    to: AppRoutes.AnalyzeDump,
  },
};
