import * as moment from "moment";
// @ts-ignore
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment);

export function formatDuration(duration: number, format: "DHMSms" | "DHMS" | "DHM" | "M"| "s" | "ms"): string {
  // return "PATCHED6881";
  switch (format) {
    case "DHMSms":
      const ms = duration < 60000 ? "SSS[ms]" : ""; // less than 1 min
      return (moment.duration(duration) as any).format(`D[d] H[h] m[m] s[s] ${ms}`);
    case "DHMS":
      const formatForDHMS = duration > 3600000 ? "D[d] H[h] m[m]" : "D[d] H[h] m[m] s[s]";
      return (moment.duration(duration) as any).format(formatForDHMS);
    case "DHM":
      return (moment.duration(duration) as any).format("D[d] H[h] m[m]");
    case "M":
      return (moment.duration(duration) as any).format("m");
    case "s":
      return (moment.duration(duration) as any).format("s");
    case "ms":
      return (moment.duration(duration) as any).format("SSS");
    default:
      return "";
  }
}

/*
// tslint:disable-next-line:no-any
export function calcChannelStat(stat: any) {
  return {
    packerErrorPCT: (stat.packetLoss * 100) / stat.totalPackets || 0,
    effectiveBitRate: stat.bytes / stat.voiceDuration,
  };
}
*/

// tslint:disable-next-line:no-any
export function formatScore(stat: any): string {
  const score = (stat && stat.rank && stat.rank ? Number(stat.rank) : 0) || 0;
  return score.toFixed(1);
}

export function formatNumber(value: unknown, maximumFractionDigits = 0): string {
  const val = Number(value) || 0;
  // return val.toLocaleString("en", { maximumFractionDigits });
  return val.toFixed(maximumFractionDigits);
}

// tslint:disable-next-line:no-any
export function checkNan(value: any) {
  if (value === "NaN" || Number.isNaN(value)) {
    return "";
  }
  return value;
}

export function getPerformanceAverages(stat: any) {
  const res: any = {};
  const { performance, performanceData } = stat;
  if (performance) {
    ["browserCpu", "browserMemory", "probeCpu", "probeMemory"].forEach((prop) => {
      res[prop] = performance[prop].avg || null;
    });
    if (performance.probeMemoryMb) {
      res.probeMemoryMb = performance.probeMemoryMb.avg || null;
    }
    return res;
  } else if (performanceData) {
    return performanceData;
  } else {
    return res;
  }
}
