// tslint:disable:no-any
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ProjectsContext } from "src/containers/AppController";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Theme, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Features from "./Features";
import ICECandidatesTables from "./ICECandidatesTables";
import Ciphers from "./Ciphers";

import RTCConfiguration from "./RTCConfiguration";
import SignalingState from "./SignalingState";
import ICEConnectionState from "./ICEConnectionState";
import EventsLog from "./EventsLog";
import Graphs from "./Graphs";
import TestDuration from "./TestDuration";

export interface IConnectionProps {
  id: string;
  title: string;
  connection: IConnection;
  sessionData?: { [props: string]: any };
  webrtcInternalsGraphs: any;
}

const WordBreak = {
  wordBreak: "break-all",
} as React.CSSProperties;

const Connection = (props: IConnectionProps & RouteComponentProps<{}>) => {
  const classes = useStyles();
  const {
    id,
    title,
    connection: { rtcConfiguration, features, stats, updateLog },
    sessionData,
    webrtcInternalsGraphs,
  } = props;

  const { selectedProject } = React.useContext(ProjectsContext);

  // React.useEffect(() => {
  //   if (hide) {
  //     setHidden(true);
  //   }
  // }, []);

  const rtcConfig = Object.keys(rtcConfiguration || {}).length > 0 ? rtcConfiguration : { ...sessionData };
  const showRTCConfiguration = Object.keys(rtcConfig || {}).length > 0;

  const showCiphers =
    features &&
    features.audio_tlsVersion &&
    features.video_tlsVersion &&
    features.audio_dtlsCipher &&
    features.video_dtlsCipher &&
    features.audio_srtpCipher &&
    features.video_srtpCipher;

  const signalingStates = (updateLog || []).filter(
    (event: any) =>
      event.type.toLowerCase() === "signalingstatechange" || event.type.toLowerCase() === "onsignalingstatechange"
  );
  const iceConnectionStates = (updateLog || []).filter(
    (event: any) =>
      event.type.toLowerCase() === "iceconnectionstatechange" ||
      event.type.toLowerCase() === "oniceconnectionstatechange"
  );

  // Add special event when Peer Connection has been closed
  const closePCLog = (updateLog || []).find((event: any) => event.type.toLowerCase() === "close");
  if (closePCLog) {
    closePCLog.value = "closed (PC)";
    iceConnectionStates.push(closePCLog);
  }

  const showEventsLog = (updateLog || []).length > 5;
  const showGraphs = webrtcInternalsGraphs[id] && webrtcInternalsGraphs[id].length > 0;
  const [showFeatures, setShowFeatures] = React.useState(false);

  const empty = !showEventsLog && !showGraphs;

  const [expanded, setExpanded] = React.useState(!empty);

  const featureList = React.useMemo(() => {
    if (!features) {
      return [];
    }

    return Object.keys(features).map((key: string, index) => ({
      _id: index,
      varName: key,
      readableName: key,
      value: features[key]?.toString(),
      description: "",
    }));
  }, [features]);

  return (
    <Fade in={true}>
      <Grid id={id} item={true} xs={12}>
        <Accordion className={classes.card} expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary className={classes.toolbar} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1" style={WordBreak}>
              Connection: {title} {empty ? "(Empty Connection)" : ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {<TestDuration updateLog={updateLog} />}
            {showRTCConfiguration && (
              <>
                <RTCConfiguration config={rtcConfig} />
                <Divider className={classes.divider} />
              </>
            )}
            {showCiphers && (
              <>
                <Ciphers data={features} />
                <Divider className={classes.divider} />
              </>
            )}
            {signalingStates.length > 0 && (
              <>
                <SignalingState signalingStates={signalingStates} title={title} />
                <Divider className={classes.divider} />
              </>
            )}
            {iceConnectionStates.length > 0 && (
              <ICEConnectionState iceConnectionStates={iceConnectionStates} title={title} />
            )}
            <ICECandidatesTables stats={stats} />
            {showEventsLog && (
              <>
                <EventsLog updateLog={updateLog} title={title} />
                <Divider className={classes.divider} />
              </>
            )}
            {showGraphs && (
              <>
                <Graphs id={id} webrtcInternalsGraphs={webrtcInternalsGraphs} />
                <Divider className={classes.divider} />
              </>
            )}
            {selectedProject?.userConfig?.showWebrtcFeatures && (
              <>
                <div className={classes.btnContainer}>
                  <Button color="primary" variant="contained" onClick={() => setShowFeatures(!showFeatures)}>
                    {showFeatures ? "Hide Features" : "Show Features"}
                  </Button>
                </div>
              </>
            )}
            {showFeatures && <Features data={featureList} />}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Fade>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1) + " !important",
      marginBottom: theme.spacing(1) + " !important",
      paddingBottom: 0,
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      position: "sticky",
      top: 0,
      borderRadius: 4,
      background: "white",
      minHeight: "30px !important",
      padding: "8px 16px",
      margin: "-16px -16px 0 -16px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      zIndex: 1,
      boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%)",
      "&>div": {
        margin: "0 !important",
      },
    },
    accordionDetails: {
      flexDirection: "column",
      padding: "16px 0",
    },
    preStyles: {
      whiteSpace: "pre-wrap",
      wordBreak: "keep-all",
    },
    paragraphStyle: {
      fontSize: "12px",
      padding: "0 50px",
    },
    linkStyle: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      cursor: "pointer",
    },
    btnContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  })
);

export default withRouter(Connection);
