import { ComponentType } from "react";
import * as moment from "moment";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import OpenInBrowserOutlinedIcon from "@mui/icons-material/OpenInBrowserOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SignalWifi4BarOutlinedIcon from "@mui/icons-material/SignalWifi4BarOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import CompareArrowsOutlinedIcon from "@mui/icons-material/CompareArrowsOutlined";
import HomeMaxOutlinedIcon from "@mui/icons-material/HomeMaxOutlined";
import TonalityOutlinedIcon from "@mui/icons-material/TonalityOutlined";

import { IFiltersData } from "./Interfaces";
import { FilterKeys } from "./types";

export const emptyCityPlaceholder = "unknown";

export const defaultQualityData = {
  bitrateRecv_max: 2500,
  bitrateRecv_min: 0,
  bitrateSend_max: 2500,
  bitrateSend_min: 0,

  packetLossRecv_min: 0,
  packetLossRecv_max: 100,
  packetLossSend_min: 0,
  packetLossSend_max: 100,

  rttRecv_min: 0,
  rttRecv_max: 500,
  rttSend_min: 0,
  rttSend_max: 500,

  mos_min: 0,
  mos_max: 5,

  rank_min: 0,
  rank_max: 10,
};

export const defaultFiltersData: IFiltersData = {
  time: {
    from: moment().startOf("day").toDate().getTime(),
    to: moment().endOf("day").toDate().getTime(),
  },
  keys: [],
  os: [],
  browsers: [],
  localLocation: [],
  remoteLocation: [],

  networkTypes: [],
  protocols: [],
  ipVersions: [],
  candidateTypes: [],

  ...defaultQualityData,
};

export const filterKeys = {
  os: "os",
  browsers: "browsers",
  localLocation: "localLocation",
  remoteLocation: "remoteLocation",
  networkTypes: "networkTypes",
  ipVersions: "ipVersions",
  protocols: "protocols",
  candidateTypes: "candidateTypes",
  quality: "quality",
} as const;

export const filterMap: Record<FilterKeys, string> = {
  [filterKeys.os]: "Operating System",
  [filterKeys.browsers]: "Browsers",
  [filterKeys.localLocation]: "Device Location",
  [filterKeys.remoteLocation]: "Server Location",
  [filterKeys.networkTypes]: "Connection",
  [filterKeys.ipVersions]: "IP Type",
  [filterKeys.protocols]: "Transport",
  [filterKeys.candidateTypes]: "Session",
  [filterKeys.quality]: "Quality",
} as const;

export const FilterIcons: Record<FilterKeys, ComponentType> = {
  [filterKeys.os]: DesktopWindowsOutlinedIcon,
  [filterKeys.browsers]: OpenInBrowserOutlinedIcon,
  [filterKeys.localLocation]: LocationOnOutlinedIcon,
  [filterKeys.remoteLocation]: LocationOnOutlinedIcon,
  [filterKeys.networkTypes]: SignalWifi4BarOutlinedIcon,
  [filterKeys.ipVersions]: LabelOutlinedIcon,
  [filterKeys.protocols]: CompareArrowsOutlinedIcon,
  [filterKeys.candidateTypes]: HomeMaxOutlinedIcon,
  [filterKeys.quality]: TonalityOutlinedIcon,
} as const;
