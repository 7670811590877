import * as React from "react";

import { chartSizesArray } from "./constants";
import { ChartSizeContext } from "./Context";

export const useChartSize = () => {
  const { size, setSize, height, setHeight } = React.useContext(ChartSizeContext);

  const setChartSize = (newSize: number, init = false) => {
    if (init) {
      const storedSize = Number(localStorage.getItem("timeline-size"));
      if (chartSizesArray.includes(storedSize) && init) {
        newSize = storedSize;
      }
    }
    if (newSize === size) {
      return;
    }
    setSize(newSize);
    if (!init) {
      localStorage.setItem("timeline-size", newSize.toString());
    }
  };

  React.useEffect(() => {
    const charts = getCharts();
    if (charts.length) {
      let originalChartHeight = height;
      if (!originalChartHeight) {
        originalChartHeight = charts[0].chartHeight;
        setHeight(originalChartHeight);
      }
      resetChartsHeight(originalChartHeight as number, size as number);
    }
  }, [size]);

  const resetChartsHeight = (height: number, size: number) => {
    setTimeout(() => {
      const charts = getCharts();

      charts.forEach((chart: any) => {
        let legendSpace = 0;
        if (chart.legend?.display) {
          legendSpace = chart.legend.legendHeight || 0;
          chart.setSize(undefined, height * size + legendSpace);
        } else {
          chart.setSize(undefined, height * size);
        }
      });
    }, 0);
  };

  const getCharts = () => {
    const charts: any[] = ((window as any).Highcharts?.charts || []).filter((x: any) => x !== undefined);
    // if at least 1st cahrtHeight is missing
    // means charts are not rendered
    // rare case
    if (!charts[0]?.chartHeight) {
      return [];
    }
    return charts;
  };

  return { chartSize: size, setChartSize, resetChartsHeight, chartHeight: height };
};
