export const defaultChartsThresholds = {
  jitter_audio: 30, // Lower is better (in ms)
  jitter_video: 30, // Lower is better (in ms)

  rtt_audio: 250, // Lower is better (in ms)
  rtt_video: 250, // Lower is better (in ms)

  packetLoss_audio: 4, // Percentage // Lower is better
  packetLoss_video: 4, // Percentage // Lower is better

  packetLossNumber_audio: 10, // Number // Lower is better
  packetLossNumber_video: 10, // Number // Lower is better

  videoFrameRate: 15, // video only

  browserMemory: 100, // Not sure about the actual value
  browserCpu: 100, // Not sure about the actual value
};

export const defaultRibbonsThresholds = {
  score: [3, 4], // Higher is better
  //mos: [2, 3], // Higher is better
  mos: [3, 4], // Higher is better //https://redmine.testrtc.com/issues/7789
  user: [2, 4], // Higher is better

  callSetupTime: 500, // Value is random. Not sure if we need/have threshold for callSetupTime
  minutes: 20, // Value is random. Not sure if we need/have threshold for minutes

  // We are not using bitrate thresholds now afaik
  highlights_bitrate_incoming: [100, 200], // Only for WatchRTC Highlights
  highlights_bitrate_outgoing: [100, 200], // Only for WatchRTC Highlights
  bitrate_audio_incoming: [100, 200],
  bitrate_audio_outgoing: [100, 200],
  bitrate_video_incoming: [100, 200],
  bitrate_video_outgoing: [100, 200],

  highlights_packetLoss_incoming: [1, 4], // Only for WatchRTC Highlights
  highlights_packetLoss_outgoing: [1, 4], // Only for WatchRTC Highlights
  packetLoss_audio_incoming: [1, 4], // Percentage // Lower is better
  packetLoss_audio_outgoing: [1, 4], // Percentage // Lower is better
  packetLoss_video_incoming: [1, 4], // Percentage // Lower is better
  packetLoss_video_outgoing: [1, 4], // Percentage // Lower is better

  jitter_audio_incoming: [30, 50], // Lower is better
  jitter_audio_outgoing: [30, 50], // Lower is better
  jitter_video_incoming: [30, 50], // Lower is better
  jitter_video_outgoing: [30, 50], // Lower is better

  highlights_rtt_incoming: [200, 250], // Only for WatchRTC Highlights
  highlights_rtt_outgoing: [200, 250], // Only for WatchRTC Highlights
  rtt_audio_incoming: [200, 250], // Lower is better
  rtt_audio_outgoing: [200, 250], // Lower is better
  rtt_video_incoming: [200, 250], // Lower is better
  rtt_video_outgoing: [200, 250], // Lower is better

  browserMemory: 100, // non-watchrtc (testingRTC/upRTC/analyzeRTC) // Don't know the value
  browserCpu: 100, // non-watchrtc (testingRTC/upRTC/analyzeRTC) // Don't know the value
};
