export const chartSizes = {
  Compact: 0.6,
  Normal: 0.8,
  Large: 1,
};

export const chartSizesArray = [chartSizes.Compact, chartSizes.Normal, chartSizes.Large];
export const chartSizesLabels = {
  [chartSizes.Compact]: "Compact View",
  [chartSizes.Normal]: "Normal View",
  [chartSizes.Large]: "Large View",
};
export const nextChartHeight = {
  [chartSizes.Compact]: chartSizes.Normal,
  [chartSizes.Normal]: chartSizes.Large,
  [chartSizes.Large]: chartSizes.Compact,
};
