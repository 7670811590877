import { ReactMultiEmail, isEmail } from "react-multi-email";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Field } from "react-final-form";
import "react-multi-email/style.css";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    multiEmailField: {
      margin: 0,
      maxWidth: "100%",
      flex: "1 0 auto",
      outline: 0,
      textAlign: "left",
      lineHeight: "1.21428571em",
      padding: "0.4em 0.5em",
      backgroundColor: "rgba(0, 0, 0, 0.09)",
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      transition: "background 0.1s ease",
      fontSize: 16,
      position: "relative",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      alignContent: "flex-start",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      "& input": {
        width: "auto !important",
        outline: "none !important",
        border: "0 none !important",
        display: "inline-block !important",
        lineHeight: 1,
        verticalAlign: "baseline !important",
        padding: "0 !important",
        backgroundColor: "transparent",
        fontSize: 16,
      },
      "&:hover": {
        backgroundColor: " rgba(0, 0, 0, 0.13)",
      },
    },
    multiEmailValue: {
      fontWeight: 400,
      padding: "2px 6px",
      fontSize: 16,
      lineHeight: 1,
      verticalAlign: "baseline",
      margin: -2,
      marginRight: 4,
      backgroundColor: "rgba(0, 0, 0, 0.09)",
      textTransform: "none",
      border: "0 solid transparent",
      borderRadius: 4,
      transition: "background 0.1s ease",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      maxWidth: "100%",
    },
    multiEmailClose: {
      paddingLeft: 4,
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

const parseEmailValue = (value: any) => {
  return typeof value === "string" ? value.split(",") : value;
};

const MultiEmailField = (props: any) => {
  const { name, onChange } = props;
  const classes = useStyles();
  return (
    <Field name={name}>
      {(fieldProps: any) => {
        const value = fieldProps.input.value ? parseEmailValue(fieldProps.input.value) : [];
        return (
          <ReactMultiEmail
            className={classes.multiEmailField}
            noClass={true}
            emails={value}
            validateEmail={(email: any) => {
              if (email === "*") {
                return true;
              } else {
                return isEmail(email); // return boolean
              }
            }}
            onChange={(emails: Array<string>) => {
              if (onChange) {
                onChange(emails);
              } else {
                fieldProps.input.onChange(emails.join(","));
              }
            }}
            getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
              return (
                <span className={classes.multiEmailValue} data-tag={true} key={index}>
                  {email}
                  <span className={classes.multiEmailClose} data-tag-handle={true} onClick={() => removeEmail(index)}>
                    {"×"}
                  </span>
                </span>
              );
            }}
          />
        );
      }}
    </Field>
  );
};

export default MultiEmailField;
