import * as React from "react";
import { Statuses } from "../../constants/TestStatus";
import {
  BuildCircleOutlined,
  CheckCircleOutline,
  DoNotDisturbOnOutlined,
  ErrorOutlineOutlined,
  ExitToAppOutlined,
  HighlightOffOutlined,
  ReplayOutlined,
  TimerOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";

import "./statusicon.css";
import { useTheme } from "@mui/material";

interface StatusIconSwitchProps {
  status?: TestStatus;
  style?: React.CSSProperties;
}

export default (props: StatusIconSwitchProps): JSX.Element => {
  const theme = useTheme();

  switch (props.status) {
    case Statuses.warnings:
      return <WarningAmberOutlined style={{ color: theme.palette.warning.main, ...props.style }} />;
    case Statuses.error:
      return <DoNotDisturbOnOutlined style={{ color: theme.palette.error.main, ...props.style }} />;
    case Statuses.failure:
      return <ErrorOutlineOutlined style={{ color: theme.palette.error.main, ...props.style }} />;
    case Statuses.timeout:
      return <TimerOutlined style={{ color: "#7993AB", ...props.style }} />;
    case Statuses.completed:
      return <CheckCircleOutline style={{ color: theme.palette.success.main, ...props.style }} />;
    case Statuses.serviceFailure:
      return <BuildCircleOutlined style={{ color: "#7993AB", ...props.style }} />;
    case Statuses.terminated:
      return <HighlightOffOutlined style={{ color: "#243544", ...props.style }} />;
    case Statuses.started:
      return <ReplayOutlined style={{ color: theme.palette.primary.main, ...props.style }} className="loader-icon" />;
    case Statuses.retry:
      return <ReplayOutlined style={{ color: "#7993AB", ...props.style }} />;
    case Statuses.dismissed:
      return <ExitToAppOutlined style={{ color: "#7993AB", ...props.style }} />;
    default:
      return <div style={{ width: 24 }} />;
  }
};
