import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import DumpFileService from "src/services/DumpFileService";
import { RouteComponentProps, withRouter } from "react-router";
import withBreadcrumb, { WithBreadcrumb } from "src/components/withBreadcrumb";
import { SetNotification } from "src/actions/notificationAction";
import { AppRoutes } from "../../../constants/routes/app-routes";
import { prepareGraphs } from "./helpers/preparedGraphs";
import { useDumpUrl } from "./helpers/generateDumpUrl";
import { preparedDataForGraphs } from "./helpers/preparedDataForGraphs";
import QuickLinks from "./components/QuickLinks";
import SessionData from "./components/SessionData";
import UserMedia from "./components/UserMedia";
import Loader from "src/components/Loader/Loader";
import Connection from "./components/Connection";
import { Typography } from "@mui/material";
import { RouteParams } from "./interfaces";
import { reportError } from "src/services/ErrorService";
import { handleForbiddenError } from "../../../helpers/errorHelper";
import DbUtilsService from "../../../services/DbUtilsService";

const useFakeData = false; // didn't work process.env.REACT_FAKE_WEBRTC_INTERNALS === "true";

const AnalyzeRTC = (props: RouteComponentProps<RouteParams> & WithBreadcrumb) => {
  const [webrtcInternalsGraphs, setWebrtcInternalsGraphs] = React.useState<any>({});
  const [webRtcAnalytics, setWebRtcAnalytics] = React.useState<WebRtcAnalytics | null>(null);
  const [error, setError] = React.useState<string>("");

  const dispatch = useDispatch();
  const { user } = useSelector((state: IStore) => state.userAuth);
  const isAdmin = user?.role === "admin";

  const { isLoading, getClearDumpFile, generateDumpUrl } = useDumpUrl(props);

  React.useEffect(() => {
    checkAccess().then(() => getData());
  }, []);

  const checkAccess = async () => {
    const {
      match: {
        path,
        params: { testIterationId },
      },
    } = props;
    if (path !== AppRoutes.WebrtcInternalsFreeAnalyzeRtcId) {
      const res: any = await DbUtilsService.rpc("test_iterations", "info", testIterationId);
      if (res?.status === 403) {
        return handleForbiddenError(props.history, res?.data?.data);
      }
    }
  };

  const getData = async () => {
    const { match, breadcrumbs } = props;
    const { testIterationId, fileName } = match.params;

    breadcrumbs.update(`${AppRoutes.AnalyzeDumpResult}/${testIterationId}`, fileName);
    breadcrumbs.push(`#`, "Internals | GetStat");

    try {
      const file = await getDumpWithAdditionalInfo();
      const dataForGraps = preparedDataForGraphs(file);
      const graphsState = prepareGraphs(
        dataForGraps,
        file?.getStats?.dataFrequency || file.sessionData?.interval / 1000 || 1
      );
      setWebrtcInternalsGraphs(graphsState);
    } catch (err) {
      reportError("Couldn't get data", { err: err.stack });
      if (err.response && err.response.status) {
        if (err.response.status === 404) {
          setError("Not found");
          dispatch(SetNotification("Webrtc-Internals Dump not found"));
        } else if (err.response.status === 403) {
          return handleForbiddenError(props.history, err.response.data?.data);
        } else {
          setError(err.response.data?.message || "No WebRTC data collected");
        }
      }
    }
  };

  const getDumpWithAdditionalInfo = async () => {
    let response;
    let url;
    if (useFakeData) {
      console.warn("FAKE_WEBRTC_INTERNALS getDumpWithAdditionalInfo: Reading fake data");
      url = "fake";
      response = await import("./components/webrtc-dump-new");
      // response = { data: undefined };
    } else {
      url = await generateDumpUrl();
      response = await DumpFileService.getDumpFile(url);
    }

    if (response.data && response.data.PeerConnections) {
      setWebRtcAnalytics(response.data);
    } else if (response.data && !response.data.PeerConnections && response.data.trace) {
      setError("Legacy getstats info, not supported");
    } else {
      setError("Invalid format, No WebRTC data collected");
    }

    return response.data;
  };

  const replayAsWatchRTC = async () => {
    try {
      let url = await generateDumpUrl();
      url = url.replace("get-advance-webrtc/", `replay-testingrtc-as-watchrtc/${props.match.params.testIterationId}/`);
      const response = await DumpFileService.replayAsWatchRTC(url);
      if (response.data?.error) {
        dispatch(SetNotification(response.data.message));
      } else if (response.data?.roomId) {
        window.open(`${AppRoutes.WatchRTCRoom}/${response.data.roomId}`, "_blank");
      } else {
        dispatch(SetNotification("Couldn't replay data"));
      }
    } catch (err) {
      dispatch(SetNotification("Couldn't replay data"));
      reportError("Couldn't replay data", err);
    }
  };

  const Wrapped = ({ children }: any) => <div style={{ margin: "0 auto" }}>{children}</div>;
  return (
    <Wrapped>
      <>
        {webRtcAnalytics && (
          <QuickLinks
            getDumpFile={() => getClearDumpFile()}
            webRtcAnalytics={webRtcAnalytics}
            isLoading={isLoading}
            isAdmin={isAdmin}
            replayAsWatchRTC={replayAsWatchRTC}
            hideReplay={true}
          />
        )}
        {webRtcAnalytics && <SessionData webRtcAnalytics={webRtcAnalytics} />}
        {webRtcAnalytics ? (
          <UserMedia media={webRtcAnalytics.getUserMedia} origin={webRtcAnalytics.origin} />
        ) : (
          <React.Fragment>
            {error === "" ? <Loader /> : <Typography variant="body2">{error}</Typography>}
          </React.Fragment>
        )}
        {webRtcAnalytics &&
          Object.keys(webRtcAnalytics.PeerConnections).map((key: string) => {
            return (
              <React.Fragment key={key}>
                <Connection
                  id={key}
                  title={key}
                  connection={webRtcAnalytics.PeerConnections[key]}
                  sessionData={webRtcAnalytics.sessionData}
                  webrtcInternalsGraphs={webrtcInternalsGraphs}
                />
              </React.Fragment>
            );
          })}
      </>
    </Wrapped>
  );
};

export default withRouter(withBreadcrumb(AnalyzeRTC) as any);
