import { FileResponse } from "src/services/AxiosService";
import { saveAs } from "file-saver";

const downloadFile = (file: FileResponse) => {
  const { name, format, content } = file;
  try {
    const types = {
      zip: "application/zip",
      csv: "text/csv;charset=utf-8;",
      json: "application/json",
    } as const;
    const type = types?.[format];
    let data;

    if (type === types.zip) {
      data = Buffer.from(content, "hex");
    } else if (type === types.json) {
      data = JSON.stringify(content, null, 2);
    } else {
      data = content;
    }

    const blob = new Blob([data], { type });
    saveAs(blob, `${name}.${format}`);
  } catch (err) {
    console.error(`Failed to upload file: ${name}.${format}`);
  }
};

const downloadGCSFile = (file: any, format?: string) => {
  const blob = new Blob([file.data], { type: format || file.data.type });
  const fileName = file.headers["content-disposition"]
    .split(";")
    .find((n: string) => n.includes("filename="))
    .replace("filename=", "")
    .replace(/\"/g, "")
    .trim();
  saveAs(blob, fileName);
};

export { downloadFile, downloadGCSFile };
