import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

interface ISaveNewDialogProps {
  open: boolean;
  onSave: (name: string) => Promise<void>;
  onClose: () => void;
}
const SaveNewDialog = ({ open, onSave, onClose }: ISaveNewDialogProps) => {
  const [name, setName] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if (open) {
      setName("");
      setError("");
    }
  }, [open]);

  const handleNameChange = (e: any) => {
    const text = e.target.value;
    if (text && error) {
      setError("");
    }
    setName(text);
  };

  const handleSave = async () => {
    if (!name) {
      setError("Name is required");
      return;
    }
    if (error) {
      setError("");
    }
    await onSave(name);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="save-as-dialog" maxWidth="md">
      <div style={{ width: 300 }}>
        <DialogTitle>{"Save preset as"}</DialogTitle>
        <DialogContent>
          <TextField
            id="name"
            label="Name"
            value={name}
            onChange={handleNameChange}
            fullWidth={true}
            margin="normal"
            error={!!error}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="textSecondary">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default SaveNewDialog;
