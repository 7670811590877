import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Fade from "@mui/material/Fade";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import RTCGrid from "src/components/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
    },
    card: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    tableContainer: {
      padding: theme.spacing(3),
      display: "block",
      overflowY: "hidden",
      overflowX: "auto",
      "& th:empty": {
        height: 0,
        padding: 0,
      },
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
      "& td": {
        minWidth: 200,
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      },
      "& td:first-child": {
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      },
      "& td:last-child": {
        paddingRight: 8,
      },
    },
  })
);

interface ISessionData {
  webRtcAnalytics: any;
}

interface GridModel {
  _id: string;
  key: string;
  value: string;
}

const hardwareKeys = [
  "cores_count",
  "fps",
  "gpu",
  "isMobile",
  "tier",
  "audioOutput",
  "browser",
  "os",
  "Ip",
  "machine",
  "memory",
] as const;

type HardwareKey = typeof hardwareKeys[number];

const hardwareKeysLabels = {
  cores_count: "# of cores",
  fps: "FPS",
  gpu: "GPU",
  isMobile: "Mobile",
  tier: "Processor tier",
  audioOutput: "Audio output",
  browser: "Browser",
  os: "Operating System",
  Ip: "Ip",
  machine: "Machine",
  memory: "Memory",
};

const userAgentKeys = ["app_name", "device_model", "os", "network_stack", "darwin_os"] as const;

type UserAgentKey = typeof userAgentKeys[number];

const userAgentKeysLabels = {
  app_name: "Application Name",
  device_model: "Device Model",
  os: "OS",
  network_stack: "Network Stack",
  darwin_os: "Darwin OS",
};

const testrtcCompanionKeys = {
  general: ["os"] as const,
  cpuInfo: ["archName", "modelName", "numOfProcessors", "totalCpu"] as const,
  ramInfo: ["totalMemory"] as const,
  storageInfo: ["totalCapacity"] as const,
};

const testrtcGeneralLabels = {
  os: "Operating System",
};

const testrtcCpuInfoLabels = {
  archName: "Architecture",
  modelName: "Model Name",
  numOfProcessors: "Number of Processors",
  totalCpu: "Total CPU",
};

const testrtcRamInfoLabels = {
  totalMemory: "Total Memory",
};

const testrtcStorageInfoLabels = {
  totalCapacity: "Total Storage Capacity",
};

const mapLocalData = (data: any): GridModel[] => {
  const hardwareData: GridModel[] = [];
  const hardware = data.sessionData?.hardware as Partial<Record<HardwareKey, any>>;

  hardwareKeys.forEach((hk, index) => {
    const value = hardware?.[hk];
    if (value !== undefined) {
      hardwareData.push({
        _id: `hardware-${index}`,
        key: hardwareKeysLabels[hk],
        value: value.toString(),
      });
    }
  });

  const parseObject = (str: string): Partial<Record<UserAgentKey, string>> => {
    const obj: Partial<Record<UserAgentKey, string>> = {};

    const strArr = str?.split(" ");
    strArr?.forEach((item, index) => {
      const splitItem = item?.includes("/") ? item?.split("/") : item?.split(/(\d.*)/);
      if (splitItem && userAgentKeys[index]) {
        obj[userAgentKeys[index]] = `name: ${splitItem[0]}, version: ${splitItem[1]}`;
      }
    });
    return obj;
  };

  const parsedUserAgentData = parseObject(data.sessionData?.metadata?.userAgent);
  const userAgentData: GridModel[] = [];

  userAgentKeys.forEach((uak, index) => {
    const value = parsedUserAgentData?.[uak];
    if (value !== undefined) {
      userAgentData.push({
        _id: `userAgent-${index}`,
        key: userAgentKeysLabels[uak],
        value: value.toString(),
      });
    }
  });

  const testrtcCompanionData: GridModel[] = [];
  const testrtcCompanionStats = data.sessionData?.hardware?.testrtcCompanion?.stats;

  if (testrtcCompanionStats) {
    let index = 0;

    const general = testrtcCompanionStats.general;
    if (general) {
      testrtcCompanionKeys.general.forEach((key) => {
        const value = general[key];
        if (value !== undefined) {
          testrtcCompanionData.push({
            _id: `testrtc-general-${index++}`,
            key: testrtcGeneralLabels[key],
            value: value.toString(),
          });
        }
      });
    }

    const cpuInfo = testrtcCompanionStats.cpuInfo;
    if (cpuInfo) {
      testrtcCompanionKeys.cpuInfo.forEach((key) => {
        const value = cpuInfo[key];
        if (value !== undefined) {
          testrtcCompanionData.push({
            _id: `testrtc-cpuInfo-${index++}`,
            key: testrtcCpuInfoLabels[key],
            value: value.toString(),
          });
        }
      });
    }

    const ramInfo = testrtcCompanionStats.ramInfo;
    if (ramInfo) {
      testrtcCompanionKeys.ramInfo.forEach((key) => {
        const value = ramInfo[key];
        if (value !== undefined) {
          testrtcCompanionData.push({
            _id: `testrtc-ramInfo-${index++}`,
            key: testrtcRamInfoLabels[key],
            value: value.toString(),
          });
        }
      });
    }

    const storageInfo = testrtcCompanionStats.storageInfo;
    if (storageInfo) {
      testrtcCompanionKeys.storageInfo.forEach((key) => {
        const value = storageInfo[key];
        if (value !== undefined) {
          testrtcCompanionData.push({
            _id: `testrtc-storageInfo-${index++}`,
            key: testrtcStorageInfoLabels[key],
            value: value.toString(),
          });
        }
      });
    }

    const displayInfo = testrtcCompanionStats.displayInfo;
    if (displayInfo && Array.isArray(displayInfo)) {
      displayInfo.forEach((display: any, idx: number) => {
        testrtcCompanionData.push({
          _id: `testrtc-display-${idx}`,
          key: `Display ${idx + 1} Resolution`,
          value: `${display.width}x${display.height}`,
        });
      });
    }
  }

  const mapped = [...userAgentData, ...hardwareData, ...testrtcCompanionData];

  return mapped;
};

interface ColumnSchema {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  sortable: boolean;
  label: string;
}

const SessionData = (props: ISessionData) => {
  const classes = useStyles();

  const columnSchema: Array<ColumnSchema> = [
    {
      id: "key",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "key",
    },
    {
      id: "value",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "value",
    },
  ];

  return (
    <Fade in={true}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="subtitle1">Device Information</Typography>
          </Toolbar>
          <Divider className={classes.divider} />
          <Grid container spacing={5} className={classes.tableContainer}>
            <RTCGrid
              localData={mapLocalData(props.webRtcAnalytics)}
              columnSchema={columnSchema}
              rowProps={{
                className: classes.tableRow,
              }}
              hideHeader={true}
            />
          </Grid>
        </Card>
      </Grid>
    </Fade>
  );
};

export default SessionData;
