import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import { createStyles, makeStyles } from "@mui/styles";

import logoImgSrc from "src/assets/images/logo_text3_1.png";

const useStyles = makeStyles(
  createStyles({
    appBar: {
      height: 70,
      backgroundColor: "#FDFDFD",
      boxShadow: "none",
    },
    toolbar: {
      height: "100%",
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "10%",
    },
    logoImage: {
      width: "100%",
      maxHeight: 50,
      height: "auto",
    },
  })
);

const NavBar = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <a href="https://testrtc.com" className={classes.logoContainer}>
              <img className={classes.logoImage} src={logoImgSrc} alt="testRTC" />
            </a>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
