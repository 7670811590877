import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface IRemindPasswordModalProps {
  isOpen: boolean;
  emailRemind: string;
  onClose: () => void;
}

class RemindPasswordModal extends React.Component<IRemindPasswordModalProps> {
  public render(): JSX.Element {
    return (
      <Dialog
        open={this.props.isOpen}
        keepMounted={true}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Password Reset</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Assuming {this.props.emailRemind} exists in our records, then instructions on accessing the account have
            been sent to this email address.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}

export default RemindPasswordModal;
