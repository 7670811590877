import * as React from "react";
import * as classnames from "classnames";

import { Grid, Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ProjectPropertiesViewProps } from "../View";
import { ProjectPropertiesState } from "..";

type ButtonsProps = {
  showGeneratedConfig?: () => void;
  sticky?: boolean;
};

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      position: "sticky",
      top: -1,
      zIndex: 9999,
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      zIndex: 9999,
      padding: "10px 5px",
    },
    containerSticky: {
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      backgroundColor: "#EDEDED",
      opacity: 1,
      zIndex: 9999,
    },
    saveBtn: {
      marginLeft: 10,
    },
  })
);

const Buttons = ({
  toggleDeleteProjectDialog,
  showGeneratedConfig,
  config,
  sticky,
  isProjectDataValid,
}: ButtonsProps & ProjectPropertiesViewProps & ProjectPropertiesState) => {
  const [isSticky, setIsSticky] = React.useState(false);
  const ref = React.createRef<HTMLDivElement>();
  const classes = useStyles();

  React.useEffect(() => {
    // get the sticky element
    const stickyElm = ref?.current as any;
    if (!stickyElm || !sticky) {
      return;
    }

    const observer = new IntersectionObserver(([e]) => setIsSticky(e.intersectionRatio < 1), {
      threshold: [1],
    });

    observer.observe(stickyElm);

    // unmount
    return () => {
      observer.unobserve(stickyElm);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div ref={ref} className={classes.root}>
      <Grid
        ref={ref}
        item={true}
        xs={12}
        className={classnames.default(classes.container, isSticky ? classes.containerSticky : "")}
      >
        {showGeneratedConfig ? (
          <Button color="primary" onClick={showGeneratedConfig}>
            {`${config ? "hide" : "show"} generated config`}
          </Button>
        ) : (
          <div />
        )}
        <div>
          <Button color="primary" onClick={toggleDeleteProjectDialog}>
            {"Delete"}
          </Button>
          <Button
            disabled={!isProjectDataValid}
            variant="contained"
            type="submit"
            color="success"
            className={classes.saveBtn}
          >
            {"Save"}
          </Button>
        </div>
      </Grid>
    </div>
  );
};

export default Buttons;
