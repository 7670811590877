import * as React from "react";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

class FooterColors extends React.Component<WithStyles<"root" | "yellow" | "green" | "orange" | "blue" | "red">> {
  public render(): JSX.Element {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.yellow} />
        <div className={classes.green} />
        <div className={classes.orange} />
        <div className={classes.blue} />
        <div className={classes.red} />
      </div>
    );
  }
}

const commonStyle = {
  width: "20%",
  position: "relative",
  display: "inline-flex",
} as React.CSSProperties;

const styles = createStyles({
  root: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    left: 0,
    display: "block",
    alignItems: "flex-end",
    height: "13px",
  },
  yellow: {
    ...commonStyle,
    background: "#f1cd2b",
    height: "13px",
  },
  green: {
    ...commonStyle,
    background: "#599647",
    height: "11px",
    width: "20%",
  },
  orange: {
    ...commonStyle,
    background: "#dd7127",
    height: "9px",
  },
  blue: {
    ...commonStyle,
    background: "#3577c1",
    height: "7px",
  },
  red: {
    ...commonStyle,
    background: "#a22a21",
    height: "12px",
  },
});

export default withStyles(styles)(FooterColors);
