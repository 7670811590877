import { Field } from "react-final-form";
import {
  Button,
  Card,
  Divider,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";

import TextFieldControl from "src/components/form-elements/TextFieldControl";
import CheckboxControl from "src/components/form-elements/CheckboxControl";
import MultiEmailField from "src/components/MultiEmailField/MultiEmailField";
import { QualityRTC as QualityRTCAlerts } from "src/constants/AlertWhen";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import { InviteLockingPolicies } from "../constants/qualityRTC";

interface AgentAssureProps {
  setOptions: (options: string) => void;
  values: any;
}

const AgentAssure = ({
  classes,
  setOptions,
  values,
}: AgentAssureProps & ProjectPropertiesViewProps & ProjectPropertiesState) => {
  const onBeautifyClick = () => {
    try {
      const json = JSON.parse(values.qualityrtc_options);
      setOptions(JSON.stringify(json, null, 2));
    } catch {
      //
    }
  };
  return (
    <>
      <Card className={classes.card} style={{ padding: 0 }}>
        <Toolbar>
          <Typography variant="subtitle1">AgentAssure</Typography>
        </Toolbar>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: 220 }}>AgentAssure Enabled</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="agentAssure_enable" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Branding Subdomain</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" name="agentAssure_branding.subdomain" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Branding Logo</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" name="agentAssure_branding.logo" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Branding Favicon</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" name="agentAssure_branding.favicon" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Invites Enabled</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="qualityrtc_inviteEnable" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Detailed View Enabled</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="agentAssure_detailedView" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Invite Locking Policy</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  select={true}
                  SelectProps={{ autoWidth: true }}
                  name="qualityrtc_inviteLockingPolicy"
                  variant="standard"
                >
                  {Object.keys(InviteLockingPolicies).map((key: any, i: number) => (
                    <MenuItem key={i} value={InviteLockingPolicies[key]}>
                      {InviteLockingPolicies[key]}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Invites Purge Time</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="qualityrtc_invitePurgeTime" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Max Invite Duration (days)</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="qualityrtc_maxInviteDuration" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Max Monthly Network Tests</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="qualityrtc_maxNetworkTests" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Advanced Analytic</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="qualityrtc_advanceAnalytic" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Webhook Enable</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="qualityrtc_webhook_enable" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Retention Period</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="qualityrtc_retentionPeriod" min={1} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Alerts email</TableCell>
              <TableCell>
                <MultiEmailField name="qualityrtc_toEmailAddress" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Alert when</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" select={true} name="qualityrtc_alertWhen">
                  {Object.keys(QualityRTCAlerts).map((key: any, i: number) => (
                    <MenuItem key={i} value={QualityRTCAlerts[key]}>
                      {key}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Support account reporting</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="qualityrtc_support_account_reporting" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ width: 220 }}>Extra Fields</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" disabled name="qualityrtc_extraFields" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Show History Columns</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  disabled
                  name="qualityrtc_showHistoryColumns"
                  multiline={true}
                  rows="3"
                  resizeY={true}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Base URL (customer's website url)</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" disabled name="qualityrtc_baseURL" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Options (JSON)</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  disabled
                  name="qualityrtc_options"
                  multiline={true}
                  rows="5"
                  resizeY={true}
                />
                <Button variant="contained" style={{ marginTop: 5 }} onClick={onBeautifyClick}>
                  {"beautify"}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </>
  );
};

export default AgentAssure;
