import * as z from "zod";
import { getZodErrorMessage, validate } from "./common";
import { CustomerStorageDetailsSchema } from "./data-stream.validator";

const ProjectClientStorageValidate = (data: ProjectAWSClientStorage) => {
  try {
    validate(CustomerStorageDetailsSchema, data);
  } catch (error: any) {
    const message = typeof error === "string" ? error : getZodErrorMessage(error);
    return `Invalid Cloud Storage, ${message}`;
  }
  return "";
};

type ProjectAWSClientStorage = z.infer<typeof CustomerStorageDetailsSchema>;

export { ProjectClientStorageValidate };

export type { ProjectAWSClientStorage };
