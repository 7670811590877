import * as z from "zod";

export enum CloudPlatform {
  aws = "aws",
  gcp = "gcp",
  azure = "azure",
}
const DataStreamBaseMinimumOfConfiguredOptionsSchema = z.object({
  collectionInterval: z.number().gte(1).lte(1440),
});

const CustomerStorageBaseDetailsSchema = z.object({
  bucketName: z.string({
    required_error: "bucketName is required",
    invalid_type_error: "bucketName must be a string",
  }),
});
const Aws = CustomerStorageBaseDetailsSchema.extend({
  cloudPlatform: z.literal(CloudPlatform.aws),
  bucketRegion: z
    .string({
      required_error: "bucketRegion is required",
      invalid_type_error: "bucketRegion must be a string",
    })
    .optional(),
  credentials: z
    .object({
      accessKeyId: z
        .string({
          required_error: "accessKeyId in credentials is required",
          invalid_type_error: "accessKeyId must be a string",
        })
        .min(16, { message: "accessKeyId must have atleast 16 elements" })
        .max(128, { message: "accessKeyId must have atmost 128 elements" }),
      secretAccessKey: z.string({
        required_error: "secretAccessKey in credentials is required",
        invalid_type_error: "secretAccessKey must be a string",
      }),
    })
    .strict(),
});

const CustomerStorageDetailsSchema = z.preprocess((arg: any) => {
  if (typeof arg?.credentials === "string") {
    return { cloudPlatform: arg?.cloudPlatform, bucketName: arg.bucketName, credentials: JSON.parse(arg.credentials) };
  } else {
    return arg;
  }
}, Aws);

export { CustomerStorageDetailsSchema, DataStreamBaseMinimumOfConfiguredOptionsSchema };
