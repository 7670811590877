import * as React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AxiosResponse } from "axios";

import { axiosInstance } from "../../services/AxiosService";
import ApiPath from "../../constants/ApiPath";
import AuthService from "../../services/AuthService";
import SocketService from "../../services/SocketService";
import { FetchUser } from "../../actions/authAction";
import { AuthRoutes } from "src/constants/routes/auth-routes";
import { AppRoutes } from "src/constants/routes/app-routes";
import { dispatchDataLoading } from "../../actions/dataLoadingActions";

interface AppControllerProps {
  children: React.ReactNode;
}

export type AppControllerState = {
  projects?: Array<Project>;
  selectedProject?: Project | null;
  setSelectedProject?: (projectId: string, url?: string) => void;
};

export const ProjectsContext = React.createContext({
  projects: [],
} as AppControllerState);

const AppController = ({ children }: AppControllerProps) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const user = useSelector((state: IStore<"user">) => state.userAuth.user);

  const toggleUsersProject = React.useCallback(
    async (projectId: string, path?: string) => {
      dispatch(dispatchDataLoading(true));

      try {
        const result: AxiosResponse<LoginResponse> = await axiosInstance.post(
          `${ApiPath.api.user.changeProject}/${projectId}`,
          {
            id: projectId,
          }
        );

        const data = result.data;
        if (window.location.hostname === "localhost") {
          AuthService.setToken(data.token);
          AuthService.setExpires(data.expires);
        }

        // TODO: socket update here
        await dispatch(FetchUser());
        dispatch(dispatchDataLoading(false));

        history.replace(path || "/");
      } catch (err) {
        // TODO: add global error handler
        // tslint:disable-next-line:no-console
        console.log(err);
      }
    },
    [AuthService]
  );

  const [state, setState] = React.useState<AppControllerState>({
    projects: [],
    selectedProject: null,
  });

  const setSelectedProject = React.useCallback(
    async (projectId: string, path?: string) => {
      setState((currentState) => ({
        ...currentState,
        selectedProject: currentState.projects && currentState.projects.find((p: Project) => p._id === projectId),
      }));
      await toggleUsersProject(projectId, path);
      SocketService._instance.refresh();
    },
    [toggleUsersProject, SocketService._instance]
  );

  const contextValue = React.useMemo(
    () => ({
      projects: state.projects,
      selectedProject: state.selectedProject,
      setSelectedProject,
    }),
    [state.projects, state.selectedProject, setSelectedProject]
  );

  const getProjectDetails = async (projectId: string | null): Promise<Project | null> => {
    if (!projectId) {
      return null;
    }

    try {
      const result: AxiosResponse<Project> = await axiosInstance.get(
        `${ApiPath.api.projects.root}/projectSetup/${projectId}`
      );

      return result.data;
    } catch (err) {
      // TODO: add global error handler
      // tslint:disable-next-line:no-console
      console.log(err);
    }
    return null;
  };

  const setProject = async () => {
    const result: AxiosResponse<Array<Project>> = await axiosInstance.get(ApiPath.api.projects.all, {
      params: {
        userId: user._id,
      },
    });

    const projects = result.data.sort((a, b) => a.name_sort.localeCompare(b.name_sort));
    let selectedProject = null;

    const isProjects = projects?.length > 0;

    if (!isProjects) {
      await AuthService.logout("testrtc");
      history.push(AuthRoutes.SignIn);
      return;
    }

    const userProject = user?.project;

    const isUserProject = !!userProject?._id;
    const isInProjects = projects.some((p: any) => p._id === userProject._id);
    const isUserProjectValid = isUserProject && (isInProjects || user.role === "admin");

    if (!isUserProject || (isUserProject && !isUserProjectValid)) {
      selectedProject = projects[0];
      await toggleUsersProject(selectedProject._id);
    }

    if (user.role !== "admin") {
      projects.forEach((p: Project) => {
        if (userProject && p._id === userProject._id) {
          selectedProject = p;
        }
      });
    } else {
      selectedProject = { _id: userProject._id };
    }

    const project = await getProjectDetails(selectedProject && selectedProject._id);

    if (!isInProjects && user.role === "admin" && project) {
      projects.push(project);
    }

    setState({
      projects,
      selectedProject: project,
    });
  };

  React.useEffect(() => {
    (async () => {
      if (user) {
        const localUser = user as any;
        if (!localUser.loggedInAs && !localUser.acceptTerms) {
          // go to user-settings page
          history.push(AppRoutes.UserSettings);
        }

        await setProject();
      }
    })();
  }, []);

  return <ProjectsContext.Provider value={contextValue}>{children}</ProjectsContext.Provider>;
};

export default AppController;
