export const MapTypeIcon = {
  warning: "🟡",
  error: "🔴",
  success: "🟢",
  neutral: "⚫",
};

export const MapType = {
  warning: "Warning",
  error: "Error",
  success: "Success",
  info: "info",
};

export const COLORS = {
  error: `rgba(222, 28, 26, 1)`,
  warning: `rgba(255, 157, 0, 1)`,
  info: `rgba(0, 161, 95, 1)`,
};

export const MapMetric = {
  "audio-packetLoss-send": "Audio Packet Loss % (Outgoing)",
  "audio-packetLoss-recv": "Audio Packet Loss % (Incoming)",
  "video-packetLoss-send": "Video Packet Loss % (Outgoing)",
  "video-packetLoss-recv": "Video Packet Loss % (Incoming)",

  "audio-bitrate-send": "Audio Bitrate (Outgoing)",
  "audio-bitrate-recv": "Audio Bitrate (Incoming)",
  "video-bitrate-send": "Video Bitrate (Outgoing)",
  "video-bitrate-recv": "Video Bitrate (Incoming)",

  "audio-jitter-send": "Audio Jitter (Outgoing)",
  "audio-jitter-recv": "Audio Jitter (Incoming)",
  "video-jitter-send": "Video Jitter (Outgoing)",
  "video-jitter-recv": "Video Jitter (Incoming)",

  "audio-rtt-send": "Audio Round Trip Time",
  "video-rtt-send": "Video Round Trip Time",

  callSetupTime: "Call Setup Time",
  score: "Score",
  userRating: "User Rating",
  MOS: "MOS",

  //ICERestart: "ICERestart", // not used, moved to insights
  //ICEFailure: "ICEFailure", // not used, moved to insights
  //isGetUserMediaError: "GetUserMediaError", // not used, moved to insights
  isPCLocalIPDifferFromPublicIP: "PCLocalIPDifferFromPublicIP",
  RulesEngine: "RulesEngine",
};

export const MapOperator = {
  "<": "<",
  "<=": "<=",
  ">=": ">=",
  ">": ">",
  "=": "=",
};

export const ThresholdHints = {
  "audio-packetLoss-send": "0-100%",
  "audio-packetLoss-recv": "0-100%",
  "video-packetLoss-send": "0-100%",
  "video-packetLoss-recv": "0-100%",

  "audio-bitrate-send": "0-1,000 (Kbps)",
  "audio-bitrate-recv": "0-1,000 (Kbps)",
  "video-bitrate-send": "0-10,000 (Kbps)",
  "video-bitrate-recv": "0-10,000 (Kbps)",

  "audio-jitter-send": "0-10,000 (milliseconds)",
  "audio-jitter-recv": "0-10,000 (milliseconds)",
  "video-jitter-send": "0-10,000 (milliseconds)",
  "video-jitter-recv": "0-10,000 (milliseconds)",

  "audio-rtt-send": "0-10,000 (milliseconds)",
  "video-rtt-send": "0-10,000 (milliseconds)",

  callSetupTime: "0-10,000 (seconds)",
  score: "0-10",
  userRating: "1-5",
  MOS: "1-5",
};

export const ThresholdMeasurements = {
  "audio-packetLoss-send": "%",
  "audio-packetLoss-recv": "%",
  "video-packetLoss-send": "%",
  "video-packetLoss-recv": "%",

  "audio-bitrate-send": "Kbps",
  "audio-bitrate-recv": "Kbps",
  "video-bitrate-send": "Kbps",
  "video-bitrate-recv": "Kbps",

  "audio-jitter-send": "milliseconds",
  "audio-jitter-recv": "milliseconds",
  "video-jitter-send": "milliseconds",
  "video-jitter-recv": "milliseconds",

  "audio-rtt-send": "milliseconds",
  "video-rtt-send": "milliseconds",

  callSetupTime: "milliseconds",
};

export const ThresholdRanges = {
  "audio-packetLoss-send": { from: 0, to: 100 },
  "audio-packetLoss-recv": { from: 0, to: 100 },
  "video-packetLoss-send": { from: 0, to: 100 },
  "video-packetLoss-recv": { from: 0, to: 100 },

  "audio-bitrate-send": { from: 0, to: 1000 },
  "audio-bitrate-recv": { from: 0, to: 1000 },
  "video-bitrate-send": { from: 0, to: 10000 },
  "video-bitrate-recv": { from: 0, to: 10000 },

  "audio-jitter-send": { from: 0, to: 10000 },
  "audio-jitter-recv": { from: 0, to: 10000 },
  "video-jitter-send": { from: 0, to: 10000 },
  "video-jitter-recv": { from: 0, to: 10000 },

  "audio-rtt-send": { from: 0, to: 10000 },
  "video-rtt-send": { from: 0, to: 10000 },

  callSetupTime: { from: 0, to: 10000 },
  score: { from: 0, to: 10 },
  userRating: { from: 0, to: 5 },
  MOS: { from: 0, to: 5 },
};
