import * as React from "react";
import { connect } from "react-redux";

interface AppContentSpinnerProps {
  Loader: React.ComponentType;
  isForced?: boolean;
}

type StoreProps = {
  dataLoading: boolean;
};

class AppContentSpinner extends React.Component<AppContentSpinnerProps & StoreProps> {
  render() {
    const { Loader, isForced, dataLoading } = this.props;

    if (isForced || dataLoading) {
      return <Loader />;
    } else {
      return null;
    }
  }
}

const mapStateToProps = (store: IStore) => ({
  dataLoading: store.dataLoading.dataLoading,
});

export default connect<StoreProps, {}, AppContentSpinnerProps>(mapStateToProps)(AppContentSpinner);
