export const defaultChartColors = ["#edc240", "#afd8f8", "#cb4b4b", "#4da74d", "#9440ed"];

export const hexToRgbaColorMap = {
  "#a22a21": "rgba(162,42,33,1)",
  "#f1cd2b": "rgba(241,205,43,1)",
  "#dd7127": "rgba(221,113,39,1)",
  "#ddcc27": "rgba(221,204,39,1)",
  "#dd2738": "rgba(221,39,56,1)",
  "#b2f12b": "rgba(178,241,43,1)",
  "#cd2bf1": "rgba(205,43,241,1)",
  "#4682c0": "rgba(70,130,192,1)",
  "#559542": "rgba(85,149,66,1)",
  "#745671": "rgba(116,86,113,1)",
  "#567459": "rgba(86,116,89,1)",
  "#262d31": "rgba(38,45,49,1)",
  "#c08446": "rgba(192,132,70,1)",
  "#a22159": "rgba(162,33,89,1)",
} as const;
