import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";

const MultiSelectControl = ({
  _classes,
  input: { name, onChange, value },
  _meta,
  _label,
  options,
  _disabled,
  onchange,
  ...rest
}: any) => {
  return (
    <Select
      {...rest}
      name={name}
      id="mutiple-grid-presets"
      multiple={true}
      value={value}
      // tslint:disable-next-line: no-any
      onChange={(e: any) => {
        onChange(e);
        if (onchange) {
          onchange(e);
        }
      }}
      input={<Input />}
      variant="standard"
      sx={{
        maxWidth: {
          xl: "1527px",
          lg: "990px",
          md: "860px",
          sm: "616px",
          xs: "400px",
        },
      }}
    >
      {options &&
        options.map((o: any, index: any) => (
          <MenuItem
            key={index}
            value={o.value}
            style={{
              margin: 2,
            }}
          >
            {o.label}
          </MenuItem>
        ))}
    </Select>
  );
};

export default MultiSelectControl;
