import * as React from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface ISubscribeModalProps {
  isOpen: boolean;
  emailRemind: string;
  onClose: () => void;
}

class SubscribeModal extends React.Component<ISubscribeModalProps> {
  public render(): JSX.Element {
    return (
      <Dialog
        open={this.props.isOpen}
        keepMounted={true}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Subscribed to testRTC Newsletter</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Request to confirm subscription was sent to {this.props.emailRemind}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}

export default SubscribeModal;
