import { useContext, useEffect, useRef, useState } from "react";
import { Card, CircularProgress, TextField, Tooltip } from "@mui/material";
import { ProjectsContext } from "../../../AppController";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import { useAxios } from "../../../../services/AxiosService";
import ApiPath from "../../../../constants/ApiPath";

interface ComponentType {
  open: boolean;
  projects: Project[];
  currentId: string;
  isAdmin: boolean;

  close(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    items: {
      position: "absolute",
      left: 0,
      top: 36,
      padding: 8,
      width: 260,
      zIndex: 1001,
      overflowY: "auto",
      maxHeight: "min(70vh, 620px)",
      boxShadow: theme.shadows[2],
    },
    item: {
      width: "100%",
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: "4px 10px",
      cursor: "pointer",
      borderRadius: theme.shape.borderRadius,
      whiteSpace: "nowrap",
      "&.active": {
        color: "#3d3d3d",
        background: "#d3d3d3",
        cursor: "default",
        pointerEvents: "none",
      },
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
    search: {
      top: -8,
      position: "sticky",
      background: "white",
      marginTop: -8,
      marginBottom: 8,
      paddingTop: 8,
      boxShadow: "0px 1px 2px 3px rgb(255 255 255 / 96%)",
    },
    inputWrap: {
      width: "100%",
    },
    inputSearch: {
      padding: "6px 30px 6px 10px",
    },
    cancelSearch: {
      position: "absolute",
      right: 5,
      top: 8,
      cursor: "pointer",
      color: "#ccc",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
    notFoundMessage: {
      padding: 8,
      color: theme.palette.error.main,
    },
    loading: {
      margin: "20px auto",
      display: "block",
    },
  })
);

const UserProjectsList = ({ isAdmin, open, projects, currentId, close }: ComponentType): JSX.Element | null => {
  let mounted = true;
  const classes = useStyles();

  const { setSelectedProject } = useContext(ProjectsContext);

  const [isResult, setResult] = useState<boolean>(true);
  const [searchResult, setProjects] = useState<Project[]>([]);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean | false>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { axios, cancelToken } = useAxios();

  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (open) {
      inputRef?.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    if (search && search.length > 1) {
      setResult(true);
      setLoading(true);
      const delayDebounceFn = setTimeout(async () => {
        await axios
          .get(ApiPath.api.projects.search + `?s=${search}`, {
            cancelToken,
          })
          .then((res) => {
            if (mounted) {
              setLoading(false);
              setResult(!!res.data.length);
              setProjects(res.data);
            }
          })
          .catch((_e) => {
            if (mounted) {
              setLoading(false);
              setResult(false);
            }
          });
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    } else {
      if (mounted) {
        setProjects([]);
      }
      return;
    }
  }, [search]);

  if (!setSelectedProject) {
    return <>Loading...</>;
  }

  const projectsList = searchResult.length ? searchResult : projects;

  return (
    <Card variant="outlined" className={classes.items} hidden={!open}>
      {isAdmin && (
        <div className={classes.search}>
          <TextField
            inputProps={{
              className: classes.inputSearch,
              ref: inputRef,
              autoComplete: "off",
            }}
            className={classes.inputWrap}
            name="name"
            placeholder="Search project..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="standard"
          />
          <Tooltip title="Cancel Search">
            <CloseIcon
              className={classes.cancelSearch}
              onClick={() => {
                setResult(true);
                setSearch("");
              }}
            />
          </Tooltip>
        </div>
      )}
      {!isResult && <div className={classes.notFoundMessage}>No projects found!</div>}
      {!loading ? (
        projectsList.map((p: Project) => (
          <div
            className={`${classes.item} ${p._id === currentId ? "active" : ""}`}
            key={p._id}
            onClick={() => {
              setSelectedProject(p._id);
              close();
            }}
          >
            {`${p.agentAssure_enable ? "AA - " : ""}${p.name}`}
          </div>
        ))
      ) : (
        <CircularProgress className={classes.loading} />
      )}
    </Card>
  );
};

export default UserProjectsList;
