import * as React from "react";
// import { useState } from "react";
import {
  Card,
  Typography,
  Toolbar,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  MenuItem,
} from "@mui/material";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import CheckboxControl from "src/components/form-elements/CheckboxControl";
import MultiSelectControl from "src/components/form-elements/MultiSelectControl";
import { ProbeRTCTests, IProbeRTCTest } from "src/constants/ProbeRTCTests";
import { ProbeRTC as ProbeRTCAlerts } from "src/constants/AlertWhen";

import { Products } from "./DataStream";
import DataStreamConfigurationCard from "./DataStream";

import * as _ from "lodash";
import { PrivacyActions } from "./QualityRTC";

interface IProbeRTC {
  values: any;
  setOptions: (value: string) => void;
  setActiveJobs: (options: string) => void;
}

const ProbeRTC = ({
  classes,
  values,
  setOptions,
  setProjectDataValid,
  setActiveJobs,
  isProjectDataValid,
}: IProbeRTC & ProjectPropertiesViewProps & ProjectPropertiesState) => {
  const upload = React.useRef<any>(null);
  const options = Object.keys(ProbeRTCTests).map((x: string) => {
    const option = ProbeRTCTests[x] as IProbeRTCTest;
    return {
      value: option.name,
      label: option.title,
    };
  });

  const handleFileChosen = (event: any) => {
    const customizer = (_objValue: any, srcValue: any) => {
      if (_.isArray(srcValue?.allList)) {
        return srcValue;
      }
    };
    const file = event.target["files"][0];
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = (evt: Event) => {
      const text = evt.target ? evt.target["result"] : {};
      try {
        const json = JSON.parse(text);
        json.testsList = undefined;
        // console.log(json);
        // console.log(JSON.parse(values.opp_options));
        const options = _.mergeWith(JSON.parse(values.opp_options || null), json, customizer);
        setOptions(JSON.stringify(options, null, 2));
      } catch {
        //
      }
    };
  };

  const handleOptionsValidation = (event: any) => {
    try {
      const value = JSON.parse(event.target.value);
      setOptions(JSON.stringify(value, null, 4));
      setProjectDataValid(true);
    } catch {
      console.log("error");
      setProjectDataValid(false);
    }
    // setOptions("test");
  };

  const required = (value: number) => (value ? undefined : "Required");
  const mustBeNumber = (value: number) => (isNaN(value) ? "Must be a number" : undefined);
  const isPositiveInteger = (min: number) => (value: number) =>
    Number.isInteger(+value) && +value > min ? undefined : `Should be positive integer`;
  const composeValidators = (...validators: any) => (value: any) =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined);

  return (
    <>
      <Card className={classes.card} style={{ padding: 0 }}>
        <Toolbar>
          <Typography variant="subtitle1">probeRTC</Typography>
        </Toolbar>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: 220 }}>probeRTC Enable</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="opp_enable" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Max Active Probes</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  validate={composeValidators(required, mustBeNumber, isPositiveInteger(0))}
                  type="number"
                  name="opp_maxActiveProbes"
                  defaultValue={0}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Normal Run</TableCell>
              <TableCell>
                <Field component={MultiSelectControl} name="opp_normalRun" options={options} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Thorough Run</TableCell>
              <TableCell>
                <Field component={MultiSelectControl} name="opp_thoroughtRun" options={options} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Options</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="opp_options"
                  multiline={true}
                  rows="5"
                  resizeY={true}
                  style={{ outline: `${isProjectDataValid ? "none" : "1px solid red"}` }}
                  onBlur={handleOptionsValidation}
                />
                <>
                  <input
                    type="file"
                    ref={upload}
                    style={{ display: "none" }}
                    accept="application/json"
                    onChange={handleFileChosen}
                  />
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{
                      marginTop: 5,
                    }}
                    onClick={() => {
                      upload.current.click();
                    }}
                    size="small"
                  >
                    Import
                  </Button>
                </>
                {!isProjectDataValid && (
                  <div
                    style={{
                      color: "red",
                      margin: "5px 10px",
                      fontSize: 15,
                      maxWidth: "max-content",
                      display: "inline-block",
                    }}
                  >
                    Not valid JSON
                  </div>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Alert When</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="opp_alertWhen"
                  select={true}
                  SelectProps={{ autoWidth: true }}
                >
                  {Object.keys(ProbeRTCAlerts).map((key: any, i: number) => (
                    <MenuItem key={i} value={ProbeRTCAlerts[key]}>
                      {key}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <Card className={classes.card} style={{ padding: 0 }}>
        <Toolbar>
          <Typography variant="subtitle1">probeRTC Privacy</Typography>
        </Toolbar>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: 220 }}>PII Removal interval ( days )</TableCell>
              <TableCell>
                <Field component={TextFieldControl} min={1} type="number" name="opp_privacy.interval" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>On PII Removal Event Action</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  select={true}
                  SelectProps={{ autoWidth: true }}
                  name="opp_privacy.onRemove"
                  variant="standard"
                >
                  {Object.keys(PrivacyActions).map((key: any, i: number) => (
                    <MenuItem key={i} value={PrivacyActions[key]}>
                      {PrivacyActions[key]}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>On Test Save Action</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  select={true}
                  SelectProps={{ autoWidth: true }}
                  name="opp_privacy.onStore"
                  variant="standard"
                >
                  {Object.keys(PrivacyActions).map((key: any, i: number) => (
                    <MenuItem key={i} value={PrivacyActions[key]}>
                      {PrivacyActions[key]}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <DataStreamConfigurationCard
        classes={classes}
        values={values}
        product={Products.probe}
        setActiveJobs={setActiveJobs}
      />
    </>
  );
};

export default ProbeRTC;
