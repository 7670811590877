import { PUSH_BREADCRUMB_NODE, RESET_BREADCRUMB, UPDATE_BREADCRUMB_NODE } from "../constants/ActionTypes";
import { ISetBreadcrumbAction } from "../actions/breadcrumbActions";

const defaultState: BreadcrumbReducerModel = {
  nodes: [],
};

export default (state: BreadcrumbReducerModel = defaultState, action: ISetBreadcrumbAction) => {
  switch (action.type) {
    case PUSH_BREADCRUMB_NODE: {
      return {
        ...state,
        nodes: [...state.nodes, action.payload],
      };
    }
    case RESET_BREADCRUMB: {
      return defaultState;
    }
    case UPDATE_BREADCRUMB_NODE: {
      const newNodes = state.nodes.slice(0, -1);
      newNodes.push(action.payload);
      return { ...state, nodes: newNodes };
    }
    default:
      return state;
  }
};
