// tslint:disable:no-any
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Theme, Divider, Typography, Toolbar } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import GraphPanel from "./GraphPanel";

type StyledComponent = WithStyles<"card" | "panelRoot" | "divider" | "toolbar">;

export interface GraphsProps {
  id: string;
  webrtcInternalsGraphs: any;
}

const Graphs = (props: StyledComponent & GraphsProps & RouteComponentProps<{}>) => {
  const { id, webrtcInternalsGraphs, classes } = props;

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <Typography variant="subtitle1" style={{ marginTop: 20, paddingBottom: 10 }}>
          {`Statistics/Metrics connection ${id}`}
        </Typography>
      </Toolbar>

      {webrtcInternalsGraphs[id].map((graph: any, i: number) => {
        return (
          <React.Fragment key={i}>
            <GraphPanel id={i.toString()} graph={graph} />
            {i < webrtcInternalsGraphs[id].length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    panelRoot: {
      boxShadow: "none",
      "&::before": {
        height: 0,
      },
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  });

const decorate = withStyles(styles);

export default withRouter(decorate(Graphs));
