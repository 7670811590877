import * as React from "react";

import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useChartSize } from "./useChartSize";
import { chartSizesArray, chartSizesLabels } from "./constants";
import { ZoomOutMap } from "@mui/icons-material";

interface IChartSizeProps {
  defaultSize: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginRight: theme.spacing(1),
      "& > button": {
        padding: 5,
        marginRight: theme.spacing(1),
        "&:last-child": {
          margin: 0,
        },
      },
    },
    buttonWrap: {
      color: "rgb(119 119 119)",
      width: 80,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      "& > svg": {
        width: 16,
      },
      "& > span": {
        width: 60,
      },
    },
  })
);

const ChartSize = (props: IChartSizeProps) => {
  const classes = useStyles();
  const { chartHeight, chartSize, setChartSize, resetChartsHeight } = useChartSize();

  const cIdx = chartSizesArray.indexOf(chartSize);
  const nextSize = chartSizesArray[cIdx < chartSizesArray.length - 1 ? cIdx + 1 : 0];

  React.useEffect(() => {
    setTimeout(() => {
      setChartSize(props.defaultSize, true);
    }, 500);
  }, [props.defaultSize]);

  React.useEffect(() => {
    // chart is immutable, it will redraw when options get changed. need to resize chart at each render.
    resetChartsHeight(chartHeight, chartSize);
  });

  return (
    <div className={classes.container}>
      <Tooltip title={`Switch to ${chartSizesLabels[nextSize]}`} placement="top">
        <Button
          color="inherit"
          onClick={() => {
            setChartSize(nextSize);
          }}
          variant={"outlined"}
          size="small"
        >
          <div className={classes.buttonWrap}>
            <ZoomOutMap style={{ transform: `scale(${chartSize})` }} />
            <span>{chartSizesLabels[chartSize].replace(" View", "")}</span>
          </div>
        </Button>
      </Tooltip>
    </div>
  );
};

export * from "./constants";
export * from "./Context";
export * from "./useChartSize";
export default ChartSize;
