import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { HideNotification } from "src/actions/notificationAction";

const useStyles = makeStyles(
  createStyles({
    snackbarContainer: {
      zIndex: 99998,
    },
  })
);

const SnackbarNotifier = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const notification = useSelector((store: IStore) => store.notification.notification);

  const hideNotification = () => dispatch(HideNotification());

  useEffect(
    function autoHide() {
      if (notification) {
        setTimeout(hideNotification, 10000);
      }
    },
    [notification]
  );

  const isHtml = (str: string) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  };

  const message = isHtml(notification) ? (
    <span id="notification-snackbar" dangerouslySetInnerHTML={{ __html: notification }} />
  ) : (
    <span id="notification-snackbar">{notification}</span>
  );

  return (
    <Snackbar
      message={message}
      open={!!notification}
      onClose={hideNotification}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      className={classes.snackbarContainer}
      data-cy="snack-message"
    />
  );
};

export default SnackbarNotifier;
