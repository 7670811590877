import { AxiosResponse } from "axios";
import { axiosInstance } from "./AxiosService";
import ApiPath from "../constants/ApiPath";
import * as Cookies from "es-cookie";
import * as moment from "moment";

const isSsoSuccessCookieKey = "isSsoSuccess";

export default class AuthService {
  public static getTokenKey = (): string => {
    const envArray = ["staging3", "staging", "app3", "app"];
    const hostname = window.location.hostname.split(".")[0];
    const key = envArray.find((item) => item === hostname);
    return key ? `${key}_token` : "token";
  };
  public static login(email: string, password: string, bodyParams: Record<string, string | number | boolean> = {}) {
    return axiosInstance
      .post(
        `${ApiPath.auth.loginLocal}`,
        { email: email, password: password, ...bodyParams },
        { validateStatus: (status) => status < 500 }
      )
      .then((res: AxiosResponse<LoginResponse>) => {
        if (res.status === 200) {
          if (window.location.hostname === "localhost" || bodyParams?.assureAuth) {
            this.setToken(res.data.token);
            this.setExpires(res.data.expires);
          }
          return Promise.resolve(res.data);
        } else {
          return Promise.reject(res.data);
        }
      });
  }

  public static collectDocumentCookies() {
    const token = Cookies.get("token");

    // Muly: For now, need to fix
    const expires = moment(new Date()).add(1, "d").toString(); // Cookies.get("expires");

    if (token) {
      this.setToken(token);
      this.setExpires(expires || "");
    }
  }

  public static async logout(platform: "testrtc" | "agent-assure") {
    try {
      if (AuthService.loggedIn()) {
        await axiosInstance.post(ApiPath.api.user.logout);
      }
    } catch (err) {
      // no need to do anything, error indicate user already logged out
    } finally {
      localStorage.removeItem("token");
      localStorage.removeItem("expires");
      localStorage.removeItem("Email");

      if (platform === "testrtc") {
        const tokenKey = this.getTokenKey();
        Cookies.remove(tokenKey, { domain: "testrtc.com" });
      } else {
        Cookies.remove("token", { domain: "spearline.com" });
      }
      // for local dev
      Cookies.remove("token");
    }
  }

  public static loggedIn(): boolean {
    const token = this.getToken();
    const expires = this.getExpires();
    if (!expires) {
      return !!token;
    }

    return !!token && !this.isTokenExpired(expires);
  }

  public static setExpires(expires: string) {
    localStorage.setItem("expires", expires);
  }

  public static getExpires(): number {
    const expires = localStorage.getItem("expires");
    const parsedExpiresDate = Date.parse(expires !== null ? expires : "");
    if (isNaN(parsedExpiresDate)) {
      return 0;
    }
    return parsedExpiresDate;
  }

  public static getToken(): string {
    const tokenKey = this.getTokenKey();
    const token = Cookies.get(tokenKey) || localStorage.getItem("token");
    return token !== null ? token : "";
  }

  public static setToken(token: string) {
    localStorage.setItem("token", token);
  }

  public static isTokenExpired(expires: number): boolean {
    try {
      return expires < Date.now();
    } catch (err) {
      return false;
    }
  }

  public static setRedirectUrl(url: string) {
    localStorage.setItem("redirectUrl", url);
  }

  public static getRedirectUrl(): string {
    const redirectUrl = localStorage.getItem("redirectUrl");
    return redirectUrl !== null ? redirectUrl : "";
  }

  public static getIsSsoSuccess() {
    return Cookies.get(isSsoSuccessCookieKey);
  }

  public static removeIsSsoSuccess(platform: "testrtc" | "agent-assure") {
    const domain = platform === "testrtc" ? "testrtc.com" : "spearline.com";
    Cookies.remove(isSsoSuccessCookieKey, { domain });
    // for local dev
    Cookies.remove(isSsoSuccessCookieKey);
  }
}
