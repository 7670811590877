import { Grid, Theme, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoIcon: {
      color: theme.palette.text.primary,
    },
  })
);

const LabelCell = ({ label, tooltip, docsLink }: { label: string; tooltip?: string; docsLink?: string }) => {
  const classes = useStyles();

  let infoIconJsx: JSX.Element | null = null;
  if (tooltip && docsLink) {
    infoIconJsx = (
      <Tooltip
        style={{ marginLeft: 5 }}
        placement={"right-start"}
        title={<Typography variant={"body1"}>{tooltip}</Typography>}
      >
        <a href={docsLink} target="_blank" rel="noreferrer">
          <InfoOutlined className={classes.infoIcon} />
        </a>
      </Tooltip>
    );
  } else if (tooltip) {
    infoIconJsx = (
      <Tooltip
        style={{ marginLeft: 5 }}
        placement={"right-start"}
        title={<Typography variant={"body1"}>{tooltip}</Typography>}
      >
        <InfoOutlined className={classes.infoIcon} />
      </Tooltip>
    );
  } else if (docsLink) {
    infoIconJsx = (
      <a href={docsLink} target="_blank" rel="noreferrer">
        <InfoOutlined className={classes.infoIcon} />
      </a>
    );
  }

  return (
    <Grid alignItems="center" container={true} direction="row">
      <p style={{ flex: 1 }}>{label}</p>
      {infoIconJsx}
    </Grid>
  );
};

export default LabelCell;
