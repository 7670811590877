export const VisibilityRules = {
  remove: ["roundTripTime", "timestamp", "remoteTimestamp", "dataChannelIdentifier"],
  hide: {
    "media-source-video": ["frames"],
    "inbound-rtp-audio": [
      "totalSamplesReceived",
      "totalSamplesDuration",
      "jitterBufferEmittedCount",
      "jitterBufferDelay",
      "headerBytesReceived",
      "jitterBufferTargetDelay",
      "jitterBufferMinimumDelay",
      "concealedSamples",
      "concealmentEvents",
      "insertedSamplesForDeceleration",
      "silentConcealedSamples",
      "removedSamplesForAcceleration",
      "totalAudioEnergy",
    ],
    "inbound-rtp-video": [
      "framesDecoded",
      "qpSum",
      "totalSquaredFrameDelay",
      "headerBytesReceived",
      "framesReceived",
      "jitterBufferTargetDelay",
      "jitterBufferMinimumDelay",
      "totalProcessingDelay",
      "totalInterFrameDelay",
      "pliCount",
      "totalDecodeTime",
      "keyFramesDecoded",
      "totalSquaredInterFrameDelay",
      "[totalDecodeTime/framesDecoded_in_ms]",
    ],
    "outbound-rtp-audio": [
      "hugeFramesSent",
      "retransmittedBytesSent",
      "retransmittedPacketsSent",
      "nackCount",
      "keyFramesEncoded",
      "pliCount",
      "qualityLimitationResolutionChanges",
    ],
    "outbound-rtp-video": [
      "hugeFramesSent",
      "retransmittedBytesSent",
      "retransmittedPacketsSent",
      "nackCount",
      "keyFramesEncoded",
      "pliCount",
      "qualityLimitationResolutionChanges",
    ],
    transport: ["selectedCandidatePairChanges"],
    track: [
      "totalSamplesReceived",
      "jitterBufferEmittedCount",
      "jitterBufferDelay",
      "concealedSamples",
      "silentConcealedSamples",
    ],
    "remote-inbound-rtp-audio": [
      "timestamp", // - remove from graphs altogether
      "roundTripTimeMeasurements",
      "roundTripTime", // - remove from graphs altogether
      "totalRoundTripTime",
    ],
    RTCRemoteOutboundRtpAudioStream: [
      "timestamp", // - remove from graphs altogether
      "roundTripTimeMeasurements",
      "roundTripTime", // - remove from graphs altogether
      "totalRoundTripTime",
    ],
    RTCRemoteOutboundRtpVideoStream: [
      "timestamp", // - remove from graphs altogether
      "roundTripTimeMeasurements",
      "roundTripTime", // - remove from graphs altogether
      "totalRoundTripTime",
    ],
    "data-channel": ["dataChannelIdentifier"],
  },
  mixed: {
    "candidate-pair": {
      visible: [
        "[bytesSent_in_bits/s]",
        "[bytesReceived_in_bits/s]",
        "availableOutgoingBitrate",
        "availableIncomingBitrate",
        "bytesSent",
        "bytesReceived",
        "requestsReceived",
        "requestsSent",
        "responsesReceived",
        "consentRequestsSent",
        "responsesSent",
        "currentRoundTripTime",
      ],
      disabled: [
        "bytesReceived",
        "bytesSent",
        "consentRequestsSent",
        "requestsSent",
        "responsesReceived",
        "requestsReceived",
        "currentRoundTripTime",
        "responsesSent",
      ],
    },
  },
};
