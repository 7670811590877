import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Field, Form } from "react-final-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Slide } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import TextFieldControl from "src/components/form-elements/TextFieldControl";
import FormValidators from "src/helpers/form-validators";
import { axiosInstance } from "src/services/AxiosService";
import ApiPath from "src/constants/ApiPath";
import { AuthRoutes } from "../../../constants/routes/auth-routes";
import { SUPPORT_DOMAIN } from "../../../constants/message.constants";

const useStyles = makeStyles(
  createStyles({
    root: {
      "& .MuiInputBase-input": {
        paddingTop: "24px",
      },
    },
  })
);

const ForgotPassword = (props: RouteComponentProps<{}>) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async (values: any): Promise<any> => {
    setIsLoading(true);

    try {
      await axiosInstance.get(`${ApiPath.api.requestPassword}/${values.email}`);
    } catch (err) {
    } finally {
      props.history.push(
        {
          pathname: AuthRoutes.SignIn,
        },
        { isPasswordRemind: true, emailRemind: values.email }
      );
      setIsLoading(false);
    }

    // return axiosInstance
    //   .get(`${ApiPath.api.requestPassword}/${values.email}`)
    //   .then(() => {
    //     this.props.history.push({
    //       pathname: LoginRoutes.SignIn,
    //       state: { isPasswordRemind: true, emailRemind: values.email },
    //     });
    //   })
    //   .catch((_err: IForgotFormError) => {
    //     return { email: "User with this email not found" };
    //   });
  };

  return (
    <Grid container={true} direction="row" justifyContent="center">
      <Slide direction="right" in={true} mountOnEnter={true} unmountOnExit={true}>
        <Grid item={true} xs={8} md={4}>
          <Typography variant={"h6"}>Forgot your password?</Typography>
          <br />
          <Typography variant="body2">
            Enter your email address to reset your password. You may need to check your spam folder or unblock emails
            from the {SUPPORT_DOMAIN} domain
          </Typography>
          <br />

          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} className={classes.root}>
                    <Field
                      component={TextFieldControl}
                      type="text"
                      name="email"
                      label="Email"
                      validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.isEmail)}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Button type="submit" variant="contained" color="secondary" disabled={isLoading}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Slide>
    </Grid>
  );
};

export default withRouter(ForgotPassword);
