import * as React from "react";
// import { FormControl, FormHelperText } from "@mui/material";
import { FieldRenderProps } from "react-final-form";
import { Theme } from "@mui/material/styles";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
// import Select from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

interface ISelectOption {
  value: string | number;
  label: string;
  condition?: boolean;
}

interface ISelectFieldControl {
  label?: string;
  emptyOption?: boolean;
  options?: Array<ISelectOption>;
  renderIcon?: (value: string | number) => React.ReactNode;
  disabled?: boolean;
  // tslint:disable-next-line: no-any
  onchange?: (e: any) => any;
}

class SelectFieldControl extends React.Component<
  FieldRenderProps & ISelectFieldControl & WithStyles<"root" | "label" | "inputWithIcon" | "input" | "icon">
> {
  public render(): JSX.Element {
    const {
      classes,
      input: { name, onChange, value, ...restInput },
      meta,
      label,
      emptyOption,
      options,
      renderIcon,
      disabled,
      onchange,
      ...rest
    } = this.props;

    return (
      <TextField
        classes={{
          root: classes.root,
        }}
        {...rest}
        label={label}
        InputLabelProps={{
          classes: {
            root: classes.label,
            outlined: classes.label,
          },
        }}
        InputProps={{
          classes: {
            input: renderIcon ? classes.inputWithIcon : classes.input,
          },
        }}
        inputProps={restInput as any}
        value={value}
        name={name}
        // tslint:disable-next-line: no-any
        onChange={(e: any) => {
          onChange(e);
          if (onchange) {
            onchange(e);
          }
        }}
        select={true}
        variant="outlined"
        helperText={(meta.error || meta.submitError) && meta.touched ? meta.error || meta.submitError : undefined}
        disabled={disabled}
      >
        {emptyOption && (
          <MenuItem value="">
            <em>{label}</em>
          </MenuItem>
        )}
        {options &&
          options.map((o, index) => (
            <MenuItem
              key={index}
              value={o.value}
              style={{
                backgroundColor: o.condition ? "rgb(70, 130, 195, 0.5)" : "unset",
                margin: 1,
              }}
            >
              {renderIcon && o.value && <div className={classes.icon}>{renderIcon(o.value)}</div>}
              {o.label}
            </MenuItem>
          ))}
      </TextField>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: 0,
      marginBottom: 0,
    },
    input: {
      paddingTop: 3,
      paddingBottom: 4,
      fontSize: theme.typography.pxToRem(13),
    },
    inputWithIcon: {
      display: "flex",
      alignItems: "center",
      paddingTop: 3,
      paddingBottom: 4,
      fontSize: theme.typography.pxToRem(13),
    },
    label: {
      transform: "translate(14px, 7.5px) scale(1)",
      fontSize: theme.typography.pxToRem(13),
      "&.Mui-focused, &.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: 8,
    },
  });

export default withStyles(styles)(SelectFieldControl);
