import { Button } from "@mui/material";
import { useState, useEffect } from "react";

interface NetworkBannerProps {
  onReload: () => void;
}

const NetworkBanner: React.FC<NetworkBannerProps> = ({ onReload }) => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (!isOffline) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: "#FDFDFD",
        color: "#D32F2F",
        padding: "10px",
        textAlign: "center",
        zIndex: 10000,
        gap: 8,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 500,
      }}
    >
      Network lost.{" "}
      <Button variant="contained" onClick={onReload} color="error">
        Reload page
      </Button>
    </div>
  );
};

export default NetworkBanner;
