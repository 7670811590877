import { combineReducers } from "redux";

import userAuthReducer from "./reducers/userAuthReducer";
import dictionaryReducer from "./reducers/dictionaryReducer";
import notificationReducer from "./reducers/notificationReducer";
import breadcrumbReducer from "./reducers/breadcrumbReducer";
import dataLoadingReducer from "./reducers/dataLoadingReducer";

const rootReducer = combineReducers({
  userAuth: userAuthReducer,
  dictionary: dictionaryReducer,
  notification: notificationReducer,
  breadcrumb: breadcrumbReducer,
  dataLoading: dataLoadingReducer,
});

export default rootReducer;
