import { FilterValue } from "../../../../components/Grid";

export enum RuleType {
  Critical = "Error",
  Fair= "Warning",
  Information = "Information",
}

enum AnalyzeRTCRuleCategory {
  Device = "Insights-Device",
  Signaling = "Insights-Signaling",
  Network = "Insights-Network",
  Media = "Insights-Media",
  AudioSpecific = "Insights-AudioSpecific",
  VideoSpecific = "Insights-VideoSpecific",
}

export interface AnalyzeRTCRule {
  id: number;
  enabled: boolean;
  category: AnalyzeRTCRuleCategory;
  severity: RuleType;
  message: string | object;
  link: string | null;
  threshold?: string | object;
}

enum QualityRTCRuleCategory {
  Miscellaneous = "Miscellaneous",
  Media = "Media",
  Network = "Network",
  Connectivity = "Connectivity",
  Throughput = "Throughput",
  Peripherals = "Peripherals",
}

export interface QualityRTCRule {
  id: number;
  enabled: boolean;
  category: QualityRTCRuleCategory;
  severity: RuleType;
  message: string | object;
  link: string | null;
  threshold?: string | object;
}

export enum InsightsProducts {
  qualityRTC = "qualityRTC",
  analyzeRTC = "analyzeRTC",
}

export type InsightConfigRule = QualityRTCRule | AnalyzeRTCRule;

type SelectOptions = {
  qualityRTC: FilterValue[];
  analyzeRTC: FilterValue[];
};

export const RuleCategoryOptions: SelectOptions = {
  qualityRTC: [
    { label: "Miscellaneous", value: "Miscellaneous" },
    { label: "Media", value: "Media" },
    { label: "Network", value: "Network" },
    { label: "Connectivity", value: "Connectivity" },
    { label: "Throughput", value: "Throughput" },
    { label: "Peripherals", value: "Peripherals" },
  ],
  analyzeRTC: [
    { value: "Insights-Device", label: "Device" },
    { value: "Insights-Signaling", label: "Signaling" },
    { value: "Insights-Network", label: "Network" },
    { value: "Insights-Media", label: "Media" },
    { value: "Insights-AudioSpecific", label: "Audio Specific" },
    { value: "Insights-VideoSpecific", label: "Video Specific" },
  ],
};

export const RuleSeverityOptions: FilterValue[] = [
  { label: "Error", value: "Error" },
  { label: "Warning", value: "Warning" },
  { label: "Information", value: "Information" },
];
