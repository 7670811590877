import ConfirmDialog from "src/components/Dialog/Confirm";

interface IDeleteConfirmationProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}
const DeleteConfirmation = ({ open, onConfirm, onClose }: IDeleteConfirmationProps) => {
  return (
    <ConfirmDialog
      open={open}
      title="Are you sure?"
      content="You will not be able to recover this preset!"
      handleConfirm={onConfirm}
      handleClose={onClose}
    />
  );
};

export default DeleteConfirmation;
