import * as React from "react";
import { useState } from "react";
import {
  Card,
  Typography,
  Toolbar,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  Checkbox,
} from "@mui/material";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import ConfirmDialog from "src/components/Dialog/Confirm";
import DatePickerControl from "src/components/DatePicker";
import * as moment from "moment";

const Actions = ({
  classes,
  toggleDeleteHistoryDialog,
  deleteHistoryDialogOpened,
  confirmDeleteHistory,
  exportCsv,
}: ProjectPropertiesViewProps & ProjectPropertiesState) => {
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);
  const [omitMonitors, setOmitMonitors] = useState(false);
  return (
    <Card className={classes.card} style={{ padding: 0 }}>
      <Toolbar>
        <Typography variant="subtitle1">Actions</Typography>
      </Toolbar>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: 220 }}>User history</TableCell>
            <TableCell>
              <Button variant="contained" onClick={toggleDeleteHistoryDialog}>
                {"Delete test and monitor run history"}
              </Button>
              <p>{"Will use account Test History value"}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Generate usage report of executions</TableCell>
            <TableCell>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <Typography variant="body2">Start date</Typography>
                  <DatePickerControl value={from} onChange={(e: any) => setFrom(e)} maxDate={to} />
                </Grid>
                <Grid item={true} xs={12}>
                  <Typography variant="body2">End date</Typography>
                  <DatePickerControl value={to} onChange={(e: any) => setTo(e)} minDate={from} />
                </Grid>
                {from && to && (
                  <React.Fragment>
                    <Grid item={true} xs={12} container={true} direction="row" alignItems="center">
                      <Typography variant="body2">Omit monitors</Typography>
                      <Checkbox
                        value={omitMonitors}
                        onChange={(_e: any, checked: boolean) => setOmitMonitors(checked)}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          exportCsv(moment(from).toISOString(), moment(to).toISOString(), omitMonitors);
                          setFrom(undefined);
                          setTo(undefined);
                          setOmitMonitors(false);
                        }}
                      >
                        {"Export to csv"}
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <ConfirmDialog
        open={deleteHistoryDialogOpened}
        title="Are you sure?"
        content="You will not be able to recover history!"
        handleConfirm={confirmDeleteHistory}
        handleClose={toggleDeleteHistoryDialog}
      />
    </Card>
  );
};

export default Actions;
