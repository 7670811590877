export const SharedRoutes = {
  root: "/shared",

  ProbeRtcView: "/shared/probertc-view",
  ProbeRtcViewId: "/shared/probertc-view/:objectId",

  ProbeRtcResults: "/shared/probertc-view/results",
  ProbeRtcResultsId: "/shared/probertc-view/results/:probeId/:objectId",

  QualityRTCInviteTests: "/shared/quality-rtc-invite-tests/:inviteId/:apiKey",
};
