import * as React from "react";

import ApiPath from "src/constants/ApiPath";
import { useAxios } from "src/services/AxiosService";
import { reportError } from "src/services/ErrorService";
import { getFilterViews } from "./api/filterViews";
import { WatchRTCFilterView } from "./types";
import { defaultFiltersData } from "./constants";
import { useUrlFilterData } from "./hooks/useUrlFilterData";
import { IFiltersData, IAppliedFiltersData, INotification } from "./Interfaces";

export interface IWatchRTCContext {
  isLoading: boolean;
  filtersData: IFiltersData;
  selectedFiltersData: IFiltersData;
  appliedFiltersData: IAppliedFiltersData;
  activeFilters: string[];
  notifications: INotification[];
  watchrtcNotifications: number | null;
  selectedFilterViewId: string;
  filterViews: WatchRTCFilterView[];
  setIsLoading: (flag: boolean) => void;
  setFiltersData: (data: IFiltersData) => void;
  setSelectedFiltersData: (data: IFiltersData) => void;
  setAppliedFiltersData: (data: IFiltersData) => void;
  setActiveFilters: (activeFilters: string[]) => void;
  setNotifications: (notifications: INotification[]) => void;
  setSelectedFilterViewId: React.Dispatch<React.SetStateAction<string>>;
  setFilterViews: React.Dispatch<React.SetStateAction<WatchRTCFilterView[]>>;
}

export const WatchRTCContext = React.createContext<IWatchRTCContext>({} as any);

export function WatchRTCProvider(props: any) {
  const { axios, cancelToken } = useAxios();

  const { getUrlFilterData } = useUrlFilterData();
  const [isLoading, setIsLoading] = React.useState(false);
  const [filtersData, setFiltersData] = React.useState<IFiltersData>(defaultFiltersData);
  const [selectedFiltersData, setSelectedFiltersData] = React.useState<IFiltersData>(defaultFiltersData);
  const [appliedFiltersData, _setAppliedFiltersData] = React.useState<IAppliedFiltersData>(defaultFiltersData as any);
  const [activeFilters, setActiveFilters] = React.useState<string[]>([]);
  const [notifications, _setNotifications] = React.useState<INotification[]>([]);
  const [watchrtcNotifications, setWatchrtcNotifications] = React.useState<number | null>(null);
  const [selectedFilterViewId, setSelectedFilterViewId] = React.useState<string>("");
  const [filterViews, setFilterViews] = React.useState<WatchRTCFilterView[]>([]);

  const setAppliedFiltersData = (data: IFiltersData) => {
    _setAppliedFiltersData(data as IAppliedFiltersData);
  };

  React.useEffect(() => {
    let intervalId: number;

    if (!props.isFramed) {
      fetchNotifications();
      intervalId = (setInterval(fetchNotifications, 60000) as unknown) as number;

      // on init check URL for filters and set as default value
      const urlFilterData = getUrlFilterData();
      if (urlFilterData) {
        setSelectedFiltersData({ ...appliedFiltersData, ...urlFilterData.filtersData });
        setAppliedFiltersData({ ...appliedFiltersData, ...urlFilterData.filtersData });
        setActiveFilters(urlFilterData.activeFilters);
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const fetchNotifications = async () => {
    try {
      const result = await axios.get<INotification[]>(`${ApiPath.api.watchRTCNotification}`, { cancelToken });
      setNotifications(result.data);
    } catch (err) {
      reportError("Couldn't fetch notifications count", err);
      setNotifications([]);
    }
  };
  const setNotifications = (notifications: INotification[]) => {
    setWatchrtcNotifications(notifications.filter((x) => !x.read).length);
    _setNotifications(notifications);
  };

  React.useEffect(() => {
    async function initFilterViews() {
      const { filterViews, error } = await getFilterViews();

      if (!error) {
        setFilterViews(filterViews);
      }
    }

    if (!props.isFramed) {
      initFilterViews();
    }
  }, []);

  return (
    <WatchRTCContext.Provider
      value={{
        isLoading,
        filtersData,
        selectedFiltersData,
        appliedFiltersData,
        activeFilters,
        notifications,
        watchrtcNotifications,
        selectedFilterViewId,
        filterViews,
        setIsLoading,
        setFiltersData,
        setSelectedFiltersData,
        setAppliedFiltersData,
        setActiveFilters,
        setNotifications,
        setSelectedFilterViewId,
        setFilterViews,
      }}
    >
      {props.children}
    </WatchRTCContext.Provider>
  );
}
