import * as React from "react";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { numberWithCommas } from "src/helpers/numbers";

import { Channel, IICECandidatesTable } from "./Interfaces";

interface IICECandidatesTableProps {
  table: IICECandidatesTable;
}
interface IICECandidatesTablesNewProps {
  data: IICECandidatesTable[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      padding: 0,
      minHeight: 24,

      breakInside: "avoid",
      breakAfter: "avoid",
    },
    contentContainer: {
      paddingTop: 10,
    },
    expansionPanel: {
      width: "100%",
      display: "block",

      breakInside: "avoid",
    },
    expandHead: {
      "& div": {
        justifyContent: "space-between",
      },
    },
    expandHeadFist: {
      display: "inline-flex",
      alignItems: "center",
    },
    expandHeadSecond: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    expandDetailHead: {
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "space-between",
      },

      "& p": {
        margin: 0,
      },
    },
    resultTableHead: {
      marginBottom: 5,
    },
    pairId: {
      overflow: "visible",
      textOverflow: "ellipsis",
    },
    center: {
      textAlign: "center",
    },
    xsRight: {
      [theme.breakpoints.down("md")]: {
        textAlign: "right",
      },
    },
    showMoreButton: {
      display: "block",
      margin: "16px auto 24px",
    },
  })
);

const maxChannelsToShow = 4;

const colors = {
  waiting: "rgb(241, 205, 43)",
  "in-progress": "rgb(221, 113, 39)",
  succeeded: "rgb(85, 149, 66)",
  failed: "rgb(162, 42, 33)",
};

const ExpandChannelDetails = (props: any) => {
  const channel: Channel = props.channel;
  const state = channel.state;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(state === "succeeded");

  channel.pairIdName = channel.pairIdName.replace("RTCIceCandidatePair_", "");
  channel.localIdName = channel.localIdName ? channel.localIdName : channel.pairIdName.replace("RTCIceCandidate_", "");
  channel.remoteIdName = channel.remoteIdName
    ? channel.remoteIdName
    : channel.pairIdName.replace("RTCIceCandidate_", "");

  const kBytesSent = numberWithCommas(Math.ceil(channel.bytesSent / 1000));
  const kBytesReceived = numberWithCommas(Math.ceil(channel.bytesReceived / 1000));
  const rtt = Math.trunc(channel.rtt * 1000) || null;
  const priority = channel?.priority?.toLocaleString("en-US");

  return (
    <Accordion
      style={{
        borderLeft: `7px solid ${colors[state]}`,
      }}
      className={classes.expansionPanel}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary className={classes.expandHead} expandIcon={<ExpandMoreIcon />}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} md={2} className={classes.pairId}>
            {channel.pairIdName}
          </Grid>
          <Grid item={true} xs={3} md={2} className={classes.center}>
            {channel.state}
          </Grid>
          <Hidden mdDown={true}>
            <Grid item={true} xs={3} className={classes.center}>
              {kBytesSent}/{kBytesReceived}
            </Grid>
          </Hidden>
          <Grid item={true} xs={2}>
            {channel.localType}/{channel.localRelayProtocol || channel.localProtocol}
          </Grid>
          <Grid item={true} xs={2}>
            {channel.remoteType}/{channel.remoteRelayProtocol || channel.remoteProtocol}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container={true} spacing={2} justifyContent="space-between">
          <Grid item={true} xs={12} md={4} className={classes.expandDetailHead}>
            <Typography className={classes.resultTableHead} variant="subtitle1">
              {"Local"}
            </Typography>
            <div className={classes.xsRight}>
              <p>{channel.localIdName}</p>
              <p>
                {channel.localIpAddress}:{channel.localPort}
              </p>
              <p>
                {channel.localType}/{channel.localRelayProtocol || channel.localProtocol}
              </p>
            </div>
          </Grid>
          <Grid item={true} xs={12} md={4} className={classes.expandDetailHead}>
            <Typography className={classes.resultTableHead} variant="subtitle1">
              {"Remote"}
            </Typography>
            <div className={classes.xsRight}>
              <p>{channel.remoteIdName}</p>
              <p>
                {channel.remoteIpAddress}:{channel.remotePort}
              </p>
              <p>
                {channel.remoteType}/{channel.remoteRelayProtocol || channel.remoteProtocol}
              </p>
            </div>
          </Grid>
          <Grid item={true} xs={12} md={4} className={classes.expandDetailHead}>
            <Typography className={classes.resultTableHead} variant="subtitle1">
              {"Stats"}
            </Typography>
            <div className={classes.xsRight}>
              <div>
                <p>Sent: {kBytesSent} kb</p>
                <p>Recv: {kBytesReceived} kb</p>
                {rtt && <p>RTT: {rtt} ms</p>}
                <p>Priority: {priority}</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const ICECandidatesTable = ({ table }: IICECandidatesTableProps) => {
  const classes = useStyles();

  const nonSuccessfulChannels = table.channels.filter(function isChannelNotSuccessful(channel) {
    return channel.state !== "succeeded";
  });

  const hasOnlyNonSuccessChannels = nonSuccessfulChannels.length === table.channels.length;
  const hasMixOfChannels = nonSuccessfulChannels.length > 0 && table.channels.length - nonSuccessfulChannels.length > 0;

  const isShowMoreButtonVisible = table.channels.length > maxChannelsToShow || hasMixOfChannels;
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleIsOpen = () => {
    setIsOpen((currentIsOpen) => !currentIsOpen);
  };

  const channelsJsx = table.channels
    .filter(function isChannelSuccessfulOrTableOpenOrOnlyNonSuccessChannels(channel) {
      return channel.state === "succeeded" || isOpen || hasOnlyNonSuccessChannels;
    })
    .filter(function limitNumberOfSucceededPairs(_channel, index) {
      return isOpen ? true : index < maxChannelsToShow;
    })
    .map((channel, i) => <ExpandChannelDetails key={i} channel={channel} />);

  return (
    <div>
      <Toolbar className={classes.toolbar}>
        <Typography variant="subtitle1">
          ICE Candidate Pairs: {table.name} {table.channels.length === 0 && ": Empty"}
        </Typography>
        <Typography variant="caption">State: {table.dtlsState}</Typography>
        <Typography variant="caption">Sent: {numberWithCommas(Math.ceil(table.bytesSent / 1000))} kb</Typography>
        <Typography variant="caption">
          Received: {numberWithCommas(Math.ceil(table.bytesReceived / 1000))} kb
        </Typography>
      </Toolbar>
      <Grid className={classes.contentContainer} container={true} spacing={0}>
        {channelsJsx}
      </Grid>
      {isShowMoreButtonVisible ? (
        <Button onClick={toggleIsOpen} variant="contained" className={classes.showMoreButton}>
          {isOpen ? "Show less" : `Show ${table.channels.length - channelsJsx.length} more`}
        </Button>
      ) : null}
    </div>
  );
};

const ICECandidatesTablesNew = ({ data }: IICECandidatesTablesNewProps) => {
  const classes = useStyles();

  return (
    <>
      <Divider className={classes.divider} />

      {data.map((table: IICECandidatesTable, _idx: number) => (
        <ICECandidatesTable table={table} key={_idx} />
      ))}
    </>
  );
};

export default ICECandidatesTablesNew;
