import * as React from "react";
import Grid from "@mui/material/Grid";
import { Theme, IconButton, Button } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import GOOGLE_IMAGE from "src/assets/socials/google.svg";
import FACEBOOK_IMAGE from "src/assets/socials/facebook.svg";
import LINKEDIN_IMAGE from "src/assets/socials/linkedin.svg";
import TWITTER_IMAGE from "src/assets/socials/twitter.svg";
import { RouteComponentProps } from "react-router";
import SendFeedbackModal from "./SendFeedbackModal";
import { axiosInstance } from "src/services/AxiosService";
import ApiPath from "src/constants/ApiPath";
import { AuthRoutes } from "../../../constants/routes/auth-routes";
import { reportError } from "src/services/ErrorService";

type StyledComponent = WithStyles<"fab" | "socialIcon">;

type Props = {
  reportId: string;
};

const SocialButtons = (props: Props & RouteComponentProps & StyledComponent) => {
  const { classes, reportId } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const data = {
    text: "WebRTC Internals file analyze by testRTC",
    media:
      "http://cdn.mcauto-images-production.sendgrid.net/ff65e9dbf25b926e/8d8dd997-a675-4042-bb50-040ef36a3901/936x268.png",
    url: window.location.origin + "/report/" + reportId,
    hashtags: "testrtc, webrtc",
    width: 500,
    height: 500,
  };

  const googlePlusShare = () => {
    window.open(
      "//plus.google.com/share?url=" + encodeURIComponent(data.url),
      "sharer",
      "toolbar=0,status=0,width=" + data.width + ",height=" + data.height
    );
  };
  const facebookShare = () => {
    window.open(
      "//www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(data.url),
      "sharer",
      "toolbar=0,status=0,width=" + data.width + ",height=" + data.height
    );
  };
  const linkedInShare = () => {
    window.open(
      "//www.linkedin.com/shareArticle?mini=true&url=" +
        encodeURIComponent(data.url) +
        "&title=" +
        encodeURI(data.text),
      "sharer",
      "toolbar=0,status=0,width=" + data.width + ",height=" + data.height
    );
  };
  const twitterShare = () => {
    let urlString = "//www.twitter.com/intent/tweet?";

    if (data.text) {
      urlString += "text=" + encodeURIComponent(data.text);
    }

    if (data.hashtags) {
      urlString += "&hashtags=" + encodeURI(data.hashtags);
    }

    // Default to the current page if a URL isn't specified
    urlString += "&url=" + encodeURIComponent(data.url);

    window.open(urlString, "sharer", "toolbar=0,status=0,width=" + data.width + ",height=" + data.height);
  };

  const subscribeToUpdates = () => {
    props.history.push(AuthRoutes.Subscribe);
  };

  const sendFeedback = async (values: any) => {
    try {
      const { feedback } = values;

      await axiosInstance.post(`${ApiPath.api.adminUtils.freeReportFeedback}/${props.reportId}`, {
        feedback,
      });
    } catch (err) {
      reportError("Couldn't send feedback", err);
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <Grid container={true} justifyContent="space-between" alignItems="center">
      <Grid item={true} xs={"auto"}>
        {false && (
          <>
            <IconButton color="primary" aria-label="add" className={classes.fab} onClick={googlePlusShare} size="large">
              <img draggable={false} className={classes.socialIcon} src={GOOGLE_IMAGE} alt="google" />
            </IconButton>
            <IconButton color="primary" aria-label="add" className={classes.fab} onClick={facebookShare} size="large">
              <img draggable={false} className={classes.socialIcon} src={FACEBOOK_IMAGE} alt="facebook" />
            </IconButton>
            <IconButton color="primary" aria-label="add" className={classes.fab} onClick={linkedInShare} size="large">
              <img draggable={false} className={classes.socialIcon} src={LINKEDIN_IMAGE} alt="linkedin" />
            </IconButton>
            <IconButton color="primary" aria-label="add" className={classes.fab} onClick={twitterShare} size="large">
              <img draggable={false} className={classes.socialIcon} src={TWITTER_IMAGE} alt="twitter" />
            </IconButton>
          </>
        )}
      </Grid>
      <Grid item={true} xs={12}>
        <Grid container={true} justifyContent="flex-end">
          <Button color="primary" aria-label="add" onClick={subscribeToUpdates}>
            {"Subscribe to updates"}
          </Button>
          <Button color="secondary" aria-label="add" variant="contained" onClick={() => setIsOpen(true)}>
            {"Send us feedback"}
          </Button>
        </Grid>
      </Grid>
      <SendFeedbackModal isOpen={isOpen} onClose={() => setIsOpen(false)} onSubmit={sendFeedback} />
    </Grid>
  );
};

const styles = (_theme: Theme) =>
  createStyles({
    fab: {
      "& img": {
        height: 30,
        width: 30,
      },
    },
  });

const decorate = withStyles(styles);

export default decorate(SocialButtons);
