import { EventHandler, MouseEvent, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Avatar, Chip, IconButton, Theme, Tooltip, alpha, useTheme } from "@mui/material";
import {
  AddOutlined as AddOutlinedIcon,
  BookmarkBorderOutlined as BookmarkBorderOutlinedIcon,
  ContentPasteSearchOutlined as ContentPasteSearchOutlinedIcon,
  HorizontalRuleOutlined as HorizontalRuleOutlinedIcon,
} from "@mui/icons-material";
import cn from "classnames";
import { capitalize } from "lodash";

import { ReactComponent as StatisticsIcon } from "src/assets/images/icons/svg/statistics.svg";
import NavigationButton from "../NavigationButton";
import Section, { SectionConfig } from "./components/Section";
import Notes, { NotesProps } from "./components/Notes";
import CustomKeys, { CustomKeysProps } from "./components/CustomKeys";
import AlertArea, { AlertAreaProps } from "./components/AlertArea";
import StatusIcon from "../Test/StatusIcon";
import { Statuses } from "src/constants/TestStatus";

export type OverviewConfig = [SectionConfig, SectionConfig, SectionConfig] | [SectionConfig, SectionConfig];

interface OverviewPanelBaseProps {
  data: OverviewConfig;
  titleOnClick?: EventHandler<MouseEvent<HTMLElement>>;
  collapseStorageKey?: string;
  status?: TestStatus;
  statusId?: string;
  onStatusClick?: React.MouseEventHandler<HTMLDivElement>;
  warningsCount?: number;
  errorsCount?: number;
  isBookmarked?: boolean;
  toggleBookmark?: () => void;
  traceLink?: string;
  advancedAnalyticsLink?: string;
  otherContentJsx?: JSX.Element | null;
}

type OverviewPanelProps = OverviewPanelBaseProps & NotesProps & AlertAreaProps & Partial<CustomKeysProps>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      margin: "16px auto 0",
      border: "1px solid #DEDEDE",
      borderRadius: "4px",
      background: "#fff",

      breakInside: "avoid",
    },
    header: { padding: "8px 16px 8px 8px", display: "flex", alignItems: "center", rowGap: 4, flexWrap: "wrap" },
    statusBar: {
      flex: 1,
      display: "flex",
      columnGap: 16,
      alignItems: "center",
    },
    title: {
      margin: "0 0 0 8px",

      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 700,
      letterSpacing: "0.15px",
    },
    chip: { height: 26 },
    chipErrors: { borderColor: alpha(theme.palette.error.main, 0.5), color: theme.palette.error.main },
    chipWarnings: { borderColor: alpha(theme.palette.warning.main, 0.5), color: theme.palette.warning.main },
    chipLabel: { padding: "0 6px" },
    chipAvatar: {
      "&&&": {
        width: 20,
        height: 20,
        marginLeft: 4,
        marginRight: 0,

        fontSize: 12,
        color: "#FFFFFF",
      },
    },
    chipAvatarErrors: {
      "&&&": {
        backgroundColor: theme.palette.error.main,
      },
    },
    chipAvatarWarnings: {
      "&&&": {
        backgroundColor: theme.palette.warning.main,
      },
    },
    actionsContainer: {
      padding: "4px 0 4px 8px",
      display: "flex",
      columnGap: 24,
      alignItems: "center",
      flexWrap: "wrap",
    },
    bookmarkButton: { margin: "-4px -8px" },
    bookmarkIcon: { width: 24, height: "auto" },
    bookmarkIconBookmarked: { color: theme.palette.primary.main },
    traceIcon: {
      width: 16,
      height: 16,
    },
    advancedAnalyticsIcon: {
      width: 12,
      height: 12,

      ".Mui-disabled & path": {
        stroke: theme.palette.action.disabled,
      },
    },
    collapseIcon: {},
    expandIcon: {},

    contentContainer: { padding: "0 16px 24px" },
    sectionsContainer: { margin: "-8px 0 0", display: "flex", columnGap: 16, flexWrap: "wrap" },
  })
);

const OverviewPanel = ({
  data,
  titleOnClick,
  collapseStorageKey,
  status,
  statusId,
  onStatusClick,
  warningsCount,
  errorsCount,
  notes,
  saveNotes,
  isBookmarked,
  toggleBookmark,
  traceLink,
  advancedAnalyticsLink,
  error,
  errorLevel,
  additionalInfo,
  customKeysTitle,
  customKeysTitleTooltip,
  customKeys,
  tablePageLink,
  otherContentJsx,
}: OverviewPanelProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const [isCollapsed, setIsCollapsed] = useState(
    collapseStorageKey ? Boolean(sessionStorage.getItem(collapseStorageKey)) : false
  );
  const toggleIsCollapsed = () => {
    const newIsCollapsed = !isCollapsed;

    if (collapseStorageKey) {
      if (newIsCollapsed) {
        sessionStorage.setItem(collapseStorageKey, "true");
      } else {
        sessionStorage.removeItem(collapseStorageKey);
      }
    }

    setIsCollapsed(newIsCollapsed);
  };

  let statusBorderColor: string | undefined;
  let statusTextColor: string | undefined;

  const shouldDisplayStatus = status && Object.values(Statuses).includes(status);

  if (shouldDisplayStatus) {
    const statusBorderColorMap: Record<TestStatus, string> = {
      [Statuses.warnings]: alpha(theme.palette.warning.main, 0.5),
      [Statuses.error]: alpha(theme.palette.error.main, 0.5),
      [Statuses.failure]: alpha(theme.palette.error.main, 0.5),
      [Statuses.timeout]: alpha("#547189", 0.5),
      [Statuses.finished]: alpha("#547189", 0.5),
      [Statuses.completed]: alpha(theme.palette.success.main, 0.5),
      [Statuses.serviceFailure]: alpha("#547189", 0.5),
      [Statuses.terminated]: alpha("#547189", 0.5),
      [Statuses.started]: alpha("#547189", 0.5),
      [Statuses.retry]: alpha("#547189", 0.5),
      [Statuses.dismissed]: alpha("#547189", 0.5),
    };
    const statusTextColorMap: Record<TestStatus, string> = {
      [Statuses.warnings]: theme.palette.warning.main,
      [Statuses.error]: theme.palette.error.main,
      [Statuses.failure]: theme.palette.error.main,
      [Statuses.timeout]: "#547189",
      [Statuses.finished]: "#547189",
      [Statuses.completed]: theme.palette.success.main,
      [Statuses.serviceFailure]: "#547189",
      [Statuses.terminated]: "#547189",
      [Statuses.started]: "#547189",
      [Statuses.retry]: "#547189",
      [Statuses.dismissed]: "#547189",
    };

    statusBorderColor = statusBorderColorMap[status];
    statusTextColor = statusTextColorMap[status];
  }

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <IconButton onClick={toggleIsCollapsed}>
          {!isCollapsed ? (
            <HorizontalRuleOutlinedIcon className={classes.collapseIcon} />
          ) : (
            <AddOutlinedIcon className={classes.expandIcon} />
          )}
        </IconButton>

        <div className={classes.statusBar}>
          <h2 className={classes.title} onClick={titleOnClick}>
            Overview
          </h2>

          {shouldDisplayStatus ? (
            <Tooltip title="Test completion status">
              <Chip
                id={statusId}
                icon={<StatusIcon status={status} style={{ width: 20, height: 20, marginLeft: 4 }} />}
                label={capitalize(status?.replace("-", " "))}
                onClick={onStatusClick}
                clickable={Boolean(onStatusClick)}
                size="small"
                variant="outlined"
                classes={{ label: classes.chipLabel }}
                className={classes.chip}
                sx={{ borderColor: statusBorderColor, color: statusTextColor }}
              />
            </Tooltip>
          ) : null}

          {!shouldDisplayStatus && errorsCount ? (
            <Chip
              avatar={<Avatar className={cn(classes.chipAvatar, classes.chipAvatarErrors)}>{errorsCount}</Avatar>}
              label={errorsCount === 1 ? "Error" : "Errors"}
              size="small"
              variant="outlined"
              classes={{ label: classes.chipLabel }}
              className={cn(classes.chip, classes.chipErrors)}
            />
          ) : null}
          {!shouldDisplayStatus && warningsCount ? (
            <Chip
              avatar={<Avatar className={cn(classes.chipAvatar, classes.chipAvatarWarnings)}>{warningsCount}</Avatar>}
              label={warningsCount === 1 ? "Warning" : "Warnings"}
              size="small"
              variant="outlined"
              classes={{ label: classes.chipLabel }}
              className={cn(classes.chip, classes.chipWarnings)}
            />
          ) : null}
        </div>

        <div className={classes.actionsContainer}>
          <Notes notes={notes} saveNotes={saveNotes} />
          {toggleBookmark ? (
            <Tooltip title="Bookmark test (appears as marked on history list)">
              <IconButton onClick={toggleBookmark} className={classes.bookmarkButton}>
                {!isBookmarked ? (
                  <BookmarkBorderOutlinedIcon className={classes.bookmarkIcon} />
                ) : (
                  <BookmarkBorderOutlinedIcon className={cn(classes.bookmarkIcon, classes.bookmarkIconBookmarked)} />
                )}
              </IconButton>
            </Tooltip>
          ) : null}
          <NavigationButton
            text="Trace"
            link={traceLink}
            iconJsx={<ContentPasteSearchOutlinedIcon className={classes.traceIcon} />}
          />
          <NavigationButton
            text="Internals"
            link={advancedAnalyticsLink}
            iconJsx={<StatisticsIcon className={classes.advancedAnalyticsIcon} />}
          />
        </div>
      </header>

      <div style={{ display: !isCollapsed ? "block" : "none" }} className={classes.contentContainer}>
        <AlertArea notes={notes} error={error} errorLevel={errorLevel} additionalInfo={additionalInfo} />

        <div className={classes.sectionsContainer}>
          {data.map((sectionData) => (
            <Section {...sectionData} key={sectionData.title} />
          ))}
        </div>

        <CustomKeys
          customKeysTitle={customKeysTitle}
          customKeysTitleTooltip={customKeysTitleTooltip}
          customKeys={customKeys}
          tablePageLink={tablePageLink}
        />

        {otherContentJsx}
      </div>
    </div>
  );
};

export default OverviewPanel;
