import { HIDE_NOTIFICATION, SET_NOTIFICATION } from "../constants/ActionTypes";

export const HideNotification = () => {
  return {
    type: HIDE_NOTIFICATION,
  };
};

export const SetNotification = (message: string) => {
  return {
    type: SET_NOTIFICATION,
    payload: message,
  };
};
