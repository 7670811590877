import { Typography, Button } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import GOOGLE_IMAGE from "src/assets/socials/google.svg";

const useStyles = makeStyles(
  createStyles({
    socialButtonRoot: {
      minWidth: "50px",
      minHeight: "40px",
      borderRadius: "5px",
      textTransform: "none",
      backgroundColor: "white",
      boxShadow: "1px 1px 3px rgb(0 0 0 / 30%)",
    },
    socialButtonLabel: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    socialIcon: {
      width: "25px",
      marginRight: 15,
    },
  })
);

const LoginSocialButtons = () => {
  const classes = useStyles();

  const loginOAuth = (provider: string) => {
    window.location.href = `${process.env.REACT_APP_API_PATH}/auth/${provider}`;
  };

  return (
    <Button
      color="inherit"
      onClick={() => loginOAuth("google")}
      classes={{
        root: classes.socialButtonRoot,
      }}
    >
      <img draggable={false} className={classes.socialIcon} src={GOOGLE_IMAGE} alt="google" />
      <Typography variant="body2">Continue with Google</Typography>
    </Button>
  );
};

export default LoginSocialButtons;
