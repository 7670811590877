// import { useState } from "react";
import { Card, Typography, Toolbar, Divider, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import CheckboxControl from "src/components/form-elements/CheckboxControl";
import FormValidators from "src/helpers/form-validators";

type ScriptingProps = {
  values: any;
};

const Scripting = ({
  classes,
}: // values,
// generateApiKey,
ScriptingProps & ProjectPropertiesViewProps & ProjectPropertiesState) => {
  return (
    <Card className={classes.card} style={{ padding: 0 }}>
      <Toolbar>
        <Typography variant="subtitle1">Scripting</Typography>
      </Toolbar>
      <Divider />
      <Table
        sx={{
          width: "100%",
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell style={{ width: 220 }}>Media Storage</TableCell>
            <TableCell>
              <Field component={CheckboxControl} type="checkbox" name="enableMediaStorage" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Machine-Probes</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="text" name="machineAgentGroups" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Enable Manual SDK</TableCell>
            <TableCell>
              <Field component={CheckboxControl} type="checkbox" name="allowSDK" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Enable Manual DA</TableCell>
            <TableCell>
              <Field component={CheckboxControl} type="checkbox" name="allowManualDA" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Screenshot limit</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="screenshotLimit" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>User Configuration (JSON)</TableCell>
            <TableCell
              sx={{
                minWidth: "100%",
              }}
            >
              <Field
                component={TextFieldControl}
                type="text"
                name="userConfig"
                multiline={true}
                rows="5"
                resizeY={true}
                validate={FormValidators.composeValidators(FormValidators.isValidJson)}
              />
            </TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>API Key</TableCell>
            <TableCell>
              {values.apiKey}
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={() => generateApiKey(values)}
              >
                regenerate
              </Button>
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </Card>
  );
};

export default Scripting;
