import * as classnames from "classnames";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import List from "@mui/material/List";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Badge from "@mui/material/Badge";

import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";

import NavItemComponent from "./NavItemComponent";
import { Theme } from "@mui/material";
import { withRouter } from "react-router-dom";

const NavItem = (props: any) => {
  const {
    name,
    link,
    Icon,
    disabled,
    className,
    items = [],
    child = [],
    isNested,
    hidden = false,
    badgeValue,
    onToggleClick,
    open,
  } = props;

  const classes = useStyles();
  const hasChildren = items?.length > 0;

  const itemsAll = getItemsAll(items);

  const childActive = hasShadowChildActive(itemsAll, child, isNested);
  const isActive =
    itemsAll.filter((item: any) => `${item.link}` === window.location.pathname).length > 0 || childActive;

  const hasChildrenAndIsActive = hasChildren && isActive;

  function handleClick() {
    onToggleClick && onToggleClick(name);
  }

  const ListItemIconInner = (!!Icon && <Icon />) || "";

  const ExpandIcon = open ? (
    <IconExpandLess className={classes.iconToggle} />
  ) : (
    <IconExpandMore className={classes.iconToggle} />
  );

  const ListItemRoot = (
    <NavItemComponent
      link={link}
      className={classnames.default(classes.navItem, isActive && "active", className)}
      style={{
        paddingLeft: `${!ListItemIconInner ? 64 : 8}px`,
      }}
      onClick={handleClick}
    >
      {!!ListItemIconInner && <ListItemIcon className={classes.navItemIcon}>{ListItemIconInner}</ListItemIcon>}
      {badgeValue > 0 ? (
        <Badge badgeContent={badgeValue} classes={{ badge: classes.badge }} color="error" max={9}>
          <ListItemText primary={name} disableTypography={true} />
        </Badge>
      ) : (
        <ListItemText primary={name} disableTypography={true} />
      )}
      {hasChildren && ExpandIcon}
    </NavItemComponent>
  );

  const ListItemChildren = hasChildren ? (
    <div className={classes.navItemChildren}>
      <Collapse in={open} timeout="auto" unmountOnExit={true}>
        <List component="div" disablePadding={true}>
          {items.map((item: any) => (
            <NavItem {...item} isNested={true} key={item.name || item.link} />
          ))}
        </List>
      </Collapse>
    </div>
  ) : null;

  if (hidden) {
    return null;
  }

  return (
    <div
      className={classnames.default(
        hasChildrenAndIsActive && classes.navItemWrapperActive,
        disabled && classes.navItemWrapperDisabled
      )}
    >
      {ListItemRoot}
      {ListItemChildren}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navItemWrapper: {
      position: "relative",
    },
    navItemWrapperActive: {
      // background: "rgba(0, 0, 0, 0.08)",
      // color: theme.palette.secondary.main,
    },
    navItemWrapperDisabled: {
      opacity: 0.5,
      pointerEvents: "none",
    },
    navItem: {
      position: "relative",
      transition: "all .23s ease",
      color: "#ADB0B7",
      fontSize: 13,
      textDecoration: "none",
      borderLeft: "4px solid transparent",
      "&.active:not(.open)": {
        // borderLeft: `4px solid ${theme.palette.primary.light}`,
        backgroundColor: "#304c5d",
        color: "#53b6ef",
        "& .MuiListItemIcon-root .injected-svg": {
          fill: "#53b6ef",
          color: "#53b6ef",
        },
      },
      "&.open": {
        color: theme.palette.secondary.main,
        backgroundColor: "#304c5d",
        "& .MuiListItemIcon-root": {
          color: theme.palette.secondary.main,
        },
      },
    },
    navItemChildren: {
      transition: "background .23s ease",
      // position: "absolute",
      "& .active": {
        backgroundColor: "#2b3f4b !important",
      },
    },
    navItemChildrenActive: {
      background: "rgba(0, 0, 0, 0.1)",
    },
    navItemCollapsed: {
      whiteSpace: "nowrap",
      flexWrap: "nowrap",
      // width: theme.sidebar.widthCollapsed,
      "& $iconToggle": {
        position: "absolute",
        // bottom: -1,
        fontSize: 14,
        top: "50%",
        marginTop: "-0.5em",
        transform: "rotate(90deg)",
        right: "3px",
      },
      "&.active": {
        background: "rgba(0, 0, 0, 0.08)",
      },
    },

    navItemIcon: {
      "& svg": {
        height: 25,
        width: 25,
        fill: "#ADB0B7",
        margin: 0,
        "& g": {
          "& *": {
            stroke: "#ADB0B7",
            strokeWidth: 1.2,
          },
        },
      },
    },
    iconToggle: {},
    iconToggleInactive: {
      opacity: 0.35,
    },
    iconSpacer: {
      fontSize: 13,
      marginLeft: 6,
    },
    badge: {
      background: "#a22a21",
      left: 70,
      top: 13,
    },
  })
);

// ----------------------
const hasShadowChildActive = (items = [], child = [], nested = false) => {
  let childActive = false;
  if (!nested) {
    items.forEach((item: any) => {
      if (item.child) {
        item.child.forEach((item: string) => {
          if (window.location.pathname.includes(item)) {
            childActive = true;
          }
        });
      }
    });
    child.forEach((item: string) => {
      if (window.location.pathname.includes(item)) {
        childActive = true;
      }
    });
  } else {
    child.forEach((item: string) => {
      if (window.location.pathname.includes(item)) {
        childActive = true;
      }
    });
  }

  return childActive;
};

// Flattened array of all children
const getItemsAll = (items: any) => {
  return items.reduce((allItems: any, item: any) => {
    // let res = allItems.concat([item])
    if (item.items && item.items.length) {
      return allItems.concat([item], getItemsAll(item.items));
    } else {
      return allItems.concat([item]);
    }
  }, []);
};

export default withRouter(NavItem);
