export const FrameRoutes = {
  root: "/frame",

  WatchRTCRoom: "/frame/watchrtc/history/room",
  WatchRTCRoomId: "/frame/watchrtc/history/room/:objectId?",
  WatchRTCPeer: "/frame/watchrtc/history/peer",
  WatchRTCPeerId: "/frame/watchrtc/history/peer/:objectId?/:isLive?",
  WatchRTCPeerTrace: "/frame/watchrtc/history/peer/:objectId?/trace",

  WebrtcInternalsWatchRtc: "/frame/logs/webrtc-internals-watch-rtc",
  WebrtcInternalsWatchRtcId: "/frame/logs/webrtc-internals-watch-rtc/:testIterationId?/:fileName/:dumpUrl",

  Forbidden: "/frame/403",
  NotFound: "/frame/404",
  WrongLink: "/frame/400",
  ServerError: "/frame/500",
};
