/* tslint:disable */

export function toKeyValue(obj: any) {
  const paths = recursivelyGetPathToValue(obj);
  const keyValue: any = [];

  for (let i = 0; i < paths.length; i++) {
    const str = paths[i];
    const index = str.lastIndexOf(".");

    const key = str.substr(0, index);

    // replace {*} with .
    // see Connection.tsx method makeJson
    const value = str.substr(index + 1).replace(/\{\*\}/g, ".");

    keyValue.push({ key, value });
  }

  return keyValue;
}

function recursivelyGetPathToValue(obj: any) {
  if (isValue(obj)) {
    return obj;
  }

  const isDecimal = (prop: any) => {
    if (isNumber(prop) && prop.toString().indexOf(".") == 1) {
      return prop.toString().replace(".", ",").substring(0, 4);
    }
    return prop;
  };

  const result: any = [];
  Object.keys(obj).map((key) => {
    const prop = obj[key];

    if (isValue(prop)) {
      const res = `${key}.${isDecimal(prop)}`;
      result.push(res);
      // eslint-disable-next-line
      return;
    }

    if (isObject(prop)) {
      const res = recursivelyGetPathToValue(prop);
      processNestedResult(result, res, key);
      // eslint-disable-next-line
      return;
    }
    if (isArray(prop)) {
      for (let i = 0; i < prop.length; i++) {
        const res = recursivelyGetPathToValue(prop[i]);
        processNestedResult(result, res, key);
      }
      // eslint-disable-next-line
      return;
    }
    // eslint-disable-next-line
    return;
  });
  return result;
}

function processNestedResult(commonResultRef: any, res: any, key: any) {
  if (isArray(res)) {
    for (let i = 0; i < res.length; i++) {
      commonResultRef.push(`${key}.${res[i]}`);
    }
  } else {
    commonResultRef.push(`${key}.${res}`);
  }
}

function isObject(value: any) {
  return value && typeof value === "object" && value.constructor === Object;
}
function isString(value: any) {
  return typeof value === "string" || value instanceof String;
}
function isNumber(value: any) {
  return typeof value === "number" && isFinite(value);
}
function isArray(value: any) {
  return Array.isArray(value);
}
function isBoolean(value: any) {
  return typeof value === "boolean";
}

function isValue(value: any) {
  return isString(value) || isNumber(value) || isBoolean(value);
}

export function getDepth(obj: any) {
  if (!obj || obj.length === 0 || typeof obj !== "object") {
    return 0;
  }
  const keys = Object.keys(obj);
  let depth = 0;
  keys.forEach((key) => {
    /* w   w w  .  d e    m  o 2   s  .c   o  m */
    const tmpDepth = getDepth(obj[key]);
    if (tmpDepth > depth) {
      depth = tmpDepth;
    }
  });

  return depth + 1;
}
