/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";

import { IPreset, Preset } from ".";

export interface IPresetsContext {
  preset?: IPreset;
  presets: IPreset[];
  defaultPresetConfig: Preset;
  setPreset: (value: IPreset | undefined) => void;
  setPresets: (value: IPreset[]) => void;
  setDefaultPresetConfig: (value: Preset) => void;
}

export const PresetsContext = React.createContext<IPresetsContext>(null!);

export function PresetsProvider(props: any) {
  const [presets, setPresets] = React.useState<IPreset[]>([]);
  const [preset, setPreset] = React.useState<IPreset>();
  const [defaultPresetConfig, setDefaultPresetConfig] = React.useState<Preset>(null!);

  return (
    <PresetsContext.Provider
      value={{
        preset,
        presets,
        defaultPresetConfig,
        setPreset,
        setPresets,
        setDefaultPresetConfig,
      }}
    >
      {props.children}
    </PresetsContext.Provider>
  );
}
