import * as React from "react";
import { Table, TableBody, TableRow, TableCell, Theme } from "@mui/material";

import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";

type UserMediaTableCellProps = {
  data: Array<any>;
  cellsStyle?: any;
};

type StyledComponent = WithStyles<"cell" | "row" | "standard" | "table">;

export class KeyValueTableCell extends React.Component<UserMediaTableCellProps & StyledComponent> {
  render() {
    const { classes, data, cellsStyle } = this.props;
    return (
      <Table className={classes.table}>
        <TableBody>
          {data.map((item: any, index: number) => {
            return (
              <TableRow key={index} className={classes.row}>
                <TableCell className={classes.cell} style={cellsStyle ? cellsStyle[0] : {}}>
                  {item.key}
                </TableCell>
                <TableCell className={classes.cell} style={cellsStyle ? cellsStyle[1] : {}}>
                  {item.value}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    table: {
      width: "auto",
    },
    cell: {
      borderBottom: "none",
      borderRight: "none !important",
      padding: "4px",
      minWidth: 360,
      [theme.breakpoints.down("lg")]: {
        minWidth: 260,
      },
      [theme.breakpoints.down("md")]: {
        minWidth: 200,
      },
      "&:last-child": {
        width: "100%",
      },
    },
    row: {
      height: "auto",
      borderBottom: "1px solid white",
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
      "&:hover": {
        backgroundColor: "#d3d3d3",
      },
    },
  });

const decorate = withStyles(styles);

export default decorate(KeyValueTableCell);
