import { Tooltip, Box, Stack, Typography, Divider, useTheme } from "@mui/material";
import { ElementType, ReactNode } from "react";
import { IRibbonThreshold, ribbonsThresholdHelper } from "src/helpers/thresholdHelper";
import { ProjectsContext } from "src/containers/AppController";
import { useContext } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ORANGE_COLOR = "#ff9c00";
const RED_COLOR = "#de1c1c";
const GREEN_COLOR = "#00a15e";

export interface GraphProps {
  type?:
    | "score"
    | "mos"
    | "user"
    | "pl_inc"
    | "pl_out"
    | "pl_audio_inc"
    | "pl_audio_out"
    | "pl_video_inc"
    | "pl_video_out"
    | "rtt_inc"
    | "rtt_out"
    | "rtt_audio_inc"
    | "rtt_audio_out"
    | "rtt_video_inc"
    | "rtt_video_out"
    | "jitter"
    | "jitter_audio_inc"
    | "jitter_audio_out"
    | "jitter_video_inc"
    | "jitter_video_out"
    | "browserCpu"
    | "browserMemory"
    | "default";
  value?: number | string;
  thresholds?: IRibbonThreshold;
  tickType?: number[];
}

export interface ICustomToolTip extends GraphProps {
  children?: ReactNode;
  wrapperElement?: ElementType;
  wrapperClassName?: string;
  description: string;
  position?:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
  graph?: boolean;
  id?: string;
}

const reverseTypes = [
  "pl_inc",
  "pl_out",
  "pl_audio_inc",
  "pl_audio_out",
  "pl_video_inc",
  "pl_video_out",
  "rtt_inc",
  "rtt_out",
  "rtt_audio_inc",
  "rtt_audio_out",
  "rtt_video_inc",
  "rtt_video_out",
  "jitter",
  "jitter_audio_inc",
  "jitter_audio_out",
  "jitter_video_inc",
  "jitter_video_out",
];
const percTypes = ["pl_inc", "pl_out", "pl_audio_inc", "pl_audio_out", "pl_video_inc", "pl_video_out"];

const noGraphValues = ["-", "N/A", 0];

const twoDigitTypes = ["browserCpu", "browserMemory"];

const showGraph = (value: any) => {
  if (noGraphValues.includes(value as string | number)) {
    return true;
  }
  return false;
};

const indicatorType = (type: any) => {
  if (type !== undefined && percTypes.includes(type)) {
    return "%";
  }
  if (type !== undefined && twoDigitTypes.includes(type)) {
    return type === "browserCpu" ? "%" : "MB";
  }
  return "";
};

const TooltipGraph = ({ value, type, tickType }: GraphProps) => {
  const theme = useTheme();

  if (!tickType) {
    return null;
  }

  const getColor = (value: number) => {
    if (tickType.length <= 3) {
      return value <= tickType[1] ? GREEN_COLOR : RED_COLOR;
    }
    if (value <= tickType[1]) {
      if (type !== undefined && reverseTypes.includes(type)) {
        return GREEN_COLOR;
      }
      return RED_COLOR;
    } else if (value > tickType[2]) {
      if (type !== undefined && reverseTypes.includes(type)) {
        return RED_COLOR;
      }
      return GREEN_COLOR;
    }
    return ORANGE_COLOR;
  };

  const defaultSettings = [
    {
      type: "bar",
      name: "green",
      color: GREEN_COLOR,
      grouping: false,
      data: [Number(value)],
      dataLabels: {
        enabled: true,
        y: -16.8,
        useHTML: true,
        formatter: function () {
          return `<div style="text-align: center; display:flex; flex-direction: column; align-items: center; justify-content: end;">
              <div style="color: ${getColor(Number(value))}; font-size:11px; line-height:12px;">${Number(
            value
          )}${indicatorType(type)}</div>
              <div style="height: 10px; width: 1.5px; background: ${getColor(Number(value))}; margin-top: 2px;"></div>
            </div>`;
        },
        align: "center",
        verticalAlign: "middle",
      },
    },
    {
      type: "bar",
      pointPlacement: 0,
      color: GREEN_COLOR,
      grouping: false,
      name: "green",
      data: [tickType[3]],
    },
    {
      type: "bar",
      name: "yellow",
      color: ORANGE_COLOR,
      grouping: false,
      data: [tickType[2]],
    },
    {
      type: "bar",
      name: "red",
      color: RED_COLOR,
      grouping: false,
      data: [tickType[1]],
    },
  ];

  const reverseSettings = [
    {
      type: "bar",
      name: "green",
      color: GREEN_COLOR,
      grouping: false,
      data: [Number(value)],
      dataLabels: {
        enabled: true,
        y: -16.8,
        useHTML: true,
        formatter: function () {
          return `<div style="text-align: center; display:flex; flex-direction: column; align-items: center; justify-content: end;">
              <div style="color: ${getColor(Number(value))}; font-size:11px; line-height:12px;">${Number(
            value
          )}${indicatorType(type)}</div>
              <div style="height: 10px; width: 1.5px; background: ${getColor(Number(value))};"></div>
            </div>`;
        },
        align: "center",
        verticalAlign: "middle",
      },
    },
    {
      type: "bar",
      pointPlacement: 0,
      color: RED_COLOR,
      grouping: false,
      name: "green",
      data: [tickType[3]],
    },
    {
      type: "bar",
      name: "yellow",
      color: ORANGE_COLOR,
      grouping: false,
      data: [tickType[2]],
    },
    {
      type: "bar",
      name: "red",
      color: GREEN_COLOR,
      grouping: false,
      data: [tickType[1]],
    },
  ];
  const twoDigitSettings = [
    {
      type: "bar",
      name: "green",
      color: GREEN_COLOR,
      grouping: false,
      data: [Number(value)],
      dataLabels: {
        enabled: true,
        y: -16.8,
        useHTML: true,
        formatter: function () {
          return `<div style="text-align: center; display:flex; flex-direction: column; align-items: center; justify-content: end;">
              <div style="color: ${getColor(Number(value))}; font-size:11px; line-height:12px;">${Number(
            value
          )}${indicatorType(type)}</div>
              <div style="height: 10px; width: 1.5px; background: ${getColor(Number(value))}; margin-top: 2px;"></div>
            </div>`;
        },
        align: "center",
        verticalAlign: "middle",
      },
    },
    {
      type: "bar",
      name: "red",
      color: RED_COLOR,
      grouping: false,
      data: [tickType[2]],
    },
    {
      type: "bar",
      name: "green",
      color: GREEN_COLOR,
      grouping: false,
      data: [tickType[1]],
    },
  ];

  const settings = () => {
    if (type !== undefined && reverseTypes.includes(type)) {
      return reverseSettings;
    }
    if (type !== undefined && twoDigitTypes.includes(type)) {
      return twoDigitSettings;
    }
    return defaultSettings;
  };

  const chartOptions = {
    chart: {
      type: "bar",
      height: 72,
      width: 222,
      marginBottom: 0,
      marginTop: 0,
      backgroundColor: theme.palette.text.primary,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    title: null,
    subtitle: null,
    caption: null,
    xAxis: {
      title: {
        text: "",
      },
      tickWidth: 0,
      lineWidth: 0,
      innerWidth: 200,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      title: null,
      gridLineWidth: 0,
      offset: -50,
      padding: 0,
      tickPositions: tickType,
      labels: {
        useHTML: true,
        overflow: "allow",
        style: {
          color: "#FFF",
          fontSize: type === "browserCpu" ? "9.6px" : "10px",
          lineHeight: "12px",
        },
        formatter: function (x: any) {
          return `<span style="${x.isLast ? "margin: -10px" : "margin: 0px"}">${x.value}</span>`;
        },
        align: "right",
        y: 40,
      },
    },
    tooltip: {
      enabled: true,
    },
    plotOptions: {
      series: {
        pointWidth: 12,
        borderWidth: 0,
        enableMouseTracking: false,
        animation: false,
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    series: settings(),
  };
  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

const TooltipTitle = ({ description, value, type, graph }: ICustomToolTip) => {
  const { selectedProject } = useContext(ProjectsContext);
  let tickType: number[];
  const thresholds = ribbonsThresholdHelper(selectedProject?.configData?.ribbon_thresholds);

  function compareNumbers(a: number, b: number) {
    return a - b;
  }

  const scoreGraph = [0, ...thresholds.score.sort(compareNumbers), 10];
  const mosGraph = [1, ...thresholds.mos.sort(compareNumbers), 5];
  const userGraph = [1, ...thresholds.user.sort(compareNumbers), 5];
  const packetLossGraph_incoming = [0, ...thresholds.highlights_packetLoss_incoming.sort(compareNumbers), 5];
  const packetLossGraph_outgoing = [0, ...thresholds.highlights_packetLoss_outgoing.sort(compareNumbers), 5];
  const packetLoss_audio_incomingGraph = [0, ...thresholds.packetLoss_audio_incoming.sort(compareNumbers), 5];
  const packetLoss_audio_outgoingGraph = [0, ...thresholds.packetLoss_audio_outgoing.sort(compareNumbers), 5];
  const packetLoss_video_incomingGraph = [0, ...thresholds.packetLoss_video_incoming.sort(compareNumbers), 5];
  const packetLoss_video_outgoingGraph = [0, ...thresholds.packetLoss_video_outgoing.sort(compareNumbers), 5];
  const rttGraph_incoming = [0, ...thresholds.highlights_rtt_incoming.sort(compareNumbers), 500];
  const rttGraph_outgoing = [0, ...thresholds.highlights_rtt_outgoing.sort(compareNumbers), 500];
  const rtt_audio_incomingGraph = [0, ...thresholds.rtt_audio_incoming.sort(compareNumbers), 500];
  const rtt_audio_outgoingGraph = [0, ...thresholds.rtt_audio_outgoing.sort(compareNumbers), 500];
  const rtt_video_incomingGraph = [0, ...thresholds.rtt_video_incoming.sort(compareNumbers), 500];
  const rtt_video_outgoingGraph = [0, ...thresholds.rtt_video_outgoing.sort(compareNumbers), 500];
  const jitterGraph = [0, ...thresholds.jitter_audio_incoming.sort(compareNumbers), 150];
  const jitter_audio_incomingGraph = [0, ...thresholds.jitter_audio_incoming.sort(compareNumbers), 150];
  const jitter_audio_outgoingGraph = [0, ...thresholds.jitter_audio_incoming.sort(compareNumbers), 150];
  const jitter_video_incomingGraph = [0, ...thresholds.jitter_audio_incoming.sort(compareNumbers), 150];
  const jitter_video_outgoingGraph = [0, ...thresholds.jitter_audio_incoming.sort(compareNumbers), 150];
  const cpuGraph = [0, thresholds.browserCpu, 200];
  const memoryGraph = [0, thresholds.browserMemory, 1920];

  switch (type) {
    case "score":
      tickType = scoreGraph;
      break;
    case "mos":
      tickType = mosGraph;
      break;
    case "user":
      tickType = userGraph;
      break;
    case "pl_inc":
      tickType = packetLossGraph_incoming;
      break;
    case "pl_out":
      tickType = packetLossGraph_outgoing;
      break;
    case "pl_audio_inc":
      tickType = packetLoss_audio_incomingGraph;
      break;
    case "pl_audio_out":
      tickType = packetLoss_audio_outgoingGraph;
      break;
    case "pl_video_inc":
      tickType = packetLoss_video_incomingGraph;
      break;
    case "pl_video_out":
      tickType = packetLoss_video_outgoingGraph;
      break;
    case "rtt_inc":
      tickType = rttGraph_incoming;
      break;
    case "rtt_out":
      tickType = rttGraph_outgoing;
      break;
    case "rtt_audio_inc":
      tickType = rtt_audio_incomingGraph;
      break;
    case "rtt_audio_out":
      tickType = rtt_audio_outgoingGraph;
      break;
    case "rtt_video_inc":
      tickType = rtt_video_incomingGraph;
      break;
    case "rtt_video_out":
      tickType = rtt_video_outgoingGraph;
      break;
    case "jitter":
      tickType = jitterGraph;
      break;
    case "jitter_audio_inc":
      tickType = jitter_audio_incomingGraph;
      break;
    case "jitter_audio_out":
      tickType = jitter_audio_outgoingGraph;
      break;
    case "jitter_video_inc":
      tickType = jitter_video_incomingGraph;
      break;
    case "jitter_video_out":
      tickType = jitter_video_outgoingGraph;
      break;
    case "browserCpu":
      tickType = cpuGraph;
      break;
    case "browserMemory":
      tickType = memoryGraph;
      break;
    default:
      tickType = [1, 2, 3, 5];
  }

  if (!value) {
    return null;
  }

  if (showGraph(value)) {
    return null;
  }

  const rowStyle = () => {
    if (value > tickType[tickType.length - 1]) {
      return "row";
    }
    if (value < tickType[0]) {
      return "row-reverse";
    }
    return "row";
  };

  return (
    <Stack divider={graph && <Divider orientation="horizontal" flexItem color="#fff" />}>
      <Box
        sx={{
          padding: "10px",
          display: "flex",
        }}
      >
        <Typography sx={{ fontSize: "11px", fontWeight: 600, lineHeight: "12px" }}>
          {`${description}${!graph && value ? ":" : ""}`} {!graph && value && value}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: "3px",
          display: "flex",
          flexDirection: rowStyle(),
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {graph && <TooltipGraph value={value} type={type} thresholds={thresholds} tickType={tickType} />}
        {graph && value > tickType[tickType.length - 1] ? (
          <Stack
            sx={{
              marginRight: "10px",
              marginLeft: "-5px",
              marginBottom: "22px",
              zIndex: "9999",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: "12px",
                color: RED_COLOR,
                fontWeight: "bold",
              }}
            >
              {`${value}${indicatorType(type)}`}
            </Typography>
            <Box
              sx={{
                width: "1.5px",
                height: "10px",
                background: RED_COLOR,
              }}
            ></Box>
            <Box
              sx={{
                height: "12px",
                width: "12px",
                background: RED_COLOR,
              }}
            ></Box>
          </Stack>
        ) : value < tickType[0] ? (
          <Stack
            sx={{
              marginRight: "-7px",
              marginLeft: "3px",
              marginBottom: "22px",
              zIndex: "9999",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: "12px",
                color: RED_COLOR,
                fontWeight: "bold",
              }}
            >
              {`${value}${indicatorType(type)}`}
            </Typography>
            <Box
              sx={{
                width: "1.5px",
                height: "10px",
                background: RED_COLOR,
              }}
            ></Box>
            <Box
              sx={{
                height: "14px",
                width: "14px",
                borderRadius: 99999,
                background: RED_COLOR,
              }}
            ></Box>
          </Stack>
        ) : (
          ""
        )}
      </Box>
    </Stack>
  );
};

const CustomToolTip = ({
  children,
  wrapperElement: WrapperElement = "div",
  wrapperClassName,
  description,
  value,
  type = "default",
  graph = false,
  position = "top",
  id,
}: ICustomToolTip) => {
  return (
    <Tooltip
      title={<TooltipTitle description={description} value={value} type={type} graph={graph} />}
      placement={position}
      arrow={showGraph(value)}
      enterDelay={1000}
      enterNextDelay={1000}
      componentsProps={{
        tooltip: {
          sx: {
            padding: "0px",
          },
        },
      }}
    >
      <WrapperElement className={wrapperClassName} id={id}>
        {children}
      </WrapperElement>
    </Tooltip>
  );
};

export default CustomToolTip;
