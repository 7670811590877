import * as React from "react";
import View from "./View";
import { ChartsState, ChartsProps } from "../index";

class Channels extends React.Component<ChartsState & ChartsProps> {
  render() {
    return <View {...this.props} />;
  }
}

export default Channels;
