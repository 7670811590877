import * as React from "react";
import * as moment from "moment";
import cn from "classnames";

import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import DisplaySDP from "./DisplaySDP";

interface IEventsLog {
  updateLog: any[];
  title: string;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",

      breakInside: "avoid",
      breakAfter: "avoid",
    },
    table: {
      width: "100%",

      "@media print": {
        tableLayout: "fixed",
      },
    },
    eventTimeHeading: {
      width: "10%",

      "@media print": { width: 200 },
    },
    eventTypeHeading: {
      width: "10%",

      "@media print": { width: 250 },
    },
    eventMessageHeading: {},
    eventTimeCell: {
      "&&&&": {
        textAlign: "left",
      },
    },
    eventTypeCell: {
      "&&&&": {
        textAlign: "right",
        overflowWrap: "anywhere",
        color: "black",
      },
    },
    eventMessageCell: {
      "&&&&": {
        maxWidth: "100%",
        position: "relative",
      },
    },
    extraInfo: {
      paddingTop: 4,

      "@media print": {
        width: "100%",
      },
    },
    showMoreButton: {
      display: "block",
      margin: "8px auto",
    },
  })
);

let prevEventTime: any = null;
let background = "#fff";

const maxEventsToShow = 20;

const EventsLog = ({ updateLog, title }: IEventsLog) => {
  const classes = useStyles();

  const isShowMoreButtonVisible = updateLog.length > maxEventsToShow;
  const [isOpen, setIsOpen] = React.useState(false);
  const [expandedRows, setExpandedRows] = React.useState<Set<number>>(new Set());

  const toggleRowExpand = (index: number) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const toggleIsOpen = () => {
    setIsOpen((currentIsOpen) => !currentIsOpen);
  };

  const generateTraceEventId = (event: ProcessEvent) => {
    if (typeof event.value === "object") {
      return `${title}${event.type}${event.time}${JSON.stringify(event.value).substring(0, 30)}`;
    }
    return `${title}${event.type}${event.time}${event.value.substring(0, 30)}`;
  };

  const processTraceEvent = (event: ProcessEvent, index: number, prevEvent?: ProcessEvent) => {
    prevEventTime = prevEventTime || event.time;
    if (event.time !== prevEventTime) {
      prevEventTime = event.time;
      background = background === "#f9f9f9" ? "#fff" : "#f9f9f9";
    }
    if (event.type.indexOf("Failure") !== -1) {
      background = "#a22a21";
    }
    if (event.type === "iceConnectionStateChange") {
      switch (event.value) {
        case "ICEConnectionStateConnected":
        case "ICEConnectionStateCompleted":
        case "kICEConnectionStateConnected":
        case "kICEConnectionStateCompleted":
          background = "#559542";
          break;
        case "ICEConnectionStateFailed":
        case "kICEConnectionStateFailed":
          background = "#a22a21";
          break;
        default:
          break;
      }
    }

    const showValue = (value: any) => {
      if (!value || value === "null") {
        return "none";
      }
      if (typeof value === "object") {
        return JSON.stringify(value);
      }
      try {
        JSON.parse(value);
        return value;
      } catch {
        return value;
      }
    };

    const eventSeconds = moment(event.time).seconds();
    const prevEventSeconds = prevEvent ? moment(prevEvent?.time).seconds() : 0;
    const isSecondsSame = eventSeconds === prevEventSeconds;
    const expandable = (event.value && event.value.length > 150) || typeof event.value === "object";
    return (
      <tr
        key={index}
        id={generateTraceEventId(event)}
        className="log-line"
        // tslint:disable-next-line: no-shadowed-variable
        onClick={() => {
          if (expandable) {
            toggleRowExpand(index);
          }
        }}
      >
        <td
          className={cn(classes.eventTimeCell, "log-time")}
          style={{
            color: `${index === 0 ? "grey" : isSecondsSame ? "grey" : "blue"}`,
          }}
        >
          {moment(event.time).format("YYYY-MM-DD HH:mm:ss.SSS")}
        </td>
        <td className={cn(classes.eventTypeCell, "log-time")}>{event.type}</td>
        <td className={cn(classes.eventMessageCell, "ellipsis", { expandable: expandedRows.has(index) })}>
          <label className="log-message" style={{ display: "flex", marginTop: -4 }}>
            <ExpandMoreIcon style={{ visibility: expandable ? "visible" : "hidden" }} />
            <span
              className="span-message"
              style={{
                paddingTop: 4,
                paddingLeft: 25,
              }}
            >
              {showValue(event.value)}
            </span>
            {expandable && (
              <div className={cn(classes.extraInfo, "extra-info")} onClick={(e) => e.preventDefault()}>
                {processExtraInfo(event)}
              </div>
            )}
          </label>
        </td>
      </tr>
    );
  };

  const processExtraInfo = (event: ProcessEvent) => {
    const isObject = typeof event.value === "object";
    if (isObject) {
      event.value = JSON.stringify(event.value, null, 2);
    }

    if (["create", "onicecandidate"].includes(event.type)) {
      const parts = event.value.split(/,(?![^\{]*\})/).map((part) => part.trim());
      const formattedValue = parts.join(",\n");
      return <pre>{formattedValue}</pre>;
    }
    switch (event.type) {
      case "setLocalDescription":
        return <DisplaySDP event={event} />;
      case "setRemoteDescription":
        return <DisplaySDP event={event} />;
      case "createOfferOnSuccess":
        return <DisplaySDP event={event} />;
      case "createAnswerOnSuccess":
        return <DisplaySDP event={event} />;
      case "transceiverAdded":
      case "transceiverModified": {
        try {
          const json = event.value.split(/\(\)\[.*?]:{/);
          return (
            <div>
              {json[0]}
              <pre>{json[1]}</pre>
            </div>
          );
        } catch {
          return event.value;
        }
      }
      default: {
        return event.value;
      }
    }
  };

  let eventsToShow = updateLog;
  let showMoreButtonText = "";

  if (isShowMoreButtonVisible && isOpen) {
    showMoreButtonText = "Show less";
  } else if (isShowMoreButtonVisible) {
    eventsToShow = updateLog.slice(0, maxEventsToShow);
    showMoreButtonText = `Show ${updateLog.length - maxEventsToShow} more`;
  }

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <Typography variant="subtitle1">Events Log/API calls</Typography>
      </Toolbar>
      <div className="logger internals-logs" style={{ cursor: "default", overflowX: "auto", paddingTop: 10 }}>
        <div className="row">
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.eventTimeHeading} />
                <th className={classes.eventTypeHeading} />
                <th className={classes.eventMessageHeading} />
              </tr>
            </thead>
            <tbody>{eventsToShow.map((event, index) => processTraceEvent(event, index, updateLog[index - 1]))}</tbody>
          </table>
        </div>
        {isShowMoreButtonVisible ? (
          <Button onClick={toggleIsOpen} variant="contained" className={classes.showMoreButton}>
            {showMoreButtonText}
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default EventsLog;
