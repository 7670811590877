import { ReactElement } from "react";
import { FormControlLabel, FormHelperText, FormControl, Checkbox, Theme } from "@mui/material";
import { FieldRenderProps } from "react-final-form";
import { createStyles, makeStyles } from "@mui/styles";

interface CheckboxControlSimpleProps {
  label?: string | ReactElement;
  className?: string;
  labelClassName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: 3,

      "&$checked": {
        color: theme.palette.primary.main,
      },
    },
    checked: {},
    label: { position: "relative", top: 1, letterSpacing: "0.15px" },
  })
);

const CheckboxControlSimple = ({
  input: { name, value, onChange },
  meta,
  label,
  className,
  labelClassName,
}: FieldRenderProps & CheckboxControlSimpleProps) => {
  const classes = useStyles();

  return (
    <FormControl error variant="standard" className={className}>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={Boolean(value)}
            onChange={onChange}
            value={value.toString()}
            classes={{ root: classes.root, checked: classes.checked }}
          />
        }
        label={label ?? ""}
        className={labelClassName}
        classes={{ label: classes.label }}
      />
      {meta.touched && (meta.error || meta.submitError) && (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CheckboxControlSimple;
