export const QualityRTC = {
  Never: "never",
  Always: "always",
  Yellow: "yellow",
  Red: "red",
  Ask: "ask",
};

export const ProbeRTC = {
  Never: "never",
  Yellow: "yellow",
  Red: "red",
  "Start Stop": "start-stop",
};
