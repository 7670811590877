import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ApiPath from "src/constants/ApiPath";
import { axiosInstance, isAxiosError } from "src/services/AxiosService";
import { SetNotification } from "src/actions/notificationAction";

export interface ExportConfig {
  uploadProcessTtlInSec: number;
  delayBeforeExportStartInSec: number;
  maxObjectsToExport?: number;
}

const useExportConfig = (isDisabled?: boolean) => {
  const dispatch = useDispatch();

  const [exportConfig, setExportConfig] = useState<ExportConfig>();

  const fetchExportConfig = async () => {
    let result: ExportConfig | undefined;

    try {
      const response = await axiosInstance.get<{
        data: ExportConfig;
      }>(ApiPath.api.adminUtils.exportConfig);
      result = response.data?.data;
    } catch (error) {
      if (isAxiosError<string | { message?: string }>(error)) {
        if (typeof error.response?.data === "string") {
          dispatch(SetNotification(error.response?.data));
        } else {
          dispatch(SetNotification(error.response?.data?.message || "Failed to fetch export config"));
        }
      }
    }

    return result;
  };

  useEffect(function initExportConfig() {
    if (!isDisabled) {
      fetchExportConfig().then((result) => {
        setExportConfig(result);
      });
    }
  }, []);

  return { exportConfig };
};

export default useExportConfig;
