import * as React from "react";
import { Preset } from ".";
import usePresetsContext from "./usePresetsContext";

export default function usePresetConfig(gridApi: any) {
  const { defaultPresetConfig, setDefaultPresetConfig } = usePresetsContext();

  React.useEffect(() => {
    if (gridApi && !defaultPresetConfig) {
      const presetConfig = getCurrentPresetConfig();
      setDefaultPresetConfig(presetConfig);
    }
    // eslint-disable-next-line
  }, [gridApi]);

  const getCurrentPresetConfig = () => {
    const colState = gridApi.columnModel.getColumnState();
    const groupState = gridApi.columnModel.getColumnGroupState();
    const filterState = gridApi.getFilterModel();
    const presetConfig = {
      colState,
      groupState,
      sortState: (colState || [])
        .filter((x: any) => x.sort !== null)
        .map((x: any) => ({ colId: x.colId, sort: x.sort })),
      filterState,
    } as Preset;

    return presetConfig;
  };

  return { defaultPresetConfig, getCurrentPresetConfig };
}
