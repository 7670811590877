import { IS_DATA_LOADING } from "../constants/ActionTypes";

export interface ISetDataLoadingAction {
  type: string;
  payload: boolean;
}

export const dispatchDataLoading = (dataLoading: boolean) => {
  return {
    type: IS_DATA_LOADING,
    payload: dataLoading,
  };
};
