import * as React from "react";
import * as _ from "lodash";
import { Guid } from "guid-typescript";

import { formatDuration, getPerformanceAverages } from "src/helpers/testDetails";
import { makeIterationStackedSeries } from "src/components/Chart/seriesBuilder";

import View from "./View";
import { reportError } from "src/services/ErrorService";
import { AppRoutes } from "../../../constants/routes/app-routes";
import * as moment from "moment";

export interface ChartsProps {
  test: any;
  testRun: any;
  isReport: boolean;
  testEvents?: any;
  advancedAnalyticsLink?: any;
}

export interface ChartsState {
  audio?: any;
  video?: any;
  performanceData?: any;
  calc?: any;
  packetLM: string;
  voiceStartMarkerX?: any;
  channels: Array<any>;
  total: any;
  filtCusTestMetric: Metrics;
  audioMOS?: any;
  audioMosPanelClass?: string;
  hasMetrics?: boolean;
  globalEvents: Array<any>;
  resolutions: Array<any>;
  assetsFileNames: Array<string>;
  localEvents: Array<any>;
  sessionEvents: Array<any>;
  chartSamplesStartTime: Date;
  chartSamplesEndTime: Date;
  voiceStartTime: Date;
  channelReadyTime: number;
  totalChartSamples: number;
  totalPerformanceChartSamples: number;
  showVideo: boolean;
  showAudio: boolean;
  showPerformance: boolean;
  session: any;
  performance: any;
}

class Charts extends React.Component<ChartsProps, ChartsState> {
  defaultState = {
    audio: null,
    video: null,
    performanceSource: null,
    performanceData: null,
    packetLM: "#",
    calc: {},
    voiceStartMarkerX: null,
    channels: [],
    assetsFileNames: [],
    total: null,
    filtCusTestMetric: {},
    globalEvents: [],
    resolutions: [],
    localEvents: [],
    sessionEvents: [],
    chartSamplesStartTime: new Date(),
    chartSamplesEndTime: new Date(),
    voiceStartTime: new Date(),
    channelReadyTime: 0,
    totalChartSamples: 0,
    totalPerformanceChartSamples: 0,
    showAudio: false,
    showVideo: false,
    showPerformance: false,
    session: {},
    performance: {},
  };

  outgoingColors = ["#a22a21", "#f1cd2b", "#dd7127", "#ddcc27", "#dd2738", "#b2f12b", "#cd2bf1"];
  incomingColors = ["#4682c0", "#559542", "#745671", "#567459", "#262d31", "#c08446", "#a22159"];

  constructor(props: ChartsProps) {
    super(props);

    this.state = {
      ...this.defaultState,
    };

    this.pushToFileNames = this.pushToFileNames.bind(this);
    this.changePacketLMAudio = this.changePacketLMAudio.bind(this);
    this.changePacketLMVideo = this.changePacketLMVideo.bind(this);
    this.showBlock = this.showBlock.bind(this);
  }

  componentDidMount() {
    let callback: VoidFunction | undefined;

    if (this.props.test.performanceChartData) {
      callback = this.calcPerformanceCharts.bind(this);
    }

    this.calcCharts(callback);
  }

  componentDidUpdate(prevProps: ChartsProps) {
    if (!prevProps.test.performanceChartData && this.props.test.performanceChartData) {
      this.calcPerformanceCharts();
    }
  }

  changePacketLMAudio(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;

    const audio = {
      ...this.state.audio,
    };

    audio.charts = this.buildAllCharts(audio, "audio");
    audio.charts.packetlossMode = value;

    this.setState({
      audio,
      showVideo: this.showBlock(audio.charts),
    });
  }

  changePacketLMVideo(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;

    const video = {
      ...this.state.video,
    };

    video.charts = this.buildAllCharts(video, "video");
    video.charts.packetlossMode = value;

    this.setState({
      video,
      showVideo: this.showBlock(video.charts),
    });
  }

  pushToFileNames(item: string) {
    this.setState({
      ...this.state,
      assetsFileNames: [...this.state.assetsFileNames, item],
    });
  }

  // TODO: think of where better to put this function
  showBlock(charts: any) {
    if (typeof charts === "object" && charts !== null && !Array.isArray(charts)) {
      for (const chartKey in charts) {
        if (charts.hasOwnProperty(chartKey)) {
          if (charts[chartKey] && charts[chartKey].options.series && Array.isArray(charts[chartKey].options.series)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  calcPerformanceCharts() {
    const performanceData: any = {
      channels: [],
      metricIdx: 0,
      startTime: this.state.chartSamplesStartTime?.getTime() || 0,
      charts: {},
    };

    performanceData.channels.push({
      key: "performanceByProbe",
      channel: {
        color: this.outgoingColors[0],
        data: {
          startTime: performanceData.startTime,
        },
      },
      name: "By Probe",
    });

    performanceData.channels.push({
      key: "performanceByBrowser",
      channel: {
        color: this.outgoingColors[1],
        data: {
          startTime: performanceData.startTime,
        },
      },
      name: "By Browser",
    });

    performanceData.channels.push({
      key: "performanceByProbePercents",
      channel: {
        color: this.outgoingColors[0],
        data: {
          startTime: performanceData.startTime,
        },
      },
      name: "By Probe (%)",
    });

    performanceData.channels.push({
      key: "performanceByBrowserMBytes",
      channel: {
        color: this.outgoingColors[1],
        data: {
          startTime: performanceData.startTime,
        },
      },
      name: "By Browser (MBytes)",
    });

    performanceData.metricIdx += 4;

    performanceData.charts = this.buildAllCharts(performanceData, "performance");
    this.setState({
      performanceData,
      showPerformance: this.showBlock(performanceData.charts),
    });
  }

  calcCharts(callback?: VoidFunction) {
    // events to show on chart
    // let events = [];

    const { test } = this.props;
    if (test.stat) {
      const backwardPerformance = getPerformanceAverages(test.stat);
      const newState: ChartsState = {
        ...this.defaultState,
        globalEvents: this.splitEvents("global"),
        sessionEvents: this.splitEvents("session"),
        localEvents: this.getLocalEvents(),
        performance: backwardPerformance,
      };

      if (test.customTestMetric) {
        newState.hasMetrics = !!Object.keys(test.customTestMetric).length;

        for (const key in test.customTestMetric) {
          if (test.customTestMetric.hasOwnProperty(key)) {
            if (key === "VoiceQuality:MOS") {
              newState.audioMOS = test.customTestMetric[key];
            }
            /*Filters out , voiceQuality:* records and creates new array*/
            if (key.substring(0, key.indexOf(":")) !== "VoiceQuality") {
              newState.filtCusTestMetric[key] = test.customTestMetric[key];
            }
          }
        }
      }

      if (newState.audioMOS) {
        newState.audioMosPanelClass = this.getAudioMosPanelClass(newState.audioMOS);
      }

      // var testStartDate = new Date(test.startDate);

      // var voiceStartTime = new Date(test.stat.setupStartTime);

      /*
      if (voiceStartTime < testStartDate) {
        voiceStartTime = new Date(testStartDate);
        voiceStartTime.setSeconds(voiceStartTime.getSeconds() + 4.34);
        chartSamplesStartTime = new Date(test.stat.voiceStartTime);
        chartSamplesStartTime.setSeconds(chartSamplesStartTime.getSeconds() - 4.34);
      }
      */
      newState.voiceStartTime = new Date(test.stat.voiceStartTime);
      newState.chartSamplesStartTime = new Date(Math.round(test.stat.setupStartTime / 1000) * 1000);
      newState.chartSamplesEndTime = new Date();
      newState.chartSamplesEndTime.setTime(newState.voiceStartTime.getTime() + test.stat.voiceDuration);
      newState.channelReadyTime = new Date(test.stat.channelReadyTime).getTime();

      const dataFrequency = test.stat.dataFrequency || 1;
      newState.totalChartSamples = Math.round(
        (newState.chartSamplesEndTime.getTime() - newState.chartSamplesStartTime.getTime()) / (dataFrequency * 1000)
      );

      if (newState.totalChartSamples > 10000) {
        console.warn("Duration of the test is too long, browser can get stuck", {
          "Test start time": newState.chartSamplesStartTime,
          "Test end time": newState.chartSamplesEndTime,
        });
      }

      newState.totalPerformanceChartSamples = test.performanceChartSamples;

      // Calculated fields
      newState.calc = {
        voiceStartTime: newState.voiceStartTime,
        setupStartTime: new Date(test.stat.setupStartTime),
        voiceSetupTime: newState.channelReadyTime - new Date(test.stat.setupStartTime).getTime(),
        channelReadyTime: new Date(test.stat.channelReadyTime),
        rank: test.rank || test.stat.rank,
        mos: test.stat.mos ? Number(test.stat.mos).toFixed(2) : 0,
        mosCount: test.stat.mosCount,
        callSetupTime: test.stat.callSetupTime,
        timeToMedia: test.stat.timeToMedia,
      };

      newState.session = {
        sessionIdx: test.sessionIdx,
        inSessionIdx: test.inSessionIdx,
        sessionSize: test.sessionSize,
      };

      //  send_packetLossPCT: vm.test.stat.send.packetLoss * 100 / vm.test.stat.send.totalPackets,
      // recv_packetLossPCT: vm.test.stat.recv.packetLoss * 100 / vm.test.stat.recv.totalPackets,

      // sometimes is really huge number, in cae > 1000 sec don't display anything
      if (newState.calc.voiceSetupTime < 0 || newState.calc.voiceSetupTime > 1000 * 1000) {
        newState.calc.voiceSetupTime = null;
      }

      newState.voiceStartMarkerX = (newState.channelReadyTime - newState.chartSamplesStartTime.getTime()) / 1000;
      const audio = {
        channels: [],
        incomingIdx: 0,
        outgoingIdx: 0,
        startTime: newState.chartSamplesStartTime?.getTime() || 0,
        charts: {},
      };
      const video = {
        channels: [],
        incomingIdx: 0,
        outgoingIdx: 0,
        startTime: newState.chartSamplesStartTime?.getTime() || 0,
        charts: {},
      };
      const data = {
        channels: [],
        incomingIdx: 0,
        outgoingIdx: 0,
        startTime: newState.chartSamplesStartTime?.getTime() || 0,
      };

      newState.total = this.adjustStatTotal(test.stat.total);

      for (const key in test.stat.channels) {
        if (test.stat.channels.hasOwnProperty(key)) {
          const value = test.stat.channels[key];
          const isSend = value.direction === "send";

          let media: any;
          if (value.media === "audio") {
            media = audio;
            media.type = "audio";
            if (isSend) {
            } else {
            }
          } else if (value.media === "video") {
            media = video;
            media.type = "video";
            if (isSend) {
            } else {
            }
          } else {
            media = data;
          }

          const channel: any = {
            idx: (isSend ? 1 : 0) + (value.media === "video" ? 10 : 0),
            name: (isSend ? "Outgoing" : "Incoming") + " - " + (value.media === "video" ? "Video" : "Audio"),
            isOutgoing: isSend,
            channelType: value.media,
            directionTooltip: isSend ? "sent" : "received",
            data: value,
            error: value.error,
            status: value.status || "completed",
            color: isSend
              ? this.outgoingColors[media.outgoingIdx % this.outgoingColors.length]
              : this.incomingColors[media.incomingIdx % this.incomingColors.length], // going around array choosing colors
          };
          // percentage
          // https://redmine.testrtc.com/issues/4235
          // recalculate % only for recv
          channel.packetLossPCT = value.totalPackets
            ? isSend
              ? (value.packetLoss * 100) / value.totalPackets
              : (value.packetLoss / (value.totalPackets + value.packetLoss)) * 100
            : 0;

          channel.style = { "border-left-color": channel.color };

          newState.channels.push(channel);

          newState.channels = newState.channels.map((c) => ({ ...c, guid: Guid.create() }));

          let channelName = (isSend ? "Outgoing" : "Incoming") + " - ";
          channelName += value.channelDisplayName;

          media.channels.push({ key, channel, name: channelName });

          if (media.type === "audio") {
            if (isSend) {
              media.outgoingIdx += 1;
            } else {
              media.incomingIdx += 1;
            }
          } else {
            if (isSend) {
              media.outgoingIdx += 1;
            } else {
              media.incomingIdx += 1;
            }
          }
        }
      }

      newState.channels.sort((a, b) => {
        if (b.channelType === "data") {
          // Data channels goes to the end of the list
          return -1;
        }

        return a.idx - b.idx;
      });

      this.setState(newState, () => {
        audio.charts = this.buildAllCharts(audio, "audio");
        video.charts = this.buildAllCharts(video, "video");
        callback && callback();
        this.setState({
          audio,
          video,
          showAudio: this.showBlock(audio.charts),
          showVideo: this.showBlock(video.charts),
        });
      });
    }
  }

  adjustStatTotal = (total: any): ITotalStats => {
    ["audio", "video"].forEach((media) => {
      ["send", "recv"].forEach((direction) => {
        // https://redmine.testrtc.com/issues/7191
        // before this request percentage value was in "packetLoss" property
        // but from now on we need packetLoss number
        // so we move percentage value from "packetLoss" to "packetLossPCT"
        // and for backward compatibilty we remap values
        if (
          !total[media][direction]?.hasOwnProperty("packetLossPCT") &&
          total[media][direction]?.hasOwnProperty("packetLoss")
        ) {
          total[media][direction]["packetLossPCT"] = total[media][direction]["packetLoss"];
        }
      });
    });
    return total;
  };

  getAudioMosPanelClass(value: number): string {
    let panelClass;

    switch (true) {
      case value >= 3:
        panelClass = "success";
        break;
      case value >= 2 && value < 3:
        panelClass = "warning";
        break;
      case value < 2:
        panelClass = "error";
        break;
      default:
        return "";
    }

    return panelClass;
  }

  buildAllCharts(media: any, mediaType: "audio" | "video" | "performance") {
    const { test } = this.props;
    const charts: any = {};
    if (media.channels.length > 0) {
      if (mediaType === "performance") {
        charts.cpu = this.buildCharts(media, mediaType, "cpu", 1, false, null, {});
        charts.memory = this.buildCharts(media, mediaType, "memory", 1, false, null, {});
      } else {
        charts.flotBits = this.buildCharts(media, mediaType, "bits", 1000, false, null, {
          yAxis: {
            title: {
              text: "Kbits",
            },
          },
        });

        charts.flotPacket = this.buildCharts(media, mediaType, "packets", 1, null, null, {
          yAxis: {
            title: {
              text: "Packets",
            },
          },
        });

        charts.flotBitsStacked = makeIterationStackedSeries(charts.flotBits);

        charts.flotPacketLossNumber = this.buildCharts(
          media,
          mediaType,
          "loss",
          1,
          true,
          "bars",
          {
            yAxis: {
              title: {
                text: "# of packets lost",
              },
            },
          },
          true
        );

        charts.flotPacketLossPct = this.buildCharts(media, mediaType, "loss", 1, true, "bars", {
          yAxis: {
            title: {
              text: "Packet loss %",
            },
          },
        });

        // Ugly but until I have a better idea
        for (let i = 0; i < charts.flotPacketLossPct.options.series.length; i++) {
          this.convertSeriesPCT(charts, i, test.stat.dataFrequency);
        }

        charts.flotJitter = this.buildCharts(
          media,
          mediaType,
          "jitter",
          1,
          null,
          null,
          {
            yAxis: {
              title: {
                text: "ms",
              },
            },
          },
          true
        );

        if (mediaType === "video") {
          charts.frameRate = this.buildCharts(media, mediaType, "videoFrameRate", 1, null, null, {
            yAxis: {
              title: {
                text: "fps",
              },
            },
          });
        }

        charts.packetlossMode = "#";

        // build delay charts for audio and video
        // 2nd parameter is called 'videoDelay' because audio data has this property
        charts.delay = this.buildCharts(
          media,
          mediaType,
          "videoDelay",
          1,
          null,
          null,
          {
            yAxis: {
              title: {
                text: "ms",
              },
            },
          },
          true
        );
      }
    }

    return charts;
  }

  buildCharts(
    media: any,
    mediaType: "audio" | "video" | "performance",
    itemName: string,
    yFactor: number,
    dataIsCumulative: any,
    chartType: any,
    options: any,
    defaultIsZero?: any
  ) {
    const { test } = this.props;
    const dataFrequency =
      (mediaType === "performance" ? test.stat.performanceStep / 1000 : test.stat.dataFrequency) || 1;
    // chartSamplesStartTime - from database, from test info
    const testStartTime: Date = this.state.chartSamplesStartTime;
    const chartSamples: number =
      mediaType === "performance" ? this.state.totalPerformanceChartSamples : this.state.totalChartSamples; // total seconds during test
    const indexTime = new Date(testStartTime);

    // get seconds for chart where should vertical lines for events
    let maxValue = 0;
    const seriesData: Array<any> = [];

    const chartData = mediaType === "performance" ? test.performanceChartData : test.chartData;

    for (const key in media.channels) {
      if (media.channels.hasOwnProperty(key)) {
        const channel = media.channels[key];
        if (chartData[channel.key] && chartData[channel.key][itemName]?.length) {
          seriesData.push({
            name: channel.name,
            color: channel.channel.color,
            data: [],
            start: new Date(channel.channel.data.startTime),
            values: chartData[channel.key] ? chartData[channel.key][itemName] : 0,
            prevValue: 0,
          });
        }
      }
    }

    const events = this.state.globalEvents.concat(this.state.sessionEvents).concat(this.state.localEvents);

    const flot: any = {
      options: _.merge(
        {
          series: [],
          chart: {
            zooming: { type: "x" },
            height: 300,
          },
          xAxis: {
            title: {
              text: "Seconds",
            },
            labels: {
              formatter: (context: any) => {
                const splittedVal = context.value.toString().split(".");
                if (!splittedVal[1]) {
                  return splittedVal;
                }
                if (splittedVal[1].length > 2) {
                  return context.value.toFixed(2);
                }
              },
            },
            events: {
              afterSetExtremes: function (e: any) {
                const charts = (window as any).Highcharts?.charts;
                if (charts) {
                  const eMin = e.min;
                  const eMax = e.max;
                  (charts as Array<any>).forEach((c) => {
                    if (c) {
                      c.xAxis[0].setExtremes(eMin, eMax, true, false);
                    }
                  });
                }
              },
            },
          },
          yAxis: {
            min: null,
          },
          tooltip: {
            enabled: true,
            shared: true,
            backgroundColor: "#191b24bf",
            borderWidth: 0,
            style: {
              color: "#E6E6E7",
            },
            formatter: function () {
              const _this = this as any;

              let eventPoint: any = null;

              for (const point of _this.points) {
                if (
                  point.series.name.toLowerCase().indexOf("global") !== -1 ||
                  point.series.name.toLowerCase().indexOf("local") !== -1 ||
                  point.series.name.toLowerCase().indexOf("call end") !== -1
                ) {
                  eventPoint = point;
                  break;
                }
              }

              if (eventPoint) {
                const event = events.find((x) => x[0] === eventPoint.x);
                const date = new Date(0);
                date.setSeconds(eventPoint.x);
                const formattedTime = moment(testStartTime.getTime() + _this.x * 1000).format("H:mm:ss");
                if (event) {
                  const eventName = event[1];
                  const probeNumber = event[2];
                  // do not show probe number for local
                  // as local events are only for current probe/iteration
                  const isLocal = eventPoint.series.name.toLowerCase().indexOf("local") !== -1;

                  return `${
                    !isLocal ? `<strong>Probe #${probeNumber}</strong><br />` : ""
                  }<br/ ><span>${eventName}</span><br/ ><span>${formatDuration(
                    date.getTime(),
                    "DHMS"
                  )}</span><br/><span>${formattedTime}</span>`;
                }

                return `<br/ ><br/ ><strong>${eventPoint.series.name}</strong><br /><span>${formatDuration(
                  date.getTime(),
                  "DHMS"
                )}</span><br/><span>${formattedTime}</span>`;
              }
              return false;
            },
          },
        },
        options
      ),
    };

    // step is 1 second
    let chartIdx = 0;
    while (chartIdx < chartSamples) {
      indexTime.setTime(testStartTime.getTime() + chartIdx * dataFrequency * 1000); // get total seconds here

      for (const series of seriesData) {
        const i = series.start
          ? Math.round((indexTime.getTime() - series.start.getTime()) / (dataFrequency * 1000))
          : -1;

        if (series.values[i] !== null) {
          // values is array of data? yes
          if (series.values && i >= 0 && i < series.values.length) {  
            if(dataIsCumulative && (series.values[i] - series.prevValue) / yFactor < 0) { // https://cyara.atlassian.net/browse/RTC-1445, https://redmine.testrtc.com/issues/10053
              series.data[i-1][1] += (series.values[i] - series.prevValue) / yFactor
            }
            series.data.push([
              chartIdx * dataFrequency,
              (series.values[i] - series.prevValue) / yFactor < 0 ? 0 : (series.values[i] - series.prevValue) / yFactor,
            ]);

            if (maxValue < (series.values[i] - series.prevValue) / yFactor) {
              maxValue = (series.values[i] - series.prevValue) / yFactor;
            }

            if (dataIsCumulative) {
              series.prevValue = series.values[i];
            }
          } else if (defaultIsZero) {
            series.data.push([chartIdx * dataFrequency, 0]);
          } else {
            series.data.push([chartIdx * dataFrequency, null]);
          }
        }
      }

      // move to next sample
      chartIdx += 1;
    }

    // here we push events
    // dst as reference
    if (
      this.state.localEvents.length > 0 ||
      this.state.globalEvents.length > 0 ||
      this.state.sessionEvents.length > 0
    ) {
      const _events = [
        // TODO: generate unique colors, not static
        { type: "Local", color: "#EB0F23", dst: this.state.localEvents },
        { type: "Global", color: "#262d31", dst: this.state.globalEvents },
        { type: "Session", color: "#E5446D", dst: this.state.sessionEvents },
      ];

      _events
        .filter((event) => event.dst.length)
        .forEach((spec) => {
          seriesData.push({
            name: spec.type + " events - " + spec.dst.length,
            color: spec.color,
            data: spec.dst.map((event: any) => {
              // didn't want to add prob name as a second param
              // but it can't see arbitrary properties in
              // series property of plot series instance
              // if you find better approach just let me know
              return [event[0], maxValue + (maxValue / 100) * 10, event[1], this.props.test.machine];
            }),
            start: testStartTime,
            values: [],
            fill: true,
            prevValue: 0,
            yAxis: 1,
          });
        });
    }

    // trying to push resolution events
    // if (resolutions.length) {
    //   resolutions.forEach(function(evt) {
    //     if (evt !== null) {
    //       seriesData.push({
    //         name: evt.title + ' - ' + evt.data.length,
    //         color: evt.color[Math.floor(Math.random() * (evt.color.length - 1 + 1)) + 1],
    //         data: evt.data.map(function(e) {
    //           return [e[0], maxValue + ((maxValue / 100) * 10), scope.test.machine, e[1]]
    //         }),
    //         start: testStartTime,
    //         values: [],
    //         fill: true,
    //         prevValue: 0,
    //         yAxis: 3
    //       });
    //     }
    //   });
    // }

    for (const key in seriesData) {
      if (seriesData.hasOwnProperty(key)) {
        const series = seriesData[key];
        const data: any = {
          color: series.color,
          data: series.data,
          name: series.name,
          label: series.name,
          yAxis: series.yAxis,
          type: "line",
        };

        // TODO: it's ugly condition, make it nicer
        if (
          ~series.name.indexOf("Local ") ||
          ~series.name.indexOf("Global ") ||
          ~series.name.indexOf("Resolution ") ||
          ~series.name.indexOf("Session ")
        ) {
          data.type = "column";
          data.bars = {
            fillColor: data.color,
            show: true,
            fill: true,
            lineWidth: 0,
            align: "top", // : "left",
            barWidth: this.getMaxLen(seriesData) / 22 / 22,
          };
        } else if (chartType === "bars") {
          data.type = "column";
          data.bars = {
            fillColor: data.color,
            show: true,
            fill: true,
            lineWidth: 1,
            align: "top", // : "left",
            barWidth: 0.5,
          };
        }

        flot.options.series.push(data);
      }
    }

    flot.options.series = this.sortData(flot.options.series);
    return flot;
  }

  sortData(series: Array<any>) {
    try {
      if (!series) {
        return [];
      }
      const seriesCopy = _.cloneDeep(series);
      seriesCopy.forEach((s) => {
        if (!s.data) {
          return;
        }
        s.data.sort((a: Array<any>, b: Array<any>) => Number(a[0]) - Number(b[0]));
      });
      return seriesCopy;
    } catch (error) {
      reportError("Couldn't sort data for graphs", error);
      return series;
    }
  }

  convertSeriesPCT(charts: any, j: any, frequency = 1) {
    const len = charts.flotPacketLossPct.options.series[j].data.length;

    for (let i = 0; i < len; i++) {
      if (
        charts.flotPacket.options.series[j]?.data[i] &&
        charts.flotPacketLossPct.options.series[j]?.data[i] &&
        charts.flotPacket.options.series[j]?.data[i][1] &&
        charts.flotPacketLossPct.options.series[j]?.data[i][1]
      ) {
        const isRecv = charts.flotPacketLossPct.options.series[j]?.label?.indexOf("Incoming") !== -1;
        const packetsLoss = charts.flotPacketLossPct.options.series[j].data[i][1];
        const totalPackets = charts.flotPacket.options.series[j].data[i][1];
        // https://redmine.testrtc.com/issues/4235
        // recalculate % only for recv
        // https://redmine.testrtc.com/issues/5503
        // totalPackets are per second, so need to be multiplied by the frequency to have the real number of packets
        const res = isRecv
          ? (packetsLoss / (totalPackets * frequency + packetsLoss)) * 100
          : (packetsLoss / (totalPackets * frequency)) * 100;

        charts.flotPacketLossPct.options.series[j].data[i][1] = res;
      } else {
        charts.flotPacketLossPct.options.series[j].data[i][1] = 0;
      }
    }
  }

  // get max length of series
  getMaxLen(series: any) {
    let max = 0,
      idx;
    if (Array.isArray(series)) {
      const rounds = series.length;
      for (idx = 0; idx < rounds; idx++) {
        if (max < series[idx].data.length) {
          max = series[idx].data.length;
        }
      }
    } else {
      throw new Error("getMaxLen expects an array");
    }

    return max;
  }

  splitEvents(type: string) {
    const chartSamplesStartTime = new Date(this.props.test.stat.setupStartTime).getTime();
    let evts: any[] = this.props.testEvents.length > 0 ? this.props.testEvents : this.props.test.events;

    evts = evts.map((e: any) => {
      const extendedEvents = ((e.events || []) as any[]).map((ev: any) => {
        return { ...ev, inSessionIdx: e.inSessionIdx, machine: e.machine };
      });
      return { ...e, events: extendedEvents };
    });

    const dirtyEvents = evts.map((evt: any) => (evt.events ? evt.events : []));
    const events: any = [];

    dirtyEvents.forEach((el: any) => {
      Array.prototype.push.apply(events, el);
    });

    const preparedEvents = events.filter((evt: any) => {
      return evt.type === type;
    });

    return preparedEvents.length
      ? preparedEvents.map((evt: any) => {
          const timestamp = (+evt.timestamp - chartSamplesStartTime) / 1000;
          const eventName = evt.event;
          let probeNumber = evt.inSessionIdx;

          const splittedMachineName = evt.machine?.split("-");
          if (splittedMachineName?.length > 0) {
            const sessionIndex = Number(splittedMachineName[splittedMachineName.length - 1]);
            if (typeof sessionIndex === "number") {
              probeNumber = sessionIndex;
            }
          }

          return [timestamp, eventName, probeNumber];
        })
      : [];
  }

  getLocalEvents() {
    const chartSamplesStartTime = new Date(this.props.test.stat.setupStartTime).getTime();
    const events = this.props.test.events ? this.props.test.events.filter((e: any) => e.type === "local") : [];

    return events.length
      ? events.map((evt: any) => {
          const timestamp = (+evt.timestamp - chartSamplesStartTime) / 1000;
          const eventName = evt.event;

          return [timestamp, eventName];
        })
      : [];
  }

  getAdvancedAnalyticsLink = () => {
    const webrtcInternalsFile = "webrtc_internals_dump.txt";
    const getStatFile = "getstat_logs.json";
    const {
      isReport,
      test: { _id, blobBaseName, fileName },
      testRun: { runMode },
    } = this.props;

    const { assetsFileNames } = this.state;

    const getAdvancedAnalytics = () => {
      if (assetsFileNames.indexOf(webrtcInternalsFile) !== -1) {
        return webrtcInternalsFile;
      } else if (assetsFileNames.indexOf(getStatFile) !== -1) {
        return getStatFile;
      }
      return "";
    };

    const freeReportBasePath =
      window.location.href.indexOf("report/free") !== -1
        ? AppRoutes.WebrtcInternalsFreeAnalyzeRtc
        : AppRoutes.WebrtcInternalsAnalyzeRtc;

    if (isReport && blobBaseName) {
      return `${freeReportBasePath}/${_id}/${fileName}`;
    } else if (!isReport && getAdvancedAnalytics() !== "") {
      return `${AppRoutes.WebrtcInternals}/${_id}/${getAdvancedAnalytics()}/${runMode}`;
    } else {
      return "";
    }
  };

  render() {
    return (
      <View
        pushToFileNames={this.pushToFileNames}
        changePacketLMAudio={this.changePacketLMAudio}
        changePacketLMVideo={this.changePacketLMVideo}
        advancedAnalyticsLink={this.getAdvancedAnalyticsLink()}
        {...this.state}
      />
    );
  }
}

export default Charts;
