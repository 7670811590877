export const callSetupTimeColor = (value: number, callSetupThreshold: any) => {
  if (value > callSetupThreshold) {
    return "bad";
  }
  return "default";
};

export const higherIsBetterColor = (value: number | "N/A" | "-", thresholdArray: Array<number> | number): any => {
  if (value === "-") {
    return "default";
  }
  if (!value) {
    return "default";
  }

  if (Number(value) <= thresholdArray[0]) {
    return "bad";
  } else if (thresholdArray[0] < Number(value) && Number(value) <= thresholdArray[1]) {
    return "warning";
  } else {
    return "good";
  }
};

export const lowerIsBetterColor = (value: number | "N/A" | "-", thresholdArray: Array<number> | number): any => {
  // Dirty hack for now. Will fix later
  if (value === "-") {
    return "default";
  }
  if (value === 0) {
    return "good";
  }
  if (!value) {
    return "default";
  }
  if (Number(value) < thresholdArray[0]) {
    return "good";
  } else if (thresholdArray[0] <= Number(value) && Number(value) <= thresholdArray[1]) {
    return "warning";
  } else {
    return "bad";
  }
};
