// import { useState } from "react";
import { Card, Typography, Toolbar, Divider, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import ViewNames from "src/constants/GridViewNames";
import MultiSelectControl from "src/components/form-elements/MultiSelectControl";

const GridPresets = ({ classes }: ProjectPropertiesViewProps & ProjectPropertiesState) => {
  const options = Object.keys(ViewNames).map((x) => ({
    value: x,
    label: x,
  }));
  return (
    <Card className={classes.card} style={{ padding: 0 }}>
      <Toolbar>
        <Typography variant="subtitle1">Grid Presets</Typography>
      </Toolbar>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: 220 }}>Editable Presets</TableCell>
            <TableCell>
              <Field component={MultiSelectControl} name="editGridPresets" options={options} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default GridPresets;
