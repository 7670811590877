import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { axiosInstance } from "src/services/AxiosService";
import ApiPath from "src/constants/ApiPath";
import { isEmail } from "react-multi-email";

export default function SingleSignOnForm() {
  const [ssoDialogOpen, setSsoDialogOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState("");

  const handleEmailChange = (e: any) => {
    const text = e.target.value;
    if (text && error) {
      setError("");
    }
    setEmail(text);
  };

  const handleSubmit = async () => {
    if (!email) {
      setError("Please enter email");
      return;
    }

    if (!isEmail(email)) {
      setError("Please enter valid email");
      return;
    }

    const domain = email.split("@")[1]?.split(".")[0];
    const result = await axiosInstance.get(ApiPath.auth.loginSso + domain).catch((e) => e.response);

    if (result.status === 404) {
      setError("Domain not found");
      return;
    }

    if (result.status !== 200) {
      setError("SSO not configured");
      return;
    }

    location.assign(process.env.REACT_APP_API_PATH + result.data.url);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        style={{ textTransform: "none" }}
        onClick={() => setSsoDialogOpen(!ssoDialogOpen)}
      >
        Single Sign On
      </Button>
      <Dialog open={ssoDialogOpen} aria-labelledby="save-as-dialog" maxWidth="md">
        <div style={{ width: 600 }}>
          <DialogTitle>{"Single Sign On"}</DialogTitle>
          <DialogContent>
            <TextField
              id="email"
              label="Email"
              value={email}
              onChange={handleEmailChange}
              fullWidth={true}
              margin="normal"
              error={!!error}
              helperText={error}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSsoDialogOpen(false)} color="textSecondary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Sign In
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
