import * as React from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import { TestIterationContext, TestIterationState } from "./index";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Components from "./Components";
import { ReactSVG } from "react-svg";
import LoaderIcon from "../../assets/images/loading-progress.svg";
import { ErrorOutlineRounded as AlertIcon } from "@mui/icons-material";

import { Card, Typography } from "@mui/material";
import Button from "@mui/material/Button";

type StyledComponent = WithStyles<
  "circularProgress" | "detailsContainer" | "errorContainer" | "errorCard" | "errorRetry"
>;

type ViewProps = {
  retry?: any;
};

class View extends React.Component<StyledComponent & TestIterationState & ViewProps> {
  render() {
    const { classes, retry } = this.props;
    return (
      <TestIterationContext.Consumer>
        {({ test, testRun, isReport, testEvents, error, freeReport }) => {
          return (
            <React.Fragment>
              {error !== "" && (
                <div className={isReport || freeReport ? "" : classes.errorContainer}>
                  <Card className={classes.errorCard}>
                    <AlertIcon />
                    <Typography variant="h4">{error}</Typography>
                  </Card>
                  <Button variant="outlined" className={classes.errorRetry} onClick={() => retry && retry()}>
                    Retry
                  </Button>
                </div>
              )}
              {!test && error === "" && (
                <ReactSVG src={LoaderIcon} className={isReport || freeReport ? "" : classes.circularProgress} />
              )}
              {test && error === "" && (
                <Fade in={true}>
                  <Grid container={true} className={classes.detailsContainer}>
                    <Components test={test} testRun={testRun} isReport={isReport} testEvents={testEvents} />
                  </Grid>
                </Fade>
              )}
            </React.Fragment>
          );
        }}
      </TestIterationContext.Consumer>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    circularProgress: {
      position: "fixed",
      left: "calc(50% - 64px)",
      top: "calc(50% - 64px)",
    },
    errorContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: "50vh",
    },
    errorCard: {
      padding: 16,
      display: "flex",
      alignItems: "center",
      "& svg": {
        color: "rgb(68, 68, 68)",
      },
      "& h4": {
        color: "rgb(68, 68, 68)",
        fontSize: 16,
        marginLeft: 8,
      },
    },
    errorRetry: {
      marginTop: 8,
      padding: "8px 12px",
    },
    detailsContainer: {
      // maxWidth: 860,
      width: "100%",
      margin: "auto",
    },
  });

const decorate = withStyles(styles);

export default decorate(View);
