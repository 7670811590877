// tslint:disable
import { createStore, applyMiddleware, StoreEnhancer, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const devToolsExtension: StoreEnhancer<any> = window["devToolsExtension"]
  ? (window as any).devToolsExtension()
  : (f: any) => f;

// TODO: Check if we can replace redux completly by Apollo in the future
export default createStore(rootReducer, compose(applyMiddleware(thunk), devToolsExtension) as StoreEnhancer<any>);
