import { useEffect } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, ResponseType, AxiosError } from "axios";
import AuthService from "./AuthService";
import { isJSONString } from "../../src/helpers";

function authInterceptor(config: AxiosRequestConfig) {
  const finalConfig = { ...config };

  if (AuthService.loggedIn()) {
    const token = AuthService.getToken();

    if (!finalConfig.headers) {
      finalConfig.headers = {};
    }

    finalConfig.headers.Authorization = `Bearer ${token}`;
  }

  return finalConfig;
}

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_PATH}/`,
  withCredentials: true,
});

export const axiosExternalInstance = axios.create({
  baseURL: `${process.env.REACT_APP_EXTERNAL_API_PATH}/`,
});

instance.interceptors.request.use(authInterceptor);

export const axiosInstance = instance;

export const isAxiosError = <ErrorData = any>(payload: any): payload is AxiosError<ErrorData> =>
  axios.isAxiosError(payload);

export const CancelSignal = axios.CancelToken.source;

/**
 * Method checks axios error for cancellation event.
 *
 * @returns {boolean}
 */
export const isCancelSignal = (e: any) => axios.isCancel(e);

/**
 * Hook return axios instance with cancel token.<br/><br/>
 * Will automatically send signal, when component unmount, to cancel all pending requests, if there are some.<br/><br/>
 * In case of cancellation use <b>try/catch</b> to catch error and check is with <b>isCancelSignal(error)</b> to handle properly.
 * @returns {AxiosInstance}
 */
export const useAxios = () => {
  const signal = CancelSignal();

  useEffect(() => {
    return () => {
      signal.cancel("Unmount useAxios");
    };
  }, []);

  return { axios: axiosInstance, cancelToken: signal.token };
};

export const useAxiosExternal = () => {
  const signal = CancelSignal();

  useEffect(() => {
    return () => {
      signal.cancel("Unmount useAxiosExternal");
    };
  }, []);

  return { axiosExternal: axiosExternalInstance, cancelTokenExternal: signal.token };
};

export const inQueryFlag = "inquery";

export type FileResponse = {
  name: string;
  format: string;
  content: any;
  download: boolean;
};

const parseAxiosRes = (res: AxiosResponse<ResponseType>): FileResponse | any => {
  const { headers, data } = res;
  const isContentDisposition = !!headers["content-disposition"];
  const file: FileResponse = {
    name: "",
    format: "",
    content: "",
    download: false,
  };

  if (isContentDisposition) {
    const headerLine: string = headers["content-disposition"];
    const nameAndFormat = headerLine.replace(/[\w; ]+filename=/g, "").split(".");
    file.name = nameAndFormat[0];
    file.format = nameAndFormat[1];
    file.content = data;
    file.download = true;
    return file;
  } else {
    const isJson = isJSONString(data);
    if (isJson) {
      const parsedData = JSON.parse(data);
      const isFile = !!parsedData.name;
      if (isFile) {
        file.name = parsedData.name;
        file.format = parsedData.format;
        file.content = parsedData.content;
        file.download = parsedData.download;
        return file;
      } else {
        return parsedData;
      }
    } else {
      return data;
    }
  }
};

export { parseAxiosRes };
