// tslint:disable no-any
import * as React from "react";

export const asyncComponent = (importComponent: any): any => {
  class AsyncComponent extends React.Component<any, any> {
    constructor(props: any) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      window.scrollTo(0, 0);
      const { default: component } = await importComponent();

      this.setState({
        component: component,
      });
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
};

export default asyncComponent;
