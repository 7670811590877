import { useContext } from "react";
import * as moment from "moment";
import { ProjectsContext } from "../../AppController";
import { Theme, useTheme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { AccountingTypes } from "../../Admin/ProjectProperties";
import { RouteComponentProps, withRouter } from "react-router";
import { ErrorOutlineRounded as AlertIcon } from "@mui/icons-material";
import { ExternalLinks } from "../../../constants/routes/app-routes";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      textDecoration: "none",
      marginBottom: 8,
      padding: "8px 16px",
      borderRadius: _theme.shape.borderRadius,
      boxShadow: _theme.shadows[1],
      animation: ".5s 3s bounce cubic-bezier(0.18, 0.89, 0.32, 1.28)",
    },
    alert: {
      fontSize: 14,
      fontWeight: 400,
      padding: 0,
      margin: 0,
      userSelect: "none",
    },
    link: {
      color: "white",
      fontWeight: "bold",
    },
  })
);

const AlertToolbar = ({ location }: RouteComponentProps): JSX.Element | null => {
  const { selectedProject } = useContext(ProjectsContext);

  const classes = useStyles();
  const { palette } = useTheme();

  const isEvaluation = !!selectedProject && selectedProject.accountType === AccountingTypes.evaluation;
  const isSuspended = !!selectedProject && selectedProject.accountType === AccountingTypes.suspended;
  const isAdminRoute = location.pathname.includes("/app/admin");
  let isEvaluated = false;
  const expired = !!selectedProject && new Date(selectedProject.paymentExpiry).getTime() < Date.now() && selectedProject.accountType !== AccountingTypes.customer;

  if (!selectedProject || (!expired && !isSuspended) || isAdminRoute) {
    return null;
  }

  const getAlertMessage = (project: any): JSX.Element => {
    if (!project) {
      return <></>;
    }

    const left = (project.max_EvaluationTestRunCount || 100) - (project.stat_testRunCount || 0);

    if (expired || (isEvaluation && (isNaN(left) || left <= 0))) {
      isEvaluated = true;
      return (
        <>
          Your account has expired. Please contact{" "}
          <a href={ExternalLinks.RenewalRequest} className={classes.link}>
            support
          </a>{" "}
          if you wish to renew it.
        </>
      );
    } else if (isEvaluation && !expired && left > 0) {
      isEvaluated = false;
      return (
        <>
          Evaluation account valid until {moment(project.paymentExpiry).format("DD MMM YYYY")}. You have <b>{left}</b>{" "}
          more tests.
        </>
      );
    } else if (isSuspended) {
      return <>Your account is suspended. Please contact support.</>;
    }
    return <></>;
  };

  const alertMessage = getAlertMessage(selectedProject);

  const backgroundColor = isEvaluation && !isEvaluated ? "#d5f2ff" : palette.error.main;
  const color = isEvaluation && !isEvaluated ? "#444444" : "#ffffff";

  return (
    <div className={classes.wrapper} style={{ backgroundColor }}>
      <h2 className={classes.alert} style={{ color }}>
        {alertMessage}
      </h2>
      <AlertIcon style={{ color }} />
    </div>
  );
};

export default withRouter(AlertToolbar);
