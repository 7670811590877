import ICECandidatesTablesLegacy from "./ICECandidatesTablesLegacy";
import ICECandidatesTablesNew from "./ICECandidatesTablesNew";
import { Channel, IICECandidatesTable } from "./Interfaces";

export interface IICECandidatesTablesProps {
  stats: any;
}

const deduceDTLSStateFromPairState = (pairStateValue: string) => {
  switch (pairStateValue) {
    case "failed":
      return "failed";
    case "succeeded":
      return "connected";
    case "frozen":
    case "in-progress":
    case "waiting":
    default:
      return "connecting";
  }
};

const getReportProperty = (keyName: string, stats: object, property: string): string => {
  const typeKey = Object.keys(stats)
    .filter((key) => key.startsWith(keyName))
    .find((key) => key.includes(property));
  return typeKey && stats[typeKey].values ? JSON.parse(stats[typeKey].values)[0] : null;
};

const ICECandidatesTables = ({ stats }: IICECandidatesTablesProps) => {
  const convertToLegacyArray = (obj: any) => {
    const result = Object.keys(obj).map((key) => {
      return obj[key];
    });
    return result;
  };

  const getHigherStateReached = (values: string[]): string => {
    if (values.includes("succeeded")) {
      return "succeeded";
    }
    return values[values.length - 1];
  };

  const convertToStandardArray = (allStats: any) => {
    const transports = {};
    const pairs = {};
    const candidates = {};
    let t;
    let name: string;
    let reportname;
    let comp;
    // tslint:disable-next-line: forin

    for (reportname in allStats) {
      // special casing of computed stats, in particular [a-b]
      if (reportname.indexOf("[") !== -1) {
        t = reportname.split("[");
        comp = "[" + t.pop();
        name = t.join("");
        name = name.substr(0, name.length - 1);
      } else {
        t = reportname.split("-");
        comp = t.pop();
        name = t.join("-");
      }

      const inStat = allStats[reportname];

      let type = getReportProperty(name, allStats, "type");

      // Get type when in Chrome-internals (Chrome >= M105)
      if (!type && inStat.statsType) {
        type = inStat.statsType;
      }

      // Get type when in Chrome-internals (Chrome < M105)
      if (!type) {
        if (name.match(/RTCPeerConnection/)) {
          type = "peer-connection";
        } else if (name.match(/RTCDataChannel/)) {
          type = "data-channel";
        } else if (name.match(/RTCAudioSource/) || name.match(/RTCVideoSource/)) {
          type = "media-source";
        } else if (name.match(/RTCTransport/)) {
          type = "transport";
        } else if (name.match(/RTCCertificate/)) {
          type = "certificate";
        } else if (name.match(/RTCIceCandidatePair/)) {
          type = "candidate-pair";
        } else if (name.match(/RTCIceCandidate/)) {
          const isRemote = getReportProperty(name, allStats, "isRemote");
          if (isRemote) {
            type = "remote-candidate";
          } else {
            type = "local-candidate";
          }
        } else if (name.match(/MediaStreamTrack/)) {
          type = "track";
        } else if (name.match(/RTCCodec/)) {
          type = "codec";
        } else if (name.match(/RTCOutboundRTPAudioStream/) || name.match(/RTCOutboundRTPVideoStream/)) {
          type = "outbound-rtp";
        } else if (name.match(/RTCInboundRTPAudioStream/) || name.match(/RTCInboundRTPVideoStream/)) {
          type = "inbound-rtp";
        } else if (name.match(/RTCRemoteInboundRtpAudioStream/) || name.match(/RTCRemoteInboundRtpVideoStream/)) {
          type = "remote-inbound-rtp";
        } else if (name.match(/RTCRemoteOutboundRTPAudioStream/) || name.match(/RTCRemoteOutboundRTPVideoStream/)) {
          type = "remote-outbound-rtp";
        }
      }

      const stats = JSON.parse(allStats[reportname].values);
      if (type === "transport") {
        if (!transports[name]) {
          transports[name] = {};
        }
        switch (comp) {
          case "bytesSent":
          case "bytesReceived":
            // To avoid having 0 when the last value of the array is 0 whereas value was different than 0 before
            const statsFiltered = stats.filter((value: number) => value > 0);
            transports[name][comp] = statsFiltered.length > 0 ? statsFiltered[statsFiltered.length - 1] : 0;
            break;
          case "dtlsState":
          case "selectedCandidatePairId":
            transports[name][comp] = stats[stats.length - 1];
            break;
          default:
          // console.log(reportname, comp, stats);
        }
      } else if (type === "candidate-pair") {
        if (!pairs[name]) {
          pairs[name] = {};
        }
        switch (comp) {
          case "state":
            pairs[name][comp] = getHigherStateReached(stats);
            break;
          default:
            pairs[name][comp] = stats[stats.length - 1];
        }
      } else if (type === "local-candidate" || type === "remote-candidate") {
        if (!candidates[name]) {
          candidates[name] = {};
        }
        candidates[name][comp] = stats[stats.length - 1];
      }
    }

    // patch when there is no RTCTransportStats report, create a fake one
    let transportIndex = 1;
    for (const pairName in pairs) {
      if (
        ("selected" in pairs[pairName] && pairs[pairName].selected) || // Firefox case
        ("activeCandidatePair" in pairs[pairName] && pairs[pairName].activeCandidatePair) // Twilio Android case
      ) {
        if (!transports[pairs[pairName].transportId]) {
          let transportId = pairs[pairName].transportId;
          if (!transportId) {
            transportId = `T${transportIndex < 10 ? "0" : ""}${transportIndex}`;
            pairs[pairName].transportId = transportId;
          }
          transports[transportId] = {
            id: transportId,
            selectedCandidatePairId: pairName,
            dtlsState: deduceDTLSStateFromPairState(pairs[pairName].state),
            bytesSent: pairs[pairName].bytesSent || 0,
            bytesReceived: pairs[pairName].bytesReceived || 0,
          };
        }
      }
      transportIndex++;
    }

    const tables: Array<any> = [];
    // tslint:disable-next-line: forin no-shadowed-variable
    for (const t in transports) {
      const table: IICECandidatesTable = {
        name: t,
        bytesSent: transports[t].bytesSent || 0,
        bytesReceived: transports[t].bytesReceived || 0,
        dtlsState: transports[t].dtlsState,
        selectedCandidatePairId: transports[t].selectedCandidatePairId,
        channels: [],
      };
      const sortable = [];
      // tslint:disable-next-line: forin
      for (const p in pairs) {
        sortable.push([p, pairs[p]]);
      }

      sortable.sort((a: any, b: any) => {
        if (a[1].state === "succeeded" && ["in-progress", "waiting", "failed", "frozen"].includes(b[1].state)) {
          return -1;
        }

        if (a[1].state === b[1].state) {
          return b[1].priority - a[1].priority;
        }

        return 1;
      });

      const sortedPairs = {};
      sortable.forEach((item: any) => {
        sortedPairs[item[0]] = item[1];
      });

      for (const p in sortedPairs) {
        if (pairs[p].transportId !== t) {
          continue;
        }
        const pair = pairs[p];
        const localCandidate =
          candidates[pair.localCandidateId] || candidates[`local-candidate_${pair.localCandidateId}`] || {};
        const remoteCandidate =
          candidates[pair.remoteCandidateId] || candidates[`remote-candidate_${pair.remoteCandidateId}`] || {};
        const channel: Channel = {
          state: pair.state,
          bytesSent: pair.bytesSent || 0,
          bytesReceived: pair.bytesReceived || 0,
          localType: localCandidate.candidateType,
          remoteType: remoteCandidate.candidateType,
          localProtocol: localCandidate.protocol,
          remoteProtocol: remoteCandidate.protocol,
          localRelayProtocol: localCandidate.relayProtocol,
          remoteRelayProtocol: remoteCandidate.relayProtocol,
          localPort: localCandidate.port,
          remotePort: remoteCandidate.port,

          localIdName: pair.localCandidateId,
          remoteIdName: pair.remoteCandidateId,
          localIpAddress: localCandidate.address || localCandidate.ip,
          remoteIpAddress: remoteCandidate.address || remoteCandidate.ip,
          pairIdName: p,
          rtt: pair.responsesReceived ? pair.totalRoundTripTime / pair.responsesReceived : pair.currentRoundTripTime,
          priority: pair.priority,
        };
        table.channels.push(channel);
      }
      tables.push(table);
    }
    return tables;
  };

  const filterStats = (stats: Stats) => {
    const stun = {};
    let reportname;
    for (reportname in stats) {
      let comp, st;
      if (reportname.indexOf("Conn-") === 0) {
        // special casing of computed stats, in particular [a-b]
        if (reportname.indexOf("[") !== -1) {
          const t = reportname.split("[");
          comp = "[" + t.pop();
          st = t.join("");
          st = st.substr(0, st.length - 1);
        } else {
          const t = reportname.split("-");
          comp = t.pop();
          st = t.join("-");
        }

        if (!stun[st]) {
          stun[st] = {};
        }

        const filtredStats = JSON.parse(stats[reportname] && stats[reportname].values);
        switch (comp) {
          case "requestsSent":
          case "consentRequestsSent":
          case "responsesSent":
          case "requestsReceived":
          case "responsesReceived":
          case "localCandidateId":
          case "remoteCandidateId":
          case "googLocalAddress":
          case "googRemoteAddress":
          case "googLocalCandidateType":
          case "googRemoteCandidateType":
          case "googActiveConnection":
            stun[st][comp] = filtredStats[filtredStats.length - 1];
            break;
          default:
        }
      }
    }

    return stun;
  };

  let legacyGrid = true;
  let dataList = [];
  const statsResult = filterStats(stats);
  if (Object.keys(statsResult).length === 0) {
    legacyGrid = false;
    dataList = convertToStandardArray(stats);
  } else {
    dataList = convertToLegacyArray(statsResult);
  }

  if (!dataList.length) {
    return null;
  }

  return (
    <>
      {legacyGrid && <ICECandidatesTablesLegacy data={dataList} />}
      {!legacyGrid && <ICECandidatesTablesNew data={dataList} />}
    </>
  );
};

export default ICECandidatesTables;
